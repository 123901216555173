import React, { useEffect } from 'react';
import './SelectTimeSlote.scss';
import DropDown from '../Select/';
import Label from '../Label/';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";



function SelectTimeSlote({ slot, slotDate, setSelforRelative, setSlotTime, type, defaultVal, category, slotTimes }) {
    const [slotsection, setSlotSection] = React.useState("");
    const [slotDay, setSlotDay] = React.useState("");
    const patient = useSelector(state => state.patients)
    const [patientList, setPatientList] = React.useState([]);
    let history = useHistory();
    const [selectDate, setSelectedDate] = React.useState("")
    const [selectTime, setSelectedTime] = React.useState("")
    const [selectDaY, setSelectedDay] = React.useState("")

    const slotvaluesfromhis = history.location.state && history.location.state.selectedData !== undefined && history.location.state.selectedData !== "" ? history.location.state.selectedData : ''

    useEffect(() => {

        if (slotvaluesfromhis && slotvaluesfromhis !== undefined && slotvaluesfromhis !== '') {

            setSelectedDate(slotvaluesfromhis.label.split('|')[0])
            setSelectedTime(slotvaluesfromhis.label.split('|')[1])

            if (slotvaluesfromhis.label.includes("PM")) {
                let valDay = slotvaluesfromhis.label.split(' ')
                valDay = valDay[4].slice(0, -3)
                if (parseInt(valDay) === 12) {
                    setSelectedDay("Afternoon")
                } else if (parseInt(valDay) < 4) {
                    setSelectedDay("Afternoon")
                } else {
                    setSelectedDay("Evening")
                }
            } else if (slotvaluesfromhis.label.includes("AM")) {
                setSelectedDay("Morning")
            }


        }
        if (defaultVal) {
            setSelectedDate(defaultVal[0].date)
            setSelectedTime(defaultVal[0].label)
            if (defaultVal[0].label.includes("PM")) {
                setSelectedDay("Evening")
            } else if (defaultVal[0].label.includes("AM")) {
                setSelectedDay("Morning")
            }

        }
    }, [defaultVal])


    const TimeManage = (time) => {
        let afternoon = ["12", "1", "2", "3", "4"];
        if (slotsection.value == "Morning") {
            return time.filter((e) => {
                let split = e.label.split("-");
                let start = split[0].trim()
                return start.toLowerCase().indexOf("am") > -1 && (slotDay == "" ? true : e.date == slotDay.label)
            })
        }
        else if (slotsection.value == "Afternoon") {
            return time.filter((e) => {
                let split = e.label.split("-");
                let start = split[0].trim()
                let timehour = start.split(":");
                timehour = timehour[0];
                return start.toLowerCase().indexOf("pm") > -1 && afternoon.indexOf(timehour) > -1 && (slotDay == "" ? true : e.date == slotDay.label)
            })
        }
        else if (slotsection.value == "Evening") {
            return time.filter((e) => {
                let split = e.label.split("-");
                let start = split[0].trim()
                let timehour = start.split(":");
                timehour = timehour[0];
                return start.toLowerCase().indexOf("pm") > -1 && afternoon.indexOf(timehour) == -1 && (slotDay == "" ? true : e.date == slotDay.label)
            })
        }
        else {
            return []
        }
    }
    React.useEffect(() => {
        if (patient && patient.data) {
            let res = patient.data.map((e, i) => { return { label: e.full_name, value: e.uuid } })
            setPatientList(res)
        }

    }, [patient])

    const handleSection = (val, type) => {
        setSelectedDay("");
        setSelectedTime("");

        if (type == "day") {
            setSlotTime("")
            setSlotSection("")
            setSlotDay(val)
        }
        else if (type == "time") {
            setSlotTime("")
            setSlotSection(val)

        }
        else if (type == "slot") {
            setSlotTime(val)
        }
    }
    useEffect(() => {
        console.log(slotTimes, 'slotTimes')
    }, [slotTimes])
    return (
        <div className={type == "sadn" ? "SadnSelectTimeSlote" : type === "specialist" ?
            "SelectTimeSlotSpecial" : "SelectTimeSlote"}>

            {type === "specialist" && <>
                <Label text="Selected Time Slot" />
                <div className="dropHolder">
                    <div className="dropWrap">
                        <DropDown
                            placeholder={"Day"}
                            value={selectDate}
                            setValue={(value) => handleSection(value, 'day')}
                            options={slot} />
                    </div>
                    <div className="dropWrap">
                        <DropDown placeholder={"Time Period"} value={""}
                            setValue={(value) => handleSection(value, 'time')}
                            value={selectDaY !== "" ? selectDaY : slotsection}
                            options={slot.length > 0 ? [
                                { label: 'Morning', value: 'Morning', hidden: true },
                                { label: 'Afternoon', value: 'Afternoon', },
                                { label: 'Evening', value: 'Evening' },
                            ] : []}

                        />
                    </div>
                    <div className="dropWrap">
                        <DropDown
                            placeholder={"Time Slots"}
                            value={selectTime !== "" ? selectTime : slotTimes !== "" ? slotTimes : ""}
                            setValue={(value) => handleSection(value, 'slot')}
                            options={TimeManage(slotDate)} />
                    </div>
                </div>
            </>}
            {!type && <>
                <Label text="Selected Time Slot" />
                <div className="dropHolder">
                    <div className="dropWrap">
                        <DropDown placeholder={"Day"}
                            value={""}
                            setValue={(value) => setSlotDay(value)}
                            options={slot} />
                    </div>
                    <div className="dropWrap">
                        <DropDown placeholder={"Time Period"} value={""}
                            setValue={(value) => setSlotSection(value)}
                            options={slot.length > 0 ? [
                                { label: 'Morning', value: 'Morning', hidden: true },
                                { label: 'Afternoon', value: 'Afternoon', },
                                { label: 'Evening', value: 'Evening' },
                            ] : []} />
                    </div>
                    <div className="dropWrap">
                        <DropDown placeholder={"Time Slot"} value={""} setValue={(value) => setSlotTime(value)} options={TimeManage(slotDate)} />
                    </div>
                </div>
            </>}
            {type !== 'specialist' ?
                <>
                    <Label text="Select Self or Relatives" />

                    <div>
                        <DropDown
                            placeholder={"Please select"} value={""}
                            setValue={(value) => setSelforRelative(value)}
                            options={patientList} />
                    </div>

                    <div className="button" onClick={() => history.push('/addRelative')}>
                        Add Relative
                    </div></> : ''}

        </div>
    )
}

export default SelectTimeSlote
