/**
 * 
 * Filters Form
 * 
 */


import React from 'react';
import ImplementationFields from '../../utils/Fields';
import { Container, Row, Col } from 'react-bootstrap';
import './style.scss';

/**
 * 
 * @param {object} props 
 * @returns 
 */
export default function FiltersForm(props) {

    const { initialValues = {}, fields, onChange, className, style = {}, handleSubmit, metaData = {}, handleClear } = props;

    return (
        <Row className={`FilterForm ${className}`} style={style}>
            <Col xs={12}>
                <Row>
                    <Col className="form-label" xs={12}>Filter By</Col>
                </Row>
                <Row style={{ alignItems: 'center' }}>
                    {(fields || []).map((field, index) => {
                        const Input = ImplementationFields[field.type];
                        return <Col className={field.className || null} key={index} sm={6} md={6} lg style={field.style || {}}>
                            <label className="field-label">{field.label}</label>
                            <Input onChange={onChange} metaData={metaData} {...field} value={initialValues && initialValues[field.name] || false} />
                        </Col>
                    })}
                    <Col sm={6} md={6} lg className="field-button" onClick={handleSubmit} style={{ marginRight: '10px' }}>
                        APPLY FILTER
                    </Col>
                    <Col sm={6} md={6} lg className="field-button" onClick={handleClear} style={{ marginRight: '15px' }} >
                        CLEAR FILTER
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

