import React, { useEffect } from 'react';
import './ReasonForVisit.scss';
import DropDown from '../Select';
import Label from '../Label';
import { symptomsData } from '../../utils/constants'
import { propTypes } from 'react-bootstrap/esm/Image';

function ReasonForVisit(props) {
    const { setsymptom, setNotes, type } = props;
    useEffect(() => {
        if (props.types && props.types !== "" && props.reason !== "") {
            setsymptom(props.reason)
        }
    }, [])
    return (
        <div className={type == "sadn" ? "SadnReasonForVisit" : "ReasonForVisit"}
            id={type === "specialist" ? "reasonSpecial" : ''}>
            <Label text="Reason(s) for Visit" />
            <div>
                <DropDown placeholder={"Please Select"} value={props.symptom} setValue={(value) => setsymptom(value)} options={symptomsData} />
            </div>
            <Label text="Additional information" />
            <textarea name="reason_for_visit" onChange={e => setNotes(e.target.value)} className={"input"} placeholder="Please mention any information that will be useful for your doctor and upload any important documents before the call."></textarea>
        </div>
    )
}

export default ReasonForVisit