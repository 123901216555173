import React, { Fragment, useEffect, useState } from 'react'
import DashboardLayout from '../../layout/DashboardLayout'
import './Appointment.scss'
import { TitleWithNav, DoctorProfile, BookingDetails, Symptoms, VideoCard, HealthRecords, Chaperone, Button, AppointmentTipsTwo, ImportantNote, CautionDialogBox } from "../../components"
import moment from 'moment'
import { useHistory } from "react-router-dom";
import axios from '../../redux/axios';
import { api } from '../../utils/constants';
import { useSelector } from "react-redux";
import FailurePdf from '../Healthrecords/components/Healthrecords/tabs/prescription/Failurepdf.js';
import { Dialog } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import Close from "../../assets/images/close.svg";
import LabReportView from './LabReportView'

import * as RNLocalize from "react-native-localize";
import momenttz from "moment-timezone";

function AppointmentDetail() {
    let history = useHistory();
    const userdata = useSelector(state => state.auth);
    const data = history.location.data;
    const [healthRecords, setHealthRecords] = useState([])
    const [reshedule, setReshedule] = useState(false)
    const [cancel, setCancel] = useState(false)
    const [labTestView, setLabTestView] = useState(false)
    const [successCancel, setSuccessCancel] = useState(false)
    const [cancelRefund, setCancelRefund] = useState(false)
    const [next, setNext] = useState(data);
    const [update, setUpdate] = useState(false);
    const [openpdf, setOpenPdf] = useState(false);
    const [closepdf, setClosePdf] = useState(false);
    const [loader, setLoader] = useState(false);
    const [reportId, setReportId] = useState('');
    const [testInfo, setTestInfo] = useState();

    const [amount, setAmount] = useState()
    function strikeThrough(text) {
        return text
            .split('')
            .map(char => char + '\u0336')
            .join('')
    }

    useEffect(() => {
        if (!history.location.data) {
            history.push("/appointments")
        }

    }, [])

    useEffect(() => {
        if (data)
            axios.get(api.healthDetails + data.uuid).then((res) => {
                if (res.data) {
                    setHealthRecords(res.data)
                    // console.log(res.data)
                }
            }).catch((e) => {
                console.log(e)
            })
    }, [])
    useEffect(() => {
        let repeat;

        const fetchData = async () => {
            try {
                Next();
                repeat = setTimeout(fetchData, 60000); // request again after a minute

            } catch (error) {

            }
        }

        fetchData();

        return () => {
            if (repeat) {
                clearTimeout(repeat);
            }
        }
    }, []);



    const handleClickdetails = () => {
        if (data) {
            history.push({
                pathname: '/book_specialist',
                state: {
                    doctordata: data,
                }
            })

        } else {
            // setError(true)
        }


    }
    const Next = () => {
        setNext(data)
        setUpdate(!update)
    }
    const status = data && data.status ?
        data.status.charAt(0).toUpperCase() + data.status.slice(1) : ""


    const handleUpdateMed = () => {
        const Checkrel = data.patient
        if (Checkrel.relationship !== undefined && Checkrel.relationship !== null) {
            history.push({
                pathname: '/profile',
                state: {
                    prof: "medUpdate",
                    uuid: Checkrel.uuid
                },
            })

            document.querySelector('#root').scrollIntoView({
                behavior: 'smooth'
            }, 500)
        } else {
            history.push({
                pathname: '/relativeProfile',
                state: {
                    prof: "medUpdate",
                    uuid: Checkrel.uuid
                },
            })
            document.querySelector('#root').scrollIntoView({
                behavior: 'smooth'
            }, 500)
        }

    }

    const handleCancel = () => {
        setCancel(false)
        try {
            axios.get(api.appointmentall + "/cancel/" + data.uuid, { headers: { 'x-auth-token': userdata.token } })
                .then(res => {


                }).catch(err => {
                    console.log(err)


                })
            // axios.put(api.appointmentall + "/" + data.uuid + "/cancelled", {}, { headers: { 'x-auth-token': userdata.token } })
            // .then(res2 => {
            //     setSuccessCancel(true)
            // }).catch(err => {
            //     console.log(err)
            // })
        }
        catch (err) {

        }
    }


    const fetchTestInfo = async (testId) => {
        try {
            let response = await axios.get(`${api.recommendedTestPreview}/${testId}`, { headers: { 'x-auth-token': userdata.token } })
            setTestInfo(response && response.data && response.data.data)
            setLabTestView(true)
            setLoader(false)
        } catch (error) {
            console.log(error.response)
            setLoader(false)
        }

    }

    const Reschedule = () => {
        setReshedule(true);
    }
    const handleView = (type, id) => {
        setLoader(true)
        const caseid = id;
        let dt;
        let url;

        if (type == "Ep_lab_detail") {
            fetchTestInfo(id)
        } else {
            async function getpdf(id) {

                try {

                    if (type == "medicine") {
                        await axios.get(api.viewpres + id)
                            .then((res) => {
                                if (res.data) {
                                    dt = res.data

                                }
                            });
                    }
                    // else if (type == "Ep_lab_detail") {
                    //     console.log("THIS IS REQUIRD")
                    //     await axios.get(api.viewlab + id)
                    //         .then((res) => {
                    //             if (res.data) {
                    //                 dt = res.data

                    //             }
                    //         });
                    // }
                    // else if (type == "doctor_case_notes" || type == "doctorCase") {
                    //     await axios.get(api.caseNotePreview + id, { headers: { 'x-auth-token': userdata.token } })
                    //         .then((res) => {
                    //             if (res.data) {
                    //                 dt = res.data

                    //             }
                    //         });
                    // }
                    else if (type == "psychiatryCase") {
                        await axios.get(api.pysNotePreview + id, { headers: { 'x-auth-token': userdata.token } })
                            .then((res) => {
                                if (res.data) {
                                    dt = res.data
                                }
                            });
                    }
                    handlePdfOpen();
                    await fetch("data:application/pdf;base64," + dt)
                        .then(res => res.blob())
                        .then(blob => {
                            url = window.URL.createObjectURL(blob);
                        });
                    const iframe = document.querySelector('#pdf')
                    iframe.setAttribute('src', url)
                    setLoader(false)

                } catch (error) {
                    failurePdfOpen();
                    setLoader(false)
                }

            }
            getpdf(caseid);
        }

    }


    const handlePdfOpen = () => {
        setOpenPdf(true);
    };
    const handlePdfClose = () => {
        setOpenPdf(false);
    };
    const failurePdfOpen = () => {
        setClosePdf(true);
    }
    const failurePdfClose = () => {
        setClosePdf(false);
    }
    const navmed = () => {

    }


    useEffect(() => {
        axios.get(api.clinic_by_doctor + "/all", {
          headers: { "x-auth-token": userdata.token },
        })
    
          .then((r) => {
            const deviceTimeZone = RNLocalize.getTimeZone();
            const today = momenttz().tz(deviceTimeZone);
            let dataval = {
              booking_date: today.utc().format(),
              clinic_uuid: r.data.uuid,
            };
    
            axios.post(api.appointment + "sadn/waiting", dataval, {
              headers: { "x-auth-token": userdata.token },
            })
              .then((r) => {
                if (r.data) {
                //   setAmount(r.data.amount);
    
                } else {
                  console.log(r, "ressss")
                }
              })
              .catch((err) => {
                console.log(err, "err")
              });
          })
          .catch((err) => {
            if (err && err.response) {
    
              setTimeout(() => {
    
              }, 100);
            }
          });
      }, []);
    
      useEffect(() => {
        consultAmountfee()
      })
        const consultAmountfee = () => {
          axios.get(api.appointment + "sadn/booking/amount")
          .then((res) => {
            setAmount(res.data.amount)
          })
          .catch((err) => {
            console.log(err)
          })
        }
    return (
        <>
            <Dialog disableBackdropClick style={{ borderRadius: '0px' }}
                fullScreen
                maxWidth={"xl"}
                fullWidth={true}
                open={openpdf}
                scroll={"body"}
                onClose={handlePdfClose}>
                <div className="ContainerWrapper">
                    <img src={Close} alt="Close" className="Closepopup" onClick={() => handlePdfClose()} />
                    <DialogContent>
                        <iframe src="" className="pdfiframe" id="pdf" ></iframe>
                    </DialogContent>
                </div>
            </Dialog>
            <Dialog disableBackdropClick
                maxWidth={"sm"}
                open={closepdf}
                scroll={"body"}
                onClose={failurePdfClose}>
                <div className="ContainerWrapper">
                    <img src={Close} alt="Close" className="Closepopup" onClick={() => failurePdfClose()} />
                    <FailurePdf text={'Something Went Wrong!'} subtext={'Please Contact admin'} />
                </div>
            </Dialog>
            <DashboardLayout loadin={loader} load={setLoader}>
                {data && <div className="appointmentDetails">
                    <TitleWithNav title={"Home Appointment"} />
                    <div className="appointwrapper">
                        <div>
                            <DoctorProfile type={false} data={data} />
                            {data != undefined && data.local_doctor != undefined && data.local_doctor.length > 0 && <Chaperone data={data.local_doctor[0]} />}
                            <div style={{ height: 22 }} />
                            {status === 'Booked' && data.zoomData && <VideoCard next={data} />}
                        </div>
                        <div>
                            <BookingDetails data={status == "Booked" ? [{
                                title: "Date", value: moment(data.booking_date).format("ddd DD MMM YYYY")
                            }, {
                                title: "Time", value: moment(data.start_time).format("LT")
                            },
                            {
                                title: "ID", value: data.uuid
                            }, {
                                title: "Status", value: status
                            },
                            {
                                title: "Type", value: data.type == "sadn" ? `See a Doctor Now\n ₦${amount}` : data.type == "free" ? "Book a FREE Appointment" : data.type == "international" || data.type == "Find a doctor" || data.type == "normal" ? "Find a Doctor / Specialist (International & Nigerian)" : ""
                            }, {
                                title: "", value: ""
                            }] : status == "Followup" || status == "Closed" ? [{
                                title: "Date", value: moment(data.booking_date).format("ddd DD MMM YYYY")
                            }, {
                                title: "Time", value: moment(data.start_time).format("LT")

                            },
                            {
                                title: "Status", value: status
                            }, {
                                title: "Appointment ID", value: data.uuid
                            },
                            {
                                title: "Appointment Type", value: data.type == "sadn" ? `See a Doctor Now\n ₦${amount}` : data.type == "normal" || data.type == "international" || data.type == "Find a doctor" ? "Find a Doctor / Specialist (International & Nigerian)" : "Book a FREE Appointment"
                            },
                            {
                                title: "Follow-up Appointment", value: data.followup_date ? moment(data.followup_date).format("DD MMM YYYY") : "No"
                            }

                            ] : [{
                                title: "Date", value: moment(data.booking_date).format("ddd DD MMM YYYY")
                            }, {
                                title: "Time", value: moment(data.start_time).format("LT")

                            },
                            {
                                title: "Status", value: status
                            }, {
                                title: "Appointment ID", value: data.uuid
                            },
                            {
                                title: "Appointment Type", value: data.type == "sadn" ? `See a Doctor Now\n ₦${amount}` : data.type == "normal" || data.type == "international" || data.type == "Find a doctor" ? "Find a Doctor / Specialist (International & Nigerian)" : "Book a FREE Appointment"
                            }
                                , {
                                title: "", value: ""
                            }
                            ]}
                            />
                            <Symptoms data={[
                                { title: "Symptoms Presented", value: data.reasons && data.reasons.length > 0 ? typeof data.reasons[0] == "object" ? data.reasons[0].value : data.reasons[0] : "" },
                                { title: "Additional Notes Provided", value: data.desc ? data.desc : "" }
                            ]} />

                            {healthRecords && healthRecords.length > 0 &&
                                <HealthRecords data={healthRecords} handleview={handleView} />}
                            {
                                data.status === "extended" ?
                                    <>
                                        <div className="extendedNote">
                                            <img src={require('../../assets/images/caution2.svg').default} alt="" />
                                            <div className="extendedNoteChild">
                                                <h3>
                                                    Important Note
                                                </h3>
                                                <p>
                                                    Please be reminded that your extended appointment period will expire on {moment(data.extended_date).format('MMMM DD, YYYY')}.
                                                </p>
                                            </div>
                                        </div>
                                        <button className={data != undefined && data.extended_date != undefined &&
                                            moment(moment().format("YYYY-MM-DD")) <= moment(moment(data.extended_date).format("YYYY-MM-DD")) ?
                                            "extendedBtn" : "disableBtn"} onClick={() => handleClickdetails()}>Book Extended Appointment</button>

                                    </> : ''
                            }
                            {data.status === "followup" ?
                                <button className={data != undefined && data.followup_date != undefined &&
                                    moment(moment().format("YYYY-MM-DD")) <= moment(moment(data.followup_date).format("YYYY-MM-DD")) ?
                                    "extendedBtn" : "disableBtn"} onClick={() => handleClickdetails()} style={{ minWidth: '184px' }}>BOOK FOLLOW-UP</button>
                                : ''}

                        </div>

                    </div>

                    {data.status === "extended" || data.status === 'followup' ? "" :
                        <div className="appointmentIns" style={{ marginTop: '20px' }}>
                            <AppointmentTipsTwo onClick={handleUpdateMed} />
                            <ImportantNote />
                        </div>
                    }


                    <div className="appointMentBtns">
                        {data.type !== "free" && data.type !== "sadn" && data.status == "booked" ? <>
                            {moment(data.booking_date) >= moment() ?
                                <button className="resheduleAppoint"
                                    onClick={Reschedule}>
                                    reshedule appointment
                                </button> : null}
                            {moment(data.booking_date) >= moment() ?
                                <button className="cancelAppoint"
                                    onClick={() => { setCancel(true) }}
                                >
                                    cancel appointment
                                </button> : null}
                        </> : null}
                        {/* <button className="resheduleAppoint" onClick={()=>{setReshedule(true)}}>
                        reshedule appointment==
                    </button>
                    <button className="cancelAppoint" onClick={()=>{setCancel(true)}}>
                        cancel appointment
                    </button> */}


                    </div>
                </div>}

                {/* dialogs */}
                <CautionDialogBox
                    open={reshedule}
                    _onClose={() => { setReshedule(false) }}
                    resImg={require('../../assets/images/caution.svg').default}>
                    <div className="resheduleContent">
                        <p>To reschedule this appointment
                            please contact Support at
                        </p>
                        <p>Email: support@doctall.com
                            Phone Number: 09010996000</p>
                    </div>

                </CautionDialogBox>
                <CautionDialogBox
                    open={cancel}
                    _onClose={() => { setCancel(false) }}
                    resImg={require('../../assets/images/caution.svg').default}
                >
                    <div className="cancelContent">
                        <p>Are you sure you want to cancel
                            this appointment?
                        </p>
                        <p>Appointments cancelled less than 24 hours before the appointment time will not be eligible for a refund.
                        </p>
                        <p>Please refer to our refund policy for more details</p>
                        <div>
                            <button className="cancelYes"
                                onClick={() => { handleCancel() }}
                            >Yes</button>
                            <button className="cancelNo"
                                onClick={() => {
                                    setCancel(false)
                                }}>No</button>
                        </div>
                    </div>
                </CautionDialogBox>
                <CautionDialogBox
                    open={successCancel}
                    _onClose={() => { setSuccessCancel(false) }}
                    resImg={require('../../assets/images/caution.svg').default}
                >
                    <div className="resheduleContent">
                        <p>You have successfully cancelled this appointment.
                        </p>
                    </div>

                </CautionDialogBox>
                <CautionDialogBox
                    open={cancelRefund}
                    _onClose={() => { setCancelRefund(false) }}
                    resImg={require('../../assets/images/caution.svg').default}
                >
                    <div className="cancelContent">
                        <p>You have successfully cancelled your appointment.
                        </p>
                        <p>Please contact Support to claim your refund.
                        </p>
                        <p>Email: support@doctall.com
                            Phone Number: 09010996000</p>

                    </div>
                </CautionDialogBox>
            </DashboardLayout>

            <LabReportView
                open={labTestView}
                close={() => { setLabTestView(false) }}
                detailedInfo={testInfo}
                stopLoader={() => { setLoader(false) }}
                startLoader={() => { setLoader(true) }}
            />
        </>
    )
}

export default AppointmentDetail