import React, { useState, useEffect } from 'react';
import Layout from '../../layout/DashboardLayout';
import { login } from '../../redux/auth/actions';
import CryptoJS from "crypto-js";
import { useHistory } from "react-router-dom";
import LoaderComponent from '../../components/Loading/LoaderComponent';
import { useDispatch } from 'react-redux';

export default function FADNPage(props) {
    let history = useHistory()
    const [loader, setLoader] = useState(false);

    const dispatch = useDispatch();
    const SECRET_KEY = ')H@MccQffTjWnZr4u7x!A%CF*F-JaNdRgUkUkUXNh*F-JnaNDOCcTALL';

    useEffect(() => {

        try {
            setLoader(true)
            var decryption = CryptoJS.AES.decrypt(props.match.params.queryParam.toString().replaceAll("doctall", "/"), SECRET_KEY);
            let decryptKey = decryption.toString(CryptoJS.enc.Utf8);
            let _decryptedData = JSON.parse(decryptKey)
            let _data = _decryptedData[0];
            console.log(_data)
            autoLogin(_data)



        } catch (error) {
            console.log(error)
            setLoader(false)
        }
    }, [])


    const autoLogin = (_data) => {
        const payLoad = { username: _data.userName, password: _data.password };
        dispatch(
            login(payLoad, (res) => {
                localStorage.setItem("CTKN", res.token);
                setLoader(false)
                history.push({ pathname: "/specialist" });
            })
        );
    }


    return (

        <>
            <Layout>
                {/* Loader Component */}
                {loader && <LoaderComponent />}
            </Layout>
        </>

    )
}