export const usernameKey = `_da_${process.env.REACT_APP_ENV}_812022`.toLowerCase();
export const passcodeKey = `_da_${process.env.REACT_APP_ENV}_812023`.toLowerCase();
export const tokenKey = `_da_${process.env.REACT_APP_ENV}_812024`.toLowerCase();

export const specialityData = [
  {
    _id: "5fdef162b23d6f2834d3e4e2",
    name: "General Medicine (Nigerian Doctor)",
    desc: "Nigerian Medical Officers",
  },
  {
    _id: "5fdef162b23d6f2834d3e4e2",
    name: "Dermatology",
    desc: "Skin disorders",
  },
  {
    _id: "5fdef162b23d6f2834d3e4e3",
    name: "Nephrology",
    desc: "Kidney disorders",
  },
  {
    _id: "5fdef162b23d6f2834d3e4e4",
    name: "Obstetrics and Gynecology",
    desc: "Pregnancy, childbirth, postpartum and female reproductive system",
  },
  {
    _id: "5fdef162b23d6f2834d3e4e5",
    name: "Endocrinology",
    desc: "Endocrine system such as diabetes, thyroid and hormone issues",
  },
  {
    _id: "5fdef162b23d6f2834d3e4e6",
    name: "Paediatrics",
    desc: "Infants, children and adolescents",
  },
  {
    _id: "5fdef162b23d6f2834d3e4e7",
    name: "Cardiology",
    desc: "Heart and circulatory system",
  },
  {
    _id: "5fdef162b23d6f2834d3e4e8",
    name: "Neurology",
    desc: "Brain and nervous system",
  },
  {
    _id: "5fdef162b23d6f2834d3e4e9",
    name: "Urology",
    desc: "Urinary tract and male reproductive organs",
  },
  {
    _id: "5fdef162b23d6f2834d3e4ea",
    name: "Orthopaedics",
    desc: "Musculoskeletal system",
  },
  {
    _id: "5fdef162b23d6f2834d3e4eb",
    name: "Psychiatry",
    desc: "Emotional, behavioural and mental wellness",
  },
  {
    _id: "5fdef162b23d6f2834d3e4ec",
    name: "Internal Medicine",
    desc: "Adult internal diseases",
  },
  {
    _id: "5fdef162b23d6f2834d3e4ed",
    name: "Respiratory Medicine",
    desc: "Respiratory system such as asthma, TB and lung disease",
  },
  {
    _id: "5fdef162b23d6f2834d3e4ee",
    name: "General Surgery",
    desc: "Surgical speciality covering a broad range of diverse sub-specialities, of which abdominal is the most common",
  },
  {
    _id: "5fdef162b23d6f2834d3e4ef",
    name: "ENT",
    desc: "Ear, nose and throat",
  },
  {
    _id: "5fdef162b23d6f2834d3e4f0",
    name: "Haematology",
    desc: "Blood and bone marrow disorders",
  },
  {
    _id: "5fdef162b23d6f2834d3e4f1",
    name: "Gastroenterology",
    desc: "Digestive system (intestines and stomach) and related disorders",
  },
  {
    _id: "5fdef162b23d6f2834d3e4f2",
    name: "Ophthalmology",
    desc: "Eye disorders",
  },
  {
    _id: "5fdef162b23d6f2834d3e4f3",
    name: "Geriatrics",
    desc: "Healthcare for the elderly",
  },
  {
    _id: "5fdef162b23d6f2834d3e4f5",
    name: "Rheumatology",
    desc: "Musculoskeletal, autoimmune and related disorders",
  },
  { _id: "5fdef162b23d6f2834d3e4f6", name: "Oncology", desc: "Cancer" },
  {
    _id: "5fdef162b23d6f2834d3e4f7",
    name: "Fertility & IVF",
    desc: "Male and female fertility concerns",
  },
  {
    _id: "5fdef162b23d6f2834d3e4f8",
    name: "Psychology",
    desc: "Mind, behaviour and mental well-being",
  },
  {
    _id: "5fdef162b23d6f2834d3e4f9",
    name: "Life Coach",
    desc: "Personal and professional development",
  },
  {
    _id: "5fdef162b23d6f2834d3e4fa",
    name: "Dietitian",
    desc: "Diet and nutrition",
  },
];
export const blood_group = [
  "+A",
  "-A",
  "+B",
  "-B",
  "+B",
  "-O",
  "+O",
  "-AB",
  "+AB",
];

export const docTypes = [
  { label: "Prescription", value: "Prescription" },
  {
    label: "Diagnostics/Lab Test Result",
    value: "Diagnostics/Lab Test Result",
  },
  { label: "Medical Examination", value: "Medical Examination" },
  { label: "Discharge Certificate", value: "Discharge Certificate" },
  { label: "Patient Information", value: "Patient Information" },
  { label: "Laboratory Test Request", value: "Laboratory Test Request" },
  { label: "Medical History Records", value: "Medical History Records" },
  { label: "Mental Status Summary", value: "Mental Status Summary" },
  { label: "Operative Report", value: "Operative Report" },
  { label: "Physical Examination", value: "Physical Examination" },
  { label: "Others", value: "Others" },
];

export const symptomsData = [
  "Abdominal Pain",
  "Abscess",
  "Absence of Menstruation / Amenorrhea",
  "Acid Reflux / Heartburn",
  "Acne",
  "Allergic Cough",
  "Allergy Consultation",
  "Anaphylaxis / Allergy",
  "Anemia",
  "Angina",
  "Annual Check Up",
  "Anorexia",
  "Arm Pain",
  "Arm Problem(s)",
  "Arthritis",
  "Asthma",
  "Atrial Flutter",
  "Atrioventricular Septal Defect (AVSD)",
  "Back Pain",
  "Back Problems",
  "Barrett's Esophagus",
  "Basal Cell Carcinoma (BCC)",
  "Bedsores",
  "Bedwetting / Enuresis",
  "Bladder and Bowel Management",
  "Bleeding Disorder",
  "Blisters",
  "Blood Disorder",
  "Blood Pressure Testing",
  "Blood Work",
  "Blood in Stool",
  "Blood in Urine / Hematuria",
  "Body Ache / Pain",
  "Bowel and Bladder Management",
  "Bradycardia",
  "Breast Pain",
  "Breathing Problems",
  "Bronchitis",
  "Bronchoscopy",
  "Bruise / Contusion",
  "Celiac Disease",
  "Cellulitis",
  "Chest Pain",
  "Chicken Pox",
  "Chickenpox (Varicella) Vaccine",
  "Cholesterol / Lipids Checkup",
  "Cholesterol Management",
  "Chronic Cough",
  "Chronic Illness",
  "Chronic Obstructive Pulmonary Disease (COPD)",
  "Chronic Pain",
  "Cold",
  "Cold Sores / Herpes Labialis",
  "Colon Polyps",
  "Concussion",
  "Congenital Heart Disease",
  "Constipation",
  "Coronary Artery Disease",
  "Cough",
  "Crohn's Disease",
  "Daytime Sleepiness",
  "Dehydration",
  "Delirium",
  "Dengue",
  "Depression",
  "Diarrhea",
  "Diverticulitis / Diverticulosis",
  "Dizziness",
  "ECG / EKG",
  "Ear Infection",
  "Eczema",
  "Edema",
  "Electrocardiogram",
  "Esophageal Stricture",
  "Fainting / Syncope",
  "Fall",
  "Familial Adenomatous Polyposis (FAP)",
  "Fecal Occult Blood Test (FOBT)",
  "Fever",
  "Flu",
  "Flu Shot",
  "Food Allergy",
  "Food Intolerance",
  "Frailty",
  "Frequent Urination",
  "Gall Bladder Problem",
  "Gallstones",
  "Gastritis",
  "Gout",
  "HIV / AIDS",
  "HIV / AIDS Consultation",
  "HIV Pre-Exposure Prophylaxis (PrEP)",
  "Headache",
  "Hearing Problems / Ringing in Ears",
  "Hepatitis",
  "Hepatitis A Vaccine",
  "Hepatitis B",
  "Hepatitis B Vaccine",
  "Hepatitis C",
  "High Blood Pressure / Hypertension",
  "High Cholesterol / Lipid Problem",
  "Hyperlipidemia",
  "Hyperparathyroidism",
  "Hyperthyroidism / Overactive Thyroid",
  "Hypopituitarism",
  "Impaired Gait",
  "Incontinence",
  "Increased Salivation",
  "Infection Follow Up",
  "Inflammatory Bowel Disease",
  "Irritable Bowel Syndrome",
  "Itching",
  "Joint Injection",
  "Joint Pain",
  "Joint Problem ",
  "Joint Stiffness",
  "Knee Pain",
  "Lactose Intolerance",
  "Leg Pain",
  "Low Blood Sugar / Hypoglycemia",
  "Lower Back Pain / Lumbago",
  "Lower Extremity Pain",
  "Lower Extremity Swelling",
  "Measles / Rubeola",
  "Menopause",
  "Menopause Follow Up",
  "Menstrual Problems",
  "Metabolic Syndrome",
  "Migraine",
  "Muscle Pain",
  "Muscle Strain",
  "Nausea and Vomiting",
  "Neck Pain",
  "New Patient Visit",
  "Nose Bleed / Epistaxis",
  "Nutrition Issues",
  "Pain in Extremities",
  "Panic Attack",
  "Patient History",
  "Pelvic Pain",
  "Plantar Fasciitis",
  "Pneumococcal Vaccine",
  "Pneumonia",
  "Polycystic Ovary Syndrome / Metabolic Syndrome",
  "Polymyalgia Rheumatica",
  "Post-Menopausal Bleeding",
  "Pre-Surgery Checkup / Pre-Surgical Clearance",
  "Pre-Travel Checkup",
  "Pre-Travel Consultation",
  "Pre-Travel Follow Up",
  "Preventive Medicine Consultation",
  "Primary Care Consultation",
  "Primary Pulmonary Hypertension",
  "Quitting Smoking / Smoking Cessation",
  "Rash",
  "Screening for Disease / Preventive Medicine",
  "Severe Infection",
  "Sexual Dysfunction / Painful Sex / Dyspareunia",
  "Sexually Transmitted Disease (STD)",
  "Sexually Transmitted Infection",
  "Shingles",
  "Shortness of Breath / Difficulty in Breathing",
  "Shoulder Pain",
  "Sinus Problems / Sinusitis",
  "Sleep Problems",
  "Sore Throat",
  "Stiffness",
  "Stomach Pain",
  "Stress",
  "Swelling",
  "Swelling in Legs",
  "Swelling in Neck",
  "Syphilis",
  "Tetanus Shot",
  "Throbbing",
  "Tingling / Numbness / Weakness",
  "Tiredness / Fatigue",
  "Travel Medicine Consultation",
  "Type 1 Diabetes",
  "Type 2 Diabetes",
  "Ulcerative Colitis",
  "Urinary Incontinence",
  "Urinary Tract Infection (UTI)",
  "Vaccine(s)",
  "Vaginal Discharge / Infection",
  "Vitamin B12 Deficiency",
  "Vitamin D Deficiency",
  "Wellness Care",
  "Wound Care",
  "Other",
];

export const baseUrl = process.env.REACT_APP_API_URL;
export const api = {
  star_rating: baseUrl,
  login: baseUrl + "/user/auth/login",
  google_login: baseUrl + "/user/auth/google/login",
  fb_login : baseUrl + "/user/auth/facebook/login",
  register: baseUrl + "/user/auth/register",
  verify_token: baseUrl + "/user/auth/verify-token",
  availability: baseUrl + "/user/auth/availability",
  mobile_exist: baseUrl + "/user/auth/mobileNumExist/",
  send_otp: baseUrl + "/sms/send-otp",
  verify_otp: baseUrl + "/sms/verify-otp",
  signup_verify_otp: baseUrl + "/sms/register/verify-otp",
  email_send_otp: baseUrl + "/sms/doctall/send-otp",
  email_verify_otp: baseUrl + "/sms/email/verify-otp",
  blog: "https://blogs.yoopha.com/ghost/api/v2/content/posts/",
  community: "https://api.community.yoopha.com/",
  search_doctor: baseUrl + "/search/doctor",
  clinic_by_doctor: baseUrl + "/clinic",
  clinic_type: baseUrl + "/clinic/doctall",
  slots: baseUrl + "/slot",
  prescription: baseUrl + "/ep",
  lab: baseUrl + "/ep-lab",
  elabpreview: baseUrl + "/ep-lab/preview/",
  eprescription: baseUrl + "/ep/doctall",
  elab: baseUrl + "/ep-lab/doctall",
  labTest: baseUrl + "/ep-lab/labtest/list",
  eprescriptiondoctor: baseUrl + "/user/doctors/list",
  order: baseUrl + "/order",
  patient: baseUrl + "/patient",
  patientdoctall: baseUrl + "/patient/doctall",
  addRelative: baseUrl + "/patient/relation/",
  pharmacyfind: baseUrl + "/pharmacy/findall",
  pharmacy: baseUrl + "/pharmacy",
  appointment: baseUrl + "/booking/doctall/new/",
  appointmentall: baseUrl + "/booking",
  recommendedTestPreview: baseUrl + '/ep-lab/preview/recomended-test',
  speciliality: baseUrl + "/metadata/specialities",
  profile: baseUrl + "/user/profile",
  user: baseUrl + "/user",
  doctor_search: baseUrl + "/user/doctor/filter",
  resetpassword: baseUrl + "/user/auth/reset-password",
  creatIDpassword: baseUrl + "/user/auth/Createpassword/consumer",
  changepassword: baseUrl + "/user/auth/change-password",
  nextlast: baseUrl + "/booking/doctall/nextLast/",
  payment: baseUrl + "/payment/",
  labPartner: baseUrl + "/lab/test/distinct/labPartner",
  Order: baseUrl + "/order",
  upload: baseUrl + "/upload/health-record",
  uploadlist: baseUrl + "/upload/health-records",
  applyCoupon: baseUrl + "/coupon/apply",
  blockCoupon: baseUrl + "/coupon/applied/update",
  updatewalletAmnt: baseUrl + "/user/wallethistory/pay/from/wallet",

  uploaddata: baseUrl + "/upload/health-details",
  deleteupload: baseUrl + "/upload/deleteUpload",
  tokenupdate: baseUrl + "/notification/",
  paymentpreview: baseUrl + "/payment/",
  feedbackget: baseUrl + "/booking/question/fetch",
  feedbackpost: baseUrl + "/booking/feedback",
  citylist: baseUrl + "/metadata/cities",
  statelist: baseUrl + "/metadata/states",
  profileupdate: baseUrl + "/patient/doctall/profilePercentage/",
  tips: baseUrl + "/metadata/tips",
  populardropdown: baseUrl + "/uploadFile/popularTest",
  biointel: baseUrl + "/uploadFile/biointel/",
  biointeldetail: baseUrl + "/uploadFile/",
  biolocation: baseUrl + "/uploadFile/bio_location",
  displayimage: baseUrl + "/upload/avatar",
  profileimage: baseUrl + "/user/profile/consumer",
  deleteProfile: baseUrl + "/user/profile/deleteProfile",
  allcities: baseUrl + "/uploadDoc/allcities",
  allarea: baseUrl + "/uploadDoc/allarea",
  getarea: baseUrl + "/uploadDoc/getArea",
  popularhomeservice: baseUrl + "/uploadFile/popularTest/homeservice",
  multitestlist: baseUrl + "/uploadFile/popularTest/select",
  tips: baseUrl + "/metadata/tips",
  healthDetails: baseUrl + "/ep/healthrecordBKID/",
  caseNotePreview: baseUrl + "/ep-lab/caseNotepreviewejs/",
  pysNotePreview: baseUrl + "/ep-lab/psyNotePreviewejs/",
  viewpres: baseUrl + "/ep/preview/",
  viewlab: baseUrl + "/ep-lab/preview/",
  accountDelete : baseUrl + "/user/auth/delete"
};
