import types from "./types";

const initialState = {
  data: [],
  loading: false,
  error: null,
  SEARCHED_ORDERS_data:[],

};


const orderReducer = (state = initialState, action) => {

  switch (action.type) {
      
    case types.GET_ALL_ORDERS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
       // data:[...state.data, action.payload]
      };
    case types.GET_ALL_ORDERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case types.GET_SEARCHED_ORDERS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_SEARCHED_ORDERS_SUCCESS:
       
      return {
        ...state,
        loading: false,
      //  data:  action.payload
      data:[...state.data, action.payload]
      };
    case types.GET_SEARCHED_ORDERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      
      case types.GET_RECEIPT:
        return {
          ...state,
          loading: true,
        };
      case types.GET_RECEIPT_SUCCESS:
         
        return {
          ...state,
          loading: false,
         // data:  action.payload
          data: [...state.data, action.payload]
        };
      case types.GET_RECEIPT_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
   
    default:
      return state;
  }
};

export default orderReducer;
