import React, { useState } from "react";
import axios from 'axios'
import { useHistory } from "react-router-dom";
import { api } from '../../utils/constants'
import FullNavLayout from '../../layout/FullNavLayout'
import PinInput from "react-pin-input";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import './auth.scss'

function ForgetPin() {

  let history = useHistory();
  const forgetData = JSON.parse(localStorage.getItem("forgetData"))
  const [pin, setPin] = useState();
  const [verify, setVerify] = useState(false)
  const [res, setRes] = useState(false)
  const handleSubmit = async (e) => {
    e.preventDefault()
    const mobile = forgetData.number
    const otp = pin
    const val = { mobile, otp }
    console.log(val)
    await axios.post(api.verify_otp, val)
      .then((response) => {
        setVerify(true)
        setTimeout(function () { history.push('/createNewPasscode') }, 2000);
      }, (error) => { setRes(true) });
  }

  return (
    <FullNavLayout>
      <div className="forgotContainer">
        <div className="heading">Code Verification</div>
        <div className="forget_pin_Txt">A code has been sent to your email address and phone number</div>
        <div className="forget_pin_TxtTwo">Please enter the code received</div>
        <form onSubmit={handleSubmit}>
          <PinInput
            length={6}
            initialValue="*"
            secret
            onChange={(value, index) => { setPin(value) }}
            type="numeric"
            inputMode="number"
            style={{ padding: '15px' }}
            inputStyle={{ borderColor: '#ADC2C2' }}
            inputFocusStyle={{ borderColor: '#00839B' }}
            autoSelect={true}
            regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          />
          <button type="submit" className='sumbitBtn'> Confirm Code </button>
          <div className="forget_pin_TxtBottom">If you did not receive the code after 3 minutes
            please select Resend Code</div>
        </form>
      </div>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={res}
        autoHideDuration={3000}
        onClose={() => setRes(false)}>
        <Alert severity="error">
          Please Enter the correct Otp
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={verify}
        autoHideDuration={3000}
        onClose={() => setVerify(false)}>
        <Alert severity="success">
          Verification Success!
        </Alert>
      </Snackbar>

    </FullNavLayout>
  )
}

export default ForgetPin
