/**
 * 
 * Input Field
 * 
 * 
 */

import React from 'react';
import './style.scss';
import { Input } from 'antd';


/**
 * 
 * @param {object} props 
 * @returns 
 */
export default function InputField(props) {

        const { size, placeholder, value, onChange, prefix, className, style = {}, InputStyle } =props;
     return (
         <div className={`inputfield-root ${className}`} style={style}>
             <Input 
                style={InputStyle} 
                size={size} 
                placeholder={placeholder} 
                defaultValue={value} 
                onChange={onChange} 
                prefix={prefix} />
         </div>
     )
}