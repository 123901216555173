import React, { useEffect, useState } from 'react';
import './FreeBooking.scss';
import Layout from '../../layout/DashboardLayout';
import { SelectTimeSlote, AppointmentTip, ReasonForVisit, AddDocuments, TitleWithNav } from "../../components"
import axios from '../../redux/axios';
import { api } from '../../utils/constants'
import * as RNLocalize from 'react-native-localize';
import momenttz from 'moment-timezone';
import moment from 'moment';
import Toast from '../../components/Toast';
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector, useDispatch } from "react-redux";
import Submitpop from "../../components/HealthPop/index";

function arrUnique(arr) {
    var cleaned = [];
    arr.forEach(function (itm) {
        var unique = true;
        cleaned.forEach(function (itm2) {
            if (moment(itm.date).format('ddd  MM/DD') == moment(itm2.date).format('ddd  MM/DD') && moment(itm.start_time).format('LT') == moment(itm2.start_time).format('LT')) unique = false;
        });
        if (unique) cleaned.push(itm);
    });
    return cleaned;
}

function FreeBooking() {
    const [clinic, setClinic] = React.useState(false);
    const [originalslot, setOriginalSlot] = React.useState([]);
    const [slotDate, setSlotDate] = React.useState([]);
    const [slot, setSlot] = React.useState([]);
    const [slotsection, setSlotSection] = React.useState("");
    const [symptom, setsymptom] = React.useState("");
    const [notes, setNotes] = React.useState("");
    const [selfOrRelative, setSelforRelative] = React.useState('');
    const [slotTime, setSlotTime] = React.useState("");
    const [toster, setToster] = React.useState(true);
    const [msg, setMsg] = React.useState('');
    const [type, setType] = React.useState('');
    const [subDoc, setSubDoc] = React.useState([]);
    let history = useHistory();
    const [loader, setLoader] = React.useState(false)
    const [freedate, setFreedate] = useState(new Date());
    const Patients = useSelector(state => state.patients);
    const [modalVisibleHP, setmodalVisibleHP] = useState(false)


    React.useEffect(() => {
        axios.get(api.clinic_by_doctor + "/all")
            .then(res => setClinic(res.data))
            .catch(err => console.log(err))

    }, []);
    useEffect(() => {

    }, [])
    React.useEffect(() => {
        if (clinic && clinic.uuid) {
            const deviceTimeZone = RNLocalize.getTimeZone();
            const today = momenttz().tz(deviceTimeZone);
            axios.get(api.slots + "/" + clinic.uuid + "/free?booking_date=" + today.utc().format())
                .then(res => {
                    let slotlist = res.data && res.data.length > 0 ? res.data[0].slots : [];
                    slotlist = slotlist.filter((e) => e.isActive);
                    slotlist = arrUnique(slotlist);
                    setOriginalSlot(slotlist);
                    let result = slotlist.map((e, i) => {
                        return {
                            label: moment(e.date).format('ddd  MM/DD'), value: e._id
                        }
                    })
                    var outputList = [];
                    for (var x in result) {
                        // eslint-disable-next-line no-loop-func
                        if (outputList.findIndex((e) => e.label === result[x].label) === -1)
                            outputList.push(result[x]);
                    }
                    setSlot(outputList)
                    //let resulttime = slotlist.map((e, i) => { return { label: moment(e.start_time).format('LT') + ' - ' + moment(e.end_time).format('LT'), value: e._id ,date: moment(e.date).format('ddd  MM/DD')} })
                    let resulttime = slotlist.map((e, i) => { return { label: moment(e.start_time).format('LT'), value: e._id, date: moment(e.date).format('ddd  MM/DD') } })
                    setSlotDate(resulttime)
                })
                .catch(err => console.log(err))
        }
    }, [clinic])
    useEffect(() => {
        handleSelf()
    }, [selfOrRelative])

    const handleSelf = () => {
        let res;
        if (Patients != undefined && Patients !== "") {
            res = Patients.data.filter((e, i) => e.uuid == selfOrRelative.value)
        }

        if (res.length > 0) {
            let data = res[0];
            if (data.medicalQuestions && data.medicalQuestions.length > 0) {
            }
            else {
                setmodalVisibleHP(true)
            }
        }
        // setPatient(item)
    }
    const ConfirmBooking = () => {
        if (selfOrRelative == '') {
            setToster(true);
            setMsg("Please select Patient");
            setType('error');
        }
        else if (slotTime.length == 0) {
            setToster(true)
            setMsg("please select slot")
            setType('error')
        } else {
            setLoader(true)
            let datareasons = [];
            if (symptom == "") {
                datareasons.push("Abdominal Pain")
            } else {
                datareasons.push(symptom.value)
            }

            let bookingdate = originalslot.filter((e, i) => { return e._id == slotTime.value });
            bookingdate = bookingdate.length > 0 ? bookingdate[0].date : "";
            const deviceTimeZone = RNLocalize.getTimeZone();
            const today = momenttz(bookingdate).tz(deviceTimeZone);

            let bookingData = {
                video: true,
                reasons: datareasons,
                slot: slotTime.value,
                desc: notes == "" ? "NA" : notes,
                booking_date: today.utc().format(),
                patient_uuid: selfOrRelative.value,
                category: "GC",
                type: "free",
                amount: 0,
                clinic_uuid: clinic.uuid,
                documents: subDoc.length > 0 ? subDoc : []
            }
            console.log(JSON.stringify(bookingData))
            axios.post(api.appointment + "free", bookingData)
                .then(res => {
                    console.log(res.data)
                    axios.put(api.appointmentall + "/" + res.data.uuid + "/booked", {})
                        .then(res2 => {
                            console.log(res2)
                            setToster(true)
                            setMsg("Success")
                            setType('success')
                            setLoader(false)
                            history.push({ pathname: "/doctor_consultations", data: res2.data })
                        }).catch(err => {
                            setToster(true)
                            setMsg(err.response.data)
                            setType('error')
                            setLoader(false)
                        })

                })
                .catch(err => {
                    setToster(true)
                    setMsg(err.response.data)
                    setType('error')
                    setLoader(false)
                })

        }

    }


    return (
        <>
            <Layout>
                <div className="freeBooking">
                    <TitleWithNav title={"Book a FREE Appointment"} />
                    <p className="h4">
                        Schedule a time to see our on-call Nigerian Medical Officer for FREE. Earliest appointment in six (6) hours depending on availability.
                    </p>
                    <div className="containerWrapper">
                        <div className="componentContainer">
                            <SelectTimeSlote slot={slot} slotDate={slotDate} setSelforRelative={setSelforRelative} setSlotTime={setSlotTime} />
                            <AppointmentTip />
                        </div>
                        <div>
                            <ReasonForVisit setsymptom={setsymptom} setNotes={setNotes} />
                        </div>
                    </div>
                    <AddDocuments selfOrRelative={selfOrRelative} setSubDoc={setSubDoc} subDoc={subDoc}
                        freedate={freedate} setFreedate={setFreedate}
                    />
                    <div className="buttonConfirm" onClick={ConfirmBooking}>
                        {loader ? <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            Please Wait...&nbsp;
                            <CircularProgress size="25" style={{ marginLeft: 5 }} />
                        </div> : 'CONFIRM APPOINTMENT'}
                    </div>
                </div>
                <Toast text={msg} type={type} open={toster} setClose={() => { setToster(false) }} />
            </Layout>
            <Submitpop show={modalVisibleHP} onHide={() => setmodalVisibleHP(false)} />
        </>
    )
}

export default FreeBooking
