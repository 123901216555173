import React from 'react';
import './TitleWithNav.scss';
import ICON13 from '../../assets/images/ICON13.svg'
import { useHistory } from "react-router-dom";

function TitleWithNav(props) {
    let history = useHistory();

    return (
        <>
            <div className="goBack" onClick={() => { props.onBack ? props.onBack() : history.goBack() }}>
                <img crossorigin="anonymous" src={ICON13} alt="arrow" className="img-fluid" />
                <p>back</p>
            </div>

            <p className="h3">{props.title}</p>
            <div className="hr"></div><br />
        </>
    )
}

export default TitleWithNav


