import React from 'react'
import './radio.scss'

function RadioBtn(props) {
    const { name, value, img, imgAct, click, onChange, checking } = props
    return (
        <div>
            <div className="radioComponent">
                <input type="radio" name={name} onChange={onChange} id={value} value={value}
                    checked={checking}
                />
                <label className="newRadio" htmlFor={value} onClick={click}>
                    <img crossorigin="anonymous" className="radioOff" src={img} alt="" />
                    <img crossorigin="anonymous" className="radioOn" src={imgAct} alt="" />
                    <div className="radioName">{value}</div>
                </label>
            </div>
        </div>
    )
}
export default RadioBtn
