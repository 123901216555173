import './checkBtnTwo.scss';

function CheckBtnTwo(props) {
    const { name, value, img, imgAct, onChange, styles, click, id, onClick } = props
    return (
        <div>
            <div className="radioComponent2">
                <input type="checkbox" name={name} id={id} onChange={onChange} value={JSON.stringify(value)} onClick={onClick} />
                <label className="newRadio" htmlFor={id} onClick={click}>
                    <img crossorigin="anonymous" className="radioOff" src={img} alt="" />
                    <img crossorigin="anonymous" className="radioOn" src={imgAct} alt="" />
                </label>
            </div>
        </div>
    )
}

export default CheckBtnTwo