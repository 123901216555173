import React from 'react';
import './AppointmentTip.scss';
import ICON14 from '../../assets/images/ICON14.svg';
import ICON20 from '../../assets/images/ICON20.svg';
import ICON21 from '../../assets/images/ICON21.svg';

function AppointmentTip({ type }) {
    return (
        <div className="Appointment"
            id={type === "specialist" ? "AppointmentSpecial" : ''}>
            <div className="headingContainer">
                <div className="headingContainer">
                    <img crossorigin="anonymous" src={ICON14} alt="icon" />
                    <h3 className="heading">Appointment Tip </h3>
                </div>
                <p className="text">Help your doctor to better treat you by updating the Medical tab in Health Profile prior to your appointment.</p>
            </div>
        </div>
    )
}

export default AppointmentTip

export function AppointmentTipsTwo({ onClick }) {
    return (
        <div className="AppointmentTipsTwo">
            <img crossorigin="anonymous" src={ICON20} alt="icon" />
            <div className="appoHeading">
                Appointment Tip
            </div>
            <div className="appoTxt">
                Help your doctor to better treat you by updating the Medical tab in Health Profile prior to your appointment.
            </div>
            <button className="appobtn" onClick={onClick} >
                Update Medical Profile
            </button>
        </div>
    )
}

export function ImportantNote() {
    return (
        <div className="ImportantNote">
            <img crossorigin="anonymous" src={ICON21} alt="icon" />
            <div className="impHeading">
                Important Note
            </div>
            <div className="inpTxt">We have sent you a confirmation email. Please log in preferably 5 minutes before the appointment, to ensure you are available when your doctor starts the call</div>
        </div>
    )
}


export function NoAppointments() {
    return (
        <div className="NoAppointments">
            <img crossorigin="anonymous" src={ICON21} alt="icon" />

            <div className="inpTxt">Unfortunately, there are no available
                appointments within the next hour</div>
        </div>
    )
}
