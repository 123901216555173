/**
 * 
 *  Pagination Component
 *  Created Date: 3 July 2021
 *  Modified Date: 3 July 2021  
 * 
 */



import React, { useState } from 'react';
import { usePagination, DOTS } from './usePagination';
import BackLogo from '../../assets/images/backLogo.svg';
import NextLogo from '../../assets/images/nextLogo.svg';
import './style.scss';

/**
 * 
 * @param {object} props 
 * @returns 
 */
function Pagination(props) {
console.log(props.records, "props")
    const { children, records = [], onPageChange, className = '', style = {}, pageSize, isloading, setPageNum, pageChange, Count} = props;
    const [currentPage, setCurrentPage] = useState(1);
    const [page, setPage] = useState(false);
    // const totalCount = records && Array.isArray(records) && records.length > 0 ? records.length : 0;
    const totalCount = pageChange === true ? Count : (records && Array.isArray(records) && records.length > 0 ? records.length : 0);


    const paginationRange = usePagination({
        currentPage,
        totalCount,
        pageSize
    });

    const onNext = () => {
        if (currentPage != lastPage) {
            setCurrentPage(currentPage + 1);
            if(pageChange === true){
                setPageNum(currentPage  + 1)
            }
            if (onPageChange)
                onPageChange(currentPage + 1);
        }
    };

    const onPrevious = () => {
        if (currentPage != 1) {
            setCurrentPage(currentPage - 1);

            if(pageChange === true){
                setPageNum(currentPage  - 1)
            }

            if (onPageChange)
                onPageChange(currentPage - 1);
        }
    };

    const GoPage = (page) => {

        if(pageChange === true){
            setPageNum(parseInt(page));
            setCurrentPage(parseInt(page))
        }
        else{
            setCurrentPage(parseInt(page))
        }
    }

    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    let lastPage = paginationRange[paginationRange.length - 1] || 1;
    const pageRecords = pageChange ===true ? props?.records : (records && Array.isArray(records) && records.length > 0 ? records.slice(firstPageIndex, lastPageIndex) : [])
    // const pageRecords = props?.records

    return (

        <div className={`pagination ${className}`} style={style}>
            {children && typeof children === 'function' && pageRecords && pageRecords.length > 0 ? children(pageRecords) : isloading ? <div className="pagination-no-records"> Loading...</div> : <div className="pagination-no-records"> No Records Found.</div>}
            <div className="pagination-root">
                <div className="pagination-flex-box">
                    <div className="pagination-back-button" onClick={onPrevious}>
                        <img crossorigin="anonymous" src={BackLogo} />
                    </div>
                    <div className="pagination-pages">
                        Page {currentPage}{DOTS} of {lastPage}
                    </div>
                    <div className="pagination-next-button" onClick={onNext}>
                        <img crossorigin="anonymous" src={NextLogo} />
                    </div>
                </div>
                <div className="pagination-flex-box2">
                    <div className="pagination-page"> Go to page </div>
                    <input type="text" className="pagination-input-box" defaultValue="" onChange={(e) => setPage(e.target.value)} />
                    <div className="pagination-go-button" onClick={() => page && /^\d+$/.test(page) ? GoPage(page) : null}>
                        Go <img crossorigin="anonymous" src={NextLogo} style={{ paddingLeft: '8px' }} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pagination;
