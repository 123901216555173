import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects'
import { routerReducer } from "react-router-redux";
import authReducer from './auth/reducers';
import patientReducer from './patients/reducers';
import orderReducer from './orders/reducers';
import userReducer from './user/reducers';
import { saga as authSaga } from './auth'
import { saga as patientSaga } from './patients';
import { saga as orderSaga } from './orders';
import { saga as userSaga } from './user';

export function* rootSaga() {
    yield all([
        fork(authSaga),
        fork(patientSaga),
        fork(orderSaga),
        fork(userSaga),
    ])
}

export const rootReducer = combineReducers({
    auth: authReducer,
    router: routerReducer,
    patients: patientReducer,
    orders: orderReducer,
    userDetails: userReducer,
});

export default rootReducer