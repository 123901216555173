import types from "./types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PATIENTS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_PATIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };

    case types.GET_PATIENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    case types.CREATE_PATIENT:
      return {
        ...state,
        loading: true,
      };
    case types.CREATE_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.payload]
      };
    case types.CREATE_PATIENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.UPDATE_PATIENT:
      return {
        ...state,
        loading: true,
      };
    case types.UPDATE_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case types.UPDATE_PATIENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.DELETE_PATIENT:
      return {
        ...state,
        loading: true,
      };
    case types.DELETE_PATIENT_SUCCESS:
      return {
        ...state,
        loading: false
      };

    case types.DELETE_PATIENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default patientReducer;
