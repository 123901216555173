import React, { useState, useEffect } from "react";
import Layout from '../../../layout/DashboardLayout';
import { useHistory } from "react-router";
import { Row, Col } from "react-bootstrap";
import ICON13 from '../../../assets/images/ICON13.svg';
import InputField from "../../../components/InputField";
import FiltersForm from "../../../components/FiltersForm";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Search from "../../../assets/images/Search.svg";
import "./../wallet.scss";
import { useSelector } from "react-redux";
import moment from 'moment'
import axios from 'axios'

export default function Reffer() {

    const history = useHistory();
    const [show, setShow] = useState(false);

    const [activityData, setActivityData] = useState([])
    const [data, setData] = useState()

    const userdata = useSelector((state) => state.auth);

    const handleClose = () => setShow(false);
    const handleShow = (event) => {
      console.log(event.user_id,"fghjkm")
      setShow(true);
      axios.get(`${process.env.REACT_APP_API_URL}/user/wallethistory/earn/from/referal/register?user_id=${event.user_id}`)
      .then((res) => {
        setData(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
    }
    
    useEffect(() => {
      axios.get(`${process.env.REACT_APP_API_URL}/user/wallethistory/referal/register/user/list?user_uuid=${userdata.uuid}`)
        .then((res) => {
          // console.log(res.data.data,"shj")
          setActivityData(res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    }, [])

    return(
        <Layout>
    
          <div className="goBack m-5" onClick={() => history.push("/Wallet")}>
            <img crossorigin="anonymous" src={ICON13} alt="arrow" className="img-fluid" />
            <p>back</p>
        </div>
        <div className="ml-5">
            <h1>Referrals</h1>
            <Row>
                <Col md={8} >
                <InputField

                className="doctors-search"
                size="large"
                placeholder="Search for transaction"
                prefix={<img crossorigin="anonymous" src={Search} />}
                InputStyle={{ outline: "none !important" }}
                />
                </Col>
            </Row>
            <div className='mt-4'>
           <Row>
             <Col md={8} className='Main-text'>
             <table class="table" style={{marginTop:"15px"}}>
                <thead class="thead-light m-2" style={{border:"1px solid #C4F0DD"}}>
                  <tr>
                    <th scope="col">REFERRAL NAME</th>
                    <th scope="col">DATE JOINED</th>
                    <th scope="col">ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {activityData.map((item) => {
 
                  return   <tr>
                        <td>{item && item.users &&  item.users[0] && item.users[0].full_name}
                             <br/>
                             {/* {console.log(item && item.users &&  item.users[0] && item.users[0].full_name)} */}
                             {item && item.users &&  item.users[0] && item.users[0].email}
                             </td>
                        <td>{moment(item && item.users &&  item.users[0] && item.users[0].createdAt).format('MMM Do YYYY')}</td>
                        <td style={{color:"#D79E10", cursor:"pointer"}}  onClick={() => handleShow(item)}>View Details</td>
                      </tr>

                  })}
                  {/* <tr>
                    <td>Kanu Amata<br/>kanuamata@gmail.com</td>
                    <td>24th Oct, 2021 </td>
                    <td style={{color:"#D79E10"}}  onClick={handleShow}>View Details</td>
                  </tr>
                  <tr>
                    <td>Kanu Amata<br/>kanuamata@gmail.com</td>
                    <td>24th Oct, 2021 </td>
                    <td style={{color:"#D79E10"}}  onClick={handleShow}>View Details</td>
                  </tr>
                  <tr>
                    <td>Kanu Amata<br/>kanuamata@gmail.com</td>
                    <td>24th Oct, 2021 </td>
                    <td style={{color:"#D79E10"}}  onClick={handleShow}>View Details</td>
                  </tr>
                  <tr>
                    <td>Kanu Amata<br/>kanuamata@gmail.com</td>
                    <td>24th Oct, 2021 </td>
                    <td style={{color:"#D79E10"}}  onClick={handleShow}>View Details</td>
                  </tr>
                  <tr>
                    <td>Kanu Amata<br/>kanuamata@gmail.com</td>
                    <td>24th Oct, 2021 </td>
                    <td style={{color:"#D79E10"}}  onClick={handleShow}>View Details</td>
                  </tr>
                  <tr>
                    <td>Kanu Amata<br/>kanuamata@gmail.com</td>
                    <td>24th Oct, 2021 </td>
                    <td style={{color:"#D79E10"}}  onClick={handleShow}>View Details</td>
                  </tr> */}
                </tbody>
              </table>
             </Col>
             <Col md={4}></Col>
           </Row>
           </div>
        
        </div>
        <Modal show={show} onHide={handleClose} classname="center">
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
        <table class="table">
                <thead class="thead-light m-2">
                  <tr>
                    <th scope="col">DATE</th>
                    <th scope="col">ACTIVITY</th>
                    <th scope="col">AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.data.map((dat) => {
                   return <tr>
                   <td>{moment(dat && dat.createdAt).format('MMM Do YYYY')}</td>
                   <td>
                   {dat.referal_type === "register" ? "signed up with your referral code"
                          :
                          dat.referal_type === "healthprofile" ? "Healthprofile Completed"
                            :
                            dat.referal_type === "sadn" ? "  1st paid appointment"
                              :
                              dat.referal_type === "fadn" ? " 1st paid appointment"
                                :
                                ''
                        }
                   </td>
                   <td >₦{dat && dat.amount}</td>
                 </tr>
                  })}
                  {/* <tr>
                    <td>24th Oct, 2021 </td>
                    <td>Signed up with your referral code</td>
                    <td >₦100</td>
                  </tr>
                  <tr>
                    <td>24th Oct, 2021 </td>
                    <td>Fills Health Profile</td>
                    <td >₦100</td>
                  </tr>
                  <tr>
                    <td>24th Oct, 2021 </td>
                    <td>Makes 1st doctors appointment</td>
                    <td >₦100</td>
                  </tr>     */}
                </tbody>
              </table>
        </Modal.Body>
        <Modal.Footer>
          <div>Total Amount: &nbsp; ₦{data && data.sum}</div>
        </Modal.Footer>
      </Modal>

        </Layout>
    )
}