import React, { useState, useEffect } from "react";
import Layout from "../../layout/DashboardLayout";
import './Notification.scss';
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import axios from '../../redux/axios';
import { api } from '../../utils/constants';
import Notification from "./components/tabs";

const NotificationMain = () => {
    const [navidata, setNaviData] = useState([])
    const userdata = useSelector(state => state.auth);

    useEffect(() => {
        ApiFetch();
    }, []);
   
    const ApiFetch = async () => {
        axios.get(api.tokenupdate + "list/" + userdata.uuid, { headers: { 'x-auth-token': userdata.token } })
            .then(res => {
                console.log(res, 'not res send')
                let ndata = res.data;
                ndata.sort(function (a, b) {
                    return b.status.localeCompare(a.status)
                });
                setNaviData(ndata)
            })
            .catch(err => {
                setTimeout(() => {
                    // Toast.showWithGravity(err.response.data, Toast.LONG, Toast.TOP);
                }, 100);
            })
    }
    return(
    <Layout count={navidata}>
        <div className="notification-container">
       <div className="header-text"> Notification</div>
       <div className="header-border"></div>
       <Notification />
       </div>
    </Layout>
    )

}


export default NotificationMain;