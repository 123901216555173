import React, { useState, useEffect } from 'react'
import DashboardLayout from '../../layout/DashboardLayout'
import { useSelector } from 'react-redux'
import { SpecialListData } from './specialistData'
import { useHistory } from 'react-router-dom'
import axios from '../../redux/axios'
import { api, baseUrl } from '../../utils/constants'
import { Pagination, TitleWithNav, SpecialCard } from "../../components"
import FiltersForm from '../../components/FiltersForm'
import ICON10 from '.././../assets/images/ICON10.svg';
import useDebounce from '../.././hooks/useDebounce'
import './stylesDoc.scss'
import { Row, Col } from 'react-bootstrap';
import { finddoctorsFilter } from '../../utils/schema';
import Sort from '../../assets/images/Sort.svg';
import DoctorProfile2 from '../../components/DoctorProfile/docSearchProf'
import { Helmet } from 'react-helmet';

function FindAnSpecialist() {
    const userdata = useSelector(state => state.auth)
    const [searchdoctorList, setSearchDoctorList] = useState([])
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const [inputValue, setinputValue] = useState()
    const [specialities, setSpecialities] = useState([]);
    const [languages, setlanguages] = useState([])
    const [filterForm, openFilterForm] = useState(false);
    const [inital, setInital] = useState(false);
    const [filterData, setFilterData] = useState({ country: '', speciality: '', sort_type: '', start_price: '', last_price: '', language: '', search: '', type: "find_a_consultant" });
    const [isloading, setLoading] = useState(false)

    const history = useHistory()


    console.log(history.location.searchtext, "history.location.searchtext")
    const changeHandler = (e) => {
        setInital(true);
        setSearchDoctorList([]);
        setinputValue(e.target.value)
        setSearchTerm(e.target.value)
    };

    const handleFilterChange = (e) => {

        let changeData = Object.assign({}, filterData, { [e.target.name]: e.target.value });
        if (e.target && e.target.name === 'price_range') {
            changeData = Object.assign({}, filterData, { [e.target.name]: e.target.value, start_price: e.target.value[0], last_price: e.target.value[1] })
        }

        setFilterData(changeData);
    }
    const fetchDoctorsList = (data) => {
        setInital(true);
        setSearchDoctorList([]);
        data.search = searchTerm;
        setLoading(true);
        axios.get(baseUrl + '/user/doctorListFilter', { params: Object.assign({}, data), headers: { 'x-auth-token': userdata.token } })
            .then(res => {
                setSearchDoctorList(res && res.data && res.data.user || []);
                setLoading(false);
            })
            .catch(err => {
                setLoading(false);
            })
    }
    const fetchSpecialities = () => {
        axios.get(baseUrl + '/metadata/specialities', { params: Object.assign({}, { "created_by.uuid": userdata.uuid }), headers: { 'x-auth-token': userdata.token } })
            .then(res => {
                setSpecialities(res && res.data || []);
            })
            .catch(err => {
                console.log('err', err);
            })
    }
    const fetchLanguages = () => {
        axios.get(baseUrl + '/user/getTheLanguages', { params: Object.assign({}, { "created_by.uuid": userdata.uuid }), headers: { 'x-auth-token': userdata.token } })
            .then(res => {
                setlanguages(res && res.data && res.data.data || []);
                console.log("setlanguages", res)


            })
            .catch(err => {
                console.log('err', err);
            })
    }

    useEffect(
        () => {

            if (debouncedSearchTerm || debouncedSearchTerm === "") {
                let data = filterData;
                data.search = searchTerm;
                setLoading(true);
                axios.get(baseUrl + '/user/doctorListFilter', { params: Object.assign({}, data), headers: { 'x-auth-token': userdata.token } })
                    .then(res => {
                        setSearchDoctorList(res && res.data && res.data.user || []);
                        setLoading(false);
                    })
                    .catch(err => {
                        setLoading(false);
                    })
            }
        },
        [debouncedSearchTerm] // Only call effect if debounced search term changes
    );

    useEffect(() => {
        fetchSpecialities();
        fetchLanguages();
    }, [])

    useEffect(() => {
        if (history.location && history.location.searchtext) {
            setInital(true);
            setSearchDoctorList([]);
            setinputValue(history.location.searchtext)
            setSearchTerm(history.location.searchtext)
            let data = filterData;
            data.search = history.location.searchtext;
            setLoading(true);
            axios.get(baseUrl + '/user/doctorListFilter', { params: Object.assign({}, data), headers: { 'x-auth-token': userdata.token } })
                .then(res => {
                    setSearchDoctorList(res && res.data && res.data.user || []);
                    setLoading(false);
                })
                .catch(err => {
                    setLoading(false);
                })
        }
    }, [history.location])

    const clearFilter = async () => {
        setFilterData(
            { country: '', speciality: '', sort_type: '', start_price: '', last_price: '', language: '', search: '', type: "find_a_consultant" }
        )



        setSearchDoctorList([])



        fetchDoctorsList({ country: '', speciality: '', sort_type: '', start_price: '', last_price: '', language: '', search: '', type: "find_a_consultant" }
        )



    }



    return (
        <DashboardLayout>
             <Helmet>
        <title>Doctall Specialist: Video consultations with experienced specialists online
</title>
<meta 
            name='description' 
            content='Book an appointment and speak to a specialist online. Choose from a wide selection of disease specialists; gynaecologists, cardiologists, urologists and many others available now. Get downloadable prescriptions and lab test orders. Doctors available online now'
            />
      </Helmet>
            <div className="specialistContainer">
                <TitleWithNav title={"Find a Doctor / Specialist (International & Nigerian)"} />
                <div className="specialTitle">
                    Book your preferred Nigerian or International doctor / <br /> disease specialist at a time and cost that suits you.
                </div>

                <div className="searchContainerfind">
                    <Row >
                        <Col xs={12}>
                            <Row>
                                <Col md={12}>
                                    <Row style={{ paddingTop: '25px', paddingBottom: '25px' }}>
                                        <Col >
                                            <div className="searchContainerfindinput">
                                                <img crossorigin="anonymous" src={ICON10} className="searchimg" alt="search" />
                                                <input
                                                    type="text" value={inputValue}
                                                    placeholder="Search for doctors"
                                                    onChange={changeHandler}
                                                />
                                            </div>
                                        </Col>
                                        <Col className="doctors-section6-filter" onClick={() => openFilterForm(!filterForm)}>
                                            <img crossorigin="anonymous" src={Sort} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Col>

                    </Row>
                    {filterForm ? <div className="filter-section-form">
                        <Col xs={12}>
                            <FiltersForm
                                initialValues={filterData}
                                onChange={handleFilterChange}
                                fields={finddoctorsFilter}
                                metaData={Object.assign({}, { specialities: specialities.map(s => Object.assign({}, { value: s.name, label: s.name })) },
                                    { languages: languages.map(s => Object.assign({}, { value: s, label: s })) }
                                )}

                                handleSubmit={() => fetchDoctorsList(filterData)}
                                handleClear={() => clearFilter()}
                            />
                        </Col>
                    </div> : null}

                </div>
                {inital ? <Pagination
                    pageSize={6}
                    isloading={isloading}
                    records={searchdoctorList}>
                    {(lists) =>
                        <div className="listing">
                            {lists && lists.length > 0 && lists.map((item, index) => {
                                return (
                                    <>
                                        <div className="searchDoc">
                                            <DoctorProfile2 type='searchdoctor' data={item} />
                                        </div>
                                    </>
                                )
                            })}
                        </div>}
                </Pagination> :
                    <div className="specialistCards">
                        {SpecialListData.map((i) =>
                        (

                            <>
                                <SpecialCard
                                    title={i.title}
                                    para={i.parts}
                                    img={i.src}
                                    _onClick={() => {
                                        setInital(true);
                                        setSearchDoctorList([]);
                                        setinputValue(i.title)
                                        setSearchTerm(i.title)
                                    }}
                                    color={i.color}
                                />
                            </>
                        ))}
                    </div>} </div>
        </DashboardLayout>
    )
}

export default FindAnSpecialist
