import React, { useEffect, useState } from "react";
import AuthLayout from "../../layout/AuthLayout";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import CheckBtn2 from "../../components/CheckBtn/checkButton";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { Toast } from "../../components"
import { register, resendOtp } from "../../redux/auth/actions";
import axios from "../../redux/axios";
import { api } from "../../utils/constants";
import { getTokenUuid } from '../../firebase';

export default function SetPassCode() {
  const user = useSelector((state) => state.auth);
  let history = useHistory();
  const [terms, setTerms] = useState(false);
  const dispatch = useDispatch();
  const formData = JSON.parse(localStorage.getItem("regdata"));
  const [check, setCheck] = useState(false);
  const PassCodeSchema = Yup.object().shape({
    passcode: Yup.string()
      .required("Passcode Required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(4, "Must be exactly 4 digits")
      .max(4, "Must be exactly 4 digits"),
    confirmPasscode: Yup.string()
      .required("Passcode confirmation Required")
      .oneOf([Yup.ref('passcode'), null], 'Passwords must match')
      .matches(/^[0-9]+$/, "Must be Real Numbers")
      .min(4, "Must be exactly 4 digits")
      .max(4, "Must be exactly 4 digits"),
  });

  const initialValues = {
    passcode: "",
    confirmPasscode: "",
  };
  const dispatchOtp = async () => {
    const email = formData.emailAddress;
    const mobile = formData.phone.toString();
    const val = { mobile, email }
    await axios.post(api.resetpassword, val)
      .then((response) => response);
  };

  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues,
    validationSchema: PassCodeSchema,
    onSubmit: (values) => {
      if (terms === false) {
        setCheck(true);
      } else {
        const firstName = formData.firstName.trim();
        const middleName = formData.middleName;
        const lastName = formData.lastName.trim();
        const full_name =
          formData.firstName +" "+ formData.middleName +" "+ formData.lastName;
        const mobile = formData.phone.toString();
        const email = formData.emailAddress;
        const group = "consumer";
        const password = values.confirmPasscode.toString();
        const refered_code = formData.referal;
        const val = {
          full_name,
          firstName,
          lastName,
          middleName,
          email,
          mobile,
          password,
          group,
          refered_code
        };
        dispatch(
          register(val, (res) => {
            if (res.uuid !== undefined) {
              getTokenUuid(res.uuid, res.token)
              dispatchOtp();
              history.push("/confirmVerification");
              localStorage.setItem("CTKN", res.token);
              localStorage.setItem("LoginData", JSON.stringify({ mobile, password }));
            }
          })
        );
      }
    }
  },
  );

  return (
    <AuthLayout>
      <div className="setpassCodeContainer">
        <Toast text={"Accept Our Terms"}
          type="error" open={check} setClose={() => { setCheck(false) }} />
        <div className="back">
          <img crossorigin="anonymous"
            style={{ height: "12px", width: "13px" }}
            src={require("../../assets/images/back.svg").default}
            alt=""
          />
          <Link to="/signUp">Back</Link>
        </div>
        <div className="heads">
          <h2>Setup Passcode</h2>
          <p>Choose a passcode you can easily remember</p>
        </div>
        <form onSubmit={handleSubmit}>
          <label style={{ marginTop: "0px" }} htmlFor="passcode">
            Passcode
          </label>
          <div>
            <input
              className="inputForm"
              type="password"
              maxLength={4}
              name="passcode"
              value={values.passcode}
              onChange={handleChange}
            />
            {errors.passcode ? (
              <div className="error">{errors.passcode}</div>
            ) : null}
          </div>
          <label htmlFor="Confirmpasscode">Confirm Passcode</label>
          <div>
            <input
              className="inputForm"
              type="password"
              maxLength={4}
              name="confirmPasscode"
              value={values.confirmPasscode}
              onChange={handleChange}
            />
            {errors.confirmPasscode ? (
              <div className="error">{errors.confirmPasscode}</div>
            ) : null}
          </div>
          <div className="radioCheck1">
            <CheckBtn2
              name={"terms"}
              value={
                "I accept Doctall's Terms and Conditions"
              }
              onClick={() => {
                terms === true
                  ? setTerms(false)
                  : setTerms(true);
              }}
              checked={terms === true}
              img={require("../../assets/images/check.svg").default}
              imgAct={
                require("../../assets/images/checkact.svg").default
              }
            />

            {/* <input
              classname="inputForm"
              type="checkbox"
              name="checked"
              value="ok"
              onChange={handleChange}
              id="clicker"
            />
            <p>I accept Doctall's Terms and Conditions</p> */}
          </div>
          <button type="submit" className="sumbitBtn">
            Create account
          </button>
        </form>
      </div>
    </AuthLayout>
  );
}
