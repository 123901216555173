const axios = require("axios");

axios.interceptors.request.use((req) => {
  const token = localStorage.getItem('CTKN');
  req.headers["x-auth-token"] = token;
  return req;
}, (err) => {
  return Promise.reject(err)
});

export default axios;