import React, { useState } from "react";
import { useCookies } from 'react-cookie';
import DeleteFailure from "../assets/images/DeleteFailure.png";
import DeleteSuccess from "../assets/images/DeleteSuccess.png";
import Delete from "../assets/images/Delete_icon.png";
import ICON11 from "../assets/images/ICON11.svg";
import ICON12 from "../assets/images/ICON12.svg";
import ICON5 from "../assets/images/ICON5.svg";
import ICON6 from "../assets/images/ICON6.svg";
import ICON7 from "../assets/images/ICON7.svg";
import Wallet from "../assets/images/wallet.png";
import "./DashboardLayout.scss";

import { Dialog, DialogContent } from '@material-ui/core';
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Close from "../assets/images/close.svg";
import { logout } from "../redux/auth/actions";
import { api, passcodeKey, tokenKey, usernameKey } from "../utils/constants";

const DataList = [
  "Consultations",
  "Lab tests order",
  "Treatment plan orders",
  "Health Screening Orders",
  "Stored Health records",
  "Wallet amount"
]

const Sidemenumodal = (props) => {
  const history = useHistory();
  const [cookies, setCookie] = useCookies([usernameKey, passcodeKey, tokenKey]);
  const [dailogOpen, setDailogOpen] = useState(false);

  const [showmsg, setShowmsg] = useState(false);
  const [showmsg1, setShowmsg1] = useState(false);

  const userdata = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleDailog = (e) => {
    setDailogOpen(true)
  }

  const logoutfn = () => {
    localStorage.clear();
    dispatch(logout());
    console.log('consumer location clearing cookie ');
    setCookie(usernameKey, null);
    setCookie(passcodeKey, null);
    setCookie(tokenKey, null);
    setTimeout(() => {
      document.cookie = 'doctallLog' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=.doctall.com';
      history.push("/");
    });
  };

  const DeleteAccount = () => {
    axios.delete(`${api.accountDelete}/${userdata.uuid}`)
      .then((res) => {
        if (res.data.message == "Successfully Deleted!") {
          setShowmsg1(true)
        } else {
          setShowmsg(true)
        }
        console.log(res, "resp")
      })
      .catch((err) => {
        console.log(err.response, "resp")
        if (err.response.data == "Unable Delete. Some Order And Appointment is not completed.") {
          setShowmsg(true)
        }
      })
  }
  const redirectToDMP = () => {
    let url = process.env.REACT_APP_DMP_URL
    window.location.href = url
  }


  return (
    <div className={`sidemenu sidemenu-modal ${props.style}`}>
      <div
        className={
          history.location.pathname.indexOf("dashboard") > -1
            ? "menu active"
            : "menu"
        }
        onClick={() => {
          history.push("/dashboard");
        }}
      >
        <img crossorigin="anonymous" src={ICON5} alt="doctall"></img>Home
      </div>
      <div
        className={
          history.location.pathname.indexOf("appointment") > -1
            ? "menu active"
            : "menu"
        }
        onClick={() => {
          history.push("/appointments");
        }}
      >
        <img crossorigin="anonymous" src={ICON6} alt="doctall"></img>
        Appointments
      </div>
      <div
        className={
          history.location.pathname.indexOf("/healthrecords") > -1
            ? "menu active"
            : "menu"
        }
        onClick={() => {
          history.push("/healthrecords");
        }}
      >
        <img crossorigin="anonymous" src={ICON7} alt="doctall"></img>Health
        Records
      </div>

      <div
        className={
          history.location.pathname.indexOf("/Wallet") > -1
            ? "menu active"
            : "menu"
        }
        onClick={() => {
          history.push("/Wallet");
        }}
      >
        <img crossorigin="anonymous" src={Wallet} alt="doctall"></img>
        Wallet
      </div>


      <div
        className={
          history.location.pathname.indexOf("/orders-results") > -1
            ? "menu active"
            : "menu"
        }
        onClick={() => {
          history.push("/orders-results");
        }}
      >
        <img crossorigin="anonymous" src={ICON7} alt="doctall"></img>
        Orders & Results
      </div>

      {/* Support Start  */}

      <div
        className={
          history.location.pathname.indexOf("profile") > -1
            ? "menu active"
            : "menu"
        }
        onClick={() => {
          history.push("/support");
        }}
      >
        <img crossorigin="anonymous" src={ICON7} alt="doctall"></img>
        Support
      </div>

      <div
        className={"menu"
        }
        onClick={() => { handleDailog() }}
      >
        <img crossorigin="anonymous" src={Delete} alt="doctall"></img>
        Delete Account
      </div>

      {/* Support End */}


      {/* <div className="menu" style={{ color: "#ADC2C2" }}>
        <img crossorigin="anonymous" src={ICON8} alt="doctall"></img>Health
        Tracker
      </div> */}
      <div className="container marginbox" onClick={redirectToDMP} style={{ cursor: 'pointer' }}>
        Buy a Diagnostic Test with a discount
        {/* <div>COMING SOON</div> */}
      </div>
      {/* <div className="container">
        Buy a Health Panel
        <div>COMING SOON</div>
      </div> */}
      <div className="containerTwo">
        <a
          href="https://forms.clickup.com/f/4hnn1-543/J66634MTO2OOU28XUR"
          target="blank"
        >
          Help and Support
        </a>
        <a href={`${process.env.REACT_APP_NEWDOCTALL_URL}/faq`} target="blank">
          FAQs
        </a>
        {/* <a >Leave us Feedback</a> */}
        <a href={`${process.env.REACT_APP_NEWDOCTALL_URL}/terms-condition`} target="blank">
          Terms & Conditions
        </a>
        <a href={`${process.env.REACT_APP_NEWDOCTALL_URL}/partner`}  target="blank">
          Find a partner
        </a>
      </div>
      <div className="doctallCom">
        <a href={`${process.env.REACT_APP_NEWDOCTALL_URL}`}>
          <img crossorigin="anonymous" src={ICON12} alt="doctall.com"></img>
          <img crossorigin="anonymous" src={ICON11} alt="arrow"></img>
        </a>
      </div>
      <Dialog
        disableBackdropClick
        open={dailogOpen}
        onClose={() => setDailogOpen(false)}
        maxWidth='xs'
        fullWidth={true}
        minWidth="xs"
        style={{ borderRadius: "12px" }}
      >
        <DialogContent>
          <div className="DeleteAccountdiv">

            {showmsg === false && showmsg1 === false && <>
              <img
                src={Close}
                alt="close"
                className="closePop1"
                onClick={() => {
                  setShowmsg(false);
                  setShowmsg1(false);
                  setDailogOpen(false);
                }}
              />
              <img src={Delete} alt="icon" className="info1" />
              <div className="txt1">
                Are you sure you want to delete your Doctall account?
              </div>
              <br></br>
              <div className="txt1">You will lose access to your;</div>
              <div className="txt1">
                {DataList.map((item) => {
                  return <li style={{ marginLeft: "15px" }}>{item}</li>
                })}
              </div>
              <br></br>
              <div class="row">
                <div class="col-md-6">
                  <button className="btn1" onClick={() => setDailogOpen(false)}>Keep Account</button>
                </div>
                <div class="col-md-6">
                  <button className="btn11" onClick={DeleteAccount}>Delete</button>
                </div>
              </div>
            </>
            }
            {showmsg === true && <>
              <img
                src={Close}
                alt="close"
                className="closePop1"
                onClick={() => {
                  setShowmsg(false);
                  setShowmsg1(false);
                  setDailogOpen(false);
                }}
              />
              <img src={DeleteFailure} alt="icon" className="info1" />
              <div className="txt1">
                Your account cannot be deleted because there is an open order being processes. Kindly close the order to complete account deletion.
              </div>
              <br></br>
              <div className="txt1">For more details, kindly contact us on 9010996000 or support@doctall.com</div>

              <br></br>
              <div class="row">
                <div class="col-md-12">
                  <button className="btn11"
                    onClick={() => {
                      setShowmsg(false);
                      setShowmsg1(false);
                      setDailogOpen(false);
                      history.push("/orders-results");
                    }}
                  >Go to Orders</button>
                </div>
              </div>
            </>
            }

            {showmsg1 === true && <>
              <img
                src={Close}
                alt="close"
                className="closePop1"
                onClick={() => {
                  setShowmsg(false);
                  setShowmsg1(false);
                  setDailogOpen(false);
                  logoutfn();
                }}
              />
              <img src={DeleteSuccess} alt="icon" className="info1" />
              <div className="txt1">
                Your journey with Doctall ends now and we are sad to see you go. Thank you for trusting us with your health.
              </div>
              <br></br>
              <div class="row">
                <div class="col-md-12">
                  <button className="btn11"
                    onClick={() => {
                      setShowmsg(false);
                      setShowmsg1(false);
                      setDailogOpen(false);
                      logoutfn();
                    }}>Done</button>
                </div>
              </div>
            </>
            }
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default Sidemenumodal;
