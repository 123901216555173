import React from 'react'
import Layout from '../../layout/DashboardLayout'
import { ProfileForm } from '../../components'
import { useSelector } from 'react-redux'
import { ProgressBar } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import './health.scss'
import { Link, useHistory } from 'react-router-dom';

function AddRelative() {
    const [progressNum, setProgressNum] = React.useState(0);
    const userdata = useSelector(state => state.auth)
    const [loader, setLoader] = React.useState(false)
    const incrementProgress = () => {
        setProgressNum(progressNum + 25)
    }
    let history = useHistory()

    return (
        <Layout loadin={loader} load={setLoader}>
             <Helmet>
      <title>Add Relatives - Doctall Consumer Web




</title>
<meta 
            name='description' 
            content='Add relatives on Doctall.  Book appointments for relatives and dependents, see their health information and appointments

            '
            />
    </Helmet>
            <div className="profileHead">
                <div className="profileInner1">
                    <div>
                        <Link onClick={() => history.goBack()} style={{ color: '#66B5C3' }}> <img crossorigin="anonymous" style={{ margin: "0 4px 5px 0" }} src={require('../../assets/images/ICON13.svg').default} /> Back</Link>
                        <h1>Add Relative Profile</h1>
                    </div>
                </div>

                <div className="profileInner2">
                    <h4 className="progressHead">Relative Profile completion</h4>
                    <div className='progressSpace'>
                        <ProgressBar animated now={progressNum} />
                        <span style={{ color: '#37CC8C' }}>{progressNum}%</span>
                    </div>
                </div>
            </div>
            <div className="profileFormCard">
                <ProfileForm
                    Progress={incrementProgress}
                    type="addProfile"
                    setProgressNum={setProgressNum}
                    setLoading={setLoader} />
            </div>
        </Layout>
    )
}

export default AddRelative
