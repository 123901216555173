import React from 'react';
import './Label.scss';

function Label(props) {
    const {text}=props;
    return (
        <div className={`${props.hr?'label-health-record-filter':'label'}`}>
            {text}
        </div>
    )
}

export default Label
