import React from 'react'
import './cardForm.scss'

function CardForm({ head, children, type }) {
    return (
        <div className="tabCard">
            <div className="cardProfile">
                <h3>{head}</h3>
                {type === "addProfile" && <div className='cautionDiv'>
                    <img crossorigin="anonymous" alt=""
                        src={require('../../assets/images/smallCaution.svg').default} />
                    <p>Please ensure you document this relative’s contact information and not your own</p>
                </div>}

            </div>
            <div className="cardForm">
                {children}
            </div>
        </div>
    )
}

export default CardForm
