import types from "./types";

const initialState = {
  isAuthenticated: false,
  loading: false,
  splash: true,
  error: null,
  nofity: 0,
  isName: null,
  sent: null,
  location: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        loading: false,
        error: false,
        donenow: true,
      };
    case types.LOGIN_SUCCESS_NOTVERIFY:
      return {
        ...action.payload,
        loading: false,
        error: null,
        donenow: true,
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: action.payload,
      };
    case types.VERIFY_USER:
      return {
        ...state,
        loading: true,
      };
    case types.VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        splash: false,
        error: null,
        isAuthenticated: true,
        donenow: false,
        ...action.payload,
      };
    case types.VERIFY_ERROR:
      return {
        isAuthenticated: false,
        loading: false,
        splash: false,
      };
    case types.LOADING_STOP:
      return {
        ...state,
        loading: false,
        splash: false,
      };
    case types.REGISTER_USER:
      return {
        ...state,
        loading: true,
      };
    case types.REGISTER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        sent: true,
      };
    case types.REGISTER_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        loading: false,
        error: action.payload,
        sent: false,
      };
    case types.REGISTER_COMPLETE:
      return {
        ...state,
        isAuthenticated: true,
        donenow: true,
        verified: true,
      };
    case types.NOTIFY_UPDATE:
      return {
        ...state,
        nofity: action.data,
      };
    case types.CART_UPDATE:
      return {
        ...state,
        cart: action.data,
      };
    case types.UPDATE_PROFILE:
      return {
        ...state,
        profile: action.data,
      };
    case types.LOGOUT:
      return {
        isAuthenticated: false,
        loading: false,
      };
    case types.GO_BACK:
      return { isName: null };
    case types.CHECK_AVALIABLITY:
      return {
        ...state,
        isName: null,
      };
    case types.AVALIABLITY_SUCCESS:
      return {
        ...state,
        isName: true,
      };
    case types.AVALIABLITY_ERROR:
      return {
        ...state,
        isName: false,
      };
    case types.VERIFY_OTP:
      return {
        ...state,
        verified: null,
      };
    case types.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        verified: true,
      };
    case types.VERIFY_OTP_ERROR:
      return {
        ...state,
        verified: false,
      };
    case types.RESEND_OTP:
      return {
        ...state,
        resend_otp: null,
      };
    case types.RESEND_OTP_SUCCESS:
      return {
        ...state,
        resend_otp: true,
      };
    case types.RESEND_OTP_ERROR:
      return {
        ...state,
        resend_otp: false,
      };
    default:
      return state;
  }
};

export default authReducer;
