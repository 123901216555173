import React, { useState, useEffect } from "react";
import AuthLayout from "../../layout/AuthLayout";
import { useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { verifyOtp, resendOtp } from "../../redux/auth/actions";
import * as Yup from "yup";
import { Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import { Toast } from "../../components";
import axios from "../../redux/axios";
import { api, passcodeKey, usernameKey } from "../../utils/constants";
import { decrypt, encrypt } from '../../utils';
import { login } from "../../redux/auth/actions";

import { useCookies } from 'react-cookie';
import CryptoJS from 'crypto-js'

const initialValues = {
  receivedCode: "",
};

export default function ConfirmVerification() {
  const [cookies, setCookie] = useCookies([usernameKey, passcodeKey, 'passcode']);
  const [seconds, setSeconds] = useState(180);
  const [success, setSuccess] = useState(false);
  const [resentOtp, setResentOtp] = useState(false);
  const [invalidOtp, setInvalidOtp] = useState(false);
  const user = useSelector((state) => state.auth);
  let history = useHistory();
  const dispatch = useDispatch();
  console.log(localStorage.getItem("LoginData"),"LoginData")
  const LoginData = JSON.parse(localStorage.getItem("LoginData"));
  // const PASSCODE = JSON.parse(localStorage.getItem("PASSCODE"));
  console.log( LoginData, "LoginData")
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const verfiySchema = Yup.object().shape({
    receivedCode: Yup.string()
      .required()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, "Must be exactly 6 digits"),
  });

  const handleResend = async () => {
    const email = user.email;
    const mobile = user.mobile;
    const val = { mobile, email };
    await axios.post(api.resetpassword, val).then(
      (response) => {
        setResentOtp(true);
        setSeconds(180);
      },
      (error) => {
        // setRes(true)
      }
    );
  };
  useEffect(() => {
    console.log(user.verified,"user.verified")
    if (user.verified === true) {
      const username = LoginData.username;
      const password = LoginData.password;
      const type = "loginwithcredential"
      const val = { username, password, type };
      dispatch(
        login(val, (res) => {
          setSuccess(true);
          setTimeout(() => {
            history.push("/dashboard");
          }, 3000);
        })
      );
    }
  }, [user.verified]);

  useEffect(() => {
    const timer =
      seconds > 0 && setInterval(() => setSeconds(seconds - 1), 1000);
    return () => clearInterval(timer);
  }, [seconds]);

  const { handleSubmit, handleChange, values, errors, } = useFormik({
    initialValues,
    validationSchema: verfiySchema,
    onSubmit: (values) => {
      const otp = values.receivedCode.toString();
      const mobile = user.mobile;
      const username = user.mobile;
      const password = LoginData.password;
      const loginval = { username, password };
      const val = { mobile, otp };
      const typess = "loginwithcredential"
      const payLoad = { username, password, typess }
      console.log(payLoad,"res")
      dispatch(
        verifyOtp(val, (res) => {
          console.log("res", res)
          if (res === "error") {
            setInvalidOtp(true);
            {console.log(res.isAuthenticated , res.verified ,"val55")}
          }
          
          else if(res.verified === true){
            {console.log(res.isAuthenticated , res.verified ,"val55")}
            
            const username = user.mobile;
            const password = LoginData.password;
            const type = "loginwithcredential"
            const payLoad = { username, password, type }
            {console.log(payLoad,"val55")}
            dispatch(
              login(payLoad, (res) => {
                {console.log(res.isAuthenticated , res.verified ,"val55")}
                if (res.verified === false) {
                  let values = {
                    email: res.email,
                    mobile: res.mobile,
                  };
                  axios.post(api.resetpassword, values).then((res) => {
                    // setVerified(true);
                    setTimeout(function () {
                      // setVerified(false);
                      history.push("/confirmVerification");
                    }, 3000);
                  }
                  )
                }
                else if (res.verified === true) {
                  console.log("success")
                  history.push("/dashboard");
                }

                var cipherUsername = encrypt(payLoad.username);
                var cipherPassword = encrypt(payLoad.password);
            
                const cookieOptions = { path: '/', secure: process.env.REACT_APP_COOKIE_SECURE, domain: process.env.REACT_APP_COOKIE_DOMAIN }
            
                setCookie(usernameKey, cipherUsername, cookieOptions);
                setCookie(passcodeKey, cipherPassword, cookieOptions);
    
                // var cipherUsername = CryptoJS.AES.encrypt(res.username, process.env.REACT_APP_SECRET_KEY).toString();
                // var cipherPassword = CryptoJS.AES.encrypt(password, process.env.REACT_APP_SECRET_KEY).toString();
                // var cipherToken = CryptoJS.AES.encrypt(res.token, process.env.REACT_APP_SECRET_KEY).toString();
    
                // setCookie('username', cipherUsername, { domain: process.env.REACT_APP_DOMAIN });
                // setCookie('passcode', cipherPassword, { domain: process.env.REACT_APP_DOMAIN });
                // setCookie('token', cipherToken, { domain: process.env.REACT_APP_DOMAIN });
    
                localStorage.setItem("CTKN", res.token);
                localStorage.setItem("PASSCODE", password);
              })
            );
          }
        })
      );
    },
  });

  return (
    <AuthLayout>
      <div className="confirmVerification">
        <Toast
          text={"Sign-Up Success, Please wait till we redirect"}
          type="success"
          open={success}
          setClose={() => {
            setSuccess(false);
          }}
        />
        <Toast
          text={"Otp Sent successfully"}
          type="success"
          open={resentOtp}
          setClose={() => {
            setResentOtp(false);
          }}
        />
        <Toast
          text={"Invalid Otp"}
          type="error"
          open={invalidOtp}
          setClose={() => {
            setInvalidOtp(false);
          }}
        />

        <div className="heads">
          <h2>Confirm Verification Code</h2>
          <p>Kindly check your email and enter the code you received</p>
        </div>
        <form onClick={handleSubmit}>
          <label htmlFor="Verification Code">Verification Code</label>
          <div>
            <input
              className="inputForm"
              type="text"
              name="receivedCode"
              maxLength="6"
              pattern="\d{4}"
              value={values.receivedCode}
              onChange={handleChange}

            />
            {errors.receivedCode ? (
              <div className="error">{errors.receivedCode}</div>
            ) : null}
          </div>
          <button type="submit" className="sumbitBtn" 
          onClick={handleSubmit}
          >
            Confirm Code
          </button>
        </form>
        <div className="resendCode">
          <p>
            If you did not receive the code after 3 minutes please select <br />
            {seconds > 0 ? <div>Time Left: {seconds} seconds </div> : ""}
            {seconds === 0 ? (
              <>
                <button
                  onClick={() => {
                    handleResend();
                  }}
                >
                  Resend
                </button>
              </>
            ) : null}
          </p>
        </div>
      </div>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>
            <div className="dialoglimit">
              <img
                crossorigin="anonymous"
                src={require("../../assets/images/error.png").default}
                alt=""
              />
              <p>
                You have exceeded your code entry limit. To assist you, kindly
                reach out to us via
              </p>{" "}
              <div className="support">
                support@doctall.com <br />
                or call 09010996000
              </div>
              <img
                crossorigin="anonymous"
                className="xbutton"
                onClick={handleClose}
                src={require("../../assets/images/Xbutton.svg").default}
                alt=""
              />
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </AuthLayout>
  );
}
