import React, { useState, useEffect } from "react";
import Layout from "../../layout/DashboardLayout";
import './Healthrecords.scss';
import { HealthTab } from "../../components";




const Healthrecords = () => {
const [loader,setLoader]=useState(false)
    
    return (
        <>
      
        <Layout loadin={loader} load={setLoader}>
            <div className="cont-wrapper-health-record">
                <div className="text-wrap">
                <div className="header-text"></div>
                <div className="sub-header">View your most recent prescriptions</div>
                </div>
                <HealthTab load={setLoader}/>
            </div>
        </Layout>
        </>
    )
}
export default Healthrecords;