import React from 'react';
import './SecondaryLabel.scss';

function Label({ text }) {
    return (
        <div className="secondaryLabel">
            {text}
        </div>
    )
}

export default Label
