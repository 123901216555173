/**
 * 
 * Doctor Profile
 * 
 */

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Rating from '@material-ui/lab/Rating';
import DefaultProfile from '../../assets/images/pic.svg';
import Indian from "../../assets/images/indian.svg";
import Nigerian from "../../assets/images/nigerian.svg";
import British from "../../assets/images/ukflag.png";
import { getYear } from '../../utils';
import './style.scss';
import { useHistory } from "react-router-dom"



function DoctorProfile(props) {
    const { item = {} } = props;
    const { full_name, profile = {}, consultation_fee, profile_pic, slots = [], uuid, bio } = item || {};
    const { speciality = [], address = {}, languages = [], rating, practice_started_year, profileVideoUrl = [] } = profile;
    const history = useHistory();

    return (
        <Col sm={6} md className="doctor-profile-section" onClick={() => { props.onDocChange(uuid) }}>
            <Row>
                <Col xs={12}>
                    {profile && profile.avatar && profile.avatar != null && profile.avatar != "" ?
                        <img crossorigin="anonymous" style={{ width: '100%', height: '240px' }} className="doctor-profile-photo" src={profile.avatar} alt="" crossorigin="anonymous" /> :
                        <img crossorigin="anonymous" style={{ width: '100%', height: '240px', objectFit: "none" }} className="doctor-profile-photo" src={DefaultProfile} alt="" crossorigin="anonymous" />}
                </Col>
                <Col xs={12}>
                    <Row>
                        <Col sm={10}>
                            <Row>
                                <Col xs={12} className="doctor-profile-full-name">Dr {full_name || '-'}                                     {address && address.country ? <img crossorigin="anonymous" src={address.country == 'India' ? Indian : address.country === 'Nigeria' ? Nigerian : British} style={{ width: '16px', height: '16px' }} /> : null}
                                </Col>
                                <Col xs={12} className="doctor-profile-speciality">
                                    {speciality && Array.isArray(speciality) && speciality.length > 0 && speciality.join(',') || '-'}
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={2}>
                            <img crossorigin="anonymous" sc="" />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="doctor-profile-details">
                            <Row>
                                <Col xs={12} className="doctor-profile-location">
                                    <div>Location: {address && address.country || '-'}</div>
                                </Col>
                                <Col xs={12}>Experience: {practice_started_year ? getYear(practice_started_year) : '-'}</Col>
                                <Col xs={12}>Languages Spoken: </Col>
                                <Col xs={12}>{languages && Array.isArray(languages) && languages.length > 0 ? languages : '-'}</Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Rating name="disabled" value={0} disabled />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Col>
    )
}

export default DoctorProfile;
