import React, { useState, useEffect } from "react";
import DashboardLayout from '../../layout/DashboardLayout'
import './Appointment.scss'
import { TitleWithNav, ConsulationList, DoctorProfile, Pagination } from "../../components"
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import axios from '../../redux/axios';
import { api } from '../../utils/constants';

function Appointment() {
    const userdata = useSelector(state => state.auth);
    const [dataval, setDataVal] = useState([])
    const [isloading, setLoading] = useState(true)
    const [count, setCount] = useState(0)
    const [Page, setPage] = useState(1);

    const ApiFetch = (pageNum) => {
        axios.get(api.appointmentall, { params: { "created_by.uuid": userdata.uuid, 'page': pageNum, 'limit': 6 }, headers: { 'x-auth-token': userdata.token } })
            .then(res => {
                let result = res?.data?.data?.filter((e) => e.status != "pending");
                result.sort(function (a, b) {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                });
                setCount(res?.data?.count)
                setDataVal(result);
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)

            })
    }

    useEffect(() => {
        setLoading(true)
        ApiFetch(Page - 1);
    }, [Page]);



    return (
        <DashboardLayout>
            <div className="appointment">
                <TitleWithNav title={"Home Appointment"} />
                <ConsulationList />
                {dataval.length > 0 && <div className="titlelist">Browse through your appointments</div>}
                <Pagination
                    pageSize={6}
                    isloading={isloading}
                    records={dataval}
                    setPageNum={setPage}
                    pageChange={true}
                    Count={count}
                >
                    {(pageData) => {
                        return <div className="listing">
                            {dataval && dataval.length > 0 && dataval.map((item, index) => {
                                return (<ProfileList item={item} key={index} token={userdata.token} />)
                            })}
                        </div>
                    }
                    }
                </Pagination>
            </div>
        </DashboardLayout>
    )
}
const ProfileList = (props) => {
    const { item } = props
    return (
        <div className="appointDoctor">
            <DoctorProfile type="appointlist" data={item} />
        </div>
    )
}
export default Appointment