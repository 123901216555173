import React, { useState, useEffect } from "react";
import Info from "../../../../../../assets/images/infoerror.svg";
import "./labtest.scss";
import { useSelector, useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import moment from "moment";
import axios from "../../../../../../redux/axios";
import { api } from "../../../../../../utils/constants";
import { getPatients } from "../../../../../../redux/patients/actions";
import Phoneicon from "../../../../../../assets/images/phone.svg";
import Viewicon from "../../../../../../assets/images/view.svg";
import Pagination from "../../../../../../components/Pagination";
import { Dialog } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "../../../../../../assets/images/close.svg";
import FailurePdf from "../prescription/Failurepdf";
import Filterrecord from "../../components/filter";
import Toast from "../../../../../../components/Toast";
import { useHistory } from "react-router";
import { Modal } from "react-bootstrap";
import not from "../../../../../../assets/images/hpicon.svg";
import LabReportView from "../../../../../Appointment/LabReportView";
const LabTest = (props) => {

  const user = useSelector((state) => state.auth);
  const SECRET_KEY = ')H@MccQffTjWnZr4u7x!A%CF*F-JaNdRgUkUkUXNh*F-JaNDOCTALL';
  const { setLoading } = props;
  const [slotpop, setSlotPOP] = useState(false);
  const [slotpop2, setSlotPOP2] = useState(false);
  const userdata = useSelector((state) => state.auth);
  const [doctotlist, setDoctorList] = useState([]);
  const [doctor, setDoctor] = useState();
  const [date, setDate] = useState();
  const [eprescriptionlist, setEprescription] = useState([]);
  const [openpdf, setOpenPdf] = useState(false);
  const [closepdf, setClosePdf] = useState(false);
  const [filterForm, openFilterForm] = useState(false);
  const [toster, setToster] = React.useState(false);
  const [toastMsg, setToastMsg] = React.useState("");
  const [toastType, setToastType] = React.useState("");
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [testInfo, setTestInfo] = useState();

  const handleClose = () => setShow(false);


  const fetchTestInfo = async (testId) => {
    try {
      setLoading(true)
      let response = await axios.get(`${api.recommendedTestPreview}/${testId}`, { headers: { 'x-auth-token': userdata.token } })
      setTestInfo(response && response.data && response.data.data)

      if (response && response.data && response.data.data && response.data.data.tests) {
        let testList = response.data.data.tests;
        let patient_uuid = response.data.data.patient.uuid;
        let recomended_partner_name = response.data.data.recomended_partner_name
        let recomended_partner_uuid = response.data.data.recomended_partner_uuid
        console.log(response.data.data.recomended_partner_name, "recomended_partner_name")
        let passcode = localStorage.getItem("PASSCODE");
        // Slash available in env file def..
        let DMP_URL = `${process.env.REACT_APP_DMP_URL}recommended-tests`;
        let itemList = [];
        testList.map((item) => {
          itemList.push({
            'test_uuid': item.uuid,
            'patient_uuid': patient_uuid,
            'advice_id': testId,
            "recomended_partner_name":recomended_partner_name,
          "recomended_partner_uuid":recomended_partner_uuid,
          })
        })
        var data = [{
          labAdvice: itemList,
          
          loginDetails: { userName: user.username, password: passcode }
        }]
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
        let encryptedKey = ciphertext.toString().replaceAll("/", "doctall")
        let url = `${DMP_URL}/${encryptedKey}`
        window.open(url, "_blank")
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }

  }



  const ApiFetch = () => {

    axios.get(api.eprescriptiondoctor, {
      headers: { "x-auth-token": userdata.token },
    }).then((r) => {
      setDoctorList(r.data.data);
    }).catch((err) => {
      setToster(true);
      // setToastMsg("Request Failed");
      setToastType("error");
    });


    // userdata.uuid

    axios.get(api.labTest + "?user_uuid=" + userdata.uuid, {
      headers: { "x-auth-token": userdata.token },
    }).then((r) => {
      let valdata = r.data.result;
      valdata.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setEprescription(valdata);
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
      setTimeout(() => {
        setToster(true);
        setToastMsg("Request Failed");
        setToastType("error");
      }, 100);
    });
  };



  const ViewAppointment = (booking_uuid) => {
    setLoading(true);
    axios
      .get(api.appointmentall + "/" + booking_uuid, {
        headers: { "x-auth-token": userdata.token },
      })
      .then((r) => {
        if (r.data.length == 0) {
          setTimeout(() => {
            setToster(true);
            setToastMsg("No Records Found");
            setToastType("error");
          }, 100);
          setLoading(false);
        } else {
          if (r.data[0].doctor_uuid) {
            axios
              .get(api.user + "/" + r.data[0].doctor_uuid, {
                headers: { "x-auth-token": userdata.token },
              })
              .then((res) => {
                let appoint = r.data[0];
                appoint.doctors = [res.data];
                history.push({ pathname: "/appointmentdetail", data: appoint });
                setLoading(false);
              })
              .catch((err) => setLoading(false));
          } else {
            history.push({ pathname: "/appointmentdetail", data: r.data[0] });

            setLoading(false);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        setTimeout(() => {
          setToster(true);
          setToastMsg(err.response ? err.response.data : "Error");
          setToastType("error");
        }, 100);
      });
  };

  useEffect(() => {
    ApiFetch();
    setLoading(true);
  }, []);


  const handleViewPres = (e, id) => {
    const caseid = id;
    let dt;
    let url;
    async function getpdf(id) {
      try {
        await axios.get(api.viewlab + id).then((res) => {
          if (res.data) {
            dt = res.data;
          }
        });
        handlePdfOpen();
        await fetch("data:application/pdf;base64," + dt)
          .then((res) => res.blob())
          .then((blob) => {
            url = window.URL.createObjectURL(blob);
          });
        const iframe = document.querySelector("#pdf");
        iframe.setAttribute("src", url);
      } catch (error) {
        failurePdfOpen();
      }
    }

    getpdf(caseid);
  };
  const handlePdfOpen = () => {
    setOpenPdf(true);
  };
  const handlePdfClose = () => {
    setOpenPdf(false);
  };
  const failurePdfOpen = () => {
    setClosePdf(true);
  };
  const failurePdfClose = () => {
    setClosePdf(false);
  };

  const onOrderDetails = (orderInfo) => {

    if (orderInfo && orderInfo.tests && orderInfo.tests.length > 0) {
      let id = orderInfo.tests[0].order_uuid
      let order_details = orderInfo;
      let orderDetails = { id }
      history.push({ pathname: '/orders-view-lab', state: id })
    }
  }


  return (
    <>
      <Dialog
        disableBackdropClick
        style={{ borderRadius: "0px" }}
        fullScreen
        maxWidth={"xl"}
        fullWidth={true}
        open={openpdf}
        scroll={"body"}
        onClose={handlePdfClose}
      >
        <div className="ContainerWrapper">
          <img
            crossorigin="anonymous"
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => handlePdfClose()}
          />
          <DialogContent>
            <iframe
              src=""
              crossorigin="anonymous"
              className="pdfiframe"
              id="pdf"
            ></iframe>
          </DialogContent>
        </div>
      </Dialog>
      <Dialog
        disableBackdropClick
        maxWidth={"sm"}
        open={closepdf}
        scroll={"body"}
        onClose={failurePdfClose}
      >
        <div className="ContainerWrapper">
          <img
            crossorigin="anonymous"
            src={Close}
            alt="Close"
            className="Closepopup"
            onClick={() => failurePdfClose()}
          />
          <FailurePdf
            text={"Something Went Wrong!"}
            subtext={"Please Contact admin"}
          />
        </div>
      </Dialog>
      <div className="lab-health-record">
        <div className="filter-row-lab-rec">
          <Filterrecord
            setEprescription={setEprescription}
            lab={true}
            setLoading={setLoading}
            labfn={ApiFetch}
          />
        </div>

        <div className="container-pres-tab-lab">
          <div className="info-container">
            <div className="info-img">
              <img crossorigin="anonymous" src={Info} />
            </div>
            <div className="info-text">
              Lab Test Tip : Buy recommended tests via your Lab Test Advice
            </div>
          </div>

          <Pagination pageSize={6} records={eprescriptionlist}>
            {(epres) => (
              <div className="card-align-cont">
                {epres &&
                  epres.length > 0 &&
                  epres.reverse().map((val, ind) => {
                    return (
                      <>
                        <div className="card-cont-pres" key={ind}>
                          <div className="badge-cont-outer">
                            <div className="badge-cont">
                              <span>
                                {val.finalstatus && val.finalstatus == "completed" ? "Completed" : val.finalstatus}
                              </span>
                            </div>
                            {/* <div
                              className="badge-cont"
                              style={
                                val.status == "pending"
                                  ? { backgroundColor: "#FEF3C7" }
                                  : { backgroundColor: "#D1FAE5" }
                              }
                            >
                              <span
                                style={
                                  val.status == "pending"
                                    ? {
                                      color: "#92400E",
                                      textTransform: "capitalize",
                                    }
                                    : {
                                      color: "#065F46",
                                      textTransform: "capitalize",
                                    }
                                }
                              >
                                {val.status}
                              </span>
                            </div> */}
                          </div>

                          <div className="card-content-det-wrapper">
                            <div className="card-content-det-out">
                              <div className="card-content-det">
                                <div className="card-font">
                                  Lab Test Advice: {val.uuid}
                                </div>
                              </div>
                              <div className="card-content-det">
                                <div className="card-font">
                                  Recommeded by:{" "}
                                  {val.doctor && val.doctor != undefined
                                    ? "Dr" + " " + val.doctor.full_name
                                    : ""}
                                </div>
                              </div>
                              <div className="card-content-det">
                                <div className="card-font">
                                  Lab Test Advice Date:{" "}
                                  {moment(val.createdAt).format("DD, MMM YYYY")}
                                </div>
                              </div>
                              {/* {val.status == "pending" && <div className="card-content-det">
                                <div className="card-font">
                                  Order Expires on:{" "}
                                  {moment(val.expire_on).format("DD MMMM YYYY")}
                                </div>
                              </div>} */}
                            </div>
                          </div>
                          {/* <div className="pending-btn"><span>Preview Prescription</span></div> */}
                          <div className="pending-cont-row">
                            {val.finalstatus && val.finalstatus !== "completed" ? (
                              <div
                                className="pending-button"
                                onClick={() => fetchTestInfo(val.uuid)}
                              >
                                <span>Buy Test</span>
                              </div>
                            ) : (
                              <div className="view-button" onClick={() => onOrderDetails(val)}>
                                <span>View Order</span>
                              </div>
                            )}
                            <div
                              className="icon-cont"
                              onClick={() => {
                                ViewAppointment(val.booking_uuid);
                              }}
                            >
                              <img crossorigin="anonymous" src={Phoneicon} />
                            </div>
                            <div
                              className="icon-cont"
                              id={val.uuid}
                              onClick={(e) => handleViewPres(e, val.uuid)}
                            >
                              <img crossorigin="anonymous" src={Viewicon} />
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
              </div>
            )}
          </Pagination>
        </div>
      </div>
      <Toast
        text={toastMsg}
        type={toastType}
        open={toster}
        setClose={() => {
          setToster(false);
        }}
      />

      <LabReportView
        open={show}
        close={() => { setShow(false) }}
        detailedInfo={testInfo}
        stopLoader={() => { setLoading(false) }}
        startLoader={() => { setLoading(false) }}
      />


      {/* <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-upload"
      >
        <div className="upcomming">
          <img
            src={Close}
            alt="close"
            className="closePop"
            onClick={() => setShow(false)}
          />
          <img src={not} alt="icon" className="info" />
          <div className="heading">Important Message</div>
          <div className="txt">
            Currently this service is not available, but we hope to introduce it
            in the coming weeks.
          </div>
          <div className="txt">We appologise for any inconvenience caused.</div>
          <div className="btn" onClick={() => history.push("/dashboard")}>
            Back
          </div>
        </div>
      </Modal> */}
    </>
  );
};

export default LabTest;
