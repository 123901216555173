import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Closebutton from "../../assets/images/closebutton.svg";
import Circle from "../../assets/images/circle.svg";
import Tick from "../../assets/images/tick.svg";
import "./submitpop.scss";

export default function Submitpop(props) {
  const url = "https://www.surveymonkey.com/r/9RXK9RF";
  return (
    <Modal {...props}
      aria-labelledby="contained-modal-title-submit-vcenter"
      contentClassName="modal-content-submit"

    >
      <div className="feedback-submit-pop-cont">
        <div className="header">
          <div className="close-btn">
            <img crossorigin="anonymous" src={Closebutton} alt="close-btn" onClick={() => props.onHide()} ></img>
          </div>
        </div>
        <div className="success-img-cont-wrap">
          <div className="success-img-cont">
            <div className="img-wrap">
              <img crossorigin="anonymous" src={Circle} alt="circle" ></img>
              <div className="img-tick">
                <img crossorigin="anonymous" src={Tick} alt="tick" ></img>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-header-wrap">
          <div className="sub-header">
            Thank you for providing post   appointment feedback!
          </div>
        </div>
        {/* <div className="container-bottom">
          <div className="text-cls">
            Answer some additional survey questions and qualify to win ₦10,000 cash in our weekly prize draw
          </div>
          <div className="inner-cont" onClick={(e) => {
            e.preventDefault();
            window.open(url, "_blank");
          }}>
            <span>Take survey now and Enter Prize Draw </span>
          </div>
        </div> */}
      </div>
    </Modal>
  );
}


