/**
 * 
 * Video Player Component
 * 
 * 
 */


import React, { useState } from 'react';
import { Row, Col, Container, Modal } from 'react-bootstrap';
import PlayIcon from '../../assets/images/playIcon.svg';
import PlayerThumbnail from '../../assets/images/playerThumbnail.svg';
import Close from '../../assets/images/close.svg';
import ReactPlayer from 'react-player';
import './style.scss';

/**
 * 
 * @param {object} props 
 * @returns 
 */
function VideoPlayer(props) {

    const { videos = [], children, popup, config = {} } = props;
    const [playVideoUrl, setPlayVideoUrl] = useState(false);
    const [playVideo, setPlayVideo] = useState(false);
    const [showModal, setShowModal] = useState(false);
    console.log('playVideoUrl', videos);

    return (<Row>
        {children && typeof children === 'function' ? children(() => setShowModal(true)) : children || null}
        {!popup && videos && Array.isArray(videos) && videos.length > 0 ? <Col xs={12}>
            <Row>
                <Col lg={10}>
                    <Row>
                        <Col xs={12} className="video-player">
                            <ReactPlayer
                                url={playVideoUrl || videos[0]}
                                style={playVideo ? { borderRadius: '5px', maxWidth: '100%', height: '315px' } : { backgroundColor: 'rgba(3, 35, 39, 0.76)', borderRadius: '5px', maxWidth: "100%" }}
                                playing={playVideo}
                                height={"315px"}
                                {...config}
                                onEnded={() => setPlayVideo(false)} />
                        </Col>
                        {!playVideo ? <Col xs={12} className="play-button-div">
                            <div className="video-play-button" onClick={() => setPlayVideo(playVideoUrl || videos[0] ? true : false)} style={{ backgroundImage: `url(${PlayIcon})` }}>
                            </div>
                        </Col> : null}
                    </Row>
                </Col>
                <Col lg={2}>
                    <Row>
                        {(videos || []).map((video, index) => {
                            return <Col key={index} style={{ marginTop: '15px' }}>
                                <div className="video-play-button-small" onClick={() => {
                                    if (video) {
                                        setPlayVideo(false);
                                        setPlayVideoUrl(video);
                                    } else {
                                        alert('Unable to Play Video');
                                    }
                                }} style={{ backgroundImage: `url(${PlayIcon})` }}></div>
                            </Col>
                        })}
                    </Row>
                </Col>
            </Row>
        </Col> : !popup ? <Col xs={12}>
            No Videos Found.
        </Col> : null}
        <Modal show={showModal} onHide={() => setShowModal(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modal-upload">
            <div className="uploadDocuments">
                <div className="header">
                    Videos
                    <img crossorigin="anonymous" src={Close} onClick={() => setShowModal(false)} style={{ cursor: 'pointer' }} alt="Close" />
                </div>
                <hr className="heading_line" />
                <main>
                    <Container>
                        <Row>
                            <Col xs={12} className="video-player">
                                <ReactPlayer
                                    url={playVideoUrl || videos[0]}
                                    style={playVideo ? { borderRadius: '5px', maxWidth: '100%', height: '315px', maxWidth: "100%" } : { backgroundColor: 'rgba(3, 35, 39, 0.76)', borderRadius: '5px', maxWidth: "100%" }}
                                    playing={playVideo}
                                    height={"315px"}
                                    onEnded={() => setPlayVideo(false)} />
                            </Col>
                            {!playVideo ? <Col xs={12} className="play-button-div">
                                <div className="video-play-button" onClick={() => setPlayVideo(playVideoUrl || videos[0] ? true : false)} style={{ backgroundImage: `url(${PlayIcon})` }}>
                                </div>
                            </Col> : null}
                        </Row>
                    </Container>
                </main>
            </div>
        </Modal>
    </Row>)
}

export default VideoPlayer;