import React, { useState, useEffect } from 'react';
import './notificationTab.scss';
import { Tabs, Tab, Typography, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import NotificationSection from "./Notification";

function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}


const NotificationTabs = () => {
  const [value, setValue] = useState(0)

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="noti-tab-align">
      <Tabs
        style={{ backgroundColor: '#FFFFFF', paddingRight: "20px" }}
        value={value}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="#00839B"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example">

        <Tab
          style={{
            color: "#00839B",
            fontSize: '16px',
            fontFamily: "VisueltMedium",
            backgroundColor: value === 0 ? '#F2F8F9' : '#FFFFFF'
          }}
          label="Notification"
          {...a11yProps(0)} />
        {/* <Tab
          style={{
            color: "#00839B",
            fontSize: '16px',
            fontFamily: "VisueltMedium",
            backgroundColor: value === 1 ? '#F2F8F9' : '#FFFFFF'
          }}
          label="Doctor’s Chat"
          {...a11yProps(0)} disabled /> */}
      </Tabs>

      <TabPanel value={value} index={0}>
        <NotificationSection />
      </TabPanel>
      <TabPanel value={value} index={1}>
      </TabPanel>
    </div>
  )
}

export default NotificationTabs;