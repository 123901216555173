/**
 * 
 * Radio Group
 * 
 * 
 */


 import React from 'react';



 export default function RadioGroup({ children, name, value, onChange }) {
 
     const childrenWithProps = React.Children.map(children, child => {
         if (React.isValidElement(child)) {
             return React.cloneElement(child, {
                 name,
                 onChange,
                 checked: child && child.props && child.props.value && child.props.value === value || false
             });
         }
         return child;
     });
 
     return (<div role="radiogroup" aria-label="gender">
         {childrenWithProps}
     </div>)
 }
 