import React, { Suspense, StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import createStore from './store'
import initFacebookSdk from "./fbsdk.js"
import 'bootstrap/dist/css/bootstrap.min.css';
import { getFirebaseInstance } from './firebase.js';

// import firebase from "firebase/app"
// import {firebaseConfig} from "./utils/firebaseConfig"

// import "firebase/analytics";

getFirebaseInstance();

const storeParams = createStore();
initFacebookSdk().then(startApp);

function startApp() {
  ReactDOM.render(
    <CookiesProvider>
      <Provider store={storeParams.store} >
        <PersistGate loading={null} persistor={storeParams.persistor}>
          <Suspense fallback={<div>loading..</div>}>
            <StrictMode>
              <App />
            </StrictMode>
          </Suspense>
        </PersistGate>
      </Provider>
    </CookiesProvider>
    ,
    document.getElementById('root')
  );
}
