 export const SpecialListData=[
    {
    title:"Endocrinology",
    parts:"Endocrine system such as diabetes,\nthyroid and hormone issues",
    src:require('../../assets/images/ArrowPrime.svg').default,
    link:'',
    color:'#66B5C3',
    },
    {
    title:"Obstetrics and Gynecology",
    parts:"Pregnancy, childbirth, postpartum\nand female reproductive systemy",
    src:require('../../assets/images/ArrowSecond.svg').default,
    link:'',
    color:'#76DCB0',
    },
    {
    title:"Urology",
    parts:"Urinary tract and male\nreproductive organs",
    src:require('../../assets/images/ArrowRed.svg').default,
    link:'',
    color:'#FF8D77',
    },
    {
    title:"Respiratory medicine",
    parts:"Respiratory system such as\nasthma, TB and lung disease",
    src:require('../../assets/images/ArrowYellow.svg').default,
    link:'',
    color:'#FFDA90',
    },
    {
    title:"Neurology",
    parts:"Brain and nervous system",
    src:require('../../assets/images/ArrowPrime.svg').default,
    link:'',
    color:'#66B5C3',
    },
    {
    title:"Pediatrics",
    parts:"Infants, children and adolescents",
    src:require('../../assets/images/ArrowSecond.svg').default,
    link:'',
    color:'#76DCB0',
    },
    {
    title:"Psychiatry",
    parts:"Emotional, behavioural and mental wellness",
    src:require('../../assets/images/ArrowRed.svg').default,
    link:'',
    color:'#FF8D77',
    },
    {
    title:"Cardiology",
    parts:"Heart and circulatory system",
    src:require('../../assets/images/ArrowYellow.svg').default,
    link:'',
    color:'#FFDA90',
    },
    {
    title:"Nephrology",
    parts:"Kidney disorders",
    src:require('../../assets/images/ArrowPrime.svg').default,
    link:'',
    color:'#66B5C3',
    },
    {
    title:"Dermatology",
    parts:"Skin disorders",
    src:require('../../assets/images/ArrowSecond.svg').default,
    link:'',
    color:'#76DCB0',
    },
    {
    title:"Internal Medicine",
    parts:"Adult internal diseases",
    src:require('../../assets/images/ArrowRed.svg').default,
    link:'',
    color:'#FF8D77',
    },
    {
    title:"Orthopaedics",
    parts:"Musculoskeletal system",
    src:require('../../assets/images/ArrowYellow.svg').default,
    link:'',
    color:'#FFDA90',
    },

 ]
 


