import { WallpaperTwoTone } from '@material-ui/icons'
import React, { useState, useEffect, useRef } from "react";
import Layout from '../../layout/DashboardLayout';
import { Row, Col } from "react-bootstrap";
import Profile from "../../assets/images/avatar.png";
import Arrow from "../../assets/images/Arr.png";
import Right from "../../assets/images/Right.png";
import Copy from "../../assets/images/copy.png";
import Purse from "../../assets/images/Purse.png";
import Debit from "../../assets/images/debit.svg";
import Credit from "../../assets/images/Credit.svg";
import Sort from "../../assets/images/Sort.svg";
import InputField from "../../components/InputField";
import FiltersForm from "../../components/FiltersForm";
import Search from "../../assets/images/Search.svg";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import axios from 'axios'
import "./wallet.scss";
import moment from 'moment'
import { Dialog, DialogContent } from '@material-ui/core';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
// import Dialog from 'react-dialog'
import {
  FacebookShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  RedditShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  EmailShareButton,


  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Helmet } from 'react-helmet';

export default function Wallet() {
  const history = useHistory();
  const [walletData, setWalletData] = useState('')
  const [activityData, setActivityData] = useState([])
  const [historyData, setTransHistoryData] = useState([])
  const [dailogOpen, setDailogOpen] = useState(false)



  const userdata = useSelector((state) => state.auth);

  const [copySuccess, setCopySuccess] = useState('');

  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    console.log(textAreaRef.current.value)
    textAreaRef.current.select();
    document.execCommand('copy');
    // This is just personal preference.
    // I prefer to not show the the whole text area selected.
    e.target.focus();
    setCopySuccess('Copied!');
  };


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/user/wallethistory/wallet/${userdata.uuid}`)
      .then((res) => {
        setWalletData(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/user/wallethistory/referal/credit/list?user_uuid=${userdata.uuid}`)
      .then((res) => {
        // console.log(res.data.data,"shj")
        setActivityData(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/user/wallethistory/list?user_uuid=${userdata.uuid}`)
      .then((res) => {
        // console.log(res.data.data,"shj")
        setTransHistoryData(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const opend = () => {
    setDailogOpen(true)
  }
  return (
    <Layout>
|<Helmet>
      <title>Wallet - Doctall Consumer Web

</title>
<meta 
            name='description' 
            content='Invite friends to join Doctall and earn referral bonuses to your wallet. See the number of referrals you have and amount earned with your unique referral code.'
            />
    </Helmet>
      <div style={{ marginLeft: "5%" }}>
        <div className="header-text-1">Wallet</div>
      </div>
      <div className="Main mt-4 p-2" >
        <div className='main-con' style={{ display: "flex" }}>
          <Col md={8}>
            <div style={{ display: "flex" }}>
              <div>
                <img
                  crossorigin="anonymous"
                  src={Purse}
                  className="profileimg"
                  alt="doctall"
                  style={{ borderRadius: "50%", padding: "10px", width: "80px", background: "#00839B" }}
                ></img>
              </div>
              <div style={{ paddingLeft: "15px" }}>
                <h4>₦{walletData.amount}</h4>
                <p>DOCTALL Referee Bonus Amount</p>
              </div>
            </div>
          </Col>
          <Col md={4}>
            <div className="Reffer p-2">
              <h5 style={{ marginLeft: "5%" }}>REFERRALS</h5>
              <div>
                <Row style={{ display: "flex" }}>
                  <Col md={6}>
                    <img
                      crossorigin="anonymous"
                      src={Profile}
                      className="profileimg"
                      alt="doctall"
                      style={{ borderRadius: "50%", paddingRight: "7px" }}
                    ></img>+{walletData.no_of_refer}
                  </Col>
                  <Col md={4} className='View' onClick={() => history.push("/Reffer")} style={{cursor:"pointer"}}>
                    View All
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </div>
      </div>
      <div className="Sub-Main mt-5 p-4">
        <div>
          <h5>Refer Friends & Family, Earn Points</h5>
          <p>Invite a friend to join Doctall and earn ₦100 on each signup & completed health profile.<br></br> Here is your unique referral code</p>
        </div>
        <div className='main-con' style={{ display: "flex" }}>
          <Col sm={4} md={4} className="copy-button" >
            <input
              style={{ border: "none", backgroundColor: "transparent" }}
              disabled
              ref={textAreaRef}
              value={walletData.referal_code}
            />

            {copySuccess == 'Copied!' ?
              <button className='Copy' disabled><img src={Copy} />&nbsp; &nbsp;{copySuccess}&nbsp; &nbsp;</button>
              :
              <CopyToClipboard text={walletData.referal_code}
                onCopy={() => setCopySuccess('Copied!')}>
                <button className='Copy' ><img src={Copy} />&nbsp; &nbsp;Copy Code&nbsp; &nbsp;</button>

              </CopyToClipboard>
            }
          </Col>
          <Col sm={2} md={2} className="share-button" onClick={opend}>
            <img src={Arrow} />&nbsp; &nbsp; Share Code
          </Col>

        </div>
      </div><br />
      <hr style={{ width: "90%", border: "1px solid rgba(173, 194, 194, 0.3)", margin: "auto" }}></hr>
      <Row style={{ marginBottom: "10%" }}>
        <div className='Main-top mt-4'>
          <Col md={5}>
            <h5>ACTIVITY</h5>
            <div className='Main-text'>
              <div className='m-3'>
                {activityData.map((item) => {

                  return <Row className='data'>
                    <Col md={3}>
                      <img
                        crossorigin="anonymous"
                        src={Right}
                        className="profileimg"
                        alt="doctall"
                        style={{ borderRadius: "50%", padding: "7px", border: " 1px solid  rgba(3, 130, 153, 0.11)", justifyContent: "center", alignItems: "center", background: "#EFFFF8" }}
                      ></img>
                    </Col>
                    <Col md={6}>
                      <p>
                        {item && item.refered_user && item.refered_user && item.refered_user[0] && item.refered_user[0].firstName.charAt(0).toUpperCase() + item.refered_user[0].firstName.slice(1)}&nbsp;
                        {item.referal_type === "register" ? "signed up with your referral code"
                          :
                          item.referal_type === "healthprofile" ? "Healthprofile Completed"
                            :
                            item.referal_type === "sadn" ? "  1st paid appointment"
                              :
                              item.referal_type === "fadn" ? " 1st paid appointment"
                                :
                                ''
                        }
                      </p>
                      <p style={{ fontSize: "12px", marginTop: '-10%' }}>{moment(item.createdAt).format('MMM Do YYYY, h:mm a')}</p>
                    </Col>
                    <Col md={3}>
                      <p>₦{item.amount}</p>
                    </Col>
                  </Row>
                })}
              </div>
            </div>
          </Col>
          <Col md={7}>
            <h5>WALLET HISTORY</h5>

            {/* <Row className='search'>
              <Col md={12}>
                <Row style={{ paddingTop: "0px", paddingBottom: "10px" }}>
                  <Col md={8}>
                    <InputField
                      className="doctors-search"
                      size="large"
                      placeholder="Search for transaction"
                      prefix={<img crossorigin="anonymous" src={Search} />}
                      InputStyle={{ outline: "none !important" }}
                    //   onChange={changeHandler}
                    />
                  </Col>
                  <Col
                    md={4}
                    className="doctors-section6-filter"
                  // onClick={() => openFilterForm(!filterForm)}
                  >
                    <img crossorigin="anonymous" src={Sort} />
                  </Col>
                </Row>
              </Col>
            </Row> */}
            <div className='Main-text'>
              <Row>
                <Col md={12}>
                  <table class="table">
                    <thead class="thead-light m-2">
                      <tr>
                        <th scope="col">DATE</th>
                        <th scope="col">ACTIVITY</th>
                        <th scope="col">AMOUNT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {historyData.map((item) => {
                        return <tr>
                          <td>
                            {item && item.type === "deduct" ?
                            <>
                            <div class="row">
                              <div class="col-2">
                              <img src={Debit} style={{marginTop:"100%"}}></img>
                              </div>
                              <div class="col-8">
                              {moment(item.createdAt).format('MMM Do YYYY, h:mm a')}
                              </div>
                            </div>
                          {/* <img src={Debit}></img>&nbsp;&nbsp;{moment(item.createdAt).format('MMM Do YYYY, h:mm a')} */}
                          </>
                          :
                          ''
                          }
                             {item && item.type === "referal" ?
                             <>
                                                         <div class="row">
                              <div class="col-2">
                              <img src={Credit} style={{marginTop:"100%"}}></img>
                              </div>
                              <div class="col-8">
                              {moment(item.createdAt).format('MMM Do YYYY, h:mm a')}
                              </div>
                            </div>
                          {/* <img src={Credit}></img> &nbsp;&nbsp;{moment(item.createdAt).format('MMM Do YYYY, h:mm a')} */}
                         </>
                          :
                          ''
                          }
                            
                            </td>
                          <td>
                            {item && item.refered_user && item.refered_user && item.refered_user[0] && item && item.refered_user && item.refered_user && item.refered_user[0] && item.refered_user[0].firstName.charAt(0).toUpperCase() + item.refered_user[0].firstName.slice(1)}&nbsp;
                            {item.referal_type === "register" ? "signed up with your referral code"
                              :
                              item.referal_type === "healthprofile" ? "Healthprofile Completed"
                                :
                                item.referal_type === "sadn" ? "  1st paid appointment"
                                  :
                                  item.referal_type === "fadn" ? "   1st paid appointment"
                                    :
                                    item.type === "deduct" ? "Payment Commission"
                                      :
                                      ''
                            }
                          </td>
                          <td>₦{item.amount}</td>
                        </tr>
                      })}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </Col>
        </div>
      </Row>
      {
        dailogOpen &&
        // <Dialog
        // style={{backgroundColor:"red"}}
        //   title="Dialog Title"
        //   modal={true}
        //   onClose={() => setDailogOpen(false)}
        //   buttons={
        //     [{
        //       text: "Close",
        //       onClick: () => setDailogOpen(false)
        //     }]
        //   }
        //   >
        //   <h1 >Dialog Content</h1>
        //   <p>More Content. Anything goes here</p>
        // </Dialog>
        <Dialog
          disableBackdropClick
          open={dailogOpen}
          onClose={() => setDailogOpen(false)}
          maxWidth='sm'
          fullWidth={true}
          minWidth="sm"
          title='gfgh'
        >

          <DialogContent>
            <CancelOutlinedIcon
              style={{ marginRight: "0px", float: "right", marginLeft: "auto", cursor: "pointer" }}
              onClick={() => setDailogOpen(false)}
            />

            <h1>Share</h1>
            <WhatsappShareButton
              title={" "}
              separator={`Hey, use my referral code ${walletData.referal_code} to sign up on Doctall and speak to a doctor, get disease-specific treatment plans and medications; all from the comfort of your home. Click here  `}
              url={`${process.env.REACT_APP_CONSUMER_URL}/signup`}
              // url={''}
            >
              <WhatsappIcon
                size={32} round={true}
              />
            </WhatsappShareButton>
            &nbsp;&nbsp;
            <FacebookShareButton
              quote={`Hey, use my referral code ${walletData.referal_code} to sign up on Doctall and speak to a doctor, get disease-specific treatment plans and medications; all from the comfort of your home. Click here  `}
              hashtag={"#HealthyLife"}
              url={`${process.env.REACT_APP_CONSUMER_URL}/signup`}
              // url={" "}
            >
              <FacebookIcon size={32} round={true}></FacebookIcon>
            </FacebookShareButton>
            &nbsp;&nbsp;
            <LinkedinShareButton
              title={" "}
              summary={`Hey, use my referral code ${walletData.referal_code} to sign up on Doctall and speak to a doctor, get disease-specific treatment plans and medications; all from the comfort of your home. Click here   `}
              source={""}
              url={`${process.env.REACT_APP_CONSUMER_URL}/signup`}
              // url={' '}
            >
              <LinkedinIcon size={32} round={true}></LinkedinIcon>
            </LinkedinShareButton>
            &nbsp;&nbsp;
            <TwitterShareButton
              title={" "}
              via={`Hey, use my referral code ${walletData.referal_code} to sign up on Doctall and speak to a doctor, get disease-specific treatment plans and medications; all from the comfort of your home. Click here   `}
              hashtag={"#HealthyLife"}
              related={[]}
              url={`${process.env.REACT_APP_CONSUMER_URL}/signup`}
              // url={`${process.env.REACT_APP_CONSUMER_URL}/signup`}
            >
              <TwitterIcon size={32} round={true}></TwitterIcon>
            </TwitterShareButton>
            &nbsp;&nbsp;
            <TelegramShareButton
              title={`Hey, use my referral code ${walletData.referal_code} to sign up on Doctall and speak to a doctor, get disease-specific treatment plans and medications; all from the comfort of your home. Click here   `}
              // url={`${process.env.REACT_APP_CONSUMER_URL}/signup`}
              url={`${process.env.REACT_APP_CONSUMER_URL}/signup`}
            >
              <TelegramIcon size={32} round={true}></TelegramIcon>
            </TelegramShareButton>
            &nbsp;&nbsp;
            {/* <EmailShareButton
              subject={"Doctall consumer"}
              body={`Hey, use my referral code ${walletData.referal_code} to sign up on Doctall and speak to a doctor, get disease-specific treatment plans and medications; all from the comfort of your home. Click here   `}
              // url={`http://test-consumer.doctall.com/`}
              url={' '}
            >
              <EmailIcon size={32} round={true}></EmailIcon>
            </EmailShareButton> */}
          </DialogContent>
        </Dialog>

      }
    </Layout>

  )
}

