import React from 'react'
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import './style.scss'

export default function CautionDialogBox(props) {
    const { open, _onClose, resImg, children, none } = props
    return (
        <div>
            <Dialog open={open} onClose={_onClose}>
                <DialogContent>
                    <DialogContentText >
                        <div className="dialoglimit">
                            <img crossorigin="anonymous" className="xbutton" onClick={_onClose} src={require('../../assets/images/Xbutton.svg').default} alt="" />
                            <img crossorigin="anonymous" style={{ display: `${none}` }} className="res" src={resImg} alt="" />
                            {children}

                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

        </div>
    )
}
