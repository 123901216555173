import { takeLatest, put, select,all } from 'redux-saga/effects'
import axios from "../axios";
import types from './types';
import { push } from 'react-router-redux';
import { api } from '../../utils/constants'


export const getUser = state => state.auth;


export function* loginUser(payload) {
    
    try {
        console.log(payload , "payload")
        let data ;
        if(payload.data.type === "loginwithcredential"){
            const payLoad = {
                password : payload.data.password,
                username : payload.data.username
            }
            data = yield axios.post(api.login, payLoad)
        }
        else if(payload.data.type === "loginwithFb"){
            const payLoad = {
                "authToken" : payload.data.response.accessToken,
                "facebookUserId" : payload.data.response.userID
            }
            data = yield axios.post(api.fb_login, payLoad)
        }
        else{
            const payLoad = {
                "tokenId" : payload.data.response.tokenId,
            }
            data = yield axios.post(api.google_login, payLoad)
        }
        
        if(data.data.verified)
        yield put({ type: types.LOGIN_SUCCESS, payload: data.data })
        else
        yield put({ type: types.LOGIN_SUCCESS_NOTVERIFY, payload: data.data })
        // yield put(push('/dashboard'))
        yield payload.cb(data.data)
    }
    catch (err) {
        console.log(err,"payload")
        yield put({ type: types.LOGIN_ERROR, payload: err?.response?.data })
    }
}

export function* loginSuccess(payload) {
    yield put({ type: types.LOGIN_SUCCESS, payload: payload })
} 

export function* verifyUser(payload) {
    try {
        const data = yield axios.get(api.verify_token, { headers: { 'x-auth-token': payload.data } })
        yield put({ type: types.VERIFY_SUCCESS, payload: data.data })
    }
    catch (err) {
        yield put({ type: types.VERIFY_ERROR, payload: err.response.data })
    }
}


export function* registerUser(payload) {

    try {
        const data = yield axios.post(api.register, payload.data)
        yield put({ type: types.REGISTER_SUCCESS, payload: data && data.data })
        yield payload.cb(data && data.data)

    }
    catch (err) {
        yield put({ type: types.REGISTER_ERROR, payload: err && err.response && err.response.data })
        yield payload.cb("error")
        
    }
}

export function* verfiyingOtp(payload){
    try{
        const data = yield axios.post(api.verify_otp,payload.data)
        yield put({type:types.VERIFY_OTP_SUCCESS,payload:data.data})
        yield payload.cb(data.data)

    }catch(err){
       yield put({type:types.VERIFY_OTP_ERROR,payload:err.response.data})
       yield payload.cb("error")


    }
}
export function* resendOtp(payload){
   
    try{
        const data = yield axios.post(api.email_send_otp,payload.data)
        yield put({type:types.RESEND_OTP_SUCCESS})
        yield payload.cb(data)
    }catch(err){
        const data = yield put({type:types.RESEND_OTP_ERROR,payload:err.response.data})
        yield payload.cb(data)
    }
}

export function* avaliablityCheck(payload){
        
    try {
        const data = yield axios.post(api.availability, payload.data)
        yield put({ type: types.AVALIABLITY_SUCCESS, payload: data.data })
        yield payload.cb(data.data)
    }catch(err){
        yield put({ type: types.AVALIABLITY_ERROR, payload: err.response.data })
    }
}

export function* logoutCheck(payload){
        
    yield put({ type: types.LOGIN_SUCCESS, payload: {} })
}
export function* authWatcher() {
    yield takeLatest(types.LOGOUT, logoutCheck)
    yield takeLatest(types.LOGIN_USER, loginUser)
    yield takeLatest(types.SAVE_LOGIN, loginSuccess)
    yield takeLatest(types.REGISTER_USER, registerUser)
    yield takeLatest(types.VERIFY_USER, verifyUser)
    yield takeLatest(types.CHECK_AVALIABLITY, avaliablityCheck)
    yield takeLatest(types.VERIFY_OTP, verfiyingOtp)
    yield takeLatest(types.RESEND_OTP, resendOtp)


}
export default function* mainSaga() {
    yield all([authWatcher()]);
}
