/**
 * 
 * Radio Button Field
 * 
 * 
 */


 import React from 'react';
 import RadioBox from './Radio';
 import RadioGroup from './RadioGroup';
 import FormControl from '@material-ui/core/FormControl';    
 import './style.scss';
 
 export default function RadioButtonsGroup(props) {
     
    const { name, value, onChange, options = [], metaData=[]} = props;

    return (
         <FormControl component="fieldset" className="formControl">
             <RadioGroup aria-label={name} name={name} value={value} onChange={onChange}>
                 {( metaData && metaData.languages || []).map(opt => <RadioBox value={opt.value} label={opt.label} />)}
             </RadioGroup>
         </FormControl>
     );
 }
 