import { BellOutlined } from '@ant-design/icons';
import Dialog from '@material-ui/core/Dialog';
import { notification } from 'antd';
import 'antd/dist/antd.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Pubnub from "pubnub";
// import { createPubNubListener } from "pubnub-redux";
import { PubNubProvider } from "pubnub-react";
import React, { useEffect, useState } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import ReactGA from "react-ga";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import Close from './assets/images/close.svg';
import popimageapple from "./assets/images/popimageapple.svg";
import popimagegoogle from "./assets/images/popimagegoogle.svg";
import keyConfiguration from "./config/pubnub-keys.json";
import { getToken, onMessageListener } from './firebase';
import useReactPath from "./hooks/useReactPath";
import useWindowSize from "./hooks/useWindowSize";
import { logout, login } from './redux/auth/actions';
import indexRoutes from "./routes";
import privateRoutes from "./routes/private";
import ProtectedRoutes from "./routes/Protected";
import { checkSafari, decrypt } from "./utils";
import AppContainer from "./utils/AppContainer";
import { usernameKey, passcodeKey } from './utils/constants';
import Notfound from './views/notfound';
import { useLocation } from 'react-router-dom';
const pubnubConfig = Object.assign(
  {}, {
  restore: true,
  heartbeatInterval: 0
},
  { ...keyConfiguration }
);

const pubnub = new Pubnub(pubnubConfig);

const App = () => {
  const [modalShow, setModalShow] = React.useState(false);
  const [isTokenFound, setTokenFound] = useState(false);
  const [width, height] = useWindowSize();
  const location = useReactPath()
  const [toster, setToster] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [type, setType] = React.useState("");
  const userdata = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  const [cookies, setCookie] = useCookies([usernameKey, passcodeKey]);
  const history = useHistory();
console.log(userdata?.token,"window.location")

const userDetails = useSelector(state => state.userDetails);

const [count, setCount] = useState(0)
const cookieLogin = () => {

  if ((userdata?.token === undefined || userdata?.token === '') && cookies[usernameKey] && cookies[passcodeKey] && count <= 1) {
    const username = decrypt(cookies[usernameKey])
    const password = decrypt(cookies[passcodeKey])
  console.log(username, password, "Coookie11")

    dispatch(login({ username, password, type: 'loginwithcredential' }));
    // history.push({
    //   pathname : '/dashboard'
    // })
    // history.push("/dashboard");
    // window.location.reload(false);
  }
  // else if (userdata?.token && (cookies[usernameKey] === undefined || cookies[passcodeKey] === undefined)) {
  //   dispatch(logout())
  // setCount(0)
  // // history.push({
  // //   pathname : '/'
  // // })
  // //   window.location.reload(false);

  // }
}

useEffect(() => {
  //Implementing the setInterval method
  const interval = setInterval(() => {
    setCookie('ddd')
    setCount(count + 1)
    cookieLogin();
  }, 1000);

  //Clearing the interval
  return () => clearInterval(interval);
  // alert(cookies["redirect"])
}, [cookies]);

  // useEffect(() => {
  //   if (userdata.isAuthenticated && !cookies[usernameKey]) {
  //     dispatch(logout())
  //     setTimeout(() => {
  //       document.cookie = 'doctallLog' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=.doctall.com';
  //       history.push("/");
  //       window.location.reload(false);
  //     });
  //   }
  // }, [cookies]);

  const leaveApplication = () => {
    // This is required to show the current user leave immediately rather than
    // wating for the timeout period
    pubnub.unsubscribeAll();
  };

  useEffect(() => {
    window.addEventListener("beforeunload", leaveApplication);
  }, []);

  useEffect(() => {
    if (!checkSafari())
      getToken(setTokenFound);
  }, [])

  // Google Gtag
  useEffect(() => {
    if (userdata && userdata.uuid) {
      ReactGA.initialize(process.env.REACT_APP_G_TAG, {
        gaOptions: {
          name: userdata.full_name,
          userId: userdata.uuid
        },
        userId: userdata.uuid
      });
    }
    else {
      ReactGA.initialize(process.env.REACT_APP_G_TAG);
    }

  }, [userdata])

  useEffect(() => {
    if (window.innerWidth <= "767" && window.location.pathname !== '/') {
      setModalShow(true)
    }
    else {
      setModalShow(false);
    }
  }, [width, location])


  useEffect(() => {
    if (!checkSafari()) {
      let onmessage = onMessageListener().then(message => {
        console.log('message listener:', message);
        notification.info({
          message: message.notification.title,
          description: message.notification.body,
          icon: <BellOutlined style={{ color: '#108ee9' }} />,
        })


      }).catch(err => console.log('failed: ', err));

      return onmessage;
    }
  });


  return (
    <CookiesProvider>
      <PubNubProvider client={pubnub}>

        <BrowserRouter>

          <div>
            <Dialog fullScreen open={modalShow} >

              <div className="closeButtonPopup">
                <img style={{ cursor: 'pointer' }} src={Close} onClick={() => { setModalShow(false) }} alt="Close" crossorigin="anonymous" />
              </div>

              <main >
                <h1 className="better">For Better Experience Kindly Download Our Mobile Application</h1>

                <div className="playstorelink store" >
                  <a className="googleplay" rel="noreferrer" target="_blank" href="https://play.google.com/store/apps/details?id=com.doctall">
                    <img alt="" src={popimagegoogle} crossorigin="anonymous" />
                  </a>

                  <a className="applestore" rel="noreferrer" target="_blank" href="https://apps.apple.com/ng/app/doctall/id1544455716">
                    <img alt="" src={popimageapple} crossorigin="anonymous" />

                  </a>

                </div>
              </main>


            </Dialog>

          </div>
          <AppContainer>
            <Switch>
              {indexRoutes.map((route, key) => {
                console.log(route,"oute.patrh")
                return (
                  <Route
                    path={route.path}
                    component={route.component}
                    key={key}
                    exact={true}
                  />
                );
              })}
              <ProtectedRoutes>
                {privateRoutes.map((route, key) => {
                  return (
                    <Route
                    exact={route.exact} 
                      path={route.path}
                      component={route.component}
                      key={key}
                      
                    />
                  );
                })}
              </ProtectedRoutes>
              {/* {privateRoutes.map((route, key) => {
                 {indexRoutes.map((route1, key1) => {
                 if(route.path !== window.location.pathname &&  route1.path !== window.location.pathname){
                  return (
                    <Route
                    exact={true} 
                      path={"*"}
                      component={Notfound}
                      // key={key}
                      
                    />
                  );
                 }
                })}
                

                })} */}
            </Switch>
          </AppContainer>
        </BrowserRouter>
      </PubNubProvider>
    </CookiesProvider>
  );
};
// export const WithRouterApp = withRouter(App);
export default App;
