import types from "./types";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case types.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };


    case types.GET_USER_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    default:
      return state;
  }
};

export default userReducer;
