import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import AuthLayout from "../../layout/AuthLayout";
import { decrypt, encrypt } from '../../utils';

import queryString from 'query-string';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import mask from '../../assets/images/mask.svg';
import { login } from "../../redux/auth/actions";
import axios from "../../redux/axios";
import { api, passcodeKey, tokenKey, usernameKey } from "../../utils/constants";
import "./auth.scss";
import { Helmet } from 'react-helmet';

const Login = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies([usernameKey, passcodeKey, tokenKey, "redirect"]);
  const [nphone, setNphone] = useState();
  const [verified, setVerified] = useState(false);
  const [phoneerror, setPhoneerror] = useState(false);
  const [reserror, setReserror] = useState(false);
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);
  const [country, setCountry] = useState();
  const [show, setShow] = useState(false)

  const userdata1 = useSelector(state => console.log(state, "userdata"));

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((res) => {
        setCountry(res.country_code.toLowerCase());
      })
      .catch((data, status) => {
        console.log("Request failed:", data);
      });
  }, []);

  useEffect(() => {
    if (cookies[usernameKey] && cookies[passcodeKey]) {
      const username = decrypt(cookies[usernameKey])
      const password = decrypt(cookies[passcodeKey])
      dispatch(login({ username, password, type: 'loginwithcredential' }));
    }
    // alert(cookies["redirect"])
  }, [cookies])

  useEffect(() => {
    if (user) {
      if (user.isAuthenticated === true && user.verified === true) {
        let params = queryString.parse(props.location.search)
        if (params && params.redirect) {
          const url = params.redirect;
          history.push(url);
        } else {
          history.push("/dashboard");
        }
        
      }
      if (user.error === "Invalid username or password!") {
        setReserror(true);
        setTimeout(function () {
          setReserror(false);
        }, 2000);
      }
    }
  }, [user]);



  const responseGoogle = (response) => {
    console.log(response, "google")
    const type = "loginwithgoogle"
    var payLoad = { response, type };
    console.log(payLoad, "google")
    dispatch(login(payLoad, (res) => {
      console.log(res.verified)
      if (res.verified === true) {
        history.push("/dashboard");
      }
      localStorage.setItem("CTKN", res.token);
    })
    );
  }
  const responseFacebook = (response) => {

    console.log(response, "fb")
    const type = "loginwithFb"
    var payLoad = { response, type };
    console.log(payLoad, "google")
    dispatch(login(payLoad, (res) => {
      console.log(res.verified)
      if (res.verified === true) {
        history.push("/dashboard");
      }
      localStorage.setItem("CTKN", res.token);
    })
    );

    // var payLoad = {
    //     "firstname": response.name.slice(0, 30),
    //     "email": response.email,
    //     "role": "customer",
    //     "social_id": response.userID,
    //     "domain": response.graphDomain,
    // };
    // axios.post(`register/socialRegister`, payLoad)
    //     .then(res => {
    //         console.log(res, "4245")
    //         processLoginResp(res)
    //         swal("Login Successfully !", "", "success");
    //         history.push(process.env.PUBLIC_URL + '/event')
    //     }).catch((error) => {
    //         swal("Login Failed !", "Please Try Again !", "error");
    //     });

  }


  const validationSchema = Yup.object({
    Passcode: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(4, "Must be exactly 4 digits")
      .max(4, "Must be exactly 4 digits")
      .required("Required"),
  });
  const errorstyle = {
    border: reserror ? "1px solid #E04E47" : "1px solid #ADC2C2 ",
    outline: "none !important",
    borderRadius: "5px",
  };
  const errorLabel = {
    color: reserror ? "#E04E47" : "",
  };
  const { handleSubmit, handleChange, values, errors } = useFormik({
    initialValues: {
      Passcode: "",
    },
    validationSchema,
    onSubmit: (values) => {
      if (nphone === "" || nphone === undefined) {
        setPhoneerror(true);
      } else {
        setPhoneerror(false);
        const username = nphone;
        const password = values.Passcode;
        const type = "loginwithcredential"
        var val = { username, password, type };
        var values = {}
        dispatch(
          login(val, (res) => {
            if (res.verified === false) {
              values = {
                email: res.email,
                mobile: res.mobile,
              };
              axios.post(api.resetpassword, values).then((res) => {
                console.log(values,"LoginData")
                setVerified(true);
                localStorage.setItem("LoginData", JSON. stringify(val));
                setTimeout(function () {
                  setVerified(false);
                  history.push("/confirmVerification");
                }, 3000);
              })
            }
            else if (res.verified === true) {
              var cipherUsername = encrypt(res.username);
              var cipherPassword = encrypt(password);
              var cipherToken = encrypt(res.token);

              const cookieOptions = { path: '/', secure: process.env.REACT_APP_COOKIE_SECURE, domain: process.env.REACT_APP_COOKIE_DOMAIN }
              console.log('consumer location setting cookie ');
              setCookie(usernameKey, cipherUsername, cookieOptions);
              setCookie(passcodeKey, cipherPassword, cookieOptions);
              setCookie(tokenKey, cipherToken, cookieOptions);

              localStorage.setItem("CTKN", res.token);
              localStorage.setItem("PASSCODE", password);

              // navigate("/dashboard");
            }
          })
        );
      }
    },
  });

  return (
    <AuthLayout>
      <Helmet>
        <title>Consumer Web Login - Doctall
</title>
<meta 
            name='description' 
            content='Login to speak to a doctor online, book an appointment from anywhere and get medical treatment'
            />
      </Helmet>
      <div className="loginForm">
        <h1>Hi, Welcome Back</h1>
        <div>
          <p>Start living better and enjoy personalized care</p>
          {verified === true ? (
            <div className="error">Please Verify Your Account.</div>
          ) : (
            ""
          )}
        </div>

        <form onSubmit={handleSubmit}>
          <label style={errorLabel} htmlFor="Phone">
            Phone number
          </label>
          <div>
            <PhoneInput
              style={errorstyle}
              className="inputPhone"
              country={country}
              value={nphone}
              onChange={setNphone}
            />
            {phoneerror ? <div className="error">required</div> : null}
            {user.error === "Invalid username or password!" &&
              reserror === true ? (
              <div className="error">Invalid Phone Number</div>
            ) : (
              ""
            )}
          </div>

          <label style={errorLabel} htmlFor="Passcode">
            Passcode
          </label>
          <div className="inputContainer">
            <input
              style={errorstyle}
              className="inputForm"
              type={show ? "text" : "password"}
              name="Passcode"
              maxLength={4}
              onChange={handleChange}
              value={values.Passcode}
            />
            <img crossorigin="anonymous" className="mask" src={mask} alt="icon" onClick={() => setShow(!show)} style={{ cursor: 'pointer' }} />
            {errors.Passcode ? (
              <div className="error">{errors.Passcode}</div>
            ) : null}
            {user.error === "Invalid username or password!" &&
              reserror === true ? (
              <div className="error">Invalid Passcode</div>
            ) : (
              ""
            )}
          </div>
          <Link className="forgetPass" to="/forgotPassword">
            Forgot Passcode
          </Link>
          <button type="submit" className="sumbitBtn">
            Login
          </button>
          <div>
            <FacebookLogin
              appId="2887677518113793"
              autoLoad={false}
              fields="name,email,picture"
              cssClass="my-facebook-button-class"
              // textButton="&nbsp;&nbsp;&nbsp;Login"
              callback={responseFacebook}
              icon="fa-facebook"
            />
            <label style={{ marginLeft: "2%", borderRadius: "8px", width: "50%" }}>
              <GoogleLogin
                className="GoogleLogin"
                clientId="815723410350-ins2a1s6c2trpfibl0gh9nd9ts5tqtqe.apps.googleusercontent.com"
                // buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
              />
            </label>

          </div>
        </form>

        <div className="signup">
          Not registered yet? <Link to="/signup">Create an Account</Link>
        </div>
      </div>
    </AuthLayout>
  );
};

export default Login;
