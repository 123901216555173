import React, { useEffect, useState } from 'react';
import '../ReasonForVisit/ReasonForVisit.scss';
import DropDown from '../Select';
import Label from '../Label';
import { api, symptomsData } from '../../utils/constants'
import { propTypes } from 'react-bootstrap/esm/Image';
import { Input } from 'antd';
import InputField from '../InputField';
import Button from 'react-bootstrap/Button'
import axios from 'axios';
import Toast from '../Toast';
import { useSelector } from "react-redux";

export default function Applycoupon(props) {


    const {doctorUUID, doctorSpecaility, userId, totalAmount, type, finalAmount, appointmentCategory, blockCouponid, couponAppliedFlags, walletAmount, billtotal} = props;
    console.log(totalAmount)
    const [couponCode, setCouponCode] = useState()
    const [toastMessage, setToastMessage] = useState('')
    const [toastType, setToastType] = useState('')
    const [toastVisibility, setToastVisibility] = useState(false)
    const [amount, setAmount] = useState(totalAmount)
    const [successVisibility, setSuccessVisibility] = useState(false)
    const [finalMessage, setFinalMessage] = useState('')

    const [walletData, setWalletData] = useState('')

    const [visibledat, setVisible] = useState(false)
    const [visibledat1, setVisible1] = useState(false)
    const [amnt, setAmnt] = useState('')

    console.log(visibledat)
    const [msg, setmsg] = useState('')
    const [disamnt, setdisamnt] = useState(totalAmount)
    const userdata = useSelector((state) => state.auth);

    const handleCoupanCode = (event) => {
        couponAppliedFlags(false)
        setCouponCode(event.target.value)
    }
    const handleAmnt = (event) => {
        setAmnt(event.target.value)
    }
    useEffect(() => {
        setAmount(totalAmount)
        setdisamnt(totalAmount)
    }, [])

    console.log(disamnt)
    let tamnt;
   if(visibledat == true || successVisibility == true){
    // if(disamnt >= 0){
        tamnt = disamnt
        finalAmount(tamnt)
    // }else{
    //      tamnt = totalAmount
    //     finalAmount(tamnt)
    // }
   }
   else{
    tamnt = totalAmount
    finalAmount(tamnt)
    // setdisamnt(totalAmount);
   }

    

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/user/wallethistory/wallet/${userdata.uuid}`)
          .then((res) => {
            setWalletData(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      }, [])
      


      const handlePayment = () => {
       var mtm ;
       if(disamnt !== totalAmount && (disamnt !== 0)){
           mtm = disamnt
       }else{
        mtm = totalAmount
       }
       var payLoad  = {
            "totalamount" : mtm,
            "amount":amnt,
            "user_uuid" : userdata.uuid
        }
        axios.post(`${process.env.REACT_APP_API_URL}/user/wallethistory/validateForPay/from/wallet`, payLoad)
        .then((res) => {
            if(res.status === 200){

            if(disamnt !== totalAmount){
                setdisamnt(res.data.need_to_pay)
                setmsg(`You have succefully claimed ₦${amnt} from your wallet` )
                billtotal(Number(disamnt))
                walletAmount(amnt)
                setVisible(true)
               
            }else{
                setdisamnt(res.data.need_to_pay)
                setmsg(`You have succefully claimed ₦${amnt} from your wallet` )
                billtotal(Number(totalAmount))
                walletAmount(amnt)
                setVisible(true)
                
            }

        }
        })
                        

        .catch((err) => {
            console.log(err.response)
            setmsg(`insufficient Amount`)
            setVisible1(true)
            // setmsg(err && err.response && err.response.data)
        })
      }
    //   console.log(walletData.amount,"walletData")
    const handleSubmit = async () => {
        try {
            let payLoad = {
                "user_uuid": userId,
                "coupon_code": couponCode,
                "total_amount": totalAmount,
                "type": "appointment",
                "category": appointmentCategory,
                "speciality_doctor": doctorSpecaility,
                "doctor_uuid": doctorUUID
            }
            if(disamnt > 0){
                let response = await axios.post(api.applyCoupon, payLoad)
                if (response.status === 200 || response.status === 201) {
    
                    console.log(response.data)
                    setSuccessVisibility(true)
                    setAmount(response.data.finalAmount)
                    setdisamnt(response.data.finalAmount - amnt)
                    // finalAmount(response.data.finalAmount)
                    blockCouponid(response.data.coupon_applied_id)
                    if (response.data.finalmessage !== null || response.data.finalmessage !== '' && response.data.finalmessage !== undefined) {
                        setFinalMessage(response.data.finalmessage)
                    } else {
                        setFinalMessage('discount')
                    }
                    couponAppliedFlags(true)
                } else {
                    setSuccessVisibility(false)
                }
            }
            else{
                let response = await axios.post(api.applyCoupon, payLoad)
                if (response.status === 200 || response.status === 201) {
    
                    console.log(response.data)
                    setSuccessVisibility(true)
                    setAmount(response.data.finalAmount)
                    setdisamnt(response.data.finalAmount)
                    // finalAmount(response.data.finalAmount)
                    blockCouponid(response.data.coupon_applied_id)
                    if (response.data.finalmessage !== null || response.data.finalmessage !== '' && response.data.finalmessage !== undefined) {
                        setFinalMessage(response.data.finalmessage)
                    } else {
                        setFinalMessage('discount')
                    }
                    couponAppliedFlags(true)
                } else {
                    setSuccessVisibility(false)
                }
            }


        } catch (error) {
            if (error && error.response) {
                setToastVisibility(true)
                setToastMessage(error.response.data)
                setToastType("error")
            }
            setSuccessVisibility(false)
        }
    }


    return (
        <>
            <div className={type == "sadn" ? "SadnReasonForVisit" : "ReasonForVisit"} style={{ width: "100%", height:"auto" }}>
                <Label text="Consultation Fee:" />
                <Label text={"₦" + " " + totalAmount} />

                <hr></hr>
                {!successVisibility &&
                    <div >
                        {/* <div className={"input"}> */}
                        <Label text="Coupon (optional)" />
                        {/* <Label text="Apply Doctall discount coupon below" /> */}
                        <div class="row">
                            <div class="col-lg-9 col-xs-12">
                                <InputField
                                    style={{ marginTop: '1%' }}
                                    className="doctors-search"
                                    size="large"
                                    placeholder="Apply Doctall discount coupon here  if any"
                                    InputStyle={{ outline: "none !important" }}
                                    onChange={handleCoupanCode}
                                    value={couponCode}
                                />
                            </div>
                            <div class="col-lg-3 col-xs-12">
                                <Button style={{ backgroundColor: "#00839B", borderColor: "#00839B", marginTop: '4%' }} onClick={handleSubmit}>CLAIM COUPON</Button>{' '}
                            </div>
                        </div>


                    </div>
                }
                {successVisibility && 
                 <div className="info-text">
                 {`Your coupon (${couponCode && couponCode}) was successfully applied and ${finalMessage} has been applied`}
             </div>
                }
                <div>&nbsp;</div>
                <hr></hr>
                <Label text="Cash Bonus from Wallet " />
                {/* <Label text="You currently have ₦34,0989 in your wallet balance" />
             */}
                <label style={{ color: "#00839B", fontSize: "16px", fontFamily: 'Visuelt', marginTop: "-2%" }}>You currently have ₦{walletData.amount-amnt} in your wallet balance</label>
                { visibledat &&  visibledat == true ?
                <div>
                <br></br>
                 <label>{msg}</label>
                </div>
                :
                <div class="row">
                    <div class="col-lg-9 col-xs-12">
                        <InputField
                            style={{ marginTop: '1%' }}
                            className="doctors-search"
                            size="large"
                            placeholder="Enter the amount you are paying from  your wallet e.g ₦500"
                            InputStyle={{ outline: "none !important" }}
                        onChange={handleAmnt}
                        value={amnt}
                        />
                    </div>
                    
                    <div class="col-lg-3 col-xs-12-3">
                        <Button style={{ backgroundColor: "#00839B", borderColor: "#00839B", marginTop: '4%' }} onClick={handlePayment}>PAY FROM WALLET</Button>{' '}
                    </div>
                </div>
                
}
{ (visibledat == false) && (visibledat1 == true) ?
                // <div>
                 <label style={{color:"red", marginTop:"-1%", fontSize:"14px"}}> {msg}</label>
                // </div>
                :
                ''
}

{console.log(visibledat, visibledat1, "gjhjkml")}
                {/* If coupon applied sucessfully */}
                {/* {successVisibility &&
                    <div className={"input"}>
                        <div className="info-container">
                            <div className="info-text">
                                {`Your coupon (${couponCode && couponCode}) was successfully applied and ${finalMessage} has been applied`}
                            </div>
                        </div>
                    </div>
                } */}


                <Toast
                    text={toastMessage}
                    type={toastType}
                    open={toastVisibility}
                    setClose={() => {
                        setToastVisibility(false);
                    }}
                />
            </div>
            <br></br>
            <div>

            <div className='doctors-search' >
            <div style={{display:"flex"}} class="row">
                <div class="col-8">
                   <label style={{color:"#013C44", fontSize:"18px"}}>&nbsp;Total Consultation Fee</label>
                </div>
                <div class="col-4">
                  <label style={{color:"#013C44", fontSize:"18px"}}>₦{tamnt}</label>
                </div>
            </div>
            </div>
            </div>
            </>
            )
}
