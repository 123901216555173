import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import DashboardLayout from '../../layout/DashboardLayout'
import { DialogBoxWithClose, CautionDialogBox } from '../../components'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from '../../redux/axios'
import './health.scss'
import { getPatients } from '../../redux/patients/actions'
import { api } from '../../utils/constants'
import { Helmet } from 'react-helmet';

function RelativeList() {
    toast.configure()
    const [deletePopup, setDeletePopup] = useState(false)
    const dispatch = useDispatch()
    const history = useHistory()
    const [deleteName, setDeleteName] = useState('')
    const [deleteId, setDeleteId] = useState('')

    const [deleteSuccess, setDeleteSuccess] = useState(false)
    const patientdata = useSelector(state => state.patients.data)
    const user = useSelector(state => state.auth)
    const handleAddRelative = () => {
        history.push({
            pathname: '/addRelative'
        })
    }
    const handleEdit = (e) => {
        const uuid = e.currentTarget.id
        if (uuid !== undefined) {
            history.push({
                pathname: '/relativeProfile',
                state: {
                    // prof:"relative",
                    uuid: uuid
                },
            });
        }
    }

    const handleDelete = (e) => {
        setDeleteId(e.currentTarget.getAttribute("data-uuid"))
        setDeleteName(e.currentTarget.getAttribute("data-name"))
        setTimeout(() => { setDeletePopup(true) }, 500)
    }

    const DeletePatientID = async () => {

        await axios.delete(api.patient + "/" + deleteId, { headers: { 'x-auth-token': user.token } })
            .then(res => {
                setDeletePopup(false)
                setDeleteSuccess(true)
            })
            .catch(err => {
                console.log(err)

            })

    }

    useEffect(() => {
        dispatch(getPatients())
    }, [deleteSuccess])
    return (
        <div>
             <Helmet>
      <title>Relatives - Doctall Consumer Web



</title>
<meta 
            name='description' 
            content='View list of your relatives on Doctall. Book appointments for relatives and dependents, see their health information and appointments
            '
            />
    </Helmet>
            <DashboardLayout>
                <div className="profileHead">
                    <div className="profileInner1">
                        <div>
                            <h1>Relatives</h1>
                            <button><p style={{ fontSize: '18px' }}>View list of relatives</p></button>
                            {/* <p>90909090909</p> */}
                        </div>
                    </div>
                    <div className="profileInner2">
                        <button className="addRelatives" onClick={handleAddRelative}>+ Add Relative</button>
                        {/* <h4 className="progressHead">Profile completion</h4> */}
                    </div>
                </div>
                <div className="profileFormCard">
                    <div className="relativeNotice">
                        <img crossorigin="anonymous" style={{ marginBottom: "2px" }} src={require('../../assets/images/infoerror.svg').default} alt="" />
                        You can book appointments for your loved ones under your account by creating profiles for them and booking appointments on their behalf
                    </div>
                </div>

                <div className="profileFormCard">
                    {patientdata.map((i) => (<>
                        {i.relationship === undefined || i.relationship === null || i.relationship === ""
                            ?
                            <div className="relativeCard">
                                <h4 className="progressHead">{i.full_name}</h4>
                                <div>
                                    <button className='actionRelative' onClick={handleEdit} id={i.uuid}>
                                        <img crossorigin="anonymous" src={require('../../assets/images/edit.svg').default} alt="" />
                                        Edit
                                    </button>
                                    <button className='actionRelative'
                                        data-uuid={i.uuid}
                                        data-name={i.full_name}
                                        onClick={handleDelete}>
                                        <img crossorigin="anonymous" src={require('../../assets/images/delete.svg').default} alt="" />
                                        Delete
                                    </button>
                                </div>
                            </div>
                            : ""}

                    </>))}

                </div>
                <DialogBoxWithClose
                    open={deletePopup}
                    _onClose={() => { setDeletePopup(false) }}
                    resImg={require('../../assets/images/error.png').default}
                    actionBtnYes={"Yes, Delete"}
                    actionBtnNo={'No, Cancel'}
                    onClickYes={DeletePatientID}
                    onClickNo={() => { setDeletePopup(false) }}
                >
                    Are  you sure you want to delete {deleteName}?
                </DialogBoxWithClose>
                <CautionDialogBox
                    open={deleteSuccess}
                    _onClose={() => { setDeleteSuccess(false) }}
                    resImg={require('../../assets/images/success.svg').default}
                >
                    <div className="deletedContent">
                        <h2>Relative successfully deleted
                        </h2>
                        <p>You’ve successfullly deleted <br />{deleteName}</p>
                    </div>

                </CautionDialogBox>
            </DashboardLayout>

        </div>
    )
}

export default RelativeList
