import React, { useState, useEffect } from "react";
import Layout from "../../layout/DashboardLayout"
import "./Home.scss"
import { ConsulationList, VideoCard } from "../../components"
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment';
import axios from '../../redux/axios';
import { api, usernameKey, passcodeKey } from '../../utils/constants';
import { getPatients } from '../../redux/patients/actions';
import Arrow from "../../assets/images/arrow.svg";
import Feedback from "../../components/feedback/feedback";
import Submitpop from "../../components/feedback/submitpop";
import { useHistory } from "react-router-dom";
import { notifyUpdate, cartUpdate } from '../../redux/auth/actions'
import { getUserDetails } from "../../redux/user/actions";
import { CookiesProvider, useCookies } from 'react-cookie';

import { login, logout } from "../../redux/auth/actions";
import CryptoJS from "crypto-js";

const Home = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [videostate, setVideoState] = useState("");
  const [videoAble, setVideoable] = useState(false);
  const userDetails = useSelector(state => state.userDetails);

  const userdata = useSelector(state => state.auth);

  const [cookies, setCookie] = useCookies([usernameKey, passcodeKey]);

// Cookie Logout Based on cookie remove

  // const [count, setCount] = useState(0)
  // const cookieLogin = () => {

  //   if (userdata?.token && (cookies[usernameKey] === undefined || cookies[passcodeKey] === undefined)) {
  //     dispatch(logout())
  //     history.push('/')
  //   }
  // }

  // useEffect(() => {
  //   //Implementing the setInterval method
  //   const interval = setInterval(() => {
  //     setCookie('ddd')
  //     setCount(count + 1)
  //     cookieLogin();
  //   }, 1000);

  //   //Clearing the interval
  //   return () => clearInterval(interval);
  //   // alert(cookies["redirect"])
  // }, [cookies]);

  useEffect(() => {
    dispatch(getUserDetails())
  }, [])

  useEffect(() => {
    try {
      var decryption = CryptoJS.AES.decrypt(props.match.params.userId.toString().replaceAll("DMP", "/"), 'doctall');
      let decryptKey = decryption.toString(CryptoJS.enc.Utf8);
      let identifier = decryptKey.substring(4)
      let passwords = decryptKey.substring(0, 4)
      console.log(passwords, "passwords")
      const val = { username: identifier, password: passwords };
      login(val, (res) => {
        localStorage.setItem("CTKN", res.token);
        dispatch(getUserDetails())
        console.log(res, "val")
      })
      dispatch(
        login(val, (res) => {
          localStorage.setItem("CTKN", res.token);
          dispatch(getUserDetails())
        })
      );
    } catch (error) {
      dispatch(getUserDetails())
      console.log(error, "error")
    }
  }, [])

  useEffect(() => {
    ApiFetch();
    Next();
  }, []);

  // useEffect(() => {
  //   setInterval(function () {
  //     Next();
  //   }, 60000);
  // }, [])
  const [next, setNext] = useState();
  const [last, setLast] = useState();
  const [update, setUpdate] = useState(false);
  const [percent, setPercent] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [submitShow, setSubmitShow] = useState(false);
  const Patients = useSelector(state => state.patients);
  const userdata1 = useSelector(state => console.log(state, "userdata"));


  useEffect(() => {
    // ApiFetch();
    if (window.location.search.indexOf("feedback") > -1) {
      setModalShow(true)
    }
  }, []);

  useEffect(() => {
    let token = userdata.token;
    if (Patients && Patients.data && Patients.data.length > 0) {

      let data = Patients.data.filter((e) => e.relationship)[0];
      if (data) {
        if (data.medicalQuestions && data.medicalQuestions.length > 0) {
        }

        axios.get(api.profileupdate + data.uuid, { headers: { 'x-auth-token': token } })
          .then(res => {
            if (res.data && res.data.percentage)
              setPercent(res.data.percentage)
          })
          .catch(err => {
          })
      }
    }
  }, [percent])
  let repeat;
  const fetchData = async () => {
    try {
      // Next();
      repeat = setTimeout(fetchData, 60000); // request again after a minute
    } catch (error) {
      console.error(error.message)
    }
  }
  useEffect(() => {
    fetchData();
    return () => {
      if (repeat) {
        clearTimeout(repeat);
      }
    }
  }, []);
  const ApiFetch = () => {
    dispatch(getPatients())
    // Next();
    axios.get(api.tokenupdate + "/list/" + userdata.uuid, { headers: { 'x-auth-token': userdata.token } })
      .then(res => {
        if (res.data.length > 0) {
          let check = res.data.filter((e) => e.status == "unread");
          dispatch(notifyUpdate(check.length))
        }
      })
      .catch(err => {
        console.log(err)

      })
  }


  const Next = () => {
    if (userdata.uuid) {
      axios.get(api.nextlast + userdata.uuid)
        .then(res => {
          console.log(res, 'next last')
          if (res.data) {
            if (res.data[0] != null) {
              setNext(res.data[0])
            }
            else {
              setNext(undefined)
            }
            if (res.data[1] != null) {
              setLast(res.data[1])
            }
            else {
              setLast(undefined)
            }
            setUpdate(!update)
          }

        })
        .catch(err => {
        })
    }
  }
  return (
    <Layout>

      <div className="dashboardContainer">
        <div className="wrapper">
          <div className="namecontainer">
            <div className="name">Hello {userDetails && userDetails.data && userDetails.data.full_name} !</div>
            <div className="welcometext">How are you feeling today?</div>
          </div>
          <VideoCard next={next} />
        </div>
        <ConsulationList />
        <div className="content-bottom">
          <div className="col-cont">
            <div className="text-three">Last Appointment:</div>
            <div className="text-four">{last && last != null ? moment(last.booking_date).format("Do MMM, YYYY") : "NA"}</div>
            <div className="text-four">{last && last != null ? moment(last.start_time).format("hh:mm A") : ""}</div>
          </div>
          <div className="col-cont">
            {/* <div className="text-three">Medicine Refill Date:</div>
            <div className="text-four">NA</div> */}
          </div>
          <div className="col-cont" >

            <div className="text-three" >Health Profile Completion:</div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", flexWrap: "wrap" }} onClick={() => { history.push("/profile") }}>
              <div className="text-four" >{percent + "%"} </div>
              <div className="arrow-cont">
                <div className="img-cont">
                  <img crossorigin="anonymous" src={Arrow} alt="arrow-icon"></img>
                </div>

              </div>
            </div>


          </div>
          <Feedback show={modalShow} onHide={(e) => { setModalShow(false); e ? setTimeout(() => { setSubmitShow(true) }) : console.log("done") }} setshow={setModalShow} state={setSubmitShow} />
          <Submitpop show={submitShow} onHide={() => setSubmitShow(false)} />

        </div>
      </div>
    </Layout>
  );
};

export default Home;