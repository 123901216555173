import React, { useState, useEffect, useRef } from 'react';
import './Notification.scss';
import Option from "../../../../../assets/images/optiondot.svg";
import Sorticon from "../../../../../assets/images/sorticon.svg";
import RadioOn from "../../../../../assets/images/radioon.svg";
import RadioOff from "../../../../../assets/images/radiooff.svg";
import Line from "../../../../../assets/images/line.svg";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios'
import { api } from '../../../../../utils/constants';
import Pagination from "../../../../../components/Pagination";
import Profile from "../../../../../assets/images/pic.svg";
import moment from 'moment';
import { notifyUpdate } from '../../../../../redux/auth/actions';
import { useHistory } from "react-router-dom";
import useOnClickOutside from "../../../../../hooks/useOutsideClickhandler";
import Ratingstar from "../../../../../assets/images/ratingstar.svg";
import { Helmet } from 'react-helmet';


const NotificationSection = () => {
    const history = useHistory();
    const userdata = useSelector(state => state.auth);
    const [isloading, setLoading] = useState(false)
    const [navidata, setNaviData] = useState([])
    const dispatch = useDispatch()
    const [update, setUpdate] = useState(false)
    const [radiovalue, setRadiovalue] = useState([]);
    const [popup, setPopup] = useState(false);
    const [sortpop, setSortpop] = useState(false);
    const [seletall, setSelectall] = useState(false);
    const sortref = useRef();
    const ref = useRef();
    useEffect(() => {
        ApiFetch();
    }, []);
    const ApiFetch = async () => {
        setLoading(true)
        axios.get(api.tokenupdate + "list/" + userdata.uuid, { headers: { 'x-auth-token': userdata.token } })
            .then(res => {
                let ndata = res.data;
                ndata.sort(function (a, b) {
                    return b.status.localeCompare(a.status)
                });
                setNaviData(ndata)
                setLoading(false)
            })
            .catch(err => {
                setTimeout(() => {
                }, 100);
                setLoading(false)
            })
    }
    useOnClickOutside(ref, () => {
        setPopup(false);
    });
    useOnClickOutside(sortref, () => {
        setSortpop(false);
    });
    const selectallFn = async () => {
        setSelectall(!seletall)
        let temp = [];
        if (!seletall) {
            temp = navidata;
        }
        setRadiovalue(temp);
    }
    const handleRadio = (id, val) => {
        const idToRemove = id;
        if (radiovalue.includes(val)) {
            const filteredPeople = radiovalue.filter((item) => item._id !== idToRemove);
            setRadiovalue(filteredPeople);
        }
        else {
            setRadiovalue([...radiovalue, val])
        }

    }
    const Filter = (val) => {

        if (val == "Most Recent") {
            navidata.sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt);
            });
            setNaviData(navidata)
        }
        else if (val == "Oldest") {
            navidata.sort(function (a, b) {
                return new Date(a.createdAt) - new Date(b.createdAt);
            });

            setNaviData(navidata)
        }
        else if (val == "Read") {
            navidata.sort(function (a, b) {
                return a.status.localeCompare(b.status)
            });

            setNaviData(navidata)
        }
        else if (val == "Unread") {
            navidata.sort(function (a, b) {
                return b.status.localeCompare(a.status)
            });

            setNaviData(navidata)
        }
        setUpdate(!update)
    }
    const Markall = (type) => {
        setLoading(true)
        if (type != "important") {
            var data = {
                "status": type,
                "id": radiovalue.map((e) => e._id)
            }
            axios.put(api.tokenupdate + "status/all", data, {}, { headers: { 'x-auth-token': userdata.token } })
                .then(res => {
                    setRadiovalue([])
                    ApiFetch();
                })
                .catch(err => {

                    setLoading(false)
                })
        }
        else {
            var data = {
                "important": true,
                "id": radiovalue.map((e) => e._id)
            }
            axios.put(api.tokenupdate + "important/all", data, {}, { headers: { 'x-auth-token': userdata.token } })
                .then(res => {
                    setRadiovalue([])
                    ApiFetch();
                })
                .catch(err => {

                    setLoading(false)
                })
        }
    }
    const NotiClick = (detail) => {

        let type = detail.type;

        if (type != "") {
            setLoading(true)

        }

        if (type == "epAdded") {

        }
        else if (type == "eplAdded") {

        }
        else {
            if (detail.body && detail.body.booking_uuid && detail.body.booking_uuid != "") {
                let id = detail.body.booking_uuid;

                axios.get(api.appointmentall + "/" + id, { headers: { 'x-auth-token': userdata.token } })
                    .then(r => {
                        console.log(r, 'noti response bookung')
                        if (r.data.length > 0) {
                            let item = r.data[0];
                            let doctor = null;
                            if (item.doctor_uuid) {
                                axios.get(api.user + '/' + item.doctor_uuid, { headers: { 'x-auth-token': userdata.token } })
                                    .then(res => {
                                        setLoading(false)
                                        let appoint = item;
                                        appoint.doctors = [res.data];
                                        history.push({ pathname: "/appointmentdetail", data: appoint })

                                    })
                                    .catch(err => { setLoading(false) })
                            }
                            else {
                                setLoading(false)
                                history.push({ pathname: "/appointmentdetail", data: item })
                            }

                        }
                        else {
                            setLoading(false)
                        }
                    })
                    .catch(err => {
                        setLoading(false)
                        setTimeout(() => {
                        }, 100);
                    })
            }
            else {
                setLoading(false)
            }

        }
        let d = {
            "id": detail._id,
            "status": "read"
        }
        axios.put(api.tokenupdate + "status", d, { headers: { 'x-auth-token': userdata.token } })
            .then(r => {
                ApiFetch()
                dispatch(notifyUpdate(userdata.nofity - 1))
            })
            .catch(err => {
                setLoading(false)
                setTimeout(() => {
                }, 100);
            })
    }
    return (
        <div className="NotificationContainer">
             <Helmet>
      <title>Notifications - Doctall Consumer Web



</title>
<meta 
            name='description' 
            content='View all your notifications from Doctall; appointment reminders, lab test advice and prescription notifications and app updates.'
            />
    </Helmet>
            <div className="header-row">
                <div className="left-cont">
                    <div className="img-cont" onClick={() => selectallFn()}>
                        {
                            seletall ? <img crossorigin="anonymous" src={RadioOn} /> : <img crossorigin="anonymous" src={RadioOff} />
                        }

                    </div>
                    <div className="select-all-text">
                        Select all
                    </div>
                </div>
                <div className="right-cont">
                    <div className="sort-cont-out" onClick={() => setSortpop(!sortpop)}>
                        <div className="sort-text">Sort</div>
                        <div className="img-cls"><img crossorigin="anonymous" src={Sorticon} /></div>
                        {
                            sortpop ?
                                <div className="sortpopup" ref={sortref}>
                                    <div className="font-display-cls-sort">
                                        <div className="popuptext-sort" onClick={() => { Filter("Most Recent") }}>Sort by most recent </div>
                                        <div className="popuptext-sort" onClick={() => { Filter("Oldest") }}>Sort by oldest </div>
                                        <div className="popuptext-sort" onClick={() => { Filter("Read") }}>Sort by read</div>
                                        <div className="popuptext-sort" onClick={() => { Filter("Unread") }}>Sort by unread</div>
                                    </div>
                                </div> : ""
                        }
                    </div>
                    <div className="option-icon" onClick={() => setPopup(!popup)} >
                        <img crossorigin="anonymous" src={Option} />
                        {
                            popup ?
                                <div className="popup" ref={ref}>
                                    <div className="font-display-cls">
                                        <div className="popuptext" onClick={() => { Markall("read") }}>Mark all as read </div>
                                        <div className="popuptext" onClick={() => { Markall("unread") }}>Mark as unread </div>
                                        <div className="popuptext" onClick={() => { Markall("important") }}>Mark as important</div>
                                    </div>
                                </div> : ""
                        }
                    </div>

                </div>
            </div>
            <div className="subheader-text">New</div>
            <div className="content">
                <Pagination
                    pageSize={6}
                    records={navidata}>
                    {(not) =>
                        <div className="list-cont-out">
                            {not && not.length > 0 && not.map((item, ind) => {
                                return (
                                    <>

                                        <div className={item.status == "read" ?"wrapper":"wrapper read"} key={ind} onClick={(e) => { e.stopPropagation(); NotiClick(item) }}>
                                            <div className="radio-btn" onClick={(e) => { e.stopPropagation(); handleRadio(item._id, item) }}>{radiovalue.includes(item) ? <img crossorigin="anonymous" src={RadioOn} /> : <img crossorigin="anonymous" src={RadioOff} />}</div>
                                            {/* <div className="profile-img"><img crossorigin="anonymous" src={Profile} /></div> */}
                                            <div className="list-right-container">
                                                <div className="header-container-wrapper">
                                                    <div className="header-container-font-cls">{item.title}</div>
                                                    <div className="line-icon"><img crossorigin="anonymous" src={Line} /></div>
                                                    <div className="timing-text">{moment(item.createdAt) < moment().add(-7, "days") ? moment(item.createdAt).format("DD, MMM YYYY") : moment(item.createdAt).format("DD-MM-YYYY") == moment().format("DD-MM-YYYY") ? "Today " + moment(item.createdAt).format("LT") : moment(item.createdAt).format("dddd") + " " + moment(item.createdAt).format("LT")}</div>
                                                </div>
                                                <div className="desc-text">{item.message}</div>
                                            </div>
                                            {item.important && <div className="notify-important"><img crossorigin="anonymous" src={Ratingstar} /></div>}
                                        </div>

                                    </>
                                )
                            })}
                        </div>}
                </Pagination>
            </div>


        </div>
    )
}


export default NotificationSection;
