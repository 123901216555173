import types from './types'

export const getPatients = () => ({
    type: types.GET_PATIENTS
})


export const createPatient = (data, cb) => ({
    type: types.CREATE_PATIENT,
    data,
    cb
})
export const updatePatient = (data, patientId) => ({
    type: types.UPDATE_PATIENT,
    data,
    patientId
})
export const deletePatient = (patientId) => ({
    type: types.DELETE_PATIENT,
    patientId
})
