import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";


function AppContainer(props) {
    const [cookies, setCookies] = useCookies(["redirect"])
    const history = useHistory();

    useEffect(() => {
        // alert('load '+history.location.pathname)
    }, [])
    useEffect(() => {
        console.log("Location: " + history.location.pathname)
        // alert(history.location.pathname)
        // setCookies("redirect", history.location.pathname,)
        return history.listen((location) => {
            console.log(" return Location:"+ location.pathname)
        });
    }, [history]);

    return (
        props.children
    )
}
export default AppContainer;