import React, { Fragment, useState, useEffect, useRef } from "react";
import "./SadnBooking.scss";
import Layout from "../../layout/DashboardLayout";
import {
  SelectTimeSlote,
  AppointmentTip,
  ReasonForVisit,
  AddDocuments,
  TitleWithNav,
} from "../../components";
import { Spin } from "antd";

import axios from "../../redux/axios";
import { api } from "../../utils/constants";
import * as RNLocalize from "react-native-localize";
import momenttz from "moment-timezone";
import moment from "moment";
import Toast from "../../components/Toast";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import usePaystackHook from "../../components/Payment";
import Submitpop from "../../components/HealthPop/index";
import Applycoupon from "../../components/apply-coupon/Applycoupon";



function SadnProoceed() {
  const [clinic, setClinic] = React.useState(false);

  const [finalAmount, setFinalAmount] = React.useState(0);
  const [appliedCoupanId, setAppliedCoupanId] = React.useState('');
  const [couponAppliedFlag, setCouponsAppliedFlag] = useState(false);


  const [symptom, setsymptom] = React.useState("");
  const [notes, setNotes] = React.useState("");
  const [selfOrRelative, setSelforRelative] = React.useState("");
  const [toster, setToster] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [type, setType] = React.useState("");
  const [subDoc, setSubDoc] = React.useState([]);
  let history = useHistory();
  const [loader, setLoader] = React.useState(false);
  const [confirmloader, setconfirmloader] = React.useState(false);
  const userdata = useSelector((state) => state.auth);
  const Patients = useSelector(state => state.patients);
  const [modalVisibleHP, setmodalVisibleHP] = useState(false)
  const [patientList, setPatientList] = useState([]);
  const [patient, setPatient] = useState("");
  const [isloading, setLoading] = useState(true);
  const [reference, setReference] = useState("");
  const childRef = useRef(null);
  const [appointmentData, setAppointmentData] = useState();
  const [sadndate, setSadndate] = useState(new Date());

  const [amount, setAmount] = useState(' ')

  const [totalmt, settotal] = useState('')
  const [walletmt, setwalletmt] = useState('')

  // const paymentStart = usePaystackHook({ email: userdata.email, amt: couponAppliedFlag === true && finalAmount && finalAmount !== null && finalAmount !== '' && finalAmount !== undefined && finalAmount > 0 ? finalAmount : amount });
  const paymentStart = usePaystackHook({ email: userdata.email, amt: finalAmount && finalAmount !== null && finalAmount !== '' && finalAmount !== undefined && finalAmount > 0 && finalAmount });

  console.log(appointmentData, "appointmentData")

  const ApiFetch = () => {
    axios
      .get(api.clinic_by_doctor + "/all", {
        headers: { "x-auth-token": userdata },
      })
      .then((r) => {
        setClinic(r.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        setTimeout(() => {
          setToster(true);
          setMsg("Clinc :" + err.response.data);
          setType("error");
        }, 100);
      });
  };
  useEffect(() => {
    ApiFetch();
  }, []);

  const walletDat = () => {
    console.log("WALLET CALLED")
    var payLoad  = {
      "totalamount" : totalmt,
      "amount":walletmt,
      "order_id" : appointmentData.uuid,
      "user_uuid" : userdata.uuid
  }
    axios.put(api.updatewalletAmnt,payLoad,{ headers: { "x-auth-token": userdata.token } })
    .then((res) => {
      console.log("update")
    })
    .catch((err) => {
      console.log(err, "WALLEt ERRORR")
    })
  }

  useEffect(() => {
    axios.get(api.clinic_by_doctor + "/all", {
      headers: { "x-auth-token": userdata.token },
    })

      .then((r) => {
        const deviceTimeZone = RNLocalize.getTimeZone();
        const today = momenttz().tz(deviceTimeZone);
        // const today  = moment(new Date()).utc();
        let dataval = {
          booking_date: today.utc().format(),
          clinic_uuid: r.data.uuid,
        };

        axios.post(api.appointment + "sadn/waiting", dataval, {
          headers: { "x-auth-token": userdata.token },
        })
          .then((r) => {
            if (r.data) {
              // setAmount(r.data.amount);

            } else {
              console.log(r, "ressss")
            }
          })
          .catch((err) => {
            console.log(err, "err")
          });
      })
      .catch((err) => {
        if (err && err.response) {
          setTimeout(() => {
          }, 100);
        }
      });
  }, []);
  useEffect(() => {
    consultAmountfee()
  })
    const consultAmountfee = () => {
      axios.get(api.appointment + "sadn/booking/amount")
      .then((res) => {
        setAmount(res.data.amount)
      })
      .catch((err) => {
        console.log(err)
      })
    }

  useEffect(() => {
    if (patient && patient.data) {
      let res = patient.data.map((e, i) => {
        return { label: e.full_name, value: e.uuid };
      });
      setPatientList(res);
    }
  }, [patient]);

  const ConfirmBooking = () => {
    if (clinic && clinic.name == undefined) {
      setToster(true);
      setMsg("Please contact the doctall support team!");
      setType("error");
      return false;
    }
    if (selfOrRelative == "") {
      setToster(true);
      setMsg("Please select Patient");
      setType("error");
      return false;
    }

    setconfirmloader(true);

    let datareasons = [];
    if (symptom == "") datareasons.push("Abdominal Pain");
    else datareasons.push(symptom.value)


    // setLoading(true);

    const deviceTimeZone = RNLocalize.getTimeZone();
    const today = momenttz().tz(deviceTimeZone);
    let bookingData = {
      video: true,
      reasons: datareasons,
      desc: notes == "" ? "NA" : notes,
      booking_date: today.utc().format(),
      patient_uuid: selfOrRelative.value,
      category: "GC",
      type: "sadn",
      amount: couponAppliedFlag === true && finalAmount >= 0 ? finalAmount : amount,
      documents: subDoc.length > 0 ? subDoc : [],
      clinic_uuid: clinic.uuid,
    };
    axios
      .post(api.appointment + "sadn", bookingData, {
        headers: { "x-auth-token": userdata.token },
      })
      .then((res) => {
        setAppointmentData(res.data);
        setconfirmloader(false);

        axios
          .get(api.payment + "booking/" + res.data.uuid, {
            headers: { "x-auth-token": userdata.token },
          })
          .then((res2) => {
            if (res2.data && res2.data.reference) {
              setReference(res2.data.reference);

            } else {
              setTimeout(() => {
                setToster(true);
                setMsg("Payment Reference is Empty");
                setType("error");
              }, 100);
              setLoader(false);
            }
          }).catch((err) => {
            setTimeout(() => {
              setToster(true);
              setMsg("Payment Ref :" + err.response.data);
              setType("error");
            }, 100);
            setLoader(false);
          });
        // setLoading(false)
        // PaymentSuccess(res.data)
      })
      .catch((err) => {
        setTimeout(() => {
          setToster(true);
          setMsg("Booking :" + err && err.response && err.response.data);
          setType("error");
          setconfirmloader(false);
        }, 100);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (reference) {

      if (finalAmount === 0) {
        setLoader(false);
        applyFullDiscount()
      } else {
        setLoader(false);
        paymentStart(PaymentSuccess, PaymentCancel);
      }
    }

  }, [reference])

  const applyFullDiscount = async () => {
    try {
      blockAppliedCoupon(reference)
      walletDat()
      setLoader(true);
      let response = await axios.put(api.appointmentall + "/" + appointmentData.uuid + "/booked", {}, { headers: { "x-auth-token": userdata.token } })
      history.push({ pathname: "/doctor_consultations", data: appointmentData });
    } catch (error) {
      if (error && error.response) {
        setLoader(false);
        setToster(true);
        setMsg("Payment :" + error.response.data);
        setType("error");
      }
    }
  }

  useEffect(() => {
    handleSelf()
  }, [selfOrRelative])

  const PaymentSuccess = (e) => {
    setLoader(true);

    try {
      if (e.status == "success") {
        blockAppliedCoupon(reference)
        setLoader(true);
        walletDat();
        axios
          .put(
            api.appointmentall + "/" + appointmentData.uuid + "/booked",
            {},
            { headers: { "x-auth-token": userdata.token } }
          )
          .then((res2) => {
            history.push({ pathname: "/doctor_consultations", data: appointmentData });
          }).then((res3) => {
            // blockAppliedCoupon(reference)
            console.log("None")
          }).catch((err) => {
            setLoader(false);
            setToster(true);
            setMsg("Payment :" + err && err.response && err.response.data);
            setType("error");
          });

          
      } else {
        axios
          .put(
            api.appointmentall + "/" + appointmentData.uuid + "/declined",
            {},
            { headers: { "x-auth-token": userdata.token } }
          )
          .then((res2) => {
            setLoader(false);
          })
          .catch((err) => {
            setLoader(false);
            setToster(true);
            setMsg("Payment :" + err.response.data);
            setType("error");
          });
      }
    } catch (e) {
      setLoader(false);
    }
  };
  const PaymentCancel = (e) => {
    setLoader(true);
    axios
      .put(
        api.appointmentall + "/" + appointmentData.uuid + "/cancelled",
        {},
        { headers: { "x-auth-token": userdata.token } }
      )
      .then((res2) => {
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        setToster(true);
        setMsg("Payment :" + err.response.data);
        setType("error");
      });
  };


  const blockAppliedCoupon = async (transactionId) => {
    try {
      let response = await axios.put(`${api.blockCoupon}/${appliedCoupanId && appliedCoupanId}/${transactionId}`, { headers: { "x-auth-token": userdata.token } })
      if (response) {
        console.log("Success")
      }

    } catch (error) {
      console.log(error)
    }
  }




  const handleSelf = () => {
    let res;
    if (Patients != undefined && Patients !== "") {
      res = Patients.data.filter((e, i) => e.uuid == selfOrRelative.value)
    }

    if (res.length > 0) {
      let data = res[0];
      if (data.medicalQuestions && data.medicalQuestions.length > 0) {
      }
      else {
        setmodalVisibleHP(true)
      }
    }
    // setPatient(item)
  }

  function strikeThrough(text) {
    return text
      .split('')
      .map(char => char + '\u0336')
      .join('')
  }

  return (
    <>
      <Layout>
        <div className="freeBooking">
          <TitleWithNav title={`See a Doctor Now ₦${amount}`} />
          <Spin spinning={loader}>
            <div className="containerWrapper">
              <div className="componentContainer">
                <SelectTimeSlote
                  type="sadn"
                  setSelforRelative={setSelforRelative}
                />
                <AppointmentTip />
              </div>

              <div className="componentContainer2">
                <ReasonForVisit
                  type="sadn"
                  setsymptom={setsymptom}
                  setNotes={setNotes}
                />
              </div>
            </div>

            <AddDocuments
              selfOrRelative={selfOrRelative}
              setSubDoc={setSubDoc}
              subDoc={subDoc}
              setSadndate={setSadndate}
              sadndate={sadndate}
            />

            {/* For SADN 750 will be static as of now */}
            <Applycoupon
              userId={userdata && userdata.uuid}
              totalAmount={Number(amount)}
              finalAmount={(amount) => { setFinalAmount(amount) }}
              billtotal={(amount) => { settotal(amount) }}
              walletAmount={(amount) => {setwalletmt(amount)}}
              blockCouponid={(couponId) => { setAppliedCoupanId(couponId) }}
              appointmentCategory={"sadn"}
              couponAppliedFlags={(event) => {
                setCouponsAppliedFlag(event);
              }}
            />
            {
           console.log(finalAmount)
}
            <div className="buttonConfirm" onClick={ConfirmBooking}>
              {confirmloader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Please Wait...&nbsp;
                  <CircularProgress size="25" style={{ color: "#fff", marginLeft: 5 }} />
                </div>
              ) : (
                "CONFIRM APPOINTMENT"
              )}
            </div>
          </Spin>
        </div>

        <Toast
          text={msg}
          type={type}
          open={toster}
          setClose={() => {
            setToster(false);
          }}
        />
      </Layout>
      <Submitpop show={modalVisibleHP} onHide={() => setmodalVisibleHP(false)} />
    </>
  );
}

export default SadnProoceed;
