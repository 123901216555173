import React, { useState } from 'react';
import './filtercomponet.scss';
import DropDown from '../../../../../../components/Select/index';
import Label from '../../../../../../components/Label';
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from 'react-helmet';
import { Filter } from '@material-ui/icons';

const optionsfordate = [
    {
        label: "0-3 Months",
        value: 3
    },
    {
        label: "3-6 Months",
        value: 6
    }, {
        label: "6-12 Months",
        value: 12
    }, {
        label: "Over One Year",
        value: "above12"
    },
]
const documnetItems = [
    { label: "Prescription", value: "Prescription" },
    { label: "Diagnostics/Lab Test Result", value: "Diagnostics/Lab Test Result" },
    { label: "Medical Examination", value: "Medical Examination" },
    { label: "Discharge Certificate", value: "Discharge Certificate" },
    { label: "Patient Information", value: "Patient Information" },
    { label: "Laboratory Test Request", value: "Laboratory Test Request" },
    { label: "Medical History Records", value: "Medical History Records" },
    { label: "Mental Status Summary", value: "Mental Status Summary" },
    { label: "Operative Report", value: "Operative Report" },
    { label: "Physical Examination", value: "Physical Examination" },
    { label: "Others", value: "Others" }
]

const FilterPres = (props) => {
    const dispatch = useDispatch();
    const { setDate, setDoctor, doctorlist, Filter, setFilterpres, FilterFn, date, doctor } = props;
    const [uploadvalue, setUploadvalue] = useState("");
    const [patientvalue, setPatientValue] = useState("")
    const [doctortype, setDoctortype] = useState("");
    const [datetype, setDatetype] = useState("");
    const [store, setStore] = useState({
        date: "",
        doctor: ""
    })
    const filterfn = (value) => {
        setUploadvalue(value.value)
        props.setDocType(value.value);
    }
    const Patientfn = (value) => {
        setPatientValue(value.value)
        props.setPatient(value.value);
    }
    const handleChangeFilter = (val, type, click) => {
        setFilterpres({
            ...props.filterpres,
            date: type == "Date" ? val : "",
            doctor: type == "Doc" ? val : ""

        });
        if (type == "Doc" && type != null) {
            setDoctor(val)
            setDoctortype(type)
        }
        else if (type == "Date" && type != null) {
            setDate(val)
            setDatetype(type)
        }
        else {
            setDatetype("");
            setDoctortype("")
        }
        if (click == "clicked" && doctortype == "Doc") {
            FilterFn(doctor, "Doc")
        }
        else if (click == "clicked" && props.upload && datetype == "Date") {
            props.Filter("Date", date)
        }
        else {
            if (click == "clicked" && datetype == "Date") {
                FilterFn(date, "Date")
            }
        }
    };
    const handleclearFilter = async () => {
        props.setLoading(true)
        if (props.presfn != "") {
            setDoctor("");
            setDate("");
            props.presfn()

        }
        else if (props.labfn != "") {
            props.labfn();
            props.setDoctor("");
            props.setDate("");
        }
        else if (props.upfn != "") {
            props.upfn();
            props.setDate("");
            props.setDocType("");
        }
    }
    return (
        <>
  <Helmet>
      <title>Health Records - Doctall Consumer Web
 
</title>
<meta 
            name='description' 
            content='View all your health records; from prescriptions to lab test advice. Uploads health records and store on a secure cloud storage to download whenever you need.
            '
            />
    </Helmet>
            <div className="select-date-pres">
                <Label text="DATE" hr={true} />
                <DropDown placeholder={"Please Select"}
                    value={date}
                    setValue={(value) => handleChangeFilter(value, "Date", null)}
                    options={optionsfordate}
                    hr={true}

                />
            </div>

            <div className="select-doc-pres">
                <Label text={props.upload ? "DOCUMENT TYPE" : "DOCTOR"} hr={true} />
                <DropDown placeholder={"Please Select"}
                    value={props.upload ? props.docType : doctor}
                    setValue={(value) => props.upload ? filterfn(value) : handleChangeFilter(value, "Doc", null)}

                    options={props.upload ? documnetItems : doctorlist != undefined && doctorlist.length > 0 ? doctorlist : []}
                    hr={true}
                />
            </div>

            {
                props.upload == true ?
                    <>
                        <div className="select-doc-pres">
                            <Label text={props.upload ? " PATIENT" : "DOCTOR"} hr={true} />
                            <DropDown placeholder={"Please Select"}
                                value={props.upload ? props.patientval : doctor}
                                setValue={(value) => Patientfn(value)}

                                options={props.patientList !== "" && props.patientList.length > 0 ? props.patientList : []}
                                hr={true}
                            />
                        </div>

                        <div className="upload-align-hr">
                            {/* <div className="btn-class-hr-filter-upload"> */}
                            <div className="filter-btn-upload" onClick={() => props.upload && uploadvalue !== "" || patientvalue !== "" ? uploadvalue !== "" ? props.Filter('Doc', uploadvalue) : patientvalue !== "" ? props.Filter('Patient', patientvalue) : handleChangeFilter(null, null, 'clicked') : ""}>
                                <span>APPLY FILTER</span>
                            </div>
                            <div className="clear-btn-upload" onClick={() => handleclearFilter()}>
                                <span>CLEAR FILTER</span>
                            </div>
                            {/* </div> */}
                        </div>
                    </>
                    : ""
            }
            {
                props.upload == false ?
                    <div className="btn-class-hr-filter">
                        <div className="filter-btn" onClick={() => props.upload && uploadvalue !== "" ? props.Filter('Doc', uploadvalue) : handleChangeFilter(null, null, 'clicked')}>
                            <span>APPLY FILTER</span>
                        </div>
                        <div className="clear-btn" onClick={() => handleclearFilter()}>
                            <span>CLEAR FILTER</span>
                        </div>
                    </div> : ""


            }


        </>
    )
}


export default FilterPres;