import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from 'moment'
import './filter.scss';
import Filtercomponet from "./filtercomponent";
import axios from "../../../../../../redux/axios";
import { api } from '../../../../../../utils/constants';
import Toast from '../../../../../../components/Toast';
export default function FilterRecord(props) {
  const { setEprescription, setLoading } = props;
  const [filterpres, setFilterpres] = React.useState({
    date: '',
    doctor: '',
  });
  const [doctotlist, setDoctorList] = useState("");
  const [doctor, setDoctor] = useState()
  const [date, setDate] = useState()
  const [docType, setDocType] = useState("");
  const [toster, setToster] = React.useState(false);
  const [toastMsg, setToastMsg] = React.useState('');
  const [toastType, setToastType] = React.useState('');
  const userdata = useSelector(state => state.auth)


  const ApiFetch = () => {
    try {
      axios.get(api.eprescriptiondoctor, { headers: { 'x-auth-token': userdata.token } })
        .then(r => {
          // setDoctorList(r.data.data)
          let resultdate = r.data != undefined && r.data.data != undefined && r.data.data.length > 0 ? r.data.data.map((val, ind) => {
            return (
              {
                label: val.full_name,
                value: val.uuid
              }
            )
          }) : ""
          setDoctorList(resultdate)
        })

        .catch(err => {
          setToster(true);
          setToastMsg("Request Failed");
          setToastType('error');
        })
    }
    catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    ApiFetch()
  }, [])
  const Filter = (val, type) => {
    let dateval = date;
    let docval = doctor;
    let pval = props.patientval ? props.patientval : "";
    if (type == "Doc") {
      docval = val;
    }
    else {
      dateval = val;
    }

    setLoading(true)


    let url = props.lab ? api.labTest + "?user_uuid=" + userdata.uuid :

      api.eprescription + "?user_uuid=" + userdata.uuid;


    if (dateval) {
      let start = moment().format("YYYY-MM-DD");
      let end = moment().format("YYYY-MM-DD");
      if (dateval.value == "3") {
        start = moment().add(-3, "months").format("YYYY-MM-DD");
        end = moment().format("YYYY-MM-DD");
      }
      else if (dateval.value == "6") {
        start = moment().add(-6, "months").format("YYYY-MM-DD");
        end = moment().add(-3, "months").format("YYYY-MM-DD");
      }
      else if (dateval.value == "12") {
        start = moment().add(-12, "months").format("YYYY-MM-DD");
        end = moment().add(-6, "months").format("YYYY-MM-DD");
      }
      else {
        start = moment().add(10, "years").format("YYYY-MM-DD");
        end = moment().add(-12, "months").format("YYYY-MM-DD");
      }
      url = url + "&start_date=" + start + "&end_date=" + end

    }
    if (docval) {

      url = url + "&doctor_uuid=" + docval.value;
    }
    axios.get(url, { headers: { 'x-auth-token': userdata.token } })
      .then(r => {
        let valdata = r.data;
        valdata.sort(function (a, b) {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setEprescription(valdata)
        setLoading(false)
      })
      .catch(err => {
        setEprescription([])
        setLoading(false)
        setTimeout(() => {
          setToster(true);
          setToastMsg("Request Failed");
          setToastType('error');
        }, 100);
      })
  }
  const Filterupload = (type, val) => {
    let dateval = date;
    let docval = docType;
    let pval = props.patientval ? props.patientval : ""
    if (type == "Doc") {
      docval = val;
    }
    else if (type == "Patient") {
      pval = val;
    }
    else {
      dateval = val;
    }
    setLoading(true)
    let url = api.uploadlist + "/" + pval;
    let postdata = {};
    if (dateval) {

      let start = moment().format("YYYY-MM-DD");
      let end = moment().format("YYYY-MM-DD");
      if (dateval.value == "3") {
        start = moment().add(-3, "months").format("YYYY-MM-DD");
        end = moment().format("YYYY-MM-DD");
      }
      else if (dateval.value == "6") {
        start = moment().add(-6, "months").format("YYYY-MM-DD");
        end = moment().add(-3, "months").format("YYYY-MM-DD");
      }
      else if (dateval.value == "12") {
        start = moment().add(-12, "months").format("YYYY-MM-DD");
        end = moment().add(-6, "months").format("YYYY-MM-DD");
      }
      else {
        start = moment().add(10, "years").format("YYYY-MM-DD");
        end = moment().add(-12, "months").format("YYYY-MM-DD");
      }
      postdata = {
        startDate: start,
        endDate: end
      }

    }
    if (docval) {
      postdata.docType = docval
      if (postdata.startDate == undefined) {

        postdata.startDate = moment().add(-3, "months").format("YYYY-MM-DD");
        postdata.endDate = moment().format("YYYY-MM-DD");

      }
    }
    else {
      if (postdata.startDate == undefined) {
        postdata.startDate = moment().add(-3, "months").format("YYYY-MM-DD");
        postdata.endDate = moment().format("YYYY-MM-DD");


      }
    }


    if (Object.keys(postdata).length > 0) {
      axios.post(url, postdata, { headers: { 'x-auth-token': userdata.token } })
        .then(r => {
          let valdata = r.data;
          valdata.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          props.setUpload(valdata)
          setLoading(false)
        })
        .catch(err => {
          setToster(true);
          setToastMsg("Request Failed");
          setToastType('error');
          props.setUpload([])
          setLoading(false)
        })
    }
    else {
      axios.get(url, { headers: { 'x-auth-token': userdata.token } })
        .then(r => {
          let valdata = r.data;
          valdata.sort(function (a, b) {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          props.setUpload(valdata)
          setLoading(false)
        })
        .catch(err => {
          setToster(true);
          setToastMsg("Request Failed");
          setToastType('error');
          props.setUpload([])
          setLoading(false)
        })
    }
  }

  return (

    <>

      <div className="container-filter">
        <div className="filter-text">Filter By</div>
        <Filtercomponet Filter={Filterupload} setDocType={setDocType}
          setDoctor={setDoctor} setDate={setDate} setFilterpres={setFilterpres}
          doctorlist={doctotlist} upload={props.upload ? true : false} FilterFn={Filter} filterpres={filterpres}
          doctor={doctor} date={date} presfn={props.presfn ? props.presfn : ""} labfn={props.labfn ? props.labfn : ""}
          upfn={props.upfn ? props.upfn : ""} setLoading={setLoading} docType={docType} patientList={props.patientList ? props.patientList : ""}
          setPatient={props.setPatient ? props.setPatient : ""} patientval={props.patientval ? props.patientval : ""}
        />
      </div>
    </>
  )

}
