/**
 * 
 * Range Field
 * 
 * 
 */


import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import './style.scss';

const useStyles = makeStyles({
  root: {
    width: '100%',
    background: '#F2F8F9',
    border: '1px solid #ADC2C2',
    boxSizing: 'border-box',
    borderRadius: '5px',
    padding: '9px',
    paddingLeft: '15px',
    paddingRight: '15px',
    display: 'flex',
    alignItems: 'center'
  },
});

function valuetext(value) {
  return `${value}°C`;
}



export default function RangeField(props) {

    const { onChange, name, value, defaultValue, min, max, marks, step } = props;
    const classes = useStyles();
  
    const handleChange = (event, newValue) => {
      onChange(Object.assign({}, { target: {
        name,
        value: newValue
      }}));
    };
  
    return (
      <div className={classes.root}>
        <Slider
          value={value || defaultValue}
          onChange={handleChange}
          min={min}
          max={max}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          getAriaValueText={valuetext}
          marks={marks}
          step={step || 10}
        />
      </div>
    );
}