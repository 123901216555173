import React from 'react';
import { Modal } from 'react-bootstrap';
import Close from '../../assets/images/close.svg';
import ICON18 from '../../assets/images/ICON18.svg';
import './UploadSuccssMsg.scss'

function UploadSuccssMsg(props) {
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modal-upload"
        >
            <div className="UploadSuccssMsg">
                <img crossorigin="anonymous" className="closeButton" src={Close} alt="close" onClick={() => { props.onHide() }} />
                <div>
                    <img crossorigin="anonymous" className="tick" src={ICON18} alt="icon" /><br />
                    <div>Upload Successful</div>
                </div>
            </div>
        </Modal>
    )
}

export default UploadSuccssMsg
