import React from 'react';
import { Modal } from 'react-bootstrap';
import Close from '../../assets/images/close.svg';
import './DeletePopup.scss';
import ICON19 from '../../assets/images/ICON19.svg';


function DeletePopup(props) {
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modal-upload"
        >
            <div className="DeletePopup">
                <img crossorigin="anonymous" className="closeDel" src={Close} alt="Close" onClick={() => { props.onHide() }} />
                <div className="DelContainer">
                    <img crossorigin="anonymous" src={ICON19} alt="icons" />
                    <div>Important Notice</div>
                    <p>Are you sure you want to delete this document?</p>
                    <div className="btnContainer">
                        <div className="btnYes" onClick={props.confirmDelete}>Yes</div>
                        <div className="btnNo" onClick={() => { props.onHide() }}>No</div>
                    </div>
                </div>
            </div>

        </Modal>
    )
}

export default DeletePopup
