import React from 'react'
import { Link } from 'react-router-dom'
import './fullNavlayout.scss'

function FullNavLayout({ children }) {
    return (
        <div>
            <div className="head">
                <div className="logo">
                    <img crossorigin="anonymous" src={require('../assets/images/logo.svg').default} alt="" />
                </div>
                <Link style={{ textDecoration: 'none' }} to="/login"><button className="loginBtn">Login</button></Link>
            </div>
            <div className="bg">
                {children}
            </div>

        </div>
    )
}

export default FullNavLayout
