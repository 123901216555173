import Modal from 'react-bootstrap/Modal';
import Closebutton from "../../../../../../assets/images/closebutton.svg";
import Notice from "../../../../../../assets/images/notice.svg";
import './uploadpop.scss';


export default function MydModalWithGrid(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered contentClassName="modal-cls-upload-popup">
      <div className="upload-pop-container">
        <div className="header">
          <div className="close-btn">
            <img crossorigin="anonymous" src={Closebutton} alt="close-btn" onClick={() => props.onHide()} ></img>
          </div>
        </div>
        <div className="upload-del-img-cont">
          <div className="success-img-cont-pass-confirm">
            <div className="img-wrap">
              <div className="img-tick">
                <img crossorigin="anonymous" src={Notice} alt="tick" ></img>
              </div>
            </div>

          </div>
        </div>
        <div className="font-cont">
          <div className="header-font">Important Notice </div>
          <div className="font-one">Are  you sure you want to delete this document?</div>
        </div>
        <div className="btn-cont">
          <div className="yes-btn" onClick={() => props.handleConfirmChangePass()}><span>Yes, Delete</span></div>
          <div className="no-btn" onClick={() => props.onHide()}><span>No, Cancel</span></div>
        </div>
      </div>
    </Modal>
  );
}