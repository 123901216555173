/**
 * 
 * 
 * Doctors Details Page
 * 
 */


import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from "react-redux";
import axios from 'axios';
import { Link } from 'react-router-dom';
import Docktall from '../../assets/images/Docktall.svg';
import BackBtn from '../../assets/images/BackBtn.svg';
import BG from '../../assets/images/background.svg';
import DefaultProfile from '../../assets/images/pic.svg';
import { getYear } from '../../utils';
import Select from '../../components/Select';
import Rating from '@material-ui/lab/Rating';
import VideoPlayer from '../../components/VideoPlayer';
import DoctorProfile from './DoctorProfile';
import Indian from "../../assets/images/indian.svg";
import Nigerian from "../../assets/images/nigerian.svg";
import British from "../../assets/images/ukflag.png";
import LoginBtn from '../../assets/images/LoginBtn.svg';
import CreateAccountBtn from '../../assets/images/CreateAccountBtn.svg';
import AppointmentBtn from '../../assets/images/AppointmentBtn.svg';
import DashboardBtn from '../../assets/images/DashboardBtn.svg';
import moment from 'moment';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import './style.scss';
import { numberWithCommas } from "../../utils"
import { baseUrl, api } from '../../utils/constants'
import BlueLogo from '../../assets/images/backBlueLogo.svg';
import WhiteLogo from '../../assets/images/nextWhiteLogo.svg';


/**
 * 
 * @param {object} props 
 * @returns 
 */
function DoctorDetails(props) {


    const { match = {} } = props;
    const userdata = useSelector(state => state.auth);
    const [doctorsList, setDoctorsList] = useState({});
    const [profileData, setProfileData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [fromTime, setFromTime] = useState(false);
    const [toTime, setToTime] = useState(false);
    const [slot, setSlot] = useState([]);
    const [slotend, setSlotend] = useState([]);
    const [array, setArray] = useState([])
    const [arrayend, setArrayend] = useState([])
    const { full_name, profile = {}, consultation_fee, profile_pic, slots = [], uuid } = profileData || {};
    const { speciality = [], address = {}, languages = [], rating, practice_started_year, profileVideoUrl = [] ,bio} = profile;

    const theme = useTheme();
    const xs = useMediaQuery(theme.breakpoints.down('xs'));
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const md = useMediaQuery(theme.breakpoints.down('md'));
    const lg = useMediaQuery(theme.breakpoints.down('lg'));

    function arrUnique(arr) {
        var cleaned = [];
        arr.forEach(function (itm) {
            var unique = true;
            cleaned.forEach(function (itm2) {
                if (moment(itm.date).format('ddd  MM/DD') == moment(itm2.date).format('ddd  MM/DD') && moment(itm.start_time).format('LT') == moment(itm2.start_time).format('LT')) unique = false;
            });
            if (unique) cleaned.push(itm);
        });
        return cleaned;
    }

    useEffect(async () => {
        if (match && match.params && match.params.id) {
            await axios.get(api.slots + "/normal/doctor/" + match.params.id)
                .then(res => {
                    let slotlist = res.data && res.data.length > 0 ? res.data[0].slots : [];
                    slotlist = slotlist.filter((e) => moment(e.start_time) >= moment() && e.isActive == true);

                    slotlist = arrUnique(slotlist);

                    let resulttime = slotlist.map((e, i) => { return { label: moment(e.date).format('ddd  MM/DD') + ' | ' + moment(e.start_time).format('LT'), value: e._id, date: moment(e.date).format('ddd  MM/DD') } })

                    let resulttimeend = slotlist.map((e, i) => { return { label: moment(e.date).format('ddd  MM/DD') + ' | ' + moment(e.end_time).format('LT'), value: e._id, date: moment(e.date).format('ddd  MM/DD') } })


                    setSlot(resulttime)
                    setSlotend(resulttimeend)
                    if (resulttime.length > 0 && resulttime.length != undefined && resulttime.length != "") {
                        setArray(resulttime[0])
                    }
                    else {
                        setArray([])
                    }

                    if (resulttimeend.length > 0 && resulttimeend.length != undefined && resulttimeend.length != "") {
                        setArrayend(resulttimeend[0])
                    }
                    else {
                        setArrayend([])
                    }

                })
                .catch(err => console.log(err))
        }
    }, [])


    const fetchDoctorsList = (data) => {
        axios.get(baseUrl + '/user/doctorListFilter', { params: Object.assign({}, data, { "created_by.uuid": userdata.uuid }), headers: { 'x-auth-token': userdata.token } })
            .then(res => {
                setDoctorsList(res && res.data && res.data.user || [])
            })
            .catch(err => {
                console.log('err', err);
            })
    }

    const fetchProfileData = () => {
        if (match && match.params && match.params.id) {
            axios.get(baseUrl + `/user/${match.params.id}`, { params: Object.assign({}, { "created_by.uuid": userdata.uuid }), headers: { 'x-auth-token': userdata.token } })
                .then(res => {
                    setProfileData(res && res.data || {})
                })
                .catch(err => {
                    console.log('err', err);
                })
        }

    }
    const onDocChange = (id) => {
        const body = document.querySelector('#root');
        body.scrollIntoView({
            behavior: 'smooth'
        }, 500)
        fetchDoctorsList({ country: '', speciality: '', experience: '', start_price: '', last_price: '', language: '', search: '', type: 'SAD_landing' });
        fetchProfileData();
        axios.get(baseUrl + `/user/${id}`, { params: Object.assign({}, { "created_by.uuid": userdata.uuid }), headers: { 'x-auth-token': userdata.token } })
            .then(res => {
                setProfileData(res && res.data || {})
            })
            .catch(err => {
                console.log('err', err);
            })
    }
    useEffect(() => {
        const body = document.querySelector('#root');
        body.scrollIntoView({
            behavior: 'smooth'
        }, 500)
        fetchDoctorsList({ country: '', speciality: '', experience: '', start_price: '', last_price: '', language: '', search: '', type: 'SAD_landing' });
        fetchProfileData();
    }, []);

    const onNext = () => {
        if (currentPage != lastPage) {
            setCurrentPage(currentPage + 1);
        }
    };

    const onPrevious = () => {
        if (currentPage != 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const itemsPerSize = xs || sm ? 1 : md ? 2 : lg ? 4 : 3;
    const firstPageIndex = (currentPage - 1) * itemsPerSize;
    const lastPageIndex = firstPageIndex + itemsPerSize;
    let lastPage = Math.ceil((doctorsList.length - 2) / itemsPerSize);
    const doctorsPageList = profileData && profileData._id && doctorsList && Array.isArray(doctorsList) && doctorsList.length > 0 ? doctorsList.filter(a => a._id.toString() !== profileData._id.toString()).slice(firstPageIndex, lastPageIndex) : [];


    const FromSlots = slots && Array.isArray(slots) && slots.length > 0 && slots.map(f => {
        return {
            value: moment(f.start_time).format('DD, MMM, YYYY h:mm A'),
            label: moment(f.start_time).format('DD, MMM, YYYY - h:mm A'),
            className: 'slotList'
        }
    }) || [
            {
                value: moment().format('DD, MMM, YYYY h:mm A'),
                label: moment().format('DD, MMM, YYYY - h:mm A'),
                className: 'slotList'
            }
        ];
    const ToSlots = slots && Array.isArray(slots) && slots.length > 0 && slots.map(f => {
        return {
            value: moment(f.end_time).format('DD, MMM, YYYY h:mm A'),
            label: moment(f.end_time).format('DD, MMM, YYYY - h:mm A'),
            className: 'slotList'
        }
    }) || [
            {
                value: moment().add(30, 'minutes').format('DD, MMM, YYYY h:mm A'),
                label: moment().add(30, 'minutes').format('DD, MMM, YYYY - h:mm A'),
                className: 'slotList'
            }
        ];

    return (
        <Container fluid className="doctor-details-page">
            <Row className="doctor-details-header">
                <Col sm={6}>
                    <img crossorigin="anonymous" src={Docktall} />
                </Col>
                <Col sm={6} className="doctor-details-link">
                    {userdata.token ? <Link to="/dashboard">
                        <img crossorigin="anonymous" src={DashboardBtn} />
                    </Link> : <Row>
                        <Col>
                            <Link to="/login">
                                <img crossorigin="anonymous" src={LoginBtn} />
                            </Link>
                        </Col>
                        <Col>
                            <Link to="/signup">
                                <img crossorigin="anonymous" src={CreateAccountBtn} />
                            </Link>
                        </Col>
                    </Row>}
                </Col>
            </Row>
            <Row className="doctor-details-data" style={{ backgroundImage: `url(${BG})` }}>
                <Col md={1} />
                <Col md={10} style={!md ? { position: 'relative', top: '90px' } : null}>
                    <Row>
                        <Col xs={12} className="doctor-details-back">
                            <Link to="/">
                                <img crossorigin="anonymous" src={BackBtn} style={{ marginRight: '10px' }} /> Back
                            </Link>
                        </Col>
                        <Col xs={12} className="doctor-details-title">
                            Doctor's Profile
                        </Col>
                        <Col xs={12}>
                            <Row style={{ marginTop: '30px', marginBottom: '30px' }}>
                            <Col lg={6}>
                                    {profile && profile.avatar && profile.avatar != null && profile.avatar != "" ?
                                        <img className="doctor-details-profile-photo" crossorigin="anonymous" src={profile.avatar} alt="" /> :
                                        <div className="doctor-img"><img className="doctor-photo" crossorigin="anonymous" src={DefaultProfile} alt="" /></div>}
                                </Col>
                                <Col lg={6}>
                                    <Row>
                                        <Col xs={12} className="doctor-details-full-name">
                                            Dr {full_name}
                                        </Col>
                                        <Col xs={12} className="doctor-details-speciality">
                                            {speciality && Array.isArray(speciality) && speciality.length > 0 && speciality.join(',') || '-'}
                                        </Col>
                                        <Col xs={12} className="doctor-details-profile-details">
                                            <Row>
                                                <Col xs={12}>
                                                    <span className="lable-text">Location: {address && address.country || '-'}</span>
                                                    {address && address.country ? <img crossorigin="anonymous" src={address.country == 'India' ? Indian : address.country === 'Nigeria' ? Nigerian : British} style={{ width: '26px', height: '26px', marginLeft: '15px' }} /> : null}
                                                </Col>
                                                <Col xs={12}>
                                                    <div className="lable-text">Experience: {practice_started_year ? getYear(practice_started_year) : '-'}
                                                    </div></Col>
                                                <Col xs={12}>  <div className="lable-text">Languages Spoken: </div></Col>
                                                <Col xs={12}>  <div className="lable-text">{languages && Array.isArray(languages) && languages.length > 0 ? languages : '-'}</div></Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} className="doctor-details-rating">
                                            <Rating name="disabled" value={rating || 0} disabled /> <span className="doctor-details-rating-count">{rating}/5.0</span>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col md={1} />
            </Row>
            {console.log(speciality)}
            <Row>
                <Col md={1} />
                <Col md={10}>
                    <Row>
                        <Col md={3} className="doctor-details-speciality-title">About</Col>
                        <Col md={9}>
                            <Row>
                                <Col className="doctor-details-speciality-title">{speciality && Array.isArray(speciality) && speciality.length > 0 && speciality[0] || '-'}</Col>
                                <Col className="doctor-details-fee">₦{consultation_fee && numberWithCommas(consultation_fee) || 'Free'}</Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="doctor-details-bio">
                                    {bio}
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} className="doctor-details-specialities-title">Specialities</Col>
                                <Col xs={12}>
                                    <Row>
                                        {speciality && speciality.length !==0 ?
                                        <>
                                        {speciality.map(i=>(
                                            <>
                                            <Col md className="doctor-details-list col-md">
                                            {i}
                                            </Col>
                                            </>
                                        ))}
                                        </>
                                        : null
                                        }
                                        {/* <Col md className="doctor-details-list">
                                            cardiology
                                        </Col>
                                        <Col md className="doctor-details-list">
                                            Radiology
                                        </Col> */}
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: '30px', marginBottom: '30px' }}>
                                <Col xs={12}>
                                    <VideoPlayer videos={profileVideoUrl ? profileVideoUrl.filter((e) => e != "") : []} config={{ width: '100%' }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Row style={{ marginTop: '40px', marginBottom: '40px' }}>
                                <Col md={3} className="doctor-details-speciality-title">Available Time</Col>
                                <Col md={9}>
                                    <Row className="doctor-details-slot">
                                        <Col xs={12} className="doctor-details-time-title">Earliest Available Time</Col>
                                        <Col xs={12} style={{ padding: '0px' }}>
                                            <Row>
                                                {slot.length > 0 ?
                                                    <Col md={6} style={{ marginTop: '15px' }}>


                                                        {/* <Select placeholder={""} value={fromTime || FromSlots[0]} setValue={(value) => setFromTime(value)} options={FromSlots} /> */}
                                                        <Select placeholder={""} value={array} setValue={(value) => setArray(value)} options={slot} />

                                                    </Col> :
                                                    <Col md={6} style={{ marginTop: '15px' }}>



                                                    </Col>}
                                                {slot.length > 0 && slot !== undefined && slot !== "" ?


                                                    <Col md={6} style={{ marginTop: '15px' }}>
                                                        {/* <Select placeholder={""} value={toTime || ToSlots[0]} setValue={(value) => setToTime(value)} options={ToSlots} /> */}
                                                        <Select placeholder={""} value={arrayend} setValue={(value) => setArrayend(value)} options={slotend} />
                                                    </Col>

                                                    :
                                                    <Col md={6} style={{ marginTop: '15px' }}>



                                                    </Col>
                                                }
                                            </Row>
                                        </Col>
                                        {slot.length > 0 && slot !== undefined && slot !== "" ?

                                            <Col xs={12} style={{ padding: '0px' }}>
                                                <Link to="/dashboard" className="doctor-details-slot-button">
                                                    <img crossorigin="anonymous" src={AppointmentBtn} />
                                                </Link>
                                            </Col> : <Col xs={12} style={{ padding: '0px' }}>
                                                {/* <Link to="/" className="doctor-details-slot-button">
                                                <img crossorigin="anonymous" src={AppointmentBtn} /> */}
                                                {/* </Link> */}
                                                <div style={{ marginLeft: "16px" }}>No Slots Available</div>
                                            </Col>

                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        {doctorsPageList && doctorsPageList.length > 0 ? <Col xs={12} style={{ marginTop: '40px', marginBottom: '40px' }}>
                            <Row style={{ marginTop: '40px', marginBottom: '40px', justifyContent: 'center' }}>
                                <Col className="doctor-details-more-title" md={10}>More Doctors for you</Col>
                                <Col md={2}>
                                    <Row style={{ justifyContent: 'space-around' }}>
                                        <Col className={currentPage !== 1 ? "prevEnabledButton" : "prevDisabledButton"}>
                                            <img crossorigin="anonymous" src={currentPage !== 1 ? WhiteLogo : BlueLogo} onClick={onPrevious} />
                                        </Col>
                                        <Col className={currentPage != lastPage ? "nextEnabledButton" : "nextDisabledButton"}>
                                            <img crossorigin="anonymous" src={currentPage != lastPage ? WhiteLogo : BlueLogo} onClick={onNext} />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row style={{ justifyContent: 'center' }}>
                                {(doctorsPageList || []).map((listData, listIndex) => <DoctorProfile key={listIndex} item={listData} onDocChange={(e) => { onDocChange(e) }} />)}
                            </Row>
                        </Col> : null}
                    </Row>
                </Col>
                <Col md={1} />
            </Row>
        </Container>
    )
}


export default DoctorDetails;