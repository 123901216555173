export default {
  GET_PATIENTS: "GET_PATIENTS",
  GET_PATIENTS_SUCCESS: "GET_PATIENTS_SUCCESS",
  GET_PATIENTS_ERROR: "GET_PATIENTS_ERROR",
  CREATE_PATIENT: "CREATE_PATIENT",
  CREATE_PATIENT_SUCCESS: "CREATE_PATIENT_SUCCESS",
  CREATE_PATIENT_ERROR: "CREATE_PATIENT_ERROR",
  UPDATE_PATIENT: "UPDATE_PATIENT",
  UPDATE_PATIENT_SUCCESS: "UPDATE_PATIENT_SUCCESS",
  UPDATE_PATIENT_ERROR: "UPDATE_PATIENT_ERROR",
  DELETE_PATIENT: "DELETE_PATIENT",
  DELETE_PATIENT_SUCCESS: "DELETE_PATIENT_SUCCESS",
  DELETE_PATIENT_ERROR: "DELETE_PATIENT_ERROR"
};
