import { takeLatest, put, select, all } from "redux-saga/effects";
import axios from "../axios";
import types from "./types";
import { api } from '../../utils/constants'

export const getUser = state => state.auth

export function* getPatients() {
  console.log("Called Patient")
  try {
    const user = yield select(getUser);
    const data = yield axios.get(api.patient, {
      params: { "created_by.uuid": user.uuid },
      headers: { "x-auth-token": user.token },
    });
    yield put({ type: types.GET_PATIENTS_SUCCESS, payload: data.data });
  } catch (err) {
    yield put({ type: types.GET_PATIENTS_ERROR, payload: err.response.data });
  }
}



export function* createPatient(payload) {
  const { token } = yield select(getUser);

  try {
    const data = yield axios.post(api.patient, payload.data, { headers: { "x-auth-token": token } })
    yield put({ type: types.CREATE_PATIENT_SUCCESS, payload: data.data })
    yield payload.cb()
  }
  catch (err) {
    yield put({ type: types.CREATE_PATIENT_ERROR, payload: err.response.data })
  }
}

export function* updatePatient(payload) {
  const { token, uuid } = yield select(getUser);
  try {
    yield axios.patch(
      api.patient + "/" + payload.patientId,
      payload.data,
      { headers: { "x-auth-token": token } }
    );
    yield put({ type: types.GET_PATIENTS, uuid });
  } catch (err) {
    yield put({ type: types.UPDATE_PATIENT_ERROR, payload: err.response.data });
  }
}

export function* deletePatient(payload) {
  const { token, uuid } = yield select(getUser);

  try {
    yield axios.delete(
      api.patient + "/" + payload.patientId,
      { headers: { "x-auth-token": token } }
    );
    yield put({ type: types.GET_PATIENTS, uuid });
  } catch (err) {
    yield put({ type: types.DELETE_PATIENT_ERROR, payload: err.response.data });
  }
}

export function* patientWatcher() {
  yield takeLatest(types.GET_PATIENTS, getPatients);
  yield takeLatest(types.CREATE_PATIENT, createPatient);
  yield takeLatest(types.UPDATE_PATIENT, updatePatient);
  yield takeLatest(types.DELETE_PATIENT, deletePatient);
}
export default function* mainSaga() {
  yield all([patientWatcher()]);
}
