import React, { useState } from "react";
import "./style.scss"

const Item = (props) => {

    console.log(props, "12")

    const getFormatedWaitTime = (time) => {
        try {
            if (time === 1) {
                return `${time} Minute`
            }
            else {
                return `${time} Minutes`
            }
        } catch (error) {
            return `${time} Minutes`;
        }
    }


    return (
        <>
            <div className="ItemContainer" onClick={() => { props.onClick() }} style={{ display: 'flex', flexDirection: "column", width: `${props.width}`, border: `2px solid ${props.color}` }}>
                <div style={{ display: "flex" }}>
                    <div className="imageholder">
                        <img crossorigin="anonymous" src={props.image} className="image" />
                    </div>
                    <div className="content">

                        {props.availableAppointment === true ? <div className="title">{props.title} <div>{props.value} <span class='newprice'>₦{props.amount}</span></div> </div> : <div className="title">{props.title}</div>}

                        {props.FAD === true ? <div className="desc"><span class='fadprice' ></span>  {props.desc}  </div> : <div className="desc">{props.desc}</div>}

                    </div>

                </div>
                {props.availableAppointment && props.waitTime &&
                    <div className="next">
                        <div style={{ marginTop: '3%', backgroundColor: "#D9EDF1", borderRadius: '5px', width: "auto" }}>
                            <div style={{ color: '#013C44', fontSize: '14px', display: "flex", alignItems: 'center', padding: '5px' }}><div>{`First appointment is available in`}<span className='price'> {getFormatedWaitTime(props.waitTime)} </span> </div> </div>
                        </div>
                    </div>
                }
                {props.freeAppointment && props.freeWaitTime &&
                    <div className="next">
                        <div style={{ marginTop: '3%', backgroundColor: "#D9EDF1", borderRadius: '5px', width: "auto" }}>
                            <div style={{ color: '#013C44', fontSize: '14px', justifyContent: 'center', alignItems: 'center', padding: '5px' }}>{` First appointment is available on`}<span className='timesfree'> {props.freeWaitTime} </span>  </div>
                        </div>
                    </div>
                }
            </div>



        </>
    );
};

export default Item;