import React, { useState, useEffect } from "react";
import Drop from "./Images/Drop.png";
import Mobile from "./Images/Mobile.png";
import MobileBlue from "./Images/MobileBlue.png";
import DropBlue from "./Images/DropBlue.png";

import "./tabs.scss";
import info from "../../assets/images/info.svg";
import { Row, Col } from "react-bootstrap";

function TestBookDetails(props) {
  const [orderDetails, setorderDetails] = useState([]);
  const [patient_info, setpatient_info] = useState([]);
  const [order_info, setorder_info] = useState([]);
  // const [stage,setStage]=useState()

  useEffect(() => {
    setorderDetails(props.orderDetails);
    setpatient_info(props.orderDetails ? props.orderDetails.patient_info : {});
    setorder_info(props.orderDetails ? props.orderDetails.order_info : {});
  }, [props]);

  var stage = order_info.status;

  let color = "";
  let backgroundcolor = "";
  if (stage === "Completed") {
    color = "#065F46";
    backgroundcolor = "";
  } else if (stage === "inProgress") {
    color = "#F68B0C";
    backgroundcolor = " #FFF5E1";
  } else if (stage === "Sample collected") {
    color = "#DBEAFE";
  } else if (stage === "Doctor’s Appointment Scheduled") {
    color = "#00839B";
  } else if (stage === "pending") {
    color = "#00839B";
    backgroundcolor = "#F2F8F9";
  } else {
    color = "";
  }

  const getCustomizeStatus = (status) => {
    switch (status) {
      case "inProgress":
        return "In Progress";
        break;
      case "Inprogress":
        return "In Progress";
        break;
      case "completed":
        return "Completed";
        break;
      case "pending":
        return "Pending";
        break;
      case "cancelled":
        return "Cancelled";
        break;
      case "declined":
        return "Declined";
        break;
      case "expired":
        return "Expired";
        break;
      default:
        return status;
    }
  };


  const getOrderType = (status) => {

    switch (status) {
      case "popular":
        return "Diagnostics";
      case "recommended":
        return "Recommended Test";
      case "prescription":
        return "Prescription";
      case "biointel":
        return "Bio Intel Panel";
      default:
        return status;
    }
  }



  return (
    <div className="testbookdetails-container">
      <div
        className="booked-card"
        onClick={() =>
          props.viewIndividualOrder(
            orderDetails ? orderDetails.uuid : "",
            orderDetails
          )
        }
      >
        {/* <span className="status-button"> */}
        <Row>
          <Col md={5}>
            <div
              style={{
                padding: "4px",
                marginTop: "19px",
                color: color,
                marginLeft: "28px",
                backgroundColor: backgroundcolor,
                borderRadius: "5px",
                fontWeight: "bold",
                position: "relative",
                width: "100px",
              }}
            >
              {" "}
              {order_info
                ? getCustomizeStatus(order_info.finalStatus)
                : ""}{" "}
            </div>
            <br />
          </Col>
          <Col md={3}>
            {/* <img   style={{marginLeft:"120px",marginTop:"50%"}} src={info} alt='info' onClick={()=>props.viewIndividualOrder(orderDetails ? orderDetails.uuid:'',orderDetails)}/><br/> */}
          </Col>
        </Row>
        {/* </span> */}
        <div className="booked-card-text">
          <p style={{ fontSize: "12px" }}>ORDER ID </p>
          <p>
            <b> {orderDetails ? orderDetails.uuid : ""}</b>
          </p>{" "}
          <br />
          <p>Patient Name: {patient_info ? patient_info.patient_name : ""}</p>
          <p>Order Date: {order_info ? order_info.order_date : ""}</p>
          <p>
            Order Type:{" "}
            {getOrderType(order_info && order_info.type)}
          </p>
        </div>

        <div className="booked-card-footer">
          <section className="footer-text">
            <p>Total Cost:</p>
            <section className="footer-text-cost">
              <p> ₦{order_info ? order_info.total_cost : ""} </p>
            </section>
          </section>
          <div /* style={{ marginLeft: "19%" }} */>
            {stage === "inProgress" ? (
              <section className="footer-icons">
                {/* <img src={MobileBlue} style={{ width: "40px", margin: "5px" }} alt="Avatar" /> */}
                <img
                  src={DropBlue}
                  style={{
                    width: "40px",
                    margin: "5px",
                    backgroundColor: color,
                    color: color,
                    marginLeft: "60px",
                  }}
                  alt="Avatar"
                />
              </section>
            ) : (
              <section className="footer-icons">
                {/* <img src={MobileBlue} style={{ width: "40px", margin: "5px" }} alt="Avatar" /> */}
                <img
                  src={DropBlue}
                  style={{ width: "40px", margin: "5px", marginLeft: "60px" }}
                  alt="Avatar"
                />
              </section>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TestBookDetails;
