import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { Toast } from "../../components";
import * as Yup from "yup";
import AuthLayout from "../../layout/AuthLayout";
import CheckBtn2 from "../../components/CheckBtn/checkButton";
import PhoneInput from "react-phone-input-2";
import { Link, useHistory} from "react-router-dom";
import "react-phone-input-2/lib/bootstrap.css";
import { availability } from "../../redux/auth/actions";
import { api } from "../../utils/constants";
import axios from "../../redux/axios";
import "./auth.scss";
import { propTypes } from "react-bootstrap/esm/Image";
import { Helmet } from 'react-helmet';

function SignUp(props) {
  const initialValues = {
    firstName: "",
    middleName: "",
    lastName: "",
    emailAddress: "",
  };
  const [userexist, setUserexist] = useState(false);
  const [phone, setPhone] = useState("");
  console.log(phone,"phone")
  const [phoneerror, setPhoneerror] = useState(false);
  const [agree, setAgree] = useState(false);
  const [terms, setTerms] = useState(false);
  const [numExist, setNumExist] = useState(false);
  const [dontSum, setDontSum] = useState(false)
  const [brFn, setBrFn] = useState(false)
  const [brLn, setBrLn] = useState(false)
  const [brEm, setBrEm] = useState(false)
  const [phvalidation, setPhvalidation] = useState(false)
  const [phoneErrorMessage, setPhoneErrorMessage] = useState('Phone number should be in-between 9-15 digit')


  // for geting referral code from url//
  // const [useSearchParams, setSearchParamas] = useSearchParams();
  // console.log(useSearchParams,"search")
  let history = useHistory();
  const dispatch = useDispatch(); 
  const user = useSelector((state) => state.auth);
  const RegistrationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required').min(3).max(100)
      .test('alphabets', 'First Name  must only contain alphabets', (value) => {
        return /^[A-Za-z]+$/.test(value);
      }),
    lastName: Yup.string().required("Last Name Required")
    .test('alphabets', 'Last Name  must only contain alphabets', (value) => {
      return /^[A-Za-z]+$/.test(value);
    }),
    middleName: Yup.string()
    .test('alphabets', 'Middle Name  must only contain alphabets', (value) => {
      return /^[A-Za-z]+$/.test(value);
    }),
    emailAddress: Yup.string().required("Email Address  Required").email('Must be a valid email'),
  });
  useEffect(() => {
    if (user.isName === false) {
      setUserexist(true);
    }
  }, [user.isName]);
  const [country, setCountry] = useState();

  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((res) => {
        setCountry(res.country_code.toLowerCase());
      })
      .catch((data, status) => {
        console.log("Request failed:", data);
      });
  }, []);

  useEffect(async () => {
    if (phone.length > 10) {
      await axios
        .get(api.mobile_exist + phone)
        .then((res) => {
          if (res.data.status === "failure") {
            setNumExist(false);
            setDontSum(false);
          }
        })
        .catch();
    }
  }, [phone]);

  console.log(Object.keys(props.match.params).length,"dddddd")
  const { handleSubmit, handleChange, values, errors,touched } = useFormik({
    
    initialValues,
    validationSchema: RegistrationSchema,
    
    onSubmit: (values) => {
      console.log(values, "values")
      localStorage.clear();
      if (phvalidation) {
        setPhoneerror(true);
        setPhoneErrorMessage("Phone number should be in-between 9-15 digit")
      } else if (dontSum === true) {
        setNumExist(true);
        setPhoneErrorMessage("Phone number already used for registration")
      } else if (terms === false) {
        setAgree(true);
      } else {
        const email = values.emailAddress;
        const mobile = phone.toString();
        const val = { mobile, email };
        const middle = values.middleName === "" ? ' ' : values.middleName
        const Datas = {
          firstName: values.firstName,
          middleName: middle,
          lastName: values.lastName,
          phone: phone,
          emailAddress: values.emailAddress,
          referal: Object.keys(props.match.params).length != 0 ? props.match.params.userId : values.referal
        };
        localStorage.setItem("regdata", JSON.stringify(Datas));
        dispatch(
          availability(val, (res) => {
            if (res.status === true) {
              history.push("/setPassCode");
            }
          })
        );
      }
    },
  });
  // const handleTouched=(type,e)=>{
  // if(type==="FN"){
  // if(values.firstName === ''){
  //   setBrFn(true)
  // }
  // }else if(type==="LN"){
  //   if(values.lastName === ''){
  //     setBrLn(true)
  //   }
  // }
  //   else if(type==="EM"){
  //     if(values.emailAddress === ''){
  //       setBrEm(true)
  //     }
  // }
  // }
  const handlePhoneNumber = (value) => {
    console.log(value.length)
    setPhone(value)
    if (value.length > 9 && value.length <= 15) {
      setPhvalidation(false)
      setPhoneerror(false)
      
    }
    //  if(value.length==0){
    //   setPhoneErrorMessage("Phone number is required")
    // }
    
    else {
      setPhvalidation(true)
    }
  }
  return (

    <AuthLayout>
      <Helmet>
        <title>Consumer Web Signup - Doctall
</title>
<meta 
            name='description' 
            content='Sign up to speak to a doctor online, book an appointment from anywhere and get medical treatment'
            />
      </Helmet>
      <div className="signupContainer">
        <Toast
          text={"User already exists with entered Phone Number/Email."}
          type="warning"
          open={userexist}
          setClose={() => {
            setUserexist(false);
          }}
        />
        <Toast
          text={"Please confirm the mail"}
          type="warning"
          open={agree}
          setClose={() => {
            setAgree(false);
          }}
        />
        <Toast
          text={"User already exists."}
          type="warning"
          open={numExist}
          setClose={() => {
            setNumExist(false);
          }}
        />

        <div className="back">
          <img
            crossorigin="anonymous"
            style={{ height: "12px", width: "13px" }}
            src={require("../../assets/images/back.svg").default}
            alt=""
          />
          <Link to="/">Back</Link>
        </div>
        <div className="heads">
          <h2>Create an account</h2>
          <p>Start living better and enjoy personalized care</p>
        </div>
        <form onSubmit={handleSubmit}>
          <label style={{ marginTop: "0px" }} htmlFor="First Name">
            First Name*
          </label>
          <div>
            <input
              className="inputForm"
              onChange={handleChange}
              type="text"
              name="firstName"
              value={values.firstName}
            // onBlur={(e)=>{handleTouched('FN',e)}}
            />
            {/* {errors.firstName ? (
              <div className="error">{errors.firstName}</div>
            ) : null} */}

            {errors.firstName && touched.firstName && (
                        <div className="error">
                          {errors.firstName}
                        </div>
                      )}
          </div>

          <label htmlFor="Middle Name">Middle Name</label>
          <div>
            <input
              className="inputForm"
              onChange={handleChange}
              type="text"
              name="middleName"
              value={values.middleName}
            />
            {/* {errors.middleName ? (
              <div className="error">{errors.middleName}</div>
            ) : null} */}

            {errors.middleName && touched.middleName && (
                        <div className="error">
                          {errors.middleName}
                        </div>
                      )}
          </div>

          <label htmlFor="Last Name">Last Name*</label>
          <div>
            <input
              className="inputForm"
              onChange={handleChange}
              type="text"
              name="lastName"
              value={values.lastName}
            // onBlur={(e)=>{handleTouched('LN',e)}}
            />
            {/* {errors.lastName ? (
              <div className="error">{errors.lastName}</div>
            ) : null} */}
            {errors.lastName && touched.lastName && (
                        <div className="error">
                          {errors.lastName}
                        </div>
                      )}
          </div>

          <label htmlFor="Phone">Phone*</label>
          <div>
            <PhoneInput
              style={{ backgroundColor: "none" }}
              className="inputPhone"
              country={country}
              value={phone}
              onChange={handlePhoneNumber}
            />
            {phoneerror ? <div className="error">{phoneErrorMessage}</div> : null}
          </div>

          <label htmlFor="firstName">Email Address*</label>
          <div>
            <input
              className="inputForm"
              onChange={handleChange}
              type="email"
              name="emailAddress"
              value={values.emailAddress}
            // onBlur={(e)=>{handleTouched('EM',e)}}
            />
            {/* {errors.emailAddress ? (
              <div className="error">{errors.emailAddress}</div>
            ) : null} */}

            {errors.emailAddress && touched.emailAddress && (
                        <div className="error">
                          {errors.emailAddress}
                        </div>
                      )}

            
            
          </div>
          <label htmlFor="firstName">Referal Code(Optional)</label>
          <div>
            <input
              className="inputForm"
              onChange={handleChange}
              type="text"
              name="referal"
              value={values.referal !== " " ? props.match.params.userId : values.referal}
            />
          </div>
          <div className="radioCheck">
            <CheckBtn2
              name={"terms"}
              value={
                "I confirm that I regularly access this mailbox and that this is my preferred email address to receive personal, confidential and sensitive medical information."
              }
              onClick={() => {
                terms === true ? setTerms(false) : setTerms(true);
              }}
              checked={terms === true}
              img={require("../../assets/images/check.svg").default}
              imgAct={require("../../assets/images/checkact.svg").default}
            />
          </div>

          <button type="submit" className="sumbitBtn">
            Continue
          </button>
        </form>
      </div>
    </AuthLayout>
  );
}

export default SignUp;
