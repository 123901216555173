/**
 *
 *
 * Doctor Appointment
 *
 */

import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Rating from "@material-ui/lab/Rating";
import "./AppointmentStyle.scss";
import DefaultProfile from "../../assets/images/default_doctor_profile.jpg";
import Indian from "../../assets/images/indian.svg";
import Nigerian from "../../assets/images/nigerian.svg";
import British from "../../assets/images/ukflag.png";
import VideoBtn from "../../assets/images/VideoBtn.svg";
import EyeBtn from "../../assets/images/EyeBtn.svg";
import { Link } from "react-router-dom";
import Select from "../../components/Select";
import moment from "moment";
import { getYear } from "../../utils";
import VideoPlayer from "../VideoPlayer";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import star from "../../assets/images/star.svg";
import { numberWithCommas } from "../../utils";
import axios from "../../redux/axios";
import { api } from "../../utils/constants";
import { Helmet } from 'react-helmet';

/**
 *
 * @param {object} props
 * @returns
 */
export default function DoctorAppointment(props) {
  const [rate, setRate] = useState(0);
  const [videolink, setvideolink] = useState([]);

  console.log(props, "88858")

  function arrUnique(arr) {
    var cleaned = [];
    arr.forEach(function (itm) {
      var unique = true;
      console.log(cleaned,"cleaned")
      //commented for response page test
      
      // cleaned.forEach(function (itm2) {
      //   if (
      //     moment(itm.date).format("ddd  MM/DD") ==
      //     moment(itm2.date).format("ddd  MM/DD") &&
      //     moment(itm.start_time).format("LT") ==
      //     moment(itm2.start_time).format("LT")
      //   )
      //     unique = false;
      // });
      if (unique) cleaned.push(itm);
    });
    return cleaned;
  }

  const { item = {}, history } = props;
  const {
    full_name,
    profile = {},
    consultation_fee,
    profile_pic,
    slots = [],
    uuid,
  } = item;
  const {
    speciality = [],
    address = {},
    languages = [],
    rating,
    practice_started_year,
    profileVideoUrl = [],
  } = profile;
  const [bookSlot, setBookSlot] = useState(false);
  let doctorSlots =
    slots &&
      Array.isArray(slots) &&
      slots.length > 0 &&
      slots !== undefined &&
      slots !== ""
      ? slots
      : [];
  doctorSlots = doctorSlots.filter(
    (e) => moment(e.start_time) >= moment() && e.isActive == true
  );
  doctorSlots = arrUnique(doctorSlots);

  doctorSlots =
    (doctorSlots &&
      doctorSlots.length > 0 &&
      doctorSlots !== "" &&
      doctorSlots != undefined &&
      doctorSlots.map((f) => {
        return {
          value: moment(f.start_time).format("DD, MMM, YYYY h:mm"),
          label: `${moment(f.start_time).format("DD, MMM, YYYY")} | ${moment(
            f.start_time
          ).format("h:mm A")} - ${moment(f.end_time).format("h:mm A")}`,
          className: "slotList",
        };
      })) ||
    [
      // {
      //     value: '',
      //     // label: `${moment().format('DD, MMM, YYYY')} | ${moment().format('h:mm A')} - ${moment().add(30, 'minutes').format('h:mm A')}`,
      //     className: 'slotList'
      // }
    ];

  // let slotlist = res.data && res.data.length > 0 ? res.data[0].slots : [];
  // slotlist = slotlist.filter((e) => moment(e.start_time) >= moment() && e.isActive == true);
  // slotlist = arrUnique(slotlist);

  // const doctorSlots = slots && Array.isArray(slots) && slots.length > 0 && slots.map(f => {
  //     return {
  //         value: moment(f.start_time).format('DD, MMM, YYYY h:mm'),
  //         label: `${moment(f.start_time).format('DD, MMM, YYYY')} | ${moment(f.start_time).format('h:mm A')} - ${moment(f.end_time).format('h:mm A')}`,
  //         className: 'slotList'
  //     }
  // }) || [
  //         {
  //             value: '',
  //             label: `${moment().format('DD, MMM, YYYY')} | ${moment().format('h:mm A')} - ${moment().add(30, 'minutes').format('h:mm A')}`,
  //             className: 'slotList'
  //         }
  //     ];

  useEffect(() => {
    if (profile) {
      let videoslist =
        profile &&
        profile.profileVideoUrl &&
        profile.profileVideoUrl.filter((e) => e != "");
      setvideolink(videoslist);
    }
  }, [profile]);
  // useEffect(() => {
  //   axios
  //     .get(`${api.star_rating}/booking/feedback/starRating/${props.item.uuid}`)
  //     .then((res) => setRate(Math.round(res.data.result)));
  // }, []);

  return (
    <>
    <Helmet>
      <title>View Appointments - Doctall Consumer Web

</title>
<meta 
            name='description' 
            content='View details on all our booked and closed appointments, see lab test advice and prescriptions. Book an SADN appointment or find a doctor at a time and price that suits you'
            />
    </Helmet>
    <Col style={{ minWidth: "378px", maxWidth: "378px" }}>
      <Row
        className="doctor-profile"
        style={{ minHeight: "280px", padding: "20px" }}
      >
        <Col xs={12} className="DoctorProfileSadn">
          <div className="docContainer">
            {profile &&
              profile.avatar &&
              profile.avatar != null &&
              profile.avatar != "" ? (
              <img
                crossorigin="anonymous"
                className="avatarimg"
                src={profile.avatar}
                alt=""
              
              />
            ) : (
              <img
                crossorigin="anonymous"
                className="avatarimg"
                src={DefaultProfile}
                alt=""
               
              />
            )}
            <div className="docDetailsContainer">
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    {full_name ? `Dr ${full_name}` : "-"}
                  </Tooltip>
                }
              >
                <div className="docNAme">
                  {full_name ? `Dr ${full_name}` : "-"}
                </div>
              </OverlayTrigger>

              {/*  */}
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    {profile && profile.professional_detail && profile.professional_detail.qualification !== undefined ? `${profile.professional_detail.qualification}` : ""}
                  </Tooltip>
                }
              >
                <div >
                  <small class="font-italic">{profile && profile.professional_detail && profile.professional_detail.qualification !== undefined ? `${profile.professional_detail.qualification}` : ""} </small>
                </div>
              </OverlayTrigger>
              {/*  */}


              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    {(speciality &&
                      Array.isArray(speciality) &&
                      speciality.length > 0 &&
                      speciality.join(",")) ||
                      "-"}
                  </Tooltip>
                }
              >
                <div className="speciality">
                  {(speciality &&
                    Array.isArray(speciality) &&
                    speciality.length > 0 &&
                    speciality.join(",")) ||
                    "-"}
                </div>
              </OverlayTrigger>
              <div className="docDetails">
                <div className="details">
                  Location:&nbsp;
                  <span>{(address && address.country) || "-"}</span>
                  <span className="imgholder">
                    {" "}
                    {address && address.country ? (
                      <img
                        src={
                          address.country == "India"
                            ? Indian
                            : address.country === "Nigeria"
                              ? Nigerian
                              : British
                        }
                        style={{ width: "16px", height: "16px" }}
                      />
                    ) : null}
                  </span>
                </div>
                <div className="details">
                  Experience:&nbsp;
                  <span>
                    {practice_started_year
                      ? getYear(practice_started_year)
                      : "-"}
                  </span>
                </div>
                <div className="details">Languages Spoken:</div>
                <div className="details">
                  <span>
                    {languages &&
                      Array.isArray(languages) &&
                      languages.length > 0
                      ? languages
                      : "-"}
                  </span>
                </div>
              </div>
              {/* <div className="starcontainer">
                {[...Array(rate)].map((el, index) => {
                  return (
                    <img
                      key={index}
                      crossorigin="anonymous"
                      src={star}
                      alt="start"
                    />
                  );
                })}
              </div> */}
              <div className="hr" />
              <div className="feeContainer">
             
                {consultation_fee && consultation_fee > 0 ? (
                  <span>₦{numberWithCommas(consultation_fee)}</span>
                ) : (
                  <span>Free</span>
                )}
                   <span style={{fontSize:"10px"}}>Consultation Fee:</span>{" "}
              </div>
            </div>
          </div>
          {/* <Row style={{ marginBottom: '20px', minHeight: '190px' }}>
                         <Col style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                             <img src={profile_pic || DefaultProfile} className="profile_pic" />
                         </Col>
                         <Col>
                             <Row>
                                 <Col xs={12} style={{ padding: '0px' }} className="name">{full_name ? `Dr ${full_name}` : '-'}</Col>
                                 <Col xs={12} style={{ padding: '0px' }} className="speciality">{speciality && Array.isArray(speciality) && speciality.length > 0 && speciality.join(',') || '-'}</Col>
                                 <Col xs={12} style={{ padding: '0px' }}>
                                     <Row className="info">
                                         <Col xs={12} className="location">
                                             <div>Location: {address && address.country || '-'}</div> 
                                             {address && address.country ? <img src={address.country == 'India' ? Indian : address.country === 'Nigeria' ? Nigerian : British} style={{width: '16px', height: '16px'}} /> : null}
                                         </Col>
                                         <Col xs={12}>Experience: {practice_started_year ? getYear(practice_started_year) : '-'}</Col>
                                         <Col xs={12}>Languages Spoken:</Col>
                                         <Col xs={12}>{languages && Array.isArray(languages) && languages.length > 0 ? languages : '-'}</Col>
                                     </Row>
                                 </Col>
                                 <Col xs={12} style={{ padding: '0px' }}>
                                     <Rating name="disabled" value={rating || 0} disabled />
                                 </Col>
                                 <Col xs={12} style={{ padding: '0px' }}><hr style={{ marginTop: '5px', marginBottom: '5px' }} /></Col>
                                 <Col xs={12} style={{ padding: '0px' }} className="consultation-fee">Consultation Fee: ₦{consultation_fee || 0}</Col>
                             </Row>
                         </Col>
                     </Row> */}
        </Col>
        <Col xs={12}>
          <Row className="book_slot">
            <Col xs={12} className="book_time">
              Earliest Available Time
            </Col>
            <Col xs={12} style={{ padding: "0px", marginTop: "10px" }}>
              {doctorSlots &&
                doctorSlots.length > 0 &&
                doctorSlots !== "" &&
                doctorSlots != undefined ? (
                <Select
                  placeholder={""}
                  value={doctorSlots[0]}
                  setValue={(value) => setBookSlot(value)}
                  options={doctorSlots}
                />
              ) : (
                <div className="notallow"> No Slots Avaliable</div>
              )}
            </Col>
            <Col xs={12}>
              <Row style={{ justifyContent: "space-between" }} >
                {doctorSlots &&
                  doctorSlots.length > 0 &&
                  doctorSlots !== "" &&
                  doctorSlots != undefined ?
                  <Col lg="6" xs="6" md="6" className="book_doctor">


                    <Link to="/login">Book Doctor</Link>


                  </Col>

                  :
                  <Col lg="6" xs="6" md="6" className="linknotallow">


                    <div style={{ color: "white", cursor: "no-drop" }}>
                      {/* <Link style={{ color: "white" }} disabled={true}> */}
                        Book Doctor
                      {/* </Link> */}
                    </div>


                  </Col>
                }

<Row>
                <Col lg="6" xs="6" md="6" className="book_icon">
                  <img
                    crossorigin="anonymous"
                    src={EyeBtn}
                    onClick={() => history.push(`/doctorDetails/${uuid}`)}
                    style={{ marginRight: "10px" }}
                  />
                  {/* <VideoPlayer popup videos={profileVideoUrl}>
                     {(play) => (
                       <Col xs={12}>
                         <img
                           crossorigin="anonymous"
                           src={VideoBtn}
                           onClick={play}
                         />
                       </Col>
                     )}
                   </VideoPlayer> */}

                  {videolink !== "" &&
                    videolink !== undefined &&
                    videolink.length > 0 ? (
                    <button className="docVideoBtn">
                      <VideoPlayer popup videos={videolink}>
                        {(play) => (
                          <Col xs={12}>
                            <img
                              crossorigin="anonymous"

                              src={VideoBtn}

                              alt=""
                              onClick={play}
                            />
                          </Col>
                        )}
                      </VideoPlayer>
                    </button>
                  ) : (
                    <button style={{ cursor: "not-allowed" }} className="docVideoBtn">
                      <img
                        crossorigin="anonymous"
                        src={VideoBtn}

                        alt=""
                        style={{ opacity: "0.3" }}
                      />
                    </button>
                  )}
                </Col>
                </Row>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
    </>
  );
}
