import { Dialog, DialogContent } from '@material-ui/core';
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { default as Close, default as close } from "../assets/images/close.svg";
import DeleteFailure from "../assets/images/DeleteFailure.png";
import DeleteSuccess from "../assets/images/DeleteSuccess.png";
import Delete from "../assets/images/Delete_icon.png";
import hamburger from "../assets/images/hamburger.svg";
import not from "../assets/images/hpicon.svg";
import ICON1 from "../assets/images/ICON1.svg";
import ICON10 from "../assets/images/ICON10.svg";
import ICON11 from "../assets/images/ICON11.svg";
import ICON12 from "../assets/images/ICON12.svg";
import ICON2 from "../assets/images/ICON2.svg";
import ICON4 from "../assets/images/ICON4.svg";
import ICON5 from "../assets/images/ICON5.svg";
import ICON6 from "../assets/images/ICON6.svg";
import ICON7 from "../assets/images/ICON7.svg";
import ICON9 from "../assets/images/ICON9.svg";
import Wallet from "../assets/images/wallet.png";
import ConfirmChangePasscode from "../components/ChangePasscode/confirmpop";
import useOnClickOutside from "../hooks/useOutsideClickhandler";
import useWindowSize from "../hooks/useWindowSize";
import Sidemenumodal from "../layout/Sidemenu";
import { cartUpdate, logout, notifyUpdate } from "../redux/auth/actions";
import { api, passcodeKey, tokenKey, usernameKey } from "../utils/constants";
import "./DashboardLayout.scss";
import { Helmet } from 'react-helmet';


const DataList = [
  "Consultations",
  "Lab tests order",
  "Treatment plan orders",
  "Health Screening Orders",
  "Stored Health records",
  "Wallet amount"
]
const DashLayoutDesign = (props) => {

  const [cookies, setCookie, removeCookie] = useCookies([usernameKey, passcodeKey, tokenKey]);

  const [popup, setPopup] = useState(false);
  const dispatch = useDispatch();
  const [closer, setCloser] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [sidemenu, setSidemenu] = useState(true);
  const [isDesktopView, setIsDesktopView] = useState(true);
  const [width, height] = useWindowSize();
  const userdata = useSelector((state) => state.auth);
  const patientdata = useSelector((state) => state.patients.data);
  const [profile, setProfile] = useState(false);
  const [shownofity, setShow] = useState(false);
  const [count, setCount] = useState(0);
  const [text, setText] = useState("");
  const [submitShow, setSubmitShow] = useState(false);
  const [dailogOpen, setDailogOpen] = useState(false);

  const [showmsg, setShowmsg] = useState(false);
  const [showmsg1, setShowmsg1] = useState(false);

  const ref = useRef();

  const handleClickOpen = (e) => {
    e.preventDefault();
    setModalShow(true);
  };

  const handleDailog = (e) => {
    setDailogOpen(true)
  }

  const DeleteAccount = () => {
    axios.delete(`${api.accountDelete}/${userdata.uuid}`)
      .then((res) => {
        if (res.data.message == "Successfully Deleted!") {
          setShowmsg1(true)
        } else {
          setShowmsg(true)
        }
        console.log(res, "resp")
      })
      .catch((err) => {
        console.log(err.response, "resp")
        if (err.response.data == "Unable Delete. Some Order And Appointment is not completed." || err.response.data == "Unable Delete. Some Appointment is not completed.") {
          setShowmsg(true)
        }
      })
  }

  useEffect(() => {
    axios
      .get(api.tokenupdate + "/list/" + userdata.uuid, {
        headers: { "x-auth-token": userdata.token },
      })
      .then((res) => {
        if (res.data.length > 0) {
          let check = res.data.filter((e) => e.status == "unread");
          if (check.length > 0) {
            setShow(true);
            setCount(check.length);
            dispatch(notifyUpdate(check.length));
          }
        }
      })
      .catch((err) => {
        // Toast.showWithGravity(err.response.data, Toast.LONG, Toast.TOP);
      });
    // axios
    //   .get(api.Order + "/cart/count/" + userdata.uuid, {
    //     headers: { "x-auth-token": userdata.token },
    //   })
    //   .then((r) => {
    //     if (r.data.length > 0) {
    //       setShow(true);
    //       setCount(r.data[0].count);
    //       dispatch(cartUpdate(r.data[0].count));
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  useEffect(() => {
    if (userdata.nofity && userdata.nofity > 0) {
      setShow(true);
      setCount(userdata.nofity);
    } else {
      if (userdata.nofity == 0) {
        setShow(false);
        setCount(0);
      }
    }
  }, [userdata]);
  useEffect(() => {
    if (userdata && userdata.profile && userdata.profile.profile_pic) {
      setProfile(userdata.profile.profile_pic);
    }
  }, [userdata]);
  useOnClickOutside(ref, () => {
    setPopup(false);
  });
  const handleClose = () => {
    setOpen(false);
  };

  const RemoveCoockie = () => {
    const cookieOptions = {
      path: "/",
      secure: process.env.REACT_APP_COOKIE_SECURE,
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
    };

    removeCookie(usernameKey, cookieOptions)
    removeCookie(passcodeKey, cookieOptions)
    // removeCookie(tokenKey, cookieOptions)
  }
  const history = useHistory();
  const logoutfn = () => {
    localStorage.clear();
    console.log('consumer location clearing cookie ');
    // setCookie(usernameKey, null);
    // setCookie(passcodeKey, null);
    // setCookie(tokenKey, null);
    RemoveCoockie();
    dispatch(logout());
    setTimeout(() => {
      document.cookie = 'doctallLog' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=.doctall.com';
      history.push("/");
      window.location.reload(false);
    });
  };
  const handleConfirmChangePass = () => {
    history.push("/changescreen");
  };
  useEffect(() => {
    if (width > 0) {
      if (width < 1279) {
        setSidemenu(false);
        setIsDesktopView(false);
      } else {
        setSidemenu(true);
        setIsDesktopView(true);
      }
    }
  }, [width]);
  useEffect(() => {
    if (props.loadin) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [props.loadin]);
  const onSearch = (e) => {
    setText(e.target.value);
    console.log(e.target.value, "GGGG")


    setTimeout(() => {
      history.push({ pathname: "/specialist", searchtext: e.target.value });
    }, [500])
    // 


  };


  const redirectToDMP = () => {
    let url = process.env.REACT_APP_DMP_URL
    window.location.href = url
  }


  return (
    <>
    <Helmet>
      <title>View Dashboard - Doctall Consumer Web 
</title>
<meta 
            name='description' 
            content='View your Doctall Dshboard to see appointments and health profile. Book an SADN appointment or find a doctor at a time and price that suits you'
            />
    </Helmet>
      <div className="navbar">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginLeft: "3%",
            width: "310px",
          }}
        >
          <span
            className="sideMenuIconHolder"
            onClick={() => {
              setSidemenu(!sidemenu);
            }}
          >
            {sidemenu ? (
              <img
                crossorigin="anonymous"
                src={close}
                className="hamburger"
                alt="doctall"
              />
            ) : (
              <img
                crossorigin="anonymous"
                src={hamburger}
                className="hamburger"
                alt="doctall"
              />
            )}{" "}
          </span>
          <img
            crossorigin="anonymous"
            src={ICON1}
            className="doctallimg"
            alt="doctall"
          ></img>
        </div>
        <div className="headerNav">
          <div className="searchContainer">
            <input
              type="text"
              placeholder="keyword search"
              value={text}
              onChange={(e) => {
                onSearch(e);
              }}
            />
            <img
              crossorigin="anonymous"
              src={ICON10}
              className="searchimg"
              alt="search"
            // onClick={() => {
            //   onSearch();
            // }}
            />
          </div>
          <div className="dummy"></div>
          <div className="navbarContainerTwo">

          {/* cart button  hide from here */}
            {/* <img
              crossorigin="anonymous"
              src={ICON2}
              className="cartimg"
              alt="doctall"
              style={{ paddingRight: "31px", cursor: 'pointer' }}
              onClick={() => { setCloser(true) }}
            ></img> */}
            <div className="noti-count">
              <img
                crossorigin="anonymous"
                src={ICON9}
                className="notifyimg"
                alt="doctall"
                style={{ paddingRight: "31px" }}
                onClick={() => history.push("/notification")}
              ></img>
              {shownofity ? (
                <span
                  className={
                    count == 0 ? "button__badge_disable" : "button__badge"
                  }
                >
                  {count}
                </span>
              ) : (
                ""
              )}
            </div>
            {profile ? (
              <img
                crossorigin="anonymous"
                src={profile}
                className="profileimg"
                alt="doctall"
                style={{ borderRadius: "50%", paddingRight: "7px" }}
              ></img>
            ) : (
              // <div className="profilediv">{IntialGen(userdata)}</div>
              userdata && userdata.firstName ?
                <div className="profilediv">{userdata && userdata.firstName && userdata.firstName.charAt(0) + userdata && userdata.lastName && userdata.lastName.charAt(0)}</div>
                :
                <div className="profilediv">{userdata && userdata.firstName && userdata.full_name.charAt(0)}</div>
            )}
            <img
              crossorigin="anonymous"
              src={ICON4}
              className="arrimg"
              alt="doctall"
              style={{ cursor: "pointer" }}
              onClick={() => setPopup(!popup)}
            ></img>
            {popup ? (
              <div className="popupScreen" ref={ref}>
                <Link
                  to={{
                    pathname: "/profile",
                    state: patientdata,
                  }}
                >
                  <a>Health Profile</a>
                </Link>
                <Link to="/relatives">Relatives</Link>
                <a href="" onClick={(event) => handleClickOpen(event)}>
                  Change Passcode
                </a>
                <a
                  href="javascript:void(0)"
                  onClick={() => {
                    logoutfn();
                  }}
                >
                  Sign Out
                </a>
                <div className="line"></div>
                <div className="doctallWebApp">
                  <img
                    crossorigin="anonymous"
                    src={require("../assets/images/DOCTALLWEBAPP.svg").default}
                    alt="DOCTALLWEBAPP"
                  />
                  <img crossorigin="anonymous" src={ICON11} alt="ICON11" />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="dashContainer" style={{ position: "relative" }}>
        {isDesktopView ? (
          sidemenu ? (
            <div className={`sidemenu ${sidemenu ? "sidemenu-display" : " "}`}>
              <div
                className={
                  history.location.pathname.indexOf("dashboard") > -1
                    ? "menu active"
                    : "menu"
                }
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                <img crossorigin="anonymous" src={ICON5} alt="doctall"></img>
                Home
              </div>
              <div
                className={
                  history.location.pathname.indexOf("appointment") > -1
                    ? "menu active"
                    : "menu"
                }
                onClick={() => {
                  history.push("/appointments");
                }}
              >
                <img crossorigin="anonymous" src={ICON6} alt="doctall"></img>
                Appointments
              </div>

              <div
                className={
                  history.location.pathname.indexOf("healthrecords") > -1
                    ? "menu active"
                    : "menu"
                }
                onClick={() => {
                  history.push("/healthrecords");
                }}
              >
                <img crossorigin="anonymous" src={ICON7} alt="doctall"></img>
                Health Records
              </div>
              <div
                className={
                  history.location.pathname.indexOf("Wallet") > -1
                    ? "menu active"
                    : "menu"
                }
                onClick={() => {
                  history.push("/Wallet");
                }}
              >
                <img crossorigin="anonymous" src={Wallet} alt="doctall"></img>
                Wallet
              </div>


              <div
                className={
                  history.location.pathname.indexOf("orders-results") > -1
                    ? "menu active"
                    : "menu"
                }
                onClick={() => {
                  history.push("/orders-results");
                }}
              >
                <img crossorigin="anonymous" src={ICON7} alt="doctall"></img>
                Orders & Results
              </div>

              <div
                className={
                  history.location.pathname.indexOf("support") > -1
                    ? "menu active"
                    : "menu"
                }
                onClick={() => {
                  history.push("/support");
                }}
              >
                <img crossorigin="anonymous" src={ICON7} alt="doctall"></img>
                Support
              </div>
              <div
                className={"menu"
                }
                onClick={() => { handleDailog() }}
              >
                <img crossorigin="anonymous" src={Delete} alt="doctall"></img>
                Delete Account
              </div>


              {/* <div className="menu" style={{ color: "#ADC2C2" }}>
                <img crossorigin="anonymous" src={ICON8} alt="doctall"></img>
                Health Tracker
              </div> */}

              <div className="container marginbox" onClick={redirectToDMP} style={{ cursor: 'pointer' }}>
                Buy a Diagnostic Test with a discount
                {/* <div>COMING SOON</div> */}
              </div>
              {/* <div className="container">
                Buy a Health Panel
                <div>COMING SOON</div>
              </div> */}
              <div className="containerTwo">
                <a
                  href="https://forms.clickup.com/f/4hnn1-543/J66634MTO2OOU28XUR"
                  target="blank"
                >
                  Help and Support
                </a>
                <a href={`${process.env.REACT_APP_NEWDOCTALL_URL}/faq`} target="blank">
                  FAQs
                </a>
                {/* <a >Leave us Feedback</a> */}
                <a
                  href={`${process.env.REACT_APP_NEWDOCTALL_URL}/terms-condition`}
                  target="blank"
                >
                  Terms & Conditions
                </a>
                <a href={`${process.env.REACT_APP_NEWDOCTALL_URL}/partner`} target="blank">
                  Find a partner
                </a>
              </div>
              <div className="doctallCom">
                <a href="https://doctall.com/">
                  <img
                    crossorigin="anonymous"
                    src={ICON12}
                    alt="doctall.com"
                  ></img>
                  <img crossorigin="anonymous" src={ICON11} alt="arrow"></img>
                </a>
              </div>
            </div>
          ) : null
        ) : sidemenu ? (
          <Sidemenumodal style={sidemenu ? "sidemenu-show" : ""} />
        ) : (
          ""
        )}
        <div className="children">
          {props.children}
          {/* <div className="chatIcon">
            <img crossorigin="anonymous" src={ChatIcon}/>
          </div> */}
        </div>
      </div>

      <ConfirmChangePasscode
        show={modalShow}
        onHide={() => setModalShow(false)}
        handleConfirmChangePass={handleConfirmChangePass}
      />
      {props.loadin ? (
        <div className="loadingcontainer">
          <CircularProgress style={{ color: "#00839b" }} />
        </div>
      ) : null}
      <Modal
        show={closer}
        onHide={() => { setCloser(true) }}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-upload"
      >
        <div className="upcomming">
          <img
            src={Close}
            alt="close"
            className="closePop"
            onClick={() => setCloser(false)}
          />
          <img src={not} alt="icon" className="info" />
          <div className="heading">Important Message</div>
          <div className="txt">
            Currently this service is not available, but we hope to introduce it
            in the coming weeks.
          </div>
          <div className="txt">We appologise for any inconvenience caused.</div>
          <div className="btn" onClick={() => { setCloser(false) }}>
            ok
          </div>
        </div>
      </Modal>
      <Dialog
        disableBackdropClick
        open={dailogOpen}
        onClose={() => setDailogOpen(false)}
        maxWidth='xs'
        fullWidth={true}
        minWidth="xs"
        style={{ borderRadius: "12px" }}
      >
        <DialogContent>
          <div className="DeleteAccountdiv">

            {showmsg === false && showmsg1 === false && <>
              <img
                src={Close}
                alt="close"
                className="closePop1"
                onClick={() => {
                  setShowmsg(false);
                  setShowmsg1(false);
                  setDailogOpen(false);
                }}
              />
              <img src={Delete} alt="icon" className="info1" />
              <div className="txt1">
                Are you sure you want to delete your Doctall account?
              </div>
              <br></br>
              <div className="txt123">You will lose access to your;</div>
              <div className="txt12">
                {DataList.map((item) => {
                  return <li style={{ marginLeft: "15px" }}>{item}</li>
                })}
              </div>
              <br></br>
              <div class="row">
                <div class="col-md-6">
                  <button className="btn1" onClick={() => setDailogOpen(false)}>Keep Account</button>
                </div>
                <div class="col-md-6">
                  <button className="btn11" onClick={DeleteAccount}>Delete</button>
                </div>
              </div>
            </>
            }
            {showmsg === true && <>
              <img
                src={Close}
                alt="close"
                className="closePop1"
                onClick={() => {
                  setShowmsg(false);
                  setShowmsg1(false);
                  setDailogOpen(false);
                }}
              />
              <img src={DeleteFailure} alt="icon" className="info1" />
              <div className="txt1">
                Your account cannot be deleted because there is an open Order being processed. Kindly close the order to complete account deletion.
              </div>
              <br></br>
              <div className="txt1">For more details, kindly contact us on 09010996000 or support@doctall.com</div>

              <br></br>
              <div class="row">
                <div class="col-md-12">
                  <center>
                    <button className="btn11"
                      style={{ textAlign: "center" }}
                      onClick={() => {
                        setShowmsg(false);
                        setShowmsg1(false);
                        setDailogOpen(false);
                      history.push("/orders-results");
                    }}
                  >Go to Orders</button>
                  </center>

                </div>
              </div>
            </>
            }

            {showmsg1 === true && <>
              <img
                src={Close}
                alt="close"
                className="closePop1"
                onClick={() => {
                  setShowmsg(false);
                  setShowmsg1(false);
                  setDailogOpen(false);
                  logoutfn();
                }}
              />
              <img src={DeleteSuccess} alt="icon" className="info1" />
              <div className="txt1">
                Your journey with Doctall ends now and we are sad to see you go. Thank you for trusting us with your health.
              </div>
              <br></br>
              <div class="row">
                <div class="col-md-12">
                  <center>
                    <button className="btn11"
                      onClick={() => {
                        setShowmsg(false);
                        setShowmsg1(false);
                        setDailogOpen(false);
                        logoutfn();
                      }}>Done</button>
                  </center>
                </div>
              </div>
            </>
            }
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DashLayoutDesign;
