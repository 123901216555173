import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Closebutton from "../../assets/images/closebutton.svg";
import Circle from "../../assets/images/hpicon.svg";
import Tick from "../../assets/images/tick.svg";
import "./HealthPop.scss";

export default function Submitpop(props) {
  return (
    <Modal {...props}
      aria-labelledby="contained-modal-title-submit-vcenter"
      contentClassName="modal-content-Health-pop"

    >
      <div className="hp-submit-pop-cont">
        <div className="header">
          <div className="close-btn">
            <img crossorigin="anonymous" src={Closebutton} alt="close-btn" onClick={() => props.onHide()} ></img>
          </div>
        </div>
        <div className="success-img-cont-wrap">
          <div className="success-img-cont">
            <div className="img-wrap">
              <img crossorigin="anonymous" src={Circle} alt="circle" ></img>
            </div>
          </div>
        </div>
        <div className="sub-header-wrap">
          <div className="sub-header">
            Please complete the mandatory fields in the Personal and Medical tabs of your Health Profile for the Patient selected
          </div>
          <div className="sub-header">
            This important information will assist the doctor.
          </div>
        </div>
      </div>
    </Modal>
  );
}


