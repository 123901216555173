
import React, { useState, useEffect } from 'react'
import DashboardLayout from '../../layout/DashboardLayout'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import axios from '../../redux/axios'
import { api } from '../../utils/constants'
import { TitleWithNav, } from "../../components"
import './stylesDoc.scss'
import AboutDoctor from '../../components/DoctorProfile/aboutDoctor'
import useDebounce from '../.././hooks/useDebounce'



function DocProfile() {
  const userdata = useSelector(state => state.auth)
  const [doctorList, setDoctorList] = useState([])
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [inputValue, setinputValue] = useState()
  const history = useHistory()
  const profile = history.location.data
  useEffect(() => {
    if (!history.location.data) {
      history.push("/specialist")
    }
  }, [])



  return (
    <DashboardLayout>
     
      <div className="specialistContainer">
        <TitleWithNav title={"Find a Doctor / Specialist (International & Nigerian)"} />
        <div className="specialTitle">
          Book your preferred Nigerian or International doctor / <br /> disease specialist at a time and cost that suits you.
        </div>

        <AboutDoctor prof={profile} />
      </div>
    </DashboardLayout>
  )
}

export default DocProfile
