import { Link } from '@material-ui/core';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Close from '../assets/images/close.svg';
import useOnClickOutside from "../hooks/useOutsideClickhandler";
import './authLayout.scss'
import popimageapple from "../assets/images/popimageapple.svg";
import popimagegoogle from "../assets/images/popimagegoogle.svg";


const AuthLayout = ({ children }) => {
  const { accessToken } = useSelector(state => state.auth);
  const [modalShow, setModalShow] = React.useState(false);

  if (accessToken) {
    return <Redirect to='/dashboard' />
  }
  return (
    <div>

      <Modal show={modalShow} onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-upload"
      >
        <div className="uploadDocuments">
          <div className="header">
            App Link
            <img crossorigin="anonymous" style={{ cursor: 'pointer' }} src={Close} onClick={() => { setModalShow(false) }} alt="Close" />
          </div>
          <hr className="heading_line" />



          <main >

            <div className="playstorelink">
              <a className="googleplay" target="_blank" href="https://play.google.com/store/apps/details?id=com.doctall">
                <img crossorigin="anonymous" src={popimagegoogle} />
              </a>

              <a className="applestore" target="_blank" href="https://apps.apple.com/ng/app/doctall/id1544455716" rel="noreferrer">
                <img crossorigin="anonymous" src={popimageapple} alt="img" />

              </a>

            </div>
          </main>

        </div>
      </Modal>

      <div className="container-fluid" style={{ padding: "0", height: "100%", backgroundColor: '#F2F8F9' }}>
        <div className="row" style={{ margin: 0 }}>
          <div className="innerDiv1 col-lg-6" style={{ padding: '0' }}>
            <div className="loginImage">
              {/* <img className="imgBackground" src={require('../assets/AuthImages/bg1.svg').default}/> */}
              <div className="overlay"></div>
              <h1 className='loginHeadText'>Helping you get well and live better </h1>
              <p className='loginParaText'>Get personalized care from certified local and international doctors online </p>
            </div>
          </div>
          <div className="innerDiv2 col-lg-6" style={{ padding: '0' }}>
            <div className="logoHead">
              <Link to="/"><img crossorigin="anonymous" src={require('../assets/images/logo.svg').default} alt="" /></Link>
              <button className=" downloadBtn" onClick={() => setModalShow(true)}> Download App</button>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthLayout;