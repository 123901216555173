import React, { useEffect, useState } from "react";
import "./DoctorProfile.scss";
import star from "../../assets/images/star.svg";
import Profile from "../../assets/images/pic.svg";
import Indian from "../../assets/images/indian.svg";
import Nigerian from "../../assets/images/nigerian.svg";
import British from "../../assets/images/ukflag.png";
import Vector from "../../assets/images/Vector.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import axios from "../../redux/axios";
import DropDown from "../Select/";
import { numberWithCommas } from "../../utils";
import { api } from "../../utils/constants";
import { Toast } from "../../components";
import VideoPlayer from "../VideoPlayer";
import { Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from 'react-helmet';

function AboutDoctor(props) {
  const { prof } = props;
  const [slot, setSlot] = useState([]);
  const [array, setArray] = useState([]);
  const [error, setError] = useState(false);
  const history = useHistory();
  const [rate, setRate] = useState(0);

  function arrUnique(arr) {
    var cleaned = [];
    arr.forEach(function (itm) {
      var unique = true;
      cleaned.forEach(function (itm2) {
        if (
          moment(itm.date).format("ddd  MM/DD") ==
          moment(itm2.date).format("ddd  MM/DD") &&
          moment(itm.start_time).format("LT") ==
          moment(itm2.start_time).format("LT")
        )
          unique = false;
      });
      if (unique) cleaned.push(itm);
    });
    return cleaned;
  }
  useEffect(async () => {
    if (prof) {
      await axios
        .get(api.slots + "/normal/doctor/" + prof.uuid)
        .then((res) => {
          let slotlist =
            res.data && res.data.length > 0 ? res.data[0].slots : [];
          slotlist = slotlist.filter(
            (e) => moment(e.start_time) >= moment() && e.isActive == true
          );

          //    slotlist = slotlist.filter((e) => e.isActive);
          slotlist = arrUnique(slotlist);

          let resulttime = slotlist.map((e, i) => {
            return {
              label:
                moment(e.date).format("DD MMM YYYY") +
                " | " +
                moment(e.start_time).format("LT") +
                "-" + moment(e.end_time).format("LT")
              ,
              value: e._id,
              date: moment(e.date).format("ddd  MM/DD"),
            };
          });
          setSlot(resulttime);
          if (
            resulttime.length > 0 &&
            resulttime.length != undefined &&
            resulttime.length != ""
          ) {
            setArray(resulttime[0]);
          } else {
            setArray([]);
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  const handleClickdetails = () => {
    if (array.length !== 0) {
      history.push({
        pathname: "/book_specialist",
        state: {
          selectedData: array,
          doc: prof,
        },
      });
    } else {
      console.log(array.length);
      setError(true);
    }
  };

  const getYear = (year) => {
    if (year) {
      let currentyear = moment().format("YYYY");
      return currentyear - year + " Years";
    } else {
      return "";
    }
  };

  // useEffect(() => {
  //   axios
  //     .get(`${api.star_rating}/booking/feedback/starRating/${prof.uuid}`)
  //     .then((res) => setRate(Math.round(res.data.result)));
  // }, []);

  return (
    <div>
      <Helmet>
        <title>About Doctor | Find Information About Your Doctor
</title>
<meta 
            name='description' 
            content='Look through bios for your selected doctors, find information on their qualifications, experience and specialty. Book an appointment with the doctor of your choice.'
            />
      </Helmet>
      {prof && (
        <div className="docProfContainer">
          <Toast
            text={"Select the Date first"}
            type="error"
            open={error}
            setClose={() => {
              setError(false);
            }}
          />
          <div className="aboutprof">
            <div className="aboutHead">
              {prof.profile &&
                prof.profile.avatar &&
                prof.profile.avatar != null &&
                prof.profile.avatar != "" ? (
                <img
                  crossorigin="anonymous"
                  className="profileimg"
                  src={prof.profile.avatar}
                  alt=""
                 
                />
              ) : (
                <img
                  crossorigin="anonymous"
                  className="profileimg"
                  src={Profile}
                  alt=""
                />
              )}
              <div className="docDetails">
                <div className="details">
                  Location:&nbsp;
                  <span>
                    {prof.profile.address &&
                      Object.keys(prof.profile.address).length > 0
                      ? prof.profile.address.country
                      : ""}
                  </span>
                  <span className="imgholder">
                    <img
                      style={{ width: "24px", height: "40px", marginRight: 3 }}
                      src={
                        prof.profile.address.country.toLowerCase() == "india"
                          ? Indian
                          : prof.profile.address.country.toLowerCase() ==
                            "united kingdom"
                            ? British
                            : Nigerian
                      }
                    />
                  </span>
                </div>
                <div className="details">
                  Experience:&nbsp;
                  <span>{getYear(prof.profile.practice_started_year)}</span>
                </div>
                <div className="details">Languages Spoken:</div>
                <div className="details">
                  <span>{prof.profile.languages.join(", ")}</span>
                </div>
              </div>
              {/* <div className="starcontainer">
                {[...Array(rate)].map((el, index) => {
                  return (
                    <img
                      key={index}
                      crossorigin="anonymous"
                      src={star}
                      alt="start"
                    />
                  );
                })}
              </div> */}
              <hr />
              <div className="feeContainer">

                {prof && prof.consultation_fee > 0 ? (
                  <span style={{ fontWeight: "600" }}>₦{numberWithCommas(prof.consultation_fee)}</span>
                ) : (
                  <span>Free</span>
                )}<br />
                <span style={{ fontSize: "18px" }}>Consultation Fee:</span>{" "}
              </div>
              <div className="profSpeciality">
                <div className="titles">Specialities</div>
                {prof.profile.speciality.lenghth !== 0
                  ? prof.profile.speciality.map((i) => <button>{i}</button>)
                  : ""}
              </div>
            </div>
            <div className="aboutBody">
              <div className="aboutProfText">
                <h1>{prof.full_name}</h1>
                <p>{prof.profile.speciality.join(", ")}</p>
                <h2>About</h2>
                <p className="aboutContent">{prof.profile.bio}</p>
              </div>
              <div className="slotBooking">
                <h4>Earliest Available Time</h4>
                {slot.length > 0 ? (
                  <>
                    <div className="availableTime">
                      <DropDown
                        placeholder={"Date"}
                        // value={""}
                        value={array}
                        // value = {slot && slot[0] ? slot[0] :""}

                        setValue={(e) => setArray(e)}
                        options={slot}
                      />
                    </div>

                  </>
                ) : (
                  <div className="dateOfBooking">
                    {/* <div className="inputForm">No Slots Avaliable</div> */}
                    {/* <select name="bookingDate" className='inputForm'>
                                <option value="">No slots Found</option>
                            </select>
                             */}
                    <div>
                      <Row>
                        <Col xs={6}>
                          <div className="inputForm">No Slots Avaliable/Date <img src={Vector} style={{ marginLeft: "10%" }} /></div>
                        </Col>
                        <Col xs={6}>
                          <div className="inputForm">No Slots Avaliable/Time <img src={Vector} style={{ marginLeft: "10%" }} /></div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )}
                <div className="sectionBook">
                  {slot.length !== 0 ? (
                    <button
                      className="bookDocBtn"
                      disabled={false}
                      onClick={handleClickdetails}
                    >
                      Book Doctor
                    </button>
                  ) : (
                    <button
                      className="bookDocBtn"
                      style={{ background: "#4993a0bf" }}
                      disabled={true}
                      onClick={handleClickdetails}
                    >
                      Book Doctor
                    </button>
                  )}

                  {/* <button className="bookDocBtn" onClick={handleClickdetails}>
                                Book Doctor
                                </button> */}
                  {/* <a href="https://doctall.com/more/contact/" target="_blank"> */}
                    <p className="contactAdmin">
                      {/* Can’t find a convenient slot? <br />
                      Please contact us to book this doctor. */}
                      Please call us or WhatsApp at <br />
                      +234 9010996000 to book this doctor
                    </p>
                  {/* </a> */}
                </div>
              </div>

              <Row style={{ marginTop: "30px", marginBottom: "30px" }}>
                <Col xs={12}>
                  <VideoPlayer
                    videos={
                      prof.profile.profileVideoUrl
                        ? prof.profile.profileVideoUrl.filter((e) => e != "")
                        : []
                    }
                    config={{ width: "100%" }}
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AboutDoctor;
