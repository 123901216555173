import React from 'react';
import Layout from '../../layout/DashboardLayout';
import './HealthAndSupport.scss';
import { TitleWithNav, Button } from "../../components"

function HealthAndSupport() {
    return (
        <Layout>
            <div className="HealthAndSupport">
                <TitleWithNav title={"Help and Support"} />
                <div className="heading">
                    To assist you with any issues you might be experiencing on the app, please reach out to us on <span>support@doctall.com</span> or call <span>09010996000</span>
                </div>
                <div className="container">
                    <div className="container_heading">Hi there!</div>
                    <p>We are here to assist you with
                        any questions you may have.</p>
                </div>
                <br />
                <div className="btnContainer">
                    <a href="https://forms.clickup.com/f/4hnn1-543/J66634MTO2OOU28XUR" target="blank" className="supportBtn">
                        Start a New Conversation
                    </a>
                </div>
            </div>
        </Layout>
    )
}

export default HealthAndSupport
