import React, { useState } from "react";
import "./checkBtn.scss"

function CheckBtn2(props) {
    const { name, value, img, imgAct, onChange, styles, onClick, checked } = props
    return (
        <div>
            <div className="radioComponent">
                <input type="checkbox" name={name} onChange={onChange}
                    value={value} checked={checked} />
                <label className="newRadio" htmlFor={value} onClick={onClick}>
                    <img crossorigin="anonymous" className="radioOff" src={img} alt="" />
                    <img crossorigin="anonymous" className="radioOn" src={imgAct} alt="" />
                    <div style={styles} className="radioName">{value}</div>
                </label>
            </div>
        </div>
        //      <div>
        //      <div className="radioComponent">
        //            <input type="checkbox" name={name} onChange={onChange} value={value}
        //            />
        //            <label className="newRadio" htmlFor={value} onClick={click}>
        //                <img className="radioOff" src={img} alt="" />
        //                <img className="radioOn" src={imgAct} alt="" />
        //                <div style={styles} className="radioName">{value}</div>
        //            </label>
        //        </div>
        //  </div>
    )
}



export default CheckBtn2


