import React, { useState, useEffect } from "react";
import { Tabs, Tab, Typography, Box } from "@material-ui/core";
import "./tabs.scss";
import PropTypes from "prop-types";
import ICON10 from "../../assets/images/ICON10.svg";
import Sort from "../../assets/images/Sort.svg";
import { Row, Col } from "react-bootstrap";
import FiltersForm from "./FiltersForm";
import { ordersFilter } from "../../utils/schema";
import Search from "../../assets/images/Search.svg";
import InputField from "../../components/InputField";
import BookCards from "./BookCards";
import OrdersList from "./OrdersList";
import OrderTracking from "./OrderTracking";
import { set } from "lodash";
import { getAllOrders } from "./../../redux/orders/actions";
import DateRange from "./DateRange";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const OrdersTabs = (props) => {
  const { load } = props;
  const [value, setValue] = useState(0);
  const [searchText, setsearchText] = useState("");
  const [specialities, setSpecialities] = useState([]);
  const [filterForm, openFilterForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [languages, setlanguages] = useState([]);
  const [inputValue, setinputValue] = useState("");
  const [dataval, setDataVal] = useState([]);
  const [completeOrdersData, setcompleteOrdersData] = useState([]);
  const [filterObj, setFilterObj] = useState({
    state_date: null,
    end_date: null,
    status: null,
  });

  const [filterData, setFilterData] = useState({
    country: "",
    speciality: "",
    experience: "",
    start_price: "",
    last_price: "",
    language: "",
    search: "",
    type: "SAD_landing",
  });

  useEffect(() => {
    setcompleteOrdersData(props.completeOrdersData);
  }, props.completeOrdersData);

  const onSearch = () => {
    // history.push({ pathname: "/specialist", searchtext: text });
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const changeHandler = (e) => {
    setinputValue(e.target.value);
    setSearchTerm(e.target.value);
    props.fetchOrders(e.target.value, filterObj);
  };

  const handleFilterChange = (e) => {
    let changeData = Object.assign({}, filterData, {
      [e.target.name]: e.target.value,
    });
    if (e.target && e.target.name === "price_range") {
      changeData = Object.assign({}, filterData, {
        [e.target.name]: e.target.value,
        start_price: e.target.value[0],
        last_price: e.target.value[1],
      });
    }

    setFilterData(changeData);
  };

  const clearFilter = () => {
    setFilterData({
      country: "",
      speciality: "",
      sort_type: "",
      start_price: "",
      last_price: "",
      language: "",
      search: "",
      type: "SAD_landing",
    });
    setDataVal([]);
  };

  let handleAppliedFilter = (filterObj) => {
    setFilterObj(filterObj);

    props.fetchOrders(searchTerm, filterObj);
  };

  let toBeRenderedComponent = (index) => {
    if (!props.completeOrdersData || props.completeOrdersData.length < 0) {
      return <BookCards />;
    } else if (
      props.completeOrdersData &&
      props.completeOrdersData.length > 0
    ) {
      // return <OrderTracking />
      return (
        <OrdersList
          completeOrdersData={props.completeOrdersData}
          viewIndividualOrder={props.viewIndividualOrder}
        />
      );
    }
  };

  return (
    <div className="tab-cls-health-rec">
      <Tabs
        style={{ backgroundColor: "#FFFFFF", paddingRight: "20px" }}
        value={value}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="#00839B"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        {/* 
        <Tab
          style={{
            color: "#00839B",
            fontSize: '16px',
            fontFamily: "VisueltMedium",
            backgroundColor: value === 0 ? '#F2F8F9' : '#FFFFFF'
          }}
          label="All"
          {...a11yProps(0)} /> */}
        <Tab
          style={{
            color: "#163E8C !important",
            fontSize: "16px",
            fontFamily: "VisueltMedium",
            backgroundColor: value === 1 ? "#F2F8F9" : "#FFFFFF",
          }}
          label="Diagnostics Tests"
          {...a11yProps(0)}
        />
      </Tabs>

      <Row>
        {/* <Col sm={1} /> */}
        <Col sm={10}>
          <Row>
            <Col md={6}>
              <Row
                style={{
                  paddingTop: "25px",
                  paddingBottom: "25px",
                  marginRight: "20px",
                }}
              >
                <Col sm={8}>
                  <InputField
                    className="doctors-search"
                    size="large"
                    color="blue "
                    placeholder="Search for tests and results"
                    prefix={<img crossorigin="anonymous" src={Search} />}
                    InputStyle={{
                      outline: "none !important",
                    }}
                    value={searchTerm}
                    onChange={changeHandler}
                  />
                </Col>
                <Col
                  sm={4}
                  className="doctors-section6-filter"
                  onClick={() => openFilterForm(!filterForm)}
                >
                  <img crossorigin="anonymous" src={Sort} />
                </Col>
              </Row>
            </Col>
            <Col md={6} />
          </Row>
          {filterForm ? (
            <Row
              className="filter-section-form"
              style={{ marginRight: "25px" }}
            >
              <Col xs={12}>
                <div className="">
                  <FiltersForm
                    initialValues={filterData}
                    onChange={handleFilterChange}
                    fields={ordersFilter}
                    handleAppliedFilter={handleAppliedFilter}
                  />
                </div>
              </Col>
            </Row>
          ) : null}
        </Col>
        <Col sm={1} />
      </Row>
      <hr />
      <TabPanel value={value} index={0}>
        {toBeRenderedComponent()}
      </TabPanel>
      <TabPanel value={value} index={1}>
        Tab2
      </TabPanel>

      <TabPanel value={value} index={2}>
        Tab3
      </TabPanel>
    </div>
  );
};

export default OrdersTabs;
