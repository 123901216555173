export const OccupationList=[
  { label: 'High-level managerial, administrative or professional', value: 'High-level managerial, administrative or professional', hidden: true },
  { label: 'Mid-level managerial, administrative or professional', value: 'Mid-level managerial, administrative or professional', },
  { label: 'Supervisory, clerical and junior managerial, administrative or professional', value: ' Supervisory, clerical and junior managerial, administrative or professional' },
  { label: 'Skilled manual workers', value: 'Skilled manual workers', },
  { label: 'Other manual workers', value: 'Other manual workers' },
  { label: 'Casual workers or not currently in employment', value: 'Casual workers or not currently in employment', },
  { label: 'Retirees, students and those not seeking employment', value: 'Retirees, students and those not seeking employment' },
]


export const SectorList = [
    'Agriculture',
    'Mining and Quarrying',
    'Manufacturing',
    'Electricity, gas, steam and air-conditioning supply',
    'Water supply, sewerage, waste management and remediation',
    'Construction',
    'Trade',
    'Accommodation and food services',
    'Transportation and Storage',
    'Information and Communication',
    'Arts, entertainments and recreation',
    'Financial and insurance',
    'Real estate',
    'Professional, scientific and technical services',
    'Administrative and support services',
    'Public administration and defense',
    'Education',
    'Human health and social services',
    'Others'
]
  export const AlcoholList=['Non-drinker','Occasional drinker','Heavy drinker']
  export const MedicialInterest=["Doctor articles", "Fitness", "Health articles", "Lifestyle", "Nutrition", "Public health", "Videos"]
  export const BloodGroups=["A+", "B+", "O+", "AB+", "A-", "B-", "O-", "AB-", "Unknown"]
  export const SocialMedia =["None", "Facebook", "Instagram", "Twitter", "Tik-tok", "Whatsapp", "Snapchat", "Linkdien", "Youtube", "Others Social [Please Specify]"]
  export const UseMobile=["1-5", "6-10", "10+"]
  export const Dieting=["Eggetarian", "Non-Vegetarian", "Pescatarian", "Vegan", "Vegetarian","Other Any Diet"]
  export const Lifestyle=["Sedentary", "Moderately active", "Active", "Athletic"]
  export const Smoking=["Never smoked", "I used to, but i have quit", "1-5 a day", "5-10 a day", "10-20 a day", "Greater than 20 day"]
  export const AllergiesList = ["None.", "Aspirin", "Contrast dye", "Dust", "Eggs", "Gluten", "Insulin", "Lactose", "Local\nAnesthesia", "Mould", "Mushroom", "Nuts", "Penicillin", "Pets", "Pollen", "Seafood", "Soy", "Sulpha medication","Others [Please-Specify]"]
  export const IllnessesList = ["None", "Arthritis", "Asthma", "Cancer", "Cholesterol", "COPD", "Diabetes", "Epilepsy", "Fertility\nissues", "Heart Disease", "Hypertension", "Hypothyrodism", "Kidney Disease ", "Mental\nillness/Depression", "PCOS", "Tuberculosis","Others [Please-Specify]"]
  export const Months=[
    { label: 'Jan', value: '01', hidden: true },
    { label: 'Feb', value: '02', },
    { label: 'Mar', value: '03' },
    { label: 'Apr', value: '04', },
    { label: 'May', value: '05' },
    { label: 'June', value: '06', },
    { label: 'July', value: '07' },
    { label: 'Aug', value: '08', },
    { label: 'Sep', value: '09' },
    { label: 'Oct', value: '10', },
    { label: 'Nov', value: '11' },
    { label: 'Dec', value: '12', }
]
export const DrugStrength =['Mg','Mi',"Mcg"]
export const Frequency =["Daily","Weekly","Monthly"]
