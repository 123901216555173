import React, { useState, useEffect } from "react";
import "./profile.scss";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import imgArrow from "../../assets/images/ICON16.svg";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "../../redux/axios";
import moment from "moment";
import { api } from "../../utils/constants";
import PhoneInput from "react-phone-input-2";
import PropTypes from "prop-types";
import { RadioBtn, CheckBtn, Toast } from "../../components";
import { Tabs, Tab, Typography, Box } from "@material-ui/core";
import {
  SectorList,
  UseMobile,
  OccupationList,
  MedicialInterest,
  BloodGroups,
  SocialMedia,
  Dieting,
  Lifestyle,
  Smoking,
  AllergiesList,
  IllnessesList,
  Months,
  DrugStrength,
  Frequency,
  AlcoholList,
} from "./dataForProfile";
import { DialogBoxWithClose } from "../../components";
import DatePicker from "react-date-picker";
import CardForm from "../CardForm/cardForm";
import { getPatients } from "../../redux/patients/actions";
import { getUserDetails } from "../../redux/user/actions";
import { Helmet } from 'react-helmet';
import CheckBtn2 from "../CheckBtn/checkButton";
import Close from "../../assets/images/close.svg";
import { Dialog } from "@material-ui/core";
import Inte from "../../assets/images/caution.svg";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

function ProfileForm(props) {
  toast.configure();
  const { Progress, setProgressNum, type, uuidData, medCheck, setLoading } =
    props;
  const userdata = useSelector((state) => state.auth);
  const patientdata = useSelector((state) => state.patients.data);
  const history = useHistory();
  const dispatch = useDispatch();
  const [form1, setForm1] = useState(false);
  const [form2, setForm2] = useState(false);
  const [form3, setForm3] = useState(false);
  const [value, setValue] = useState(0);
  const [relative, SetRelative] = useState(false);
  const [enableFormHeightCm, setEnableFormHeightCm] = useState(false);
  const [enableFormHeightIn, setEnableFormHeightIn] = useState(false);
  const [date, setDate] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState(" ");
  const [gender, setGender] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [email, setEmail] = useState("");
  const [secondEmail, setSecondEmail] = useState("");
  const [othermarital, setothermarital] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [street_Name, setStreet_Name] = useState("");
  const [area, setArea] = useState("");
  const [medInterest, setMedInterest] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const [Illnesses, setIllnesses] = useState([]);
  const [socialMedia, setSocialMedia] = useState([]);
  const [specificMed, setSpecificMed] = useState([]);
  const [agreeHealth, setAgreeHealth] = useState(false);
  const [agreeAppointment, setAgreeAppointment] = useState(false);
  const [otherAllergie, setOtherAllergie] = useState([]);
  const [otherIll, setOtherIll] = useState([]);
  const [alcohol, setAlcohol] = useState("");
  const [smoking, setSmoking] = useState("");
  const [lifestyle, setLifestyle] = useState("");
  const [mobileApps, setMobileApps] = useState("");
  const [otherDiet, setOtherDiet] = useState("");
  const [diet, setDiet] = useState("");
  const [otherSocial, setOtherSocial] = useState("");
  const [uuid, setUuid] = useState("");
  const [checkOtherDiet, setCheckOtherDiet] = useState(false);
  const [update, setUpdate] = useState(false);

  const [stateRender, setStateRender] = useState(0);
  const [loadingState, setLoadingState] = useState(false);
  const [yearlist, setYearList] = useState([]);
  const [citylist, setCityList] = useState();
  const [statelist, setStateList] = useState();
  const [sector, setSector] = useState("");
  const [countryCode, setCountryCode] = useState("ng");
  const [occupation, setOccupation] = useState("");
  const [city, setCity] = useState("");
  const [states, setStates] = useState("");
  const [code, setCode] = useState("");
  const [phone, setPhone] = useState("");
  const [otherPhone, setOtherPhone] = useState("");
  const [ocode, setOCode] = useState("");
  const [weights, setWeights] = useState("");
  const [totalWeights, setTotalWeights] = useState([]);
  const [bmiCalc, setBmiCalc] = useState("");
  const [weightDate, setWeightDate] = useState(new Date());
  const [height, setHeight] = useState("");
  const [heigth_Cms, setHeight_Cms] = useState("");
  const [heigth_Ft, setHeight_Ft] = useState("");
  const [heigth_In, setHeight_In] = useState("");
  const [openBmi, setOpenBmi] = useState(false);
  const [toastHeight, setToastHeight] = useState(false);
  const [surgery, setSurgery] = useState([
    {
      Name_of_surgery: "",
      when_did_you_have_the_surgery_month: "",
      when_did_you_have_the_surgery_year: "",
      Add_description: "",
    },
  ]);
  // console.log(surgery)
  const [injury, setInjury] = useState([
    {
      Name_of_injury: "",
      how_long_ago_did_the_injury_occur_month: "",
      how_long_ago_did_the_injury_occur_year: "",
      Add_description: "",
    },
  ]);
  const [medication, setMedication] = useState([
    {
      Drug: "",
      Drug_strength: "",
      Frequency_per_day: "",
      how_long_have_you_been_taking_this_medications: "",
      drugtxt: "Mg",
      drugtxt1: "Daily",
      drugtxt2: "Days",
    },
  ]);
  const [intimation, setIntimation] = useState(false);
  const [finalSub, setFinalSub] = useState(false);
  const [errstate, setErrstate] = useState(false);
  const [chronicerr, setChronicerr] = useState(false);
  const [socialmediaerr, setSocialmediaerr] = useState(false);
  const [dieterr, setDieterr] = useState(false);
  const [maritalerr, setMaritalerr] = useState(false);
  useEffect(async () => {
    let currentyear = new Date().getFullYear();
    let startyear = currentyear - 50;
    let years = [];
    for (var x = startyear; x <= currentyear; x++) {
      years.push({ label: x.toString(), value: x.toString() });
    }
    setYearList(years);

    await axios
      .get(api.citylist, { headers: { "x-auth-token": userdata.token } })

      .then((r) => {
        let res = r.data.map((e) => {
          return { label: e.name, value: e._id, state: e.state };
        });
        if (res.length !== 0) {
          setCityList(res);
          setTimeout(setStateRender(stateRender + 1), 1000);
        }
      })
      .catch((err) => { });
    await axios
      .get(api.statelist, { headers: { "x-auth-token": userdata.token } })

      .then((r) => {
        let res = r.data.map((e) => {
          return { label: e.name, value: e._id, country: e.country };
        });
        setStateList(res);
        if (res.length !== 0) {
          setStateList(res);
          setTimeout(setStateRender(stateRender - 1), 1000);
        }
      })
      .catch((err) => { });
  }, []);

  function capitalize(s) {
    return s && s[0].toUpperCase() + s.slice(1);
  }
  const countryCodeFn = (code) => {
    if (code == "91" || code == "+91") {
      return "in";
    } else if (code == "234" || code == "+234") {
      return "ng";
    } else if (code == "1" || code == "+1") {
      return "us";
    } else if (code == "44" || code == "+44") {
      return "uk";
    } else {
      return "NG";
    }
  };

  useEffect(() => {
    if (medCheck === "medUpdate") {
      setValue(1);
    }
  }, []);
  const invalidChar = (e) => {
    (e.key === "e" && e.preventDefault()) ||
      (e.key === "-" && e.preventDefault()) ||
      (e.key === "+" && e.preventDefault());
  };
  const handleWeightsAndHeights = (type, e) => {
    const { value, maxLength } = e.target;
    let val = value.slice(0, maxLength);
    if (val < 0) {
      val = 0;
    } else {
      if (type === "weight") {
        if (val > 150) {
          val = 150;
        }
        setWeights(val);
      } else if (type === "heightCm") {
        if (val > 230) {
          val = 230;
        }
        setHeight_Cms(val);
      } else if (type === "heightFt") {
        if (val > 8) {
          val = 8;
        }
        setHeight_Ft(val);
      } else if (type === "heightIn") {
        if (val > 12) {
          val = 12;
        }
        setHeight_In(val);
      }
    }
  };
  useEffect(async () => {
    try {
      if (type === "addProfile") {
        setLoading(false);
      } else {
        setLoading(true);
      }

      let refine;
      if (type === "relative") {
        refine = patientdata && patientdata.filter((i) => i.uuid === uuidData);
      } else if (type === "owner") {
        refine = patientdata && patientdata.filter((i) => i.relationship);
        console.log(userdata,"patientdata")
      }

      if (patientdata && refine && citylist && statelist) {
        let dataProf = refine[0];
        console.log(dataProf);
        if (dataProf) {
          if (dataProf.uuid !== undefined) {
            setUuid(dataProf.uuid);
          }
          if (dataProf.dob !== undefined) {
            let dob = new Date(dataProf.dob);
            setDate(dob);
          }
          if (dataProf.personalQuestions.length !== 0) {
            setSector(dataProf.personalQuestions[1].answer);
            setOccupation(dataProf.personalQuestions[0].answer);
            setMedInterest(dataProf.personalQuestions[2].answer);
          }

          if (dataProf.mobile !== undefined && dataProf.mobile.length !== 0) {
            console.log(dataProf.mobile);
            let mobileNum = dataProf.mobile.slice(
              dataProf.mobile.length - 10,
              dataProf.mobile.length
            );
            let c1code = dataProf.mobile.slice(0, dataProf.mobile.length - 10);
            setCode(countryCodeFn(c1code));
            console.log(code.toString());
            setPhone(dataProf.mobile);
          }
          if (
            dataProf.other_mobile !== undefined &&
            dataProf.other_mobile.length !== 0
          ) {
            // if(dataProf.other_mobile > 10){setPhone(dataProf.other_mobile)}

            let mobileOtherNum = dataProf.other_mobile.slice(
              dataProf.other_mobile.length - 10,
              dataProf.other_mobile.length
            );
            let c1code = dataProf.other_mobile.slice(
              0,
              dataProf.other_mobile.length - 10
            );
            // setOCode(countryCodeFn(c1code))
            setOtherPhone(dataProf.other_mobile);
          }
          if (dataProf.weight.length !== 0) {
            let weightLoad = dataProf.weight.concat().reverse();
            setWeights(weightLoad[0].value);
            setBmiCalc(weightLoad[0].bmi);
            setWeightDate(new Date(weightLoad[0].date));
            setTotalWeights(weightLoad);
            // console.log(totalWeights)
          }

          if (dataProf.height.length !== 0) {
            setHeight(dataProf.height[0].unit);
            if (dataProf.height[0].unit === "feet Inch") {
              {
                setEnableFormHeightIn(true);
              }
              setHeight_In(dataProf.height[0].value.slice(1).trim());
              setHeight_Ft(dataProf.height[0].value.charAt(0));
            }
            if (
              dataProf.height[0].unit === "Centimeters" ||
              dataProf.height[0].unit === "centimeters"
            ) {
              setEnableFormHeightCm(true);
              setHeight_Cms(dataProf.height[0].value);
            }
          }
          if (dataProf.full_name !== undefined) {
            // console.log()
            let fullname = dataProf.full_name
              ? dataProf.full_name.split(" ")
              : "";
            fullname = fullname.filter((ee) => ee != "");
            setFirstName(fullname[0]);
            if (fullname.length == 2) {
              setLastName(fullname[1]);
            } else {
              setMiddleName(fullname[1]);
              setLastName(fullname[2]);
            }
          }
          // if (dataProf.first_name !== undefined) {
          //   setFirstName(dataProf.first_name);
          // }
          // if (dataProf.last_name !== undefined) {
          //   setLastName(dataProf.last_name);
          // }
          // if (dataProf.middle_name !== undefined) {
          //   setMiddleName(dataProf.middle_name);
          // }

          if (dataProf.gender !== undefined) {
            let genderVal = capitalize(dataProf.gender);
            setGender(genderVal);
          }
          if (dataProf.marital_status !== undefined) {
            let marital_statusval = capitalize(dataProf.marital_status);
            if (marital_statusval != "Single" && marital_statusval != "Married") {
              setMaritalStatus("Others");
              setothermarital(marital_statusval);
            } else {
              setMaritalStatus(marital_statusval);
              setothermarital("");
            }
          }
          if (dataProf.blood_group !== undefined) {
            setBloodGroup(dataProf.blood_group);
          }
          if (dataProf.email !== undefined) {
            setEmail(dataProf.email);
          }

          if (dataProf.secondary_email !== undefined) {
            setSecondEmail(dataProf.secondary_email);
          }

          if (dataProf.residential_Address.length !== 0) {
            setStreet_Name(dataProf.residential_Address[0].street);
            setHouseNumber(dataProf.residential_Address[0].door_no);
            setArea(dataProf.residential_Address[0].area);
          }

          if (dataProf.residential_Address !== 0) {
            try {
              if (dataProf.residential_Address[0].city) {
                if (citylist.length !== 0) {
                  let val = dataProf.residential_Address[0].city;
                  const cityData = citylist.filter((i) => i.value === val);
                  setCity(cityData[0]);
                }
              }

              if (dataProf.residential_Address[0].state) {
                if (statelist.length !== 0) {
                  let val = dataProf.residential_Address[0].state;
                  const stateData = statelist.filter((i) => i.value === val);
                  setStates(stateData[0]);
                }
              }
            } catch (err) {
              console.log(err);
            }
          }

          setAgreeHealth(true);
          setAgreeAppointment(true);

          // lifestyle
          if (
            dataProf.lifestyleQuestions &&
            dataProf.lifestyleQuestions.length > 0
          ) {
            setAlcohol(dataProf.lifestyleQuestions[0].answer);
            setSmoking(dataProf.lifestyleQuestions[1].answer);
            setLifestyle(dataProf.lifestyleQuestions[2].answer);

            if (dataProf.lifestyleQuestions[3].answer) {
              let dietres = dataProf.lifestyleQuestions[3].answer;
              let dietfilter = Dieting.filter((i) => i === dietres);
              if (dietfilter.length === 0) {
                setCheckOtherDiet(true);
                setOtherDiet(dataProf.lifestyleQuestions[3].answer);
                setDiet("Other Any Diet");
              } else {
                setDiet(dataProf.lifestyleQuestions[3].answer);
              }
            }

            setMobileApps(dataProf.lifestyleQuestions[4].answer);

            if (dataProf.lifestyleQuestions[5].answer) {
              let soci = dataProf.lifestyleQuestions[5].answer;
              let socis = soci.filter((i) => !SocialMedia.includes(i));
              if (socis.length !== 0) {
                setOtherSocial(socis);
                soci.push("Others Social [Please Specify]");
              }
              setSocialMedia(soci.filter((i) => SocialMedia.includes(i)));
            }
          }

          // medical questions
          if (dataProf.medicalQuestions && dataProf.medicalQuestions.length > 0) {
            if (dataProf.medicalQuestions[0].answer) {
              let all = dataProf.medicalQuestions[0].answer;
              let alli = all.filter((i) => !AllergiesList.includes(i));
              if (alli.length !== 0) {
                setOtherAllergie(alli);
                all.push("Others [Please-Specify]");
              }
              setAllergies(all.filter((i) => AllergiesList.includes(i)));
            }
            if (dataProf.medicalQuestions[1].answer) {
              let ill = dataProf.medicalQuestions[1].answer;
              let illi = ill.filter((i) => !IllnessesList.includes(i));
              if (illi.length !== 0) {
                setOtherIll(illi);
                ill.push("Others [Please-Specify]");
              }
              setIllnesses(ill.filter((i) => IllnessesList.includes(i)));
            }
            setSpecificMed(dataProf.medicalQuestions[2].answer);

            if (dataProf.medicalQuestions[3].answer === "No") {
              setForm1(false);
            } else {
              setForm1(true);
              setSurgery(JSON.parse(dataProf.medicalQuestions[3].answer));
            }

            if (dataProf.medicalQuestions[4].answer === "No") {
              setForm2(false);
            } else {
              setForm2(true);
              setInjury(JSON.parse(dataProf.medicalQuestions[4].answer));
            }
            if (dataProf.medicalQuestions[5].answer === "No") {
              setForm3(false);
            } else {
              setForm3(true);
              setMedication(JSON.parse(dataProf.medicalQuestions[5].answer));
            }
          }
        }
        setLoading(false);
      }
    } catch (error) {
      console.log(error)
      console.log(patientdata)
      setLoading(false);
    }
  }, [patientdata, citylist, statelist, loadingState]);

  useEffect(() => {
    const weightD = moment(weightDate).utc().format();
    let weight_data = {
      value: weights,
      unit: "kg",
      date: weightD,
      bmi: bmiCalc,
    };
    setTotalWeights([...totalWeights, weight_data]);
  }, [bmiCalc]);
  const BmiFunction = () => {
    let bmiCheck;
    if (weights === "") {
      setToastHeight(true);
    } else {
      let heightval = "";
      let feetval = "";
      if (enableFormHeightCm === true) {
        if (heigth_Cms === "") {
          setToastHeight(true);
        } else {
          heightval = (heigth_Cms * 0.393701) / 39.3700787;
          let bmi = parseFloat(weights) / Math.pow(parseFloat(heightval), 2);
          bmi = Math.round(bmi * 100) / 100;
          setBmiCalc(bmi.toString());
          toast.success("Weight Added", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
          });
        }
      } else if (enableFormHeightIn === true) {
        if (heigth_In === "" || heigth_Ft === "") {
          setToastHeight(true);
        } else {
          if (heigth_Ft) {
            feetval = parseFloat(heigth_Ft) * 12;
          }
          if (heigth_In) {
            feetval = parseFloat(feetval) + parseFloat(heigth_In);
          }
          heightval = feetval / 39.3700787;
          let bmi = parseFloat(weights) / Math.pow(parseFloat(heightval), 2);
          bmi = Math.round(bmi * 100) / 100;
          bmiCheck = bmi.toString();
          setBmiCalc(bmi);
          toast.success("Weight Added", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
          });
        }
      } else {
        setToastHeight(true);
      }
    }
  };

  // console.log(bmiCalc)
  const viewHistory = () => {
    setOpenBmi(true);
  };

  const handleSurgeryChange = (e) => {
    const surg = [...surgery];
    surg[e.target.dataset.id][e.target.name] = e.target.value;

    setSurgery(surg);
  };
  const handleSurgeryDropChange = (index, key, val) => {
    const surg = [...surgery];
    surg[index][key] = val.label;

    setSurgery(surg);
  };
  const addSurgeries = (type) => {
    setSurgery((i) => [
      ...i,
      {
        Name_of_surgery: "",
        when_did_you_have_the_surgery_month: "",
        when_did_you_have_the_surgery_year: "",
        Add_description: "",
      },
    ]);
  };
  const handleInjuryChange = (e) => {
    const data = [...injury];
    data[e.target.dataset.id][e.target.name] = e.target.value;

    setInjury(data);
  };
  const handleInjuryDropChange = (index, key, val) => {
    const data = [...injury];
    data[index][key] = val.label;

    setInjury(data);
  };
  const addInjury = () => {
    setInjury((i) => [
      ...i,
      {
        Name_of_injury: "",
        how_long_ago_did_the_injury_occur_month: "",
        how_long_ago_did_the_injury_occur_year: "",
        Add_description: "",
      },
    ]);
  };
  const handleMedicationChange = (e) => {
    const med = [...medication];
    med[e.target.dataset.id][e.target.name] = e.target.value;

    setMedication(med);
  };
  const handleMedicitaionDropChange = (index, key, val) => {
    const data = [...medication];
    data[index][key] = val.label;

    setMedication(data);
  };
  const addMedication = () => {
    setMedication((i) => [
      ...i,
      {
        Drug: "",
        Drug_strength: "",
        Frequency_per_day: "",
        how_long_have_you_been_taking_this_medications: "",
        drugtxt: "Mg",
        drugtxt1: "Daily",
        drugtxt2: "Days",
      },
    ]);
  };

  const deleteMed = (index, type) => {
    if (type === "med") {
      if (medication.length > 1) {
        const delMed = [...medication];
        delMed.splice(index, 1);
        setMedication(delMed);
      } else {
        setForm3(false);
      }
    } else if (type === "surg") {
      if (surgery.length > 1) {
        const delsurg = [...surgery];
        delsurg.splice(index, 1);
        setSurgery(delsurg);
      } else {
        setForm1(false);
      }
    } else if (type === "inj") {
      if (injury.length > 1) {
        const delinj = [...injury];
        delinj.splice(index, 1);
        setInjury(delinj);
      } else {
        setForm2(false);
      }
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (history.location.state === "addRelative") {
      dispatch(getPatients());
    }
  }, [history.location]);



  const PageChangeMed = () => {
    handleSubmit();
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  };

  const PageChangeSocial = () => {
    handleSubmit();
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
  };

  const PageChangefinal = () => {
    handleSubmit();
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );

    if (finalSub) {
      history.goBack();
    }
  };
  const handleotherallergy = (e) => {
    let value = e.target.value;
    let res = value.charAt(0).toUpperCase() + value.slice(1);
    if (AllergiesList.includes(res)) {
      setErrstate(true)
      setOtherAllergie("")
    }
    else {
      setOtherAllergie(e.target.value)
      setErrstate(false)
    }

  }
  const handleChronicallergy = (e) => {
    let value = e.target.value;
    let res = value.charAt(0).toUpperCase() + value.slice(1);
    if (IllnessesList.includes(res)) {
      setChronicerr(true)
      setOtherIll("")
    }
    else {
      setOtherIll(e.target.value)
      setChronicerr(false)
    }
  }
  const handleSocialerr = (e) => {
    let value = e.target.value;
    let res = value.charAt(0).toUpperCase() + value.slice(1);
    if (SocialMedia.includes(res)) {
      setSocialmediaerr(true)
      setOtherSocial("")
    }
    else {
      setOtherSocial(e.target.value)
      setSocialmediaerr(false)
    }
  }
  const handleDieterr = (e) => {
    let value = e.target.value;
    let res = value.charAt(0).toUpperCase() + value.slice(1);
    if (Dieting.includes(res)) {
      setDieterr(true)
      setOtherDiet("")
    }
    else {
      setOtherDiet(e.target.value)
      setDieterr(false)
    }
  }
  const handleMaritalerr = (e) => {
    let value = e.target.value;
    let res = value.charAt(0).toUpperCase() + value.slice(1);
    if (res == "Married" || res == "Single") {
      setMaritalerr(true);
      setothermarital("");
    }
    else {
      setothermarital(e.target.value);
      setMaritalerr(false);
    }
  }
  const handleSubmit = async () => {
    // e.preventDefault()
    const Formdata = {
      first_name: firstName.trim(),
      middle_name: middleName,
      last_name: lastName.trim(),
      dob: moment(date).format("YYYY-MM-DD"),
      gender: gender.toLowerCase(),
      marital_status: maritalStatus == "Others" ? othermarital : maritalStatus,
      mobile: phone,
      email: email,
      full_name:
        firstName +
        " " +
        (middleName !== "" ? middleName + " " : "") +
        lastName,
      other_mobile: otherPhone,
      secondary_email: secondEmail,
      languagues: [],
      residential_Address: [
        {
          door_no: houseNumber.trim(),
          street: street_Name.trim(),
          area: area.trim(),
          city: city.value,
          state: states && states.value,
        },
      ],
      height: [
        {
          value:
            enableFormHeightCm === true
              ? heigth_Cms
              : heigth_Ft + " " + heigth_In,
          unit: enableFormHeightCm === true ? "centimeters" : "feet Inch",
        },
      ],
      weight: totalWeights,
      choiceDoctor: "",
      blood_group: bloodGroup,
      // "relationship": "family",
      personalQuestions: [
        {
          question: "What Occupational description is most relevant to you?",
          answer: occupation,
        },
        {
          question: "What Sector do you work in?",
          answer: sector,
        },
        {
          question:
            "What medical or health related information would be of most interest to you? ",
          answer: medInterest,
        },
      ],
      medicalQuestions: [
        {
          question: "Select the substance for which you have allergies",
          answer:
            allergies.length !== 0
              ? allergies.map((i) =>
                i === "Others [Please-Specify]" ? otherAllergie : i
              )
              : [],
        },
        {
          question: "Do you suffer from any chronic illnesses?",
          answer:
            Illnesses.length !== 0
              ? Illnesses.map((i) =>
                i === "Others [Please-Specify]" ? otherIll : i
              )
              : [],
        },
        {
          question:
            "Would you like to receive disease specific medical information on what you’ve chosen above?",
          answer: specificMed,
        },
        {
          question: "Have you had any surgeries in the past?",
          answer:
            form1 === true
              ? JSON.stringify(
                surgery.map((e) => {
                  return {
                    Name_of_surgery: e.Name_of_surgery,
                    when_did_you_have_the_surgery_month:
                      e.when_did_you_have_the_surgery_month,
                    when_did_you_have_the_surgery_year:
                      e.when_did_you_have_the_surgery_year,
                    Add_description: e.Add_description,
                  };
                })
              )
              : "No",
        },
        {
          question: "Have you had any serious injuries in the past?",
          answer:
            form2 === true
              ? JSON.stringify(
                injury.map((e) => {
                  return {
                    Name_of_injury: e.Name_of_injury,
                    how_long_ago_did_the_injury_occur_month:
                      e.how_long_ago_did_the_injury_occur_month,
                    how_long_ago_did_the_injury_occur_year:
                      e.how_long_ago_did_the_injury_occur_year,
                    Add_description: e.Add_description,
                  };
                })
              )
              : "No",
        },
        {
          question: "Are you currently using any prescription medication?",
          answer:
            form3 === true
              ? JSON.stringify(
                medication.map((e) => {
                  return {
                    Drug: e.Drug,
                    Drug_strength: e.Drug_strength,
                    Frequency_per_day: e.Frequency_per_day,
                    how_long_have_you_been_taking_this_medications:
                      e.how_long_have_you_been_taking_this_medications,
                  };
                })
              )
              : "No",
        },
      ],
      lifestyleQuestions: [
        {
          question: "How frequently do you consume alcohol?",
          answer: alcohol,
        },
        {
          question: "How many cigarettes do you smoke per day?",
          answer: smoking,
        },
        {
          question: "How physically active is your lifestyle?",
          answer: lifestyle,
        },
        {
          question: "What does your diet mainly consist of?",
          answer: diet === "Other Any Diet" ? otherDiet : diet,
        },
        {
          question:
            "On average, how many apps do you use on your phone every day",
          answer: mobileApps,
        },
        {
          question:
            "On which social media channels are you most active (Please check all boxes that apply)",
          answer:
            socialMedia.length !== 0
              ? socialMedia.map((i) =>
                i === "Others Social [Please Specify]" ? otherSocial : i
              )
              : [],
        },
      ],
    };

    if (firstName == undefined || firstName.trim() == "") {
      toast.info("firstName is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (lastName == undefined || lastName.trim() == "") {
      toast.info("lastName is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (phone == undefined || phone.trim() == "") {
      toast.info("phone is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (email == undefined || email.trim() == "") {
      toast.info("email is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (!email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)) {
      toast.info("Please Enter a Valid Email", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (
      secondEmail !== "" &&
      !secondEmail.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      toast.info("Please Enter a Valid Secondary Email", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (gender == undefined || gender.trim() == "") {
      toast.info("gender is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (date == undefined || date == "") {
      toast.info("Date of birth is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (weightDate == undefined || weightDate == "") {
      toast.info("weightDate is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (maritalStatus == undefined || maritalStatus === "") {
      toast.info("maritalStatus is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (houseNumber == undefined || houseNumber.trim() == "") {
      toast.info("House name/number is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (street_Name == undefined || street_Name.trim() == "") {
      toast.info("Street name is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (area == undefined || area.trim() == "") {
      toast.info("Area/Village/Town  is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (states && states.value == undefined || states && states.value == "") {
      toast.info("state  is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (city.value == undefined || city.value == "") {
      toast.info("City  is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (bmiCalc === "" || bmiCalc === undefined) {
      toast.info("click  add weight button", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (totalWeights === undefined || totalWeights.length === 0) {
      toast.info("click  add weight button", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (enableFormHeightCm === false && enableFormHeightIn === false) {
      toast.info("height required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (
      (heigth_Cms == undefined || heigth_Cms == "") &&
      enableFormHeightCm === true
    ) {
      toast.info("height  is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (
      (heigth_Ft == undefined || heigth_Ft == "") &&
      enableFormHeightIn === true
    ) {
      toast.info("height  is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (weights == undefined || weights.length == 0) {
      toast.info("weight  is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (occupation === undefined || occupation === "") {
      // console.log(occupation)
      toast.info("Occupational description  is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (sector == undefined || sector == "") {
      toast.info("sector is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (medInterest == undefined || medInterest.length == 0) {
      toast.info("Medical or Health related information is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    } else if (bloodGroup == undefined || bloodGroup == "") {
      toast.info("bloodGroup is required", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    }
    if (agreeAppointment === false || agreeHealth === false) {
      toast.info("Accept the terms and condition", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        progress: undefined,
      });
      return false;
    }

    if (value !== 0) {
      if (allergies == undefined || allergies.length == 0) {
        toast.info("Please fill all the medical details is required", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          progress: undefined,
        });
        return false;
      } else if (Illnesses == undefined || Illnesses.length == 0) {
        toast.info("Please fill all the medical details is required", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          progress: undefined,
        });
        return false;
      } else if (specificMed == undefined || specificMed == "") {
        toast.info("Please fill all the medical details is required", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          progress: undefined,
        });
        return false;
      } else if (form1 === true) {
        let validationSurg = surgery.every(
          (item) =>
            item.Name_of_surgery &&
            item.Add_description &&
            item.when_did_you_have_the_surgery_month &&
            item.when_did_you_have_the_surgery_year
        );
        if (surgery == undefined || surgery == "" || !validationSurg) {
          toast.info("Please fill all the medical details is required", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
          });
          return false;
        }
      } else if (form2 === true) {
        let validateInj = injury.every(
          (item) =>
            item.Name_of_injury &&
            item.Add_description &&
            item.how_long_ago_did_the_injury_occur_month &&
            item.how_long_ago_did_the_injury_occur_year
        );
        if (injury == undefined || injury == "" || validateInj === false) {
          toast.info("Please fill all the medical details is required", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
          });
          return false;
        }
      } else if (form3 === true) {
        let validateMed = medication.every(
          (item) =>
            item.Drug &&
            item.Drug_strength &&
            item.Frequency_per_day &&
            item.how_long_have_you_been_taking_this_medications
        );
        if (medication == undefined || medication == "" || !validateMed) {
          toast.info("Please fill all the medical details is required", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
          });
          return false;
        }
      } else {
        setFinalSub(true);
      }
    }

    // const weightD = moment(weightDate).utc().format();
    // let weight_data = {
    //   value: weights,
    //   unit: "kg",
    //   date: weightD,
    //   bmi: bmiCalc,
    // };
    // setTotalWeights([...totalWeights, weight_data]);

    if (type === "addProfile" && value === 0 && relative === false) {
      await axios
        .post(api.patientdoctall, Formdata, {
          headers: { "x-auth-token": userdata.token },
        })
        .then(
          (res) => (
            setUuid(res.data.uuid),
            value === 2 ? setValue(0) : setValue(value + 1),
            SetRelative(true),
            dispatch(getPatients()),
            loadingState === true
              ? setLoadingState(false)
              : setLoadingState(true),
            axios
              .get(api.profileupdate + res.data.uuid, {
                headers: { "x-auth-token": userdata.token },
              })
              .then((i) => {
                if (i.data && i.data.percentage) {
                  setProgressNum(i.data.percentage);
                }
              }),
            toast.success("Added Relative successfully", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              progress: undefined,
            })
          )
        )
        .catch((err) => {
          console.log(err);
          toast.error("Email Already Exists", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
          });
        });
    } else {
      await axios
        .put(api.patientdoctall + "/" + uuid, Formdata, {
          headers: { "x-auth-token": userdata.token },
        })
        .then(
          (res) => (
            setUuid(res.data.uuid),
            console.log(Formdata),
            value === 2 ? setValue(0) : setValue(value + 1),
            axios
              .get(api.profileupdate + res.data.uuid, {
                headers: { "x-auth-token": userdata.token },
              })
              .then((i) => {
                if (i.data && i.data.percentage) {
                  setProgressNum(i.data.percentage);
                }
              }),
            toast.success("Profile Updated successfully", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              progress: undefined,
            })
          )
        )
        .catch((err) => {
          console.log(err);
          toast.error("Email Already Exists", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            progress: undefined,
          });
        });

      // Patch to update names
      await axios
        .patch(api.patient + "/" + uuid, Formdata, {
          headers: { "x-auth-token": userdata.token },
        })
        .then(
          (res) => (
            //to get updated values
            dispatch(getUserDetails())
          )
        )
        .catch((err) => {
          console.log(err);

        });

    }
  };
  const addSocialMedia = (e) => {
    if (e.indexOf("Others Social [Please Specify]") == -1) {
      e = e.filter((item) => item != otherSocial);
    }
    setSocialMedia(e);
    setUpdate(!update);
  };

  return (
    <div>
      <Helmet>
      <title>Health Profile - Doctall Consumer Web</title>
<meta 
            name='description' 
            content='View your health profile on Doctall. See your personal, medical and health lifestyle information. View at a glance and update when you need to.
            '
            />
    </Helmet>
      {/* toast start */}
      <Toast
        text={"Height And Weight is Required"}
        type="error"
        open={toastHeight}
        setClose={() => {
          setToastHeight(false);
        }}
      />
      {/* toast end */}

      {/* popup start */}
      <Dialog
        open={openBmi}
        onClose={() => {
          setOpenBmi(false);
        }}
      >
        <div className="bmiContainerPopup">
          <img
            crossorigin="anonymous"
            src={Close}
            alt="icon"
            onClick={() => setOpenBmi(false)}
          />
          <div className="headBmi">Weight History</div>

          {totalWeights.length !== 0 && totalWeights[0] !== undefined ? (
            <>
              {totalWeights.map((i) => (
                <>
                  <div className="bmiPopupbox">
                    <div className="childWeight">
                      <div className="heading">Weight</div>
                      <div className="value">
                        {i.value}
                        <span>{i.unit}</span>
                      </div>
                    </div>
                    <div className="childWeight">
                      <div className="heading">BMI</div>
                      <div className="value">
                        {i.bmi}
                        <span>bmi</span>
                      </div>
                    </div>
                    <div className="childWeight">
                      <div className="heading">Date</div>
                      <div className="value">
                        {moment(i.date).format("MM/DD/YYYY")}
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </>
          ) : (
            "No Records Found"
          )}
        </div>
      </Dialog>
      {/* popup end */}
      {/* tab panel */}


      <div className="tabs_conatiner">

      
      <Tabs
        style={{ backgroundColor: "#FFFFFF" }}
        value={value}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="#00839B"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
      >
        <Tab
          style={{
            color: "#00839B",
            fontSize: "16px",
            fontFamily: "VisueltMedium",
            backgroundColor: value === 0 ? "#F2F8F9" : "#FFFFFF",
          }}
          label="Personal"
          {...a11yProps(0)}
        />
        <Tab
          style={{
            color: "#00839B",
            fontSize: "16px",
            fontFamily: "VisueltMedium",
            backgroundColor: value === 1 ? "#F2F8F9" : "#FFFFFF",
          }}
          label="Medical"
          {...a11yProps(0)}
        />
        <Tab
          style={{
            color: "#00839B",
            fontSize: "16px",
            fontFamily: "VisueltMedium",
            backgroundColor: value === 2 ? "#F2F8F9" : "#FFFFFF",
          }}
          label="Lifestyle"
          {...a11yProps(0)}
        />
      </Tabs>
</div>
      <form onSubmit={handleSubmit}>
        <TabPanel value={value} index={0}>
          <CardForm head={"Personal Information"}>
            {/* sec1 */}
            <div className="formSection">
              <div className="innerFormSec">
                <label className="formName" htmlFor="FirstName">
                  Firstname*
                </label>
                <input
                  className="inputsForm"
                  type="text"
                  placeholder="first Name"
                  // readOnly={type === "owner"}
                  value={firstName}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^A-Za-z]/gi, "");
                    setFirstName(value)
                  }}
                  name="firstName"
                />
              </div>
              <div className="innerFormSec">
                <label className="formName" htmlFor="MiddleName">
                  Middlename
                </label>
                <input
                  className="inputsForm"
                  type="text"
                  placeholder="middle Name"
                  // readOnly={type === "owner"}
                  value={middleName}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^A-Za-z]/gi, "");
                    setMiddleName(value)
                  }}
                  name="MiddleName"
                />
              </div>
              <div className="innerFormSec">
                <label className="formName" htmlFor="LastName">
                  LastName*
                </label>
                <input
                  className="inputsForm"
                  type="text"
                  placeholder="last Name"
                  value={lastName}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^A-Za-z]/gi, "");
                    setLastName(value)
                  }}
                  // readOnly={type === "owner"}
                  name="LastName"
                />
              </div>
            </div>
            {/* sec2 */}
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="Firstname">
                  Date Of Birth*
                </label>
                <DatePicker
                  onChange={setDate}
                  value={date}
                  maxDate={moment().subtract(1, "day").toDate()}
                />
              </div>
              <div className="innerFormSec">
                <label className="formName" htmlFor="Firstname">
                  Gender*
                </label>
                <div className="radioSection">
                  <RadioBtn
                    name={"Gender"}
                    value={"Male"}
                    checking={gender === "Male"}
                    onChange={(e) => setGender(e.target.value)}
                    img={require("../../assets/images/radio1.svg").default}
                    imgAct={
                      require("../../assets/images/radio1act.svg").default
                    }
                  />
                  <RadioBtn
                    name={"Gender"}
                    value={"Female"}
                    checking={gender === "Female"}
                    onChange={(e) => setGender(e.target.value)}
                    // onBlur={(e)=>{ gender===""?setTouch(true):setTouch(false) }}
                    img={require("../../assets/images/radio1.svg").default}
                    imgAct={
                      require("../../assets/images/radio1act.svg").default
                    }
                  />
                  <RadioBtn
                    name={"Gender"}
                    onChange={(e) => setGender(e.target.value)}
                    checking={gender === "Rather not say"}
                    value={"Rather not say"}
                    // onBlur={(e)=>{ gender===""?setTouch(true):setTouch(false) }}
                    img={require("../../assets/images/radio1.svg").default}
                    imgAct={
                      require("../../assets/images/radio1act.svg").default
                    }
                  />
                </div>
              </div>
            </div>
            {/* sec3 */}
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="Firstname">
                  Marital Status*
                </label>
                <div className="radioSection2">
                  <RadioBtn
                    name={"MaritalStatus"}
                    value={"Single"}
                    click={() => { }}
                    onChange={(e) => setMaritalStatus(e.target.value)}
                    checking={maritalStatus === "Single"}
                    img={require("../../assets/images/radio1.svg").default}
                    imgAct={
                      require("../../assets/images/radio1act.svg").default
                    }
                  />
                  <RadioBtn
                    name={"MaritalStatus"}
                    value={"Married"}
                    click={() => { }}
                    checking={maritalStatus === "Married"}
                    onChange={(e) => setMaritalStatus(e.target.value)}
                    img={require("../../assets/images/radio1.svg").default}
                    imgAct={
                      require("../../assets/images/radio1act.svg").default
                    }
                  />
                  <RadioBtn
                    name={"MaritalStatus"}
                    onChange={(e) => setMaritalStatus("Others")}
                    click={() => { }}
                    value={"Other"}
                    checking={maritalStatus === "Others"}
                    img={require("../../assets/images/radio1.svg").default}
                    imgAct={
                      require("../../assets/images/radio1act.svg").default
                    }
                  />
                  {maritalStatus === "Others" ? (
                    <>
                      <input
                        className="inputsForm"
                        type="text"
                        name="othermarital"
                        onChange={(e) => handleMaritalerr(e)}
                        placeholder="others"
                        value={othermarital}
                      />
                      {
                        maritalerr ?
                          <div className="error1">Please type value that not matches with option</div> : ""
                      }
                    </>
                  ) : (
                    <input
                      className="inputsForm"
                      type="text"
                      disabled
                      placeholder="others"
                      style={{ cursor: "not-allowed" }}
                    />
                  )}
                </div>
                {/* {errors.MaritalStatus ?
                  <div className='error1'>{errors.MaritalStatus}</div> : null} */}
              </div>
              <div className="innerFormSec">
                <label className="formName" htmlFor="Firstname">
                  Height*
                </label>
                <div className="radioSection3">
                  <div
                    style={{
                      width: "80%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <RadioBtn
                      name={"Height"}
                      onChange={(e) => setHeight(e.target.value)}
                      click={() => {
                        setEnableFormHeightCm(true);
                        setEnableFormHeightIn(false);
                      }}
                      checking={enableFormHeightCm === true}
                      value={"Centimeters"}
                      img={require("../../assets/images/radio1.svg").default}
                      imgAct={
                        require("../../assets/images/radio1act.svg").default
                      }
                    />
                    <RadioBtn
                      name={"Height"}
                      value={"Feet / Inches"}
                      onChange={(e) => setHeight(e.target.value)}
                      checking={enableFormHeightIn === true}
                      click={() => {
                        setEnableFormHeightCm(false);
                        setEnableFormHeightIn(true);
                      }}
                      img={require("../../assets/images/radio1.svg").default}
                      imgAct={
                        require("../../assets/images/radio1act.svg").default
                      }
                    />
                  </div>
                  {enableFormHeightCm === true || height === "Centimeters" ? (
                    <input
                      className="inputsForm"
                      type="number"
                      name="heigth_Cms"
                      placeholder="000CM"
                      maxLength={3}
                      min={0}
                      onKeyDown={(e) => {
                        invalidChar(e);
                      }}
                      onChange={(e) => handleWeightsAndHeights("heightCm", e)}
                      value={heigth_Cms}
                    />
                  ) : (
                    <input
                      className="inputsForm"
                      type="number"
                      name="HeightCm"
                      placeholder="000CM"
                      value={heigth_Cms}
                      disabled
                      style={{ cursor: "not-allowed" }}
                    />
                  )}
                  {enableFormHeightIn === true || height === "Feet / Inches" ? (
                    <>
                      <input
                        style={{ left: "34%" }}
                        className="inputsForm"
                        type="number"
                        name="heigth_Ft"
                        placeholder="feet"
                        maxLength={2}
                        min={0}
                        onKeyDown={(e) => {
                          invalidChar(e);
                        }}
                        onChange={(e) => handleWeightsAndHeights("heightFt", e)}
                        value={heigth_Ft}
                      />
                      <input
                        style={{ left: "64%" }}
                        className="inputsForm"
                        type="text"
                        name="number"
                        placeholder="inch"
                        maxLength={2}
                        min={0}
                        onKeyDown={(e) => {
                          invalidChar(e);
                        }}
                        onChange={(e) => handleWeightsAndHeights("heightIn", e)}
                        value={heigth_In}
                      />
                      {/* {heigth_Ft==="" ?
                      <div className='error1' style={{ marginTop: '55px' }}>Height Feet Required</div> : null}
                      {heigth_In==="" ?
                      <div className='error1' style={{ marginTop: '55px' }}>Height Inches Required</div> : null} */}
                    </>
                  ) : (
                    <>
                      <input
                        style={{ left: "34%", cursor: "not-allowed" }}
                        disabled
                        className="inputsForm"
                        value={heigth_Ft}
                        type="text"
                        placeholder="feet"
                      />

                      <input
                        style={{ left: "64%", cursor: "not-allowed" }}
                        disabled
                        className="inputsForm"
                        value={heigth_In}
                        type="text"
                        placeholder="inch"
                      />
                    </>
                  )}
                </div>
                {/* {height==="" ?
                  <div className='error1'> Height Required </div> : null} */}
              </div>
            </div>
            {/* sec4 */}
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="Firstname">
                  Please select your blood group*
                </label>
                <div className="radioSection4">
                  {BloodGroups.map((i) => (
                    <RadioBtn
                      name={"bloodGroup"}
                      value={i}
                      checking={bloodGroup === i}
                      onChange={(e) => setBloodGroup(e.target.value)}
                      img={require("../../assets/images/radio1.svg").default}
                      imgAct={
                        require("../../assets/images/radio1act.svg").default
                      }
                    />
                  ))}
                </div>
                {/* {bloodGroup==="" ?
                  <div className='error1'>{"bloodGroup Required"}</div> : null} */}
              </div>
            </div>
          </CardForm>
          <CardForm head={"Weight"}>
            {/* sec1 */}
            <div className="formSection3">
              <div className="bmiCard">
                <div className="formName">Last Reading</div>
                <div className="bmiInfo">
                  <div className="bmiSeperator">
                    <p>
                      Weight: <span>{weights + "kg"}</span>{" "}
                    </p>
                    <p>
                      Date:{" "}
                      <span> {moment(weightDate).format("MM/DD/YYYY")}</span>{" "}
                    </p>
                    <p>
                      BMI:<span> {bmiCalc + "BMI"} </span>{" "}
                    </p>
                  </div>
                  <input
                    className="bmiHistory"
                    type="button"
                    value="View History"
                    onClick={viewHistory}
                  />
                </div>
              </div>
            </div>
            {/* sec2 */}
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="Weight">
                  Weight*
                </label>
                <input
                  className="inputsForm"
                  type="number"
                  value={weights}
                  placeholder="00 Kg"
                  maxLength={3}
                  min={0}
                  onKeyDown={(e) => {
                    invalidChar(e);
                  }}
                  onChange={(e) => handleWeightsAndHeights("weight", e)}
                  name="Weight"
                />
                {/* {weights==="" ?
                  <div className='error1'>weight Required</div> : null} */}
              </div>
              <div className="innerFormSec">
                <label className="formName" htmlFor="Firstname">
                  Date*
                </label>
                <DatePicker
                  onChange={setWeightDate}
                  value={weightDate}
                  maxDate={new Date()}
                />
              </div>
              <div className="innerFormSec">
                <label className="formName" htmlFor="bmi">
                  Body Mass Index (BMI)
                </label>
                <input
                  className="inputsForm"
                  type="text"
                  value={bmiCalc}
                  // onChange={e => setBmiCalc(e.target.value)}
                  name="bmi"
                  placeholder="00.0"
                  readOnly
                />
                {/* {errors.bmi ?
                     <div className='error1'>{errors.bmi}</div> : null} */}
              </div>
            </div>
            {/* sec3 */}
            <div className="formSection2">
              <div className="innerFormSec">
                <p className="infoBmiPara">
                  Your BMI is calculated using your height and weight
                </p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://en.wikipedia.org/wiki/Body_mass_index"
                >
                  {" "}
                  <p className="infoBmiLink">Learn more about BMI</p>
                </a>
              </div>

              <input
                type="button"
                className="addWeight"
                value="Add Weight"
                onClick={() => {
                  BmiFunction();
                }}
              />
            </div>
          </CardForm>
          <CardForm head={"Contact Information"} type={type}>
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="Phone Number">
                  Phone Number*
                </label>
                <PhoneInput
                  className="inputPhone"
                  country="ng"
                  value={phone}
                  disabled={type == "owner"}
                  onChange={setPhone}
                />
              </div>
              <div className="innerFormSec">
                <label className="formName" htmlFor="Other Phone Number">
                  Other Phone Number
                </label>
                <PhoneInput
                  className="inputPhone"
                  // country={ocode}
                  country="ng"
                  value={otherPhone}
                  onChange={setOtherPhone}
                />
              </div>
            </div>
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="Email Address">
                  Email Address*
                </label>
                <input
                  className="inputsForm"
                  type="email"
                  placeholder="Email Address"
                  disabled={type == "owner"}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  name="Email"
                />
                {/* {email==="" ?
                  <div className='error1'>Email Required</div> : null} */}
              </div>
            </div>
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="Secondary Email Address">
                  Secondary Email Address
                </label>
                <input
                  className="inputsForm"
                  type="email"
                  placeholder="Secondary Email Address"
                  value={secondEmail}
                  onChange={(e) => setSecondEmail(e.target.value)}
                  name="SecondEmail"
                />
              </div>
            </div>
            <div className="formSection" style={{ marginTop: "30px" }}>
              <label className="formName" htmlFor="Address">
                Address*
              </label>
              <div className="form3div">
                <input
                  className="inputsForm"
                  type="text"
                  placeholder="House Number/Name"
                  value={houseNumber}
                  onChange={(e) => setHouseNumber(e.target.value)}
                  name="HouseNumber"
                />
                <input
                  className="inputsForm"
                  type="text"
                  placeholder="Street Name"
                  value={street_Name}
                  onChange={(e) => setStreet_Name(e.target.value)}
                  // placeholder="Street Name"
                  name="Street_Name"
                />

                <input
                  className="inputsForm"
                  type="text"
                  placeholder="Area/VillageTown"
                  value={area}
                  onChange={(e) => setArea(e.target.value)}
                  name="Area"
                />
              </div>
              {/* {houseNumber===""?
                  <div className='error1'>House Number Required</div> : null}
                  {street_Name==="" ?
                  <div className='error1'>Street Required</div> : null}
                  {area ==="" ?
                  <div className='error1'>Area Required</div> : null} */}
            </div>
            <div className="formSection2">
              {statelist && (
                <div className="innerFormSec">
                  <label className="formName" htmlFor="City">
                    State*
                  </label>
                  <Dropdown
                    className="dropdown"
                    menuClassName="dropdownMenu"
                    controlClassName="myControlClassName"
                    arrowClassName="myArrowClassName"
                    arrowClosed={<img crossorigin="anonymous" src={imgArrow} />}
                    arrowOpen={<img crossorigin="anonymous" src={imgArrow} />}
                    placeholder="state"
                    onChange={(value) => setStates(value)}
                    value={states && states.label}
                    options={statelist}
                  />
                </div>
              )}
              {citylist && (
                <div className="innerFormSec">
                  <label className="formName" htmlFor="State">
                    City*
                  </label>
                  <Dropdown
                    className="dropdown"
                    menuClassName="dropdownMenu"
                    controlClassName="myControlClassName"
                    arrowClassName="myArrowClassName"
                    arrowClosed={<img crossorigin="anonymous" src={imgArrow} />}
                    arrowOpen={<img crossorigin="anonymous" src={imgArrow} />}
                    placeholder="city"
                    onChange={(e) => setCity(e)}
                    value={city && city.label && city.label}
                    options={citylist.filter((i) => i.state === states && states.value)}
                  />
                </div>
              )}
            </div>
          </CardForm>
          <CardForm head={"Other Information"}>
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="Occupation">
                  What occupational description is most relevant to you?*
                </label>
                <Dropdown
                  className="dropdown"
                  menuClassName="dropdownMenu"
                  controlClassName="myControlClassName"
                  arrowClassName="myArrowClassName"
                  arrowClosed={
                    <img crossorigin="anonymous" src={imgArrow} alt="icon" />
                  }
                  arrowOpen={
                    <img crossorigin="anonymous" src={imgArrow} alt="icon" />
                  }
                  placeholder={"Occupation"}
                  value={occupation}
                  onChange={(e) => setOccupation(e)}
                  options={OccupationList}
                />
                {/* {occupation==='' ?
                  <div className='error1'>Please Select the occupation</div> : null} */}
              </div>
            </div>
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="sector">
                  What sector do you work in?*
                </label>
                <Dropdown
                  className="dropdown"
                  menuClassName="dropdownMenu"
                  controlClassName="myControlClassName"
                  arrowClassName="myArrowClassName"
                  arrowClosed={
                    <img crossorigin="anonymous" src={imgArrow} alt="icon" />
                  }
                  arrowOpen={
                    <img crossorigin="anonymous" src={imgArrow} alt="icon" />
                  }
                  placeholder="sector"
                  onChange={(e) => setSector(e)}
                  value={sector}
                  options={SectorList}
                />
                {/* {sector==='' ?
                  <div className='error1'>Please Select the sector</div> : null} */}
              </div>
            </div>
            <div className="formSection2">
              <div className="innerFormSec" style={{ paddingBottom: "6%" }}>
                <label className="formName" htmlFor="sector">
                  What medical or health related information would be of most
                  interest to you?*
                </label>
                <div className="radioSection6" style={{ padding: "0" }}>
                  <CheckBtn
                    data={MedicialInterest}
                    name="medicialInterest"
                    onChange={(e) => {
                      setMedInterest(e);
                      setUpdate(!update);
                    }
                    }
                    defaultValue={medInterest}

                  />
                </div>
              </div>
            </div>
          </CardForm>
          <div className="cardFoot">
            <div className="cardProfile"></div>
            <div className="cardForm">
              <div className="formSectio2" style={{ marginTop: "0" }}>
                {/* {errors.agreeHealth || errors.agreeAppointment  ?
                      <div className='error1'>Accept our terms and conditions</div> : null} */}
                {/* {console.log(agreeHealth)} */}
                <div className="radioSection5">
                  <CheckBtn2
                    name={"agree"}
                    value={
                      "I agree to receive important personal health related information and communication from Doctall and Doctall’s Doctors"
                    }
                    onClick={() => {
                      agreeHealth === true
                        ? setAgreeHealth(false)
                        : setAgreeHealth(true);
                    }}
                    checked={agreeHealth === true}
                    img={require("../../assets/images/check.svg").default}
                    imgAct={require("../../assets/images/checkact.svg").default}
                  />
                  <CheckBtn2
                    name={"doctorconsent"}
                    value={
                      "I give the Doctor consent to see my Health Profile during my appointment and / or treatment"
                    }
                    onClick={() => {
                      agreeAppointment === true
                        ? setAgreeAppointment(false)
                        : setAgreeAppointment(true);
                    }}
                    checked={agreeAppointment === true}
                    img={require("../../assets/images/check.svg").default}
                    imgAct={require("../../assets/images/checkact.svg").default}
                  />
                  <input
                    type="button"
                    style={{ marginTop: "60px" }}
                    className="profileUpdate"
                    onClick={() => {
                      PageChangeMed();
                    }}
                    value="Update Profile"
                  />
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <CardForm head={"Allergies"}>
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="Firstname">
                  Do you suffer from any allergies?*
                </label>
                <div className="radioSection6">
                  <CheckBtn
                    data={AllergiesList}
                    name="Allergies"
                    onChange={(e) => {
                      setAllergies(e);
                      setUpdate(!update);
                    }}
                    defaultValue={allergies}
                  />
                </div>
                <div
                  className="radioSection6"
                  style={{ flexDirection: "column" }}
                >
                  {allergies.indexOf("Others [Please-Specify]") > -1 ? (
                    <>
                      <input
                        type="text"
                        className="inputsForm"
                        placeholder="Please type here"
                        onChange={(e) => handleotherallergy(e)}
                        name="otherAllergie"
                        value={otherAllergie}
                      />
                      {otherAllergie === "" ? (
                        <div className="error1">Required</div>
                      ) : null}
                      {errstate ?
                        <div className="error1">Please type value that not matches with option</div>
                        : ""}
                    </>
                  ) : (
                    <input type="text" className="inputsForm" disabled />
                  )}
                </div>
              </div>
            </div>
          </CardForm>
          <CardForm head={"Chronic Illnesses"}>
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="Firstname">
                  Do you suffer from any chronic illnesses?*
                </label>
                <div className="radioSection6">
                  <CheckBtn
                    data={IllnessesList}
                    name="Illnesses"
                    onChange={(e) => {
                      setIllnesses(e);
                      setUpdate(!update);
                    }}
                    defaultValue={Illnesses}
                  />
                </div>
                {/* {Illnesses.length===0 ?
                      <div className='error1'>select any one or none</div> : null} */}
                <div
                  className="radioSection6"
                  style={{ flexDirection: "column" }}
                >
                  {Illnesses.indexOf("Others [Please-Specify]") > -1 ? (
                    <>
                      <input
                        type="text"
                        className="inputsForm"
                        placeholder="Please type here"
                        onChange={(e) => handleChronicallergy(e)}
                        name="otherIll"
                        value={otherIll}
                      />
                      {otherIll === "" ? (
                        <div className="error1">Required</div>
                      ) : null}
                      {
                        chronicerr ?
                          <div className="error1">Please type value that not matches with option</div>
                          : ""
                      }
                    </>
                  ) : (
                    <input type="text" className="inputsForm" disabled />
                  )}
                </div>
              </div>
            </div>
          </CardForm>
          <div className="medicineChoice">
            <div className="cardProfile1"></div>
            <div className="cardForm">
              <div className="formSection2">
                <div className="innerFormSec">
                  <label className="formName" htmlFor="illnessess">
                    Would you like to receive disease specific medical
                    information on the above selected chronic illnesses?
                  </label>
                  <div className="radioSection6">
                    <RadioBtn
                      name={"specificMed"}
                      value={"Yes"}
                      onChange={(e) => setSpecificMed(e.target.value)}
                      checking={"Yes" === specificMed}
                      img={require("../../assets/images/radio1.svg").default}
                      imgAct={
                        require("../../assets/images/radio1act.svg").default
                      }
                    />
                    <RadioBtn
                      name={"specificMed"}
                      value={"No"}
                      onChange={(e) => setSpecificMed(e.target.value)}
                      checking={"No" === specificMed}
                      img={require("../../assets/images/radio1.svg").default}
                      imgAct={
                        require("../../assets/images/radio1act.svg").default
                      }
                    />
                  </div>
                  {/* {specificMed.length===0 ?
                  <div className='error1'>Required</div> : null} */}
                </div>
              </div>
            </div>
          </div>
          <CardForm head={"Surgeries"}>
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="illnessess">
                  Have you had any surgeries in the past? *
                </label>
                <div className="radioSection6">
                  <RadioBtn
                    name={"surgeries"}
                    value={"Yes [Please Specify]"}
                    click={(e) => {
                      setForm1(true);
                    }}
                    checking={form1 === true}
                    img={require("../../assets/images/radio1.svg").default}
                    imgAct={
                      require("../../assets/images/radio1act.svg").default
                    }
                  />
                  <RadioBtn
                    name={"surgeries"}
                    value={" No"}
                    click={(e) => {
                      setForm1(false);
                    }}
                    checking={form1 === false}
                    img={require("../../assets/images/radio1.svg").default}
                    imgAct={
                      require("../../assets/images/radio1act.svg").default
                    }
                  />
                </div>
              </div>
            </div>
            {form1 === true ? (
              <>
                {surgery.map((i, index) => (
                  <div key={index}>
                    <div className="formSection2">
                      <div className="innerFormSec">
                        <label className="formName" htmlFor="Name of injury">
                          Name of surgery*
                        </label>
                        <input
                          className="inputsForm"
                          style={{ resize: "none" }}
                          type="text"
                          onChange={handleSurgeryChange}
                          placeholder="Name of surgery"
                          data-id={index}
                          value={i.Name_of_surgery}
                          name="Name_of_surgery"
                        />
                        {i.Name_of_surgery === "" ? (
                          <div className="error1">Required</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="formSection2">
                      <div className="innerFormSec">
                        <label className="formName" htmlFor="injury">
                          How long ago did the surgery occur?*
                        </label>
                        <Dropdown
                          className="dropdown"
                          menuClassName="dropdownMenu"
                          controlClassName="myControlClassName"
                          arrowClassName="myArrowClassName"
                          arrowClosed={
                            <img crossorigin="anonymous" src={imgArrow} />
                          }
                          arrowOpen={
                            <img crossorigin="anonymous" src={imgArrow} />
                          }
                          placeholder="Month"
                          data-id={index}
                          onChange={(e) =>
                            handleSurgeryDropChange(
                              index,
                              "when_did_you_have_the_surgery_month",
                              e
                            )
                          }
                          value={i.when_did_you_have_the_surgery_month}
                          options={Months}
                        />
                        {i.when_did_you_have_the_surgery_month === "" ? (
                          <div className="error1">Required</div>
                        ) : null}
                      </div>
                      <div className="innerFormSec">
                        <label
                          className="formName"
                          style={{ visibility: "hidden" }}
                          htmlFor="year"
                        >
                          year{" "}
                        </label>
                        <Dropdown
                          className="dropdown"
                          menuClassName="dropdownMenu"
                          controlClassName="myControlClassName"
                          arrowClassName="myArrowClassName"
                          arrowClosed={
                            <img crossorigin="anonymous" src={imgArrow} />
                          }
                          arrowOpen={
                            <img crossorigin="anonymous" src={imgArrow} />
                          }
                          placeholder="Year"
                          data-id={index}
                          onChange={(e) =>
                            handleSurgeryDropChange(
                              index,
                              "when_did_you_have_the_surgery_year",
                              e
                            )
                          }
                          value={i.when_did_you_have_the_surgery_year}
                          options={yearlist.reverse()}
                        />
                        {i.when_did_you_have_the_surgery_year === "" ? (
                          <div className="error1">Required</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="formSection2">
                      <div className="innerFormSec">
                        <label className="formName" htmlFor="Add_description">
                          Add Description*
                        </label>
                        <textarea
                          className="inputsForm"
                          style={{
                            resize: "none",
                            paddingTop: "11px",
                            height: "87px",
                          }}
                          placeholder="Name of surgery"
                          onChange={handleSurgeryChange}
                          value={i.Add_description}
                          data-id={index}
                          name="Add_description"
                        />
                        {i.Add_description === "" ? (
                          <div className="error1">Required</div>
                        ) : null}
                      </div>
                    </div>
                    <hr />
                    <div className="formSection2">
                      <div className="innerFormSec">
                        <input
                          type="button"
                          style={{ width: "150px" }}
                          className="profileUpdate"
                          onClick={() => {
                            deleteMed(index, "surg");
                          }}
                          value={"delete"}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  className="formSection2"
                  style={{ justifyContent: "flex-end" }}
                >
                  <input
                    type="button"
                    className="profileUpdate"
                    onClick={addSurgeries}
                    value="+ Add surgery"
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </CardForm>
          <CardForm head={"Injuries"}>
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="illnessess">
                  Have you had any Injuries in the past? *
                </label>
                <div className="radioSection6">
                  <RadioBtn
                    name={"Injuries"}
                    value={"Yes[Please-specify]"}
                    click={(e) => {
                      setForm2(true);
                    }}
                    checking={form2 === true}
                    img={require("../../assets/images/radio1.svg").default}
                    imgAct={
                      require("../../assets/images/radio1act.svg").default
                    }
                  />
                  <RadioBtn
                    name={"Injuries"}
                    value={" No."}
                    click={(e) => {
                      setForm2(false);
                    }}
                    checking={form2 === false}
                    img={require("../../assets/images/radio1.svg").default}
                    imgAct={
                      require("../../assets/images/radio1act.svg").default
                    }
                  />
                </div>
              </div>
            </div>
            {form2 === true ? (
              <>
                {injury.map((i, index) => (
                  <div key={index}>
                    <div className="formSection2">
                      <div className="innerFormSec">
                        <label className="formName" htmlFor="Name_of_injury">
                          Name of Injury*
                        </label>
                        <input
                          className="inputsForm"
                          type="text"
                          placeholder="Name of injury"
                          onChange={handleInjuryChange}
                          data-id={index}
                          value={i.Name_of_injury}
                          name="Name_of_injury"
                        />
                        {i.Name_of_injury === "" ? (
                          <div className="error1">Required</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="formSection2">
                      <div className="innerFormSec">
                        <label className="formName" htmlFor="injury">
                          how long ago did the injury occur ?*
                        </label>
                        <Dropdown
                          className="dropdown"
                          menuClassName="dropdownMenu"
                          controlClassName="myControlClassName"
                          arrowClassName="myArrowClassName"
                          arrowClosed={
                            <img crossorigin="anonymous" src={imgArrow} />
                          }
                          arrowOpen={
                            <img crossorigin="anonymous" src={imgArrow} />
                          }
                          placeholder="Month"
                          data-id={index}
                          onChange={(e) =>
                            handleInjuryDropChange(
                              index,
                              "how_long_ago_did_the_injury_occur_month",
                              e
                            )
                          }
                          value={i.how_long_ago_did_the_injury_occur_month}
                          options={Months}
                        />
                        {i.how_long_ago_did_the_injury_occur_month === "" ? (
                          <div className="error1">Required</div>
                        ) : null}
                      </div>
                      <div className="innerFormSec">
                        <label
                          className="formName"
                          style={{ visibility: "hidden" }}
                          htmlFor="year"
                        >
                          year{" "}
                        </label>
                        <Dropdown
                          className="dropdown"
                          menuClassName="dropdownMenu"
                          controlClassName="myControlClassName"
                          arrowClassName="myArrowClassName"
                          arrowClosed={
                            <img crossorigin="anonymous" src={imgArrow} />
                          }
                          arrowOpen={
                            <img crossorigin="anonymous" src={imgArrow} />
                          }
                          placeholder="Year"
                          data-id={index}
                          onChange={(e) =>
                            handleInjuryDropChange(
                              index,
                              "how_long_ago_did_the_injury_occur_year",
                              e
                            )
                          }
                          value={i.how_long_ago_did_the_injury_occur_year}
                          options={yearlist.reverse()}
                        />
                        {i.how_long_ago_did_the_injury_occur_year === "" ? (
                          <div className="error1">Required</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="formSection2">
                      <div className="innerFormSec">
                        <label className="formName" htmlFor="Add_description">
                          Add Description*
                        </label>
                        <textarea
                          className="inputsForm"
                          placeholder="Description of injury"
                          style={{
                            resize: "none",
                            paddingTop: "11px",
                            height: "87px",
                          }}
                          onChange={handleInjuryChange}
                          value={i.Add_description}
                          data-id={index}
                          name="Add_description"
                        />
                        {i.Add_description === "" ? (
                          <div className="error1">Required</div>
                        ) : null}
                      </div>
                    </div>
                    <hr />
                    <div className="formSection2">
                      <div className="innerFormSec">
                        <input
                          type="button"
                          style={{ width: "150px" }}
                          className="profileUpdate"
                          onClick={() => {
                            deleteMed(index, "inj");
                          }}
                          value={"delete"}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  className="formSection2"
                  style={{ justifyContent: "flex-end" }}
                >
                  <input
                    type="button"
                    className="profileUpdate"
                    onClick={addInjury}
                    value="+ Add Injury"
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </CardForm>
          <CardForm head={"Medications"}>
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="illnessess">
                  Are you currently using any prescription medication?*
                </label>
                <div className="radioSection6">
                  <RadioBtn
                    name={"Medications"}
                    value={"Yes(Please-Specify)"}
                    click={(e) => {
                      setForm3(true);
                    }}
                    checking={form3 === true}
                    img={require("../../assets/images/radio1.svg").default}
                    imgAct={
                      require("../../assets/images/radio1act.svg").default
                    }
                  />
                  <RadioBtn
                    name={"Medications"}
                    value={"No`"}
                    click={(e) => {
                      setForm3(false);
                    }}
                    checking={form3 === false}
                    img={require("../../assets/images/radio1.svg").default}
                    imgAct={
                      require("../../assets/images/radio1act.svg").default
                    }
                  />
                </div>
              </div>
            </div>
            {form3 === true ? (
              <>
                {medication.map((i, index) => (
                  <div key={index}>
                    <div className="formSection2">
                      <div className="innerFormSec">
                        <label className="formName" htmlFor="Name_of_injury">
                          Drug Name*
                        </label>
                        <input
                          className="inputsForm"
                          type="text"
                          onChange={handleMedicationChange}
                          placeholder="Name of Drug"
                          data-id={index}
                          value={i.Drug}
                          name="Drug"
                        />
                        {i.Drug === "" ? (
                          <div className="error1">Required</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="formSection2">
                      <div className="innerFormSec">
                        <label className="formName" htmlFor="Name of injury">
                          Drug strength*
                        </label>
                        <div className="suffixDropdown fillup">
                          <input
                            type="number"
                            className="inputsForm"
                            style={{ width: "100px" }}
                            onChange={handleMedicationChange}
                            placeholder="00 Mg"
                            data-id={index}
                            value={i.Drug_strength}
                            name="Drug_strength"
                          />
                          <Dropdown
                            className="dropdown"
                            menuClassName="dropdownMenu"
                            controlClassName="myControlClassName"
                            arrowClassName="myArrowClassName"
                            arrowClosed={
                              <img crossorigin="anonymous" src={imgArrow} />
                            }
                            arrowOpen={
                              <img crossorigin="anonymous" src={imgArrow} />
                            }
                            placeholder="Mg"
                            data-id={index}
                            onChange={(e) =>
                              handleMedicitaionDropChange(index, "drugtxt", e)
                            }
                            value={i.drugtxt}
                            options={DrugStrength}
                          />
                        </div>
                        {i.Drug_strength === "" ? (
                          <div className="error1">Required</div>
                        ) : null}
                        {i.drugtxt === "" ? (
                          <div className="error1">Required</div>
                        ) : null}
                      </div>
                      <div className="innerFormSec">
                        <label className="formName" htmlFor="Name of injury">
                          Frequency*
                        </label>
                        <div className="suffixDropdown fillup">
                          <input
                            type="number"
                            className="inputsForm"
                            style={{ width: "100px" }}
                            onChange={handleMedicationChange}
                            placeholder="00"
                            data-id={index}
                            value={i.Frequency_per_day}
                            name="Frequency_per_day"
                          />
                          <Dropdown
                            className="dropdown"
                            menuClassName="dropdownMenu"
                            controlClassName="myControlClassName"
                            arrowClassName="myArrowClassName"
                            arrowClosed={
                              <img crossorigin="anonymous" src={imgArrow} />
                            }
                            arrowOpen={
                              <img crossorigin="anonymous" src={imgArrow} />
                            }
                            placeholder="Daily"
                            data-id={index}
                            onChange={(e) =>
                              handleMedicitaionDropChange(index, "drugtxt1", e)
                            }
                            value={i.drugtxt1}
                            options={Frequency}
                          />
                        </div>
                        {i.Frequency_per_day === "" ? (
                          <div className="error1">Required</div>
                        ) : null}
                        {i.drugtxt1 === "" ? (
                          <div className="error1">Required</div>
                        ) : null}
                      </div>
                      <div className="innerFormSec">
                        <label className="formName" htmlFor="Name of injury">
                          For how long?*
                        </label>
                        <div className="suffixDropdown fillup">
                          <input
                            type="number"
                            className="inputsForm"
                            style={{ width: "100px" }}
                            onChange={handleMedicationChange}
                            placeholder="00"
                            data-id={index}
                            value={
                              i.how_long_have_you_been_taking_this_medications
                            }
                            name="how_long_have_you_been_taking_this_medications"
                          />
                          <Dropdown
                            className="dropdown"
                            menuClassName="dropdownMenu"
                            controlClassName="myControlClassName"
                            arrowClassName="myArrowClassName"
                            arrowClosed={
                              <img crossorigin="anonymous" src={imgArrow} />
                            }
                            arrowOpen={
                              <img crossorigin="anonymous" src={imgArrow} />
                            }
                            placeholder="Days"
                            data-id={index}
                            onChange={(e) =>
                              handleMedicitaionDropChange(index, "drugtxt2", e)
                            }
                            value={i.drugtxt2}
                            options={Frequency}
                          />
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="formSection2">
                      <div className="innerFormSec">
                        <input
                          type="button"
                          style={{ width: "150px" }}
                          className="profileUpdate"
                          onClick={() => {
                            deleteMed(index, "med");
                          }}
                          value={"delete"}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  className="formSection2"
                  style={{ justifyContent: "flex-end" }}
                >
                  <input
                    type="button"
                    className="profileUpdate"
                    onClick={addMedication}
                    value="+ Add Medications"
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </CardForm>
          <div className="cardFoot">
            <div className="cardProfile"></div>
            <div className="cardForm">
              <div className="formSectio2" style={{ marginTop: "0" }}>
                <div className="innerFormSec">
                  <div className="radioSection5">
                    <input
                      type="button"
                      className="profileUpdate"
                      onClick={PageChangeSocial}
                      value="Update Profile"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        {/* third tab */}
        <TabPanel value={value} index={2}>
          <CardForm head={"Drink?"}>
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="illnessess">
                  How frequently do you consume alcohol?
                </label>
                <div className="radioSection6" style={{ padding: "2% 0%" }}>
                  {AlcoholList.map((i) => (
                    <>
                      <RadioBtn
                        name={"alcohol"}
                        onChange={(e) => setAlcohol(e.target.value)}
                        value={i}
                        checking={i === alcohol}
                        img={require("../../assets/images/radio1.svg").default}
                        imgAct={
                          require("../../assets/images/radio1act.svg").default
                        }
                      />
                    </>
                  ))}
                </div>
              </div>
            </div>
          </CardForm>
          <CardForm head={"Smoke?"}>
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="illnessess">
                  How many cigarettes do you smoke per day?
                </label>
                <div className="radioSection6" style={{ padding: "2% 0%" }}>
                  {Smoking.map((i) => (
                    <>
                      <RadioBtn
                        name={"Smoking"}
                        onChange={(e) => setSmoking(e.target.value)}
                        value={i}
                        checking={i === smoking}
                        img={require("../../assets/images/radio1.svg").default}
                        imgAct={
                          require("../../assets/images/radio1act.svg").default
                        }
                      />
                    </>
                  ))}
                </div>
              </div>
            </div>
          </CardForm>
          <CardForm head={"Lifestyle?"}>
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="illnessess">
                  How physically active is your lifestyle?
                </label>
                <div className="radioSection6" style={{ padding: "2% 0%" }}>
                  {Lifestyle.map((i) => (
                    <>
                      <RadioBtn
                        name={"Lifestyle"}
                        onChange={(e) => setLifestyle(e.target.value)}
                        value={i}
                        checking={i === lifestyle}
                        img={require("../../assets/images/radio1.svg").default}
                        imgAct={
                          require("../../assets/images/radio1act.svg").default
                        }
                      />
                    </>
                  ))}
                </div>
              </div>
            </div>
          </CardForm>
          <CardForm head={"Diet"}>
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="illnessess">
                  What does your diet mainly consist of?
                </label>
                <div className="radioSection6" style={{ padding: "2% 0%" }}>
                  {Dieting.map((i) => (
                    <>
                      <RadioBtn
                        name={"Diet"}
                        onChange={(e) => setDiet(e.target.value)}
                        value={i}
                        checking={i === diet}
                        click={() => { }}
                        img={require("../../assets/images/radio1.svg").default}
                        imgAct={
                          require("../../assets/images/radio1act.svg").default
                        }
                      />
                    </>
                  ))}

                  <div
                    className="radioSection6"
                    style={{ padding: "2% 0%", flexDirection: "column" }}
                  >
                    {diet.indexOf("Other Any Diet") > -1 ? (
                      <>
                        <input
                          type="text"
                          className="inputsForm"
                          placeholder="Please type here"
                          onChange={(e) => handleDieterr(e)}
                          name="otherDiet"
                          value={otherDiet}
                        />
                        {
                          dieterr ?
                            <div className="error1">Please type value that not matches with option</div> : ""
                        }
                      </>
                    ) : (
                      <input
                        type=""
                        className="inputsForm"
                        value=" "
                        disabled
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </CardForm>
          <CardForm head={"Mobile Apps?"}>
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="illnessess">
                  On average, how many apps do you use on your phone every day
                </label>
                <div className="radioSection6" style={{ padding: "2% 0%" }}>
                  {UseMobile.map((i) => (
                    <>
                      <RadioBtn
                        name={"MobileApps"}
                        onChange={(e) => setMobileApps(e.target.value)}
                        value={i}
                        checking={i === mobileApps}
                        img={require("../../assets/images/radio1.svg").default}
                        imgAct={
                          require("../../assets/images/radio1act.svg").default
                        }
                      />
                    </>
                  ))}
                </div>
              </div>
            </div>
          </CardForm>
          <CardForm head={"Social Media Channels"}>
            <div className="formSection2">
              <div className="innerFormSec">
                <label className="formName" htmlFor="illnessess">
                  On which social media channels are you most active (Please
                  check all boxes that apply)
                </label>
                <div className="radioSection6" style={{ padding: "2% 0%" }}>
                  <div className="radioSection6">
                    <CheckBtn
                      data={SocialMedia}
                      name="socialMedia"
                      onChange={(e) => {
                        addSocialMedia(e);
                      }}
                      defaultValue={socialMedia}
                    />
                  </div>
                  <div
                    className="radioSection6"
                    style={{ padding: "2% 0%", flexDirection: "column" }}
                  >
                    {socialMedia.indexOf("Others Social [Please Specify]") >
                      -1 ? (
                      <>
                        <input
                          type="text"
                          className="inputsForm"
                          placeholder="Please type here"
                          onChange={(e) => handleSocialerr(e)}
                          name="otherSocial"
                          value={otherSocial}
                        />
                        {
                          socialmediaerr ?
                            (
                              <div className="error1">Please type value that not matches with option</div>
                            )

                            : ""}
                      </>
                    ) : (
                      <input type="text" className="inputsForm" disabled />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </CardForm>
          <div className="cardFoot">
            <div className="cardProfile"></div>
            <div className="cardForm">
              <div className="formSectio2" style={{ marginTop: "0" }}>
                <div className="innerFormSec">
                  <div className="radioSection5">
                    <input
                      type="button"
                      onClick={PageChangefinal}
                      className="profileUpdate"
                      value="Update Profile"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
      </form>
      <Dialog open={intimation} onClose={() => setIntimation(false)}>
        <div className="intimation">
          <img
            crossorigin="anonymous"
            className="close"
            src={Close}
            alt="icon"
            onClick={() => setIntimation(false)}
          />
          <div className="Container">
            <img crossorigin="anonymous" src={Inte} alt="icon" />
            You are unable to create an account as you are less than 18 but you
            can become a relative of an adult account holder.
            <div className="btn">Back</div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default ProfileForm;
