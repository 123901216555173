import React, { useEffect, useState } from 'react'
import FullNavLayout from '../../layout/FullNavLayout'
import { useFormik } from 'formik';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup'
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import axios from 'axios'
import { api } from '../../utils/constants'
import './auth.scss'
import { Toast } from "../../components"
import mask from '../../assets/images/mask.svg'


function CreateNewPasscode() {
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false)
    const [res, setRes] = useState(false)
    const forgetData = JSON.parse(localStorage.getItem("forgetData"))
    const [newpascode, setNewpascode] = useState(false)
    const [repasscode, setRepasscode] = useState(false)
    const history = useHistory();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const validationSchema = Yup.object({
        NewPasscode: Yup.string()
            .required("Passcode Required")
            .matches(/^[0-9]+$/, "Must be Real digits")
            .min(4, "Must be exactly 4 digits")
            .max(4, "Must be exactly 4 digits"),
        RePasscode: Yup.string()
            .required("Passcode confirmation Required")
            .oneOf([Yup.ref('NewPasscode'), null], 'Passwords must match')
            .matches(/^[0-9]+$/, "Must be Real Numbers")
            .min(4, "Must be exactly 4 digits")
            .max(4, "Must be exactly 4 digits"),
    });

    const { handleSubmit, handleChange, values, errors } = useFormik({
        initialValues: {
            NewPasscode: '',
            RePasscode: ''
        }
        , validationSchema,
        onSubmit: async (val) => {
            if (val.NewPasscode === val.RePasscode) {
                const mobile = forgetData.number
                const stringpass = val.NewPasscode
                const password = stringpass.toString()
                const data = { mobile, password }
                await axios.post(api.changepassword, data)
                    .then((res) => {
                        setOpen(true);
                        localStorage.clear()
                    }, (err) => {
                        setRes(true)
                    })
            } else { setError(true) }


        }
    })

    return (
        <FullNavLayout>
            <div className="forgotContainer">
                <div className="heading">Create New Passcode</div>
                <div className="forget_pin_Txt spacing" style={{ fontSize: '18px' }}>Choose a passcode you can easily remember</div>
                <form onSubmit={handleSubmit}>
                    <div className="forgetNumber">
                        <label htmlFor="New Passcode">New Passcode</label>
                        <div className="errorWidth">
                            <input className="inputForm"
                                type={newpascode ? "text" : "password"}
                                name="NewPasscode"
                                maxLength={4}
                                value={values.NewPasscode}
                                onChange={handleChange}
                            />
                            <img crossorigin="anonymous" className="newMask" src={mask} onClick={() => setNewpascode(!newpascode)} alt="icon" />
                            {errors.NewPasscode ? <div className='error1' style={{ textAlign: 'left' }}>{errors.NewPasscode}</div> : null}
                        </div>

                        <label style={{ marginTop: "24px" }} htmlFor="Re-enter New Passcode">Re-enter New Passcode</label>
                        <div className="errorWidth">
                            <input className="inputForm"
                                type={repasscode ? "text" : "password"}
                                name="RePasscode"
                                maxLength={4}
                                value={values.RePasscode}
                                onChange={handleChange}
                            />
                            <img crossorigin="anonymous" className="repasscode" src={mask} alt="icon" onClick={() => setRepasscode(!repasscode)} />
                            {errors.RePasscode ? <div className='error1' style={{ textAlign: 'left' }}>{errors.RePasscode}</div> : null}
                        </div>
                    </div>
                    <button type="submit" className='sumbitBtn'>Submit</button>
                </form>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogContent>
                    <DialogContentText >
                        <div className="successDialog">
                            <img crossorigin="anonymous" src={require('../../assets/images/success.png').default} alt="" />
                            <h2>Passcode Changed Successfully</h2>
                            <p>You’ve successfully changed your passcode, proceed to login</p>
                            <Link to='/login'><button type="btn" className='sumbitBtn'> Proceed to Login</button></Link>
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <Toast text={"something went wrong!\nplease contact admin."} type="error" open={res} setClose={() => { setRes(false) }} />
            <Toast text={"something went wrong!"} type="error" open={error} setClose={() => { setError(false) }} />
        </FullNavLayout>
    )
}

export default CreateNewPasscode
