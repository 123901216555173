import React from 'react'
import Diagnostic from './Images/Diagnostic.png'
import Proactive from './Images/Proactive.png'
import { Row, Col } from "react-bootstrap";
import DateRange from "./DateRange"

function BookCards() {


    return (
        <>
        <Row>  <Col md={6}> You have not ordered a Diagnostic test or BioCheck panel </Col></Row>
        
        <div className="ItemCardsWrapper">
           
           <Row>
               <Col md={6}>
            <div className="ItemCard1"  >
                <div className="ItemContainer1-text">

                    Buy a Diagnostic Test
                </div>

                <img src={Diagnostic} alt="Avatar" style={{ width: "250px", opacity: "0.9", }} />

            </div>
            </Col>
            <Col md={6}>
            <div className="ItemCard2"  >
                <div className="ItemContainer2-text">Be proactive about your health

                </div>

                <img src={Proactive} alt="Avatar" style={{ width: "250px", opacity: "0.4", }} />

            </div>
            </Col>
            </Row>
        </div>
        </>



    )
}

export default BookCards
