import React, { useState, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import Tracker from './Tracker';
import { viewReceipt } from './../../redux/orders/actions';
import { base64toBlob } from './Utils';
import { useSelector, useDispatch } from "react-redux";
import './Tracker.scss';
import './OrderTracking.scss';
import { api } from '../../utils/constants';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Closebutton from "../../assets/images/closebutton.svg";
import { select } from "redux-saga/effects";
import TrackingStatus from './TrackingStatus';
import SynLab from '../../assets/logo/Synlab.png';
import Union from '../../assets/logo/Union.png';
import Echolab from '../../assets/logo/Echolab.png';
import Accunalysis from '../../assets/logo/Accunalysis.png';

import acc from '../../assets/logo/acc.svg';
import mecure from '../../assets/logo/mecure.svg';
import afri from '../../assets/logo/afri.svg';
import clinix from '../../assets/logo/clinix.svg';
import dna from '../../assets/logo/dna.svg';
import img from '../../assets/logo/img.svg';
import lancet from '../../assets/logo/lancet.svg';
import logo from '../../assets/logo/doctall-logo.png';

export const getUser = state => state.auth


function TrackIndividualTests(props) {

    const dispatch = useDispatch();

    const [status, changeStatus] = useState('Completed');
    const [OrderDetails, changeOrderDetails] = useState({});
    const [showStatusAs, setshowStatusAs] = useState('')

    let color = '';
    if (status === 'Completed') {
        color = '#D1FAE5'
    } else if (status === 'In-progress') {
        color = '#37CC8C'
    }

    useEffect(() => {
        getIndividualOrderDetails()
    }, [props.orderDetails])

    let getReceipt = () => {
        let orderId = props.orderDetails && props.orderDetails.order_info ? props.orderDetails.order_info.order_id : props.orderDetails && props.orderDetails.length > 0 && props.orderDetails[0].uuid;

        const user = select(getUser);
        let userUUID = user.uuid;
        let url = `${api.star_rating}/ep/orderPaymentPreview/${orderId}`

        axios.get(url, {
            headers: {
                "created_by.uuid": userUUID,
                "x-auth-token": user.token
            },
        }).then(res => {
            if (res.data.data) {
                const pdfData = res.data.data;
                const url = URL.createObjectURL(
                    base64toBlob(`data:application/pdf;base64,${pdfData}`)
                );
                window.open(url, "_blank");
            }
        })
    }

    let getIndividualOrderDetails = () => {
        console.log(props.orderDetails)
        let orderId = props.orderDetails && props.orderDetails.order_info ? props.orderDetails.order_info.order_id : props.orderDetails && props.orderDetails.length > 0 && props.orderDetails[0].uuid;
        let url = `${api.Order}/ordertracking/byOrderId?orderId=${orderId}`

        axios.get(url).then(res => {
            changeOrderDetails(res.data)
        })
    }
    let getTestResult = (result) => {
        if (result && result) {
            let resultData = result
            if (resultData && resultData.url) {
                window.open(resultData.url, "_blank");
            }
        }
    }


    let getPartnerLogo = (detailedInfo) => {

        try {
            let dynamicLogo = detailedInfo && 0 in detailedInfo && detailedInfo[0].partner_info && 0 in detailedInfo[0].partner_info && detailedInfo[0].partner_info[0].logo
            if (dynamicLogo) {
                return dynamicLogo
            } else {
                return logo
            }
        } catch (error) {
            return logo
        }
    }

    const getTestStatus = (data) => {
        let index = data.length - 1;
        // return data[index].test_status
        switch (data[index].test_status) {
            case "paymentmade":
                return "Payment Made";
                break;
            case "agreedforrefund":
                return "Agreed For Refund";
                break;
            case "agreedforreschedule":
                return "Agreed For Schedule";
                break;
            case "samplecollected":
                return "Sample Collected";
                break;
            case "acceptedtest":
                return "Accepted Test";
                break;
            case "rejectedtest":
                return "Rejected Test";
                break;
            case "agreedforaneworder":
                return "Agreed For New Order";
                break;
            case "resultuploaded":
                return "Result Uploaded";
                break;
            case "refunded":
                return "Refunded";
                break;
            case "rescheduled":
                return "Rescheduled";
                break;
            case "sampleinsufficient":
                return "Sample Insufficient";
                break;
            default:
                return status;
        }
    }



    let details = OrderDetails[0] && OrderDetails[0]["details"] ? OrderDetails[0]["details"] : []

    return (

        <div>
            {Object.keys(details).map((key, index) => {

                let finalActionDetails = OrderDetails[0] && OrderDetails[0].fullOrderDetails && OrderDetails[0].fullOrderDetails.length > 0 && OrderDetails[0].fullOrderDetails[key] ? OrderDetails[0].fullOrderDetails[key]['final_action'] : null
                //'Result released and updated'
                let showStatusAs = OrderDetails[0] && OrderDetails[0].fullOrderDetails && OrderDetails[0].fullOrderDetails.length > 0 && OrderDetails[0].fullOrderDetails[key]['doctall_action'] ? OrderDetails[0].fullOrderDetails[key]['doctall_action'] : ''
                //let showStatusAs;
                {
                    OrderDetails[0] && OrderDetails[0].fullOrderDetails.reverse().map((item) => {
                        //    setshowStatusAs(item.doctall_action).reverse()
                        // console.log(item, "show")
                    })
                }
                let testStatus = OrderDetails[0] && OrderDetails[0].details && OrderDetails[0].details[index].trackInfo.length > 0 ? getTestStatus(OrderDetails[0].details[index].trackInfo) : ''

                // && (showStatusAs == "Result Accepted")
                let hasResult = (showStatusAs === "Result Accepted" || showStatusAs === "Confirm Result Received") ? true : false

                let result = finalActionDetails && finalActionDetails['result'] && finalActionDetails['result'].length > 0 && finalActionDetails['result'][0]

                let partnerLogo = getPartnerLogo(OrderDetails[0] && OrderDetails[0])



let  firstArray = details[key].trackInfo && details[key].trackInfo

let lastElement = firstArray[firstArray.length - 1];
console.log(lastElement,"lastElement")
// firstArray.map((item) => {
//     // item.test.uuid
//     console.log(item.uuid, "dwfch")
//     secondArray.map((item11) => {
//         // console.log(item.uuid, item11.details.uuid, "46")
//         {item.uuid === item11.details.uuid ?  console.log(item.uuid, "46") : console.log("item.uuid", "46")}
//     })

// })
            
                return <>
                    {index === 0 && <span style={{ fontSize: "24px", color: "#163E8C" }}> All Test Orders</span>}
                    <div className='test-orders'>
                        <Row>
                            <Col md={5} className='mt-1 p-4'>
                                <Col>
                                    <span style={{ 'font-size': '12px', 'background': color, 'padding': '4px' }}>{testStatus} </span>
                                </Col>
                                <Col style={{ 'margin-top': '2%', 'font-size': '20px', 'white-space': 'initial' }}>
                                    <span>
                                        {details && details[key] ? details[key].tests.standardisedtestname : ''}
                                    </span>
                                </Col>
                            </Col>


                            <Col md={4} className='mt-2 p-4'>
                                <Col>
                                    <span style={{ 'font-size': '12px', marginTop: "4%" }}>Patient Name:</span>
                                </Col>
                                <Col style={{ 'font-size': '20px' }}>
                                    <span >{OrderDetails[0] && OrderDetails[0].patientsInfo[0] && OrderDetails[0].patientsInfo[0]['full_name'] ? OrderDetails[0].patientsInfo[0]['full_name'] : ''} </span>
                                </Col>

                            </Col>
                            <Col md={3} className='mt-2 p-4'>
                                <img className="img-synlab" src={getPartnerLogo(OrderDetails)} />

                            </Col>
                        </Row>

                    </div>


                    {details[key].trackInfo && details[key].trackInfo.length > 0 ?

                        <TrackingStatus trackInfo={details[key].trackInfo} order_id={props.orderDetails.uuid} />
                        : null
                    }


                    <Row>
                        {console.log(details[key].trackInfo && details[key].trackInfo,"fhgh")}
                        {/* {hasResult ?
                            <button className="viewReceipt-1" style={{ marginLeft: '2%' }} onClick={() => getTestResult(result)}>
                                VIEW RESULT
                            </button>
                            : null} */}
                            {lastElement.status === "completed" ?
                            <button className="viewReceipt-1" style={{ marginLeft: '2%' }} onClick={() => getTestResult(result)}>
                                VIEW RESULT
                            </button>
                            : null }

                        
                        {/* {OrderDetails[0] && OrderDetails[0].fullOrderDetails.map((item) => { 
                    console.log(item,"item")
                    if(item.doctall_action === 'Result Accepted' || item && item.doctall_action === 'Confirm Result Received'){
                        return  <button className="viewReceipt-1" style={{ marginLeft: '2%' }} onClick={() => getTestResult(result)}>
                        VIEW RESULT
                        { console.log(item.doctall_action, "dxgfjhbk")}
                     </button>
                    }

                })} */}

                        <button className="viewReceipt" onClick={getReceipt}>
                            View Payment Receipt
                        </button>

                    </Row>
                </>
            })}

        </div>
    )
}

export default TrackIndividualTests
