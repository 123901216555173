import { Spin } from "antd";
import firebase from "firebase/app";
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppointmentTipsTwo, BookingDetails, Chaperone, DoctorProfile, ImportantNote, TitleWithNav, VideoCard } from "../../components";
import DashboardLayout from '../../layout/DashboardLayout';
import axios from '../../redux/axios';
import { getPatients } from '../../redux/patients/actions';
import { api } from '../../utils/constants';
import './DoctorConsultations.scss';
import { getFirebaseAnalyticsInstance } from '../../firebase'



function DoctorConsultations() {



    const userdata = useSelector(state => state.auth);
    const Patients = useSelector(state => state.patients.data);
    let history = useHistory();
    const [loading, setLoading] = useState(false)
    const data = history.location.data;
    console.log(data)

    const [next, setNext] = useState(data);
    const [update, setUpdate] = useState(false);
    const dispatch = useDispatch()
    const status = "Booked"
    // const title = data && data.type == "sadn" ? `See a Doctor Now\n ₦${strikeThrough('1, 500')} ₦750` : data.type == "free" ? "Book a FREE Appointment" : "Find a Doctor / Specialist (International & Nigerian)"
    const [amount, setAmount] = useState()

    useEffect(() => {
        async function logFirebaseEvent() {
            try {
                let appointmentType = data && data.type
                await getFirebaseAnalyticsInstance().logEvent('doctor_consult_web', {
                    bookingType: appointmentType,
                    platform: 'web'
                });
            } catch (error) {
                console.log(error);
            }
        }
        logFirebaseEvent();
    }, [data]);
    const getAppointmentType = () => {
        let appointmentType = data && data.type
        console.log(data && data.type)
        switch (appointmentType) {
            case "sadn":
                return `See a Doctor Now\n ₦${amount}`
            case "free":
                return "Book a FREE Appointment"
            case "international":
                return "Find a Doctor / Specialist (International & Nigerian)"
            case "Find a doctor":
                return "Find a Doctor / Specialist (International & Nigerian)"
            case "normal":
                return "Find a Doctor / Specialist (International & Nigerian)"
            default:
                return "N/A"
        }
    }

    function strikeThrough(text) {
        return text
            .split('')
            .map(char => char + '\u0336')
            .join('')
    }


    useEffect(() => {
        if (!history.location.data) {
            history.push("/dashboard")
        }
    }, [])
    useEffect(() => {
        dispatch(getPatients())
    }, [])
    useEffect(() => {
        if (data && data.doctor_uuid) {
            setLoading(true);

            axios.get(api.user + '/' + data.doctor_uuid, { headers: { 'x-auth-token': userdata.token } })
                .then(res => {
                    data["doctors"] = [res.data]

                    setLoading(false)
                })
                .catch(err =>
                    setLoading(true)
                )
        }
        else {

            setLoading(false)
        }
    }, [data])
    useEffect(() => {
        let repeat;

        const fetchData = async () => {
            try {
                Next();
                repeat = setTimeout(fetchData, 60000); // request again after a minute

            } catch (error) {
                console.error(error.message)
            }
        }

        fetchData();

        return () => {
            if (repeat) {
                clearTimeout(repeat);
            }
        }
    }, []);
    const Next = () => {
        setNext(data)
        setUpdate(!update)
    }
    const handleUpdateMed = () => {
        const Checkrel = Patients.filter((e) => e.uuid == data.patient_uuid)[0];
        if (Checkrel) {
            if (Checkrel.relationship !== undefined && Checkrel.relationship !== null) {
                history.push({
                    pathname: '/profile',
                    state: {
                        prof: "medUpdate",
                        uuid: Checkrel.uuid
                    },
                })

                document.querySelector('#root').scrollIntoView({
                    behavior: 'smooth'
                }, 500)
            } else {
                history.push({
                    pathname: '/relativeProfile',
                    state: {
                        prof: "medUpdate",
                        uuid: Checkrel.uuid
                    },
                })
                document.querySelector('#root').scrollIntoView({
                    behavior: 'smooth'
                }, 500)
            }
        }

    }


    useEffect(() => {
        axios.get(api.clinic_by_doctor + "/all", {
            headers: { "x-auth-token": userdata.token },
        })

            .then((r) => {
                var date = new Date()
                // console.log(today, "dadad")
                // console.log(date.utc().format(), "dadad")
                // alert(date)
                let dataval = {
                    booking_date: date,
                    clinic_uuid: r.data.uuid,
                };

                axios.post(api.appointment + "sadn/waiting", dataval, {
                    headers: { "x-auth-token": userdata.token },
                })

                    .then((res) => {
                        console.log(res, "dadad")
                        // alert(res.data)
                        // setAmount(res.data.amount);
                        // if (res.data) {
                        //     console.log(res.data)
                        //     alert(res.data)
                        //   setAmount(res.data.amount);

                        // } else {
                        //   console.log(r, "ressss")
                        // }
                    })
                    .catch((err) => {
                        // alert(err.res)
                        console.log(err, "err")
                    });
            })
            .catch((err) => {
                console.log(err, "dadad")
                if (err && err.response) {

                    setTimeout(() => {

                    }, 100);
                }
            });
    }, []);

    useEffect(() => {
        consultAmountfee()
      })
        const consultAmountfee = () => {
          axios.get(api.appointment + "sadn/booking/amount")
          .then((res) => {
            setAmount(res.data.amount)
          })
          .catch((err) => {
            console.log(err)
          })
        }
    return (
        <div>
            <DashboardLayout>
                <div className="appointmentDetails">
                    <Spin spinning={loading}>
                        <TitleWithNav title={getAppointmentType()} />
                        {data && <div className="appointwrapper">
                            <div>
                                <DoctorProfile type={false} data={data} />
                                {data.local_doctor && data.local_doctor.length > 0 && <Chaperone data={data.local_doctor[0]} />}
                                <div style={{ height: 22 }} />
                                {data.zoomData && <VideoCard next={next} />}
                            </div>
                            <div>
                                <BookingDetails data={status == "Booked" ? [{
                                    title: "Date", value: moment(data.booking_date).format("ddd DD MMM YYYY")
                                }, {
                                    title: "Time", value: moment(data.start_time).format("LT")
                                },
                                {
                                    title: "ID", value: data.uuid
                                }, {
                                    title: "Status", value: status
                                },
                                {
                                    title: "Type", value: getAppointmentType()
                                }, {
                                    title: "", value: ""
                                }] : status == "Followup" || status == "Closed" ? [{
                                    title: "Date", value: moment(data.booking_date).format("ddd DD MMM YYYY")
                                }, {
                                    title: "Time", value: moment(data.start_time).format("LT")

                                },
                                {
                                    title: "Status", value: status
                                }, {
                                    title: "Appointment ID", value: data.uuid
                                },
                                {
                                    // Need to check.. 
                                    title: "Appointment Type", value: getAppointmentType()
                                },
                                {
                                    title: "Follow-up Appointment", value: data.followup_date ? moment(data.followup_date).format("DD MMM YYYY") : "No"
                                }

                                ] : [{
                                    title: "Date", value: moment(data.booking_date).format("ddd DD MMM YYYY")
                                }, {
                                    title: "Time", value: moment(data.start_time).format("LT")

                                },
                                {
                                    title: "Status", value: status
                                }, {
                                    title: "Appointment ID", value: data.uuid
                                },
                                {
                                    title: "Appointment Type", value: getAppointmentType()
                                }
                                    , {
                                    title: "", value: ""
                                }
                                ]}
                                />
                                <div style={{ height: 27 }} />

                                <div style={{ height: 27 }} />

                            </div>

                        </div>}
                        <div className="appointmentIns">

                            <AppointmentTipsTwo onClick={() => { handleUpdateMed() }} />
                            <ImportantNote />
                        </div>
                    </Spin>
                </div>
            </DashboardLayout>
        </div>
    )
}

export default DoctorConsultations
