import React, { useState, useEffect } from 'react';
import _ from "lodash";
import { Row, Col, Modal, Tabs, Tab, Button } from 'react-bootstrap';
import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";
import './custome.css'
import { api } from '../../utils/constants';
import { base64toBlob } from '../OrdersResults/Utils';
import Checkbox from "@material-ui/core/Checkbox";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import CryptoJS from "crypto-js";
import { customStyles } from './tableStyle'
const columns = [
    {
        name: 'Select All',
        selector: row => row.standardisedtestname,
    },
    {
        name: 'Status',
        selector: row => row.order_uuid,
        format: row => row && row.order_uuid ? row.order_uuid : "NA"
    }
];


export default function LabReportView(props) {

    const { open, close, detailedInfo, stopLoader, startLoader } = props;
    const { patient, booking, uuid, tests, urls, patient_uuid } = detailedInfo !== undefined ? detailedInfo : {}
    const [selectedTestList, setSelectedTestList] = useState([])
    const SECRET_KEY = ')H@MccQffTjWnZr4u7x!A%CF*F-JaNdRgUkUkUXNh*F-JaNDOCTALL';
    const user = useSelector((state) => state.auth);
    //Table Properties..
    const [selectedRows, setSelectedRows] = React.useState(false);
    const [toggledClearRows, setToggleClearRows] = React.useState(false);


    const handleChange = ({ selectedRows }) => {
        setSelectedRows(selectedRows);
        setSelectedTestList(selectedRows)
    };

    useEffect(() => {
        setSelectedTestList([])
    }, [])


    const getEncryptedData = (diff) => {
        let passcode = localStorage.getItem("PASSCODE");
        // Slash available in env file def..
        let DMP_URL = `${process.env.REACT_APP_DMP_URL}recommended-tests`;
        let itemList = [];
        selectedTestList.map((item) => {
            itemList.push({
                'test_uuid': item.uuid,
                'patient_uuid': patient_uuid,
                'advice_id': uuid
            })
        })
        var data = [{
            labAdvice: itemList,
            loginDetails: { userName: user.username, password: passcode }
        }]
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
        let encryptedKey = ciphertext.toString().replaceAll("/", "doctall")
        close()
        let url = `${DMP_URL}/${encryptedKey}`
        window.open(url, "_blank")
    }


    const navigateToDMP = () => {
        getEncryptedData("selectedTest")
    }

    const openPdf = async (id) => {
        try {
            startLoader()
            await axios.get(api.viewlab + id)
                .then((res) => {
                    if (res.data) {
                        const pdfData = res.data;
                        const url = URL.createObjectURL(
                            base64toBlob(`data:application/pdf;base64,${pdfData}`)
                        );
                        window.open(url, "_blank");
                        stopLoader()
                    }
                });
        } catch (error) {
            console.log(error)
            stopLoader()
        }
    }

    return (
        <>
            <Modal
                className="doctall-modal"
                show={open}
                onHide={() => close()}
                dialogClassName="modal-90w"
                scrollable={true}
                centered
                size='lg'
            // backdrop="static"
            >
                <Modal.Header closeButton={true}>
                    <Modal.Title>
                        <div className="doctall-modal-title" style={{ "margin": "0px 60px", fontSize: "24px", lineHeight: "22px" }}>Investigation Required Test</div>
                    </Modal.Title>
                </Modal.Header>
                <div style={{ "margin": "0px 110px", color: "#4D777C" }}>
                    <p>Patient Name : {patient && patient.full_name} </p>
                    <p>Appointment ID : {booking && booking.uuid} </p>
                    <p>Appointment Date : {booking && moment(booking.booking_date).format('LL')} </p>
                    <p>Lab Test Advice ID : {uuid && uuid} </p>
                </div>
                <Modal.Body className="modal-body" style={{ background: "#F2F8F9", margin: "auto", marginBottom: "20px" }}>
                    <div style={{ "margin": "0px 50px", background: "#F2F8F9", color: "#013C44" }}>
                        <div>

                            <DataTable
                                // title="Investigation Required Test"
                                columns={columns}
                                data={tests}
                                selectableRows
                                onSelectedRowsChange={handleChange}
                                clearSelectedRows={toggledClearRows}
                                responsive={true}
                                customStyles={customStyles}
                                // selectableRowsHighlight={true}
                                selectableRowDisabled={(row) => row.order_uuid}
                                contextMessage={{ singular: 'test', plural: 'tests', message: 'selected' }}
                            />
                        </div>

                    </div>

                    <Modal.Footer>
                        <Row>
                            <Col span={12} className="button">
                                {selectedTestList.length > 0 && <Button style={{ border: "1px solid #D0D5DD", color: "#344054", fontWeight: "600", background: "#ffffff" }} onClick={navigateToDMP} disabled={selectedTestList.length <= 0}>
                                    Buy Test
                                </Button>}
                                {selectedTestList.length <= 0 && <Button style={{ border: "1px solid #D0D5DD", color: "#344054", fontWeight: "600", background: "#ffffff" }} disabled={selectedTestList.length <= 0}>
                                    Buy Test
                                </Button>}
                                <Button variant="info" onClick={() => openPdf(uuid)}>
                                    Download
                                </Button>
                            </Col>
                            {/* <Col span={12}>
                            <Button variant="info" onClick={() => openPdf(uuid)}>
                                Download
                            </Button>
                        </Col> */}
                        </Row>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
        </>
    )
}
