import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import CryptoJS from "crypto-js";
import { login } from '../../redux/auth/actions';
import { useSelector, useDispatch } from "react-redux";
import { getDoctorDetails } from './services'
import LoaderComponent from '../../components/Loading/LoaderComponent';
import Layout from '../../layout/DashboardLayout';

export default function FADNBooking(props) {
    let history = useHistory()
    const [loader, setLoader] = useState(false);
    const [doctorInfo, setDoctorInfo] = useState();

    const dispatch = useDispatch();
    const SECRET_KEY = ')H@MccQffTjWnZr4u7x!A%CF*F-JaNdRgUkUkUXNh*F-JnaNDOCcTALL';


    useEffect(() => {

        try {
            setLoader(true)
            var decryption = CryptoJS.AES.decrypt(props.match.params.queryParam.toString().replaceAll("doctall", "/"), SECRET_KEY);
            let decryptKey = decryption.toString(CryptoJS.enc.Utf8);
            let _decryptedData = JSON.parse(decryptKey)
            let _data = _decryptedData[0];
            fetchDoctorDetails(_data)
        } catch (error) {
            console.log(error)
            setLoader(false)
        }
    }, [])

    const fetchDoctorDetails = async (_data) => {
        try {
            let response = await getDoctorDetails(_data.doctorId)
            setDoctorInfo(response.data)
            const payLoad = { username: _data.userName, password: _data.password };
            dispatch(
                login(payLoad, (res) => {
                    localStorage.setItem("CTKN", res.token);
                    setLoader(false)
                    history.push({
                        pathname: "/book_specialist",
                        state: {
                            selectedData: _data.timeSloteInfo,
                            doc: response.data,
                        },
                    });
                })
            );

        } catch (error) {
            console.log(error)
            setLoader(false)
        }

    }


    return (
        <>
            <Layout>
                {/* Loader Component */}
                {loader && <LoaderComponent />}
            </Layout>
        </>
    )
}
