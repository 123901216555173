import React from 'react';
import './Tracker.scss'

function Tracker(props) {
    
    console.log(props.orderDetails)

    return (
        <div className='tracker'>
            <div class="container">
                <div class="row">
                    <div class="col-12 col-md-10 hh-grayBox pt45 pb40" style={{marginLeft:"-4%",border:"1px solid #37CC8C",borderRadius:"10px"}}>
                    <p style={{marginTop:"-3%",fontSize:"15.134px",color:"#37CC8C"}}>ORDER TRACKING</p>
                        <div class="row justify-content-between" style={{marginLeft:"-18%"}}>
                            <div class="order-tracking completed">
                                <span class="is-complete"></span>
                                <p style={{color:"#0C44AC"}}>Payment Made<br /><span>12 Sept, 2021</span></p>
                            </div>
                            <div class="order-tracking completed">
                                <span class="is-complete"></span>
                                <p style={{color:"#0C44AC"}}>Sample Collected<br /><span>15 Sept, 2021</span></p>
                            </div>
                            <div class="order-tracking">
                                <span class="is-complete"></span>
                                <p >Result Released <br /><span> 17 Sept, 2021</span></p>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tracker
