import React, { useEffect, useState } from 'react';
import './SelectTimeSlote.scss';
import DropDown from '../Select/';
import Label from '../Label/';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";



function SelectPatient(props) {
    const { setSelforRelative } = props;
    const patient = useSelector(state => state.patients)
    const [patientList, setPatientList] = useState([]);
    let history = useHistory();


    useEffect(() => {
        if (patient && patient.data) {
            let res = patient.data.map((e, i) => { return { label: e.full_name, value: e.uuid } })
            setPatientList(res)
        }

    }, [patient])
    useEffect(() => {
        if (props.type !== "" && props.name !== "") {
            setSelforRelative(props.name)
        }
    }, [])
    return (
        <div className={props.type !== "" && props.name !== "" ? "select-box" : "selectSpecial"}>
            <Label text="Select Self or Relative" />
            {props.type !== "" && props.name !== "" ?
                <div className="show-patient-cls">
                    <span>{props.name}</span>
                </div>
                :
                <div>
                    <DropDown
                        placeholder={"Please select"} value={""}
                        setValue={(value) => setSelforRelative(value)}
                        options={patientList} />
                </div>
            }

            {
                props.type == "" && props.name == "" ?
                    <div className={"button"} onClick={() => history.push('/addRelative')}>
                        Add Relative
                    </div> : ""
            }

        </div>
    )
}

export default SelectPatient