import React, { Fragment, useState, useEffect } from "react";
import "./SadnBooking.scss";
import Layout from "../../layout/DashboardLayout";
import { NoAppointments, TitleWithNav } from "../../components";
import axios from "../../redux/axios";
import { useSelector } from "react-redux";
import { api } from "../../utils/constants";
import { Spin } from "antd";
import * as RNLocalize from "react-native-localize";
import momenttz from "moment-timezone";
import moment from "moment";
import fdLogo from "../../assets/images/fdLogo.svg";
import Toast from "../../components/Toast";
import { Helmet } from 'react-helmet';
import { useHistory } from "react-router-dom";

function SadnBooking() {
  let history = useHistory();

  const userdata = useSelector((state) => state.auth);
  const [waiting, setWaiting] = useState("");
  const [loader, setLoader] = React.useState(true);
  const [toster, setToster] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [type, setType] = React.useState("");
  const [amount, setAmount] = React.useState("");


  useEffect(() => {
    setLoader(true);
    axios
      .get(api.clinic_by_doctor + "/all", {
        headers: { "x-auth-token": userdata.token },
      })

      .then((r) => {
        const deviceTimeZone = RNLocalize.getTimeZone();
        const today = momenttz().tz(deviceTimeZone);
        let dataval = {
          booking_date: today.utc().format(),
          clinic_uuid: r.data.uuid,
        };

        axios
          .post(api.appointment + "sadn/waiting", dataval, {
            headers: { "x-auth-token": userdata.token },
          })
          .then((r) => {
            if (r.data) {
              let timeslot = moment(r.data.slot_time).format("LT");
              //let waiting_time = timeConvert(r.data.waitTime);
              setWaiting(timeslot);
              // setAmount(r.data.amount)
              setLoader(false);
            } else {
              setWaiting(false);
            }
            setLoader(false);
          })
          .catch((err) => {
            setWaiting(false);
            setLoader(false);
          });
      })
      .catch((err) => {
        setLoader(false);
        setTimeout(() => {
          setToster(true);
          setMsg("Clinc :" + err.response.data);
          setType("error");
        }, 100);
      });
  }, []);
  useEffect(() => {
    consultAmountfee()
  })
  const consultAmountfee = () => {
    axios.get(api.appointment + "sadn/booking/amount")
      .then((res) => {
        setAmount(res.data.amount)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  function strikeThrough(text) {
    return text
      .split('')
      .map(char => char + '\u0336')
      .join('')
  }

  return (
    <Layout>
      <Helmet>
        <title>Doctall SADN: See a doctor now and get immediate medical care
        </title>
        <meta
          name='description'
          content='Book an appointment and speak to a doctor online immediately. Video consultations with a doctor to discuss your symptoms and health concerns. Get downloadable prescriptions and lab test orders. Doctors available online now'
        />
      </Helmet>
      <div className="sadnBooking">
        <TitleWithNav title={`See a Doctor Now ₦${amount}`} />

        <p className="h4">See our ﬁrst available Nigerian Medical Oﬃcer</p>
        <p className="h5">The first available doctor will see you shortly</p>

        <Spin spinning={loader}>
          {waiting != false ? (
            <>
              <div className="availablelightgreen">
                <p className="next">The next available appointment is at </p>
                <div className="time">
                  <Spin spinning={loader}>
                    <p>{waiting}</p>
                  </Spin>
                </div>
              </div>

              <div className="buttons">
                <div
                  className="proceed"
                  onClick={() => {
                    history.push("/sadn");
                  }}
                >

                  Proceed to See a Doctor Now
                </div>
                <div
                  className="check"
                  onClick={() => {
                    history.push("/dashboard");
                  }}
                >

                  Check Back Later
                </div>
              </div>
            </>
          ) : (
            <>
              <NoAppointments />
              <div
                className="check"
                onClick={() => {
                  history.push("/dashboard");
                }}
              >

                Check Back Later
              </div>

              <div className="kind">or kindly proceed this option </div>

              <div className="find" onClick={() => history.push('/specialist')}>
                <div className="imageholder">
                  <img crossorigin="anonymous" src={fdLogo} className="image" />
                </div>
                <div className="content">
                  <div className="title">Find a Doctor on Specialist</div>
                  <div className="desc">
                    Book your preferred Nigerian or International specialist.
                  </div>
                </div>
              </div>
            </>
          )}
        </Spin>
      </div>

      <Toast
        text={msg}
        type={type}
        open={toster}
        setClose={() => {
          setToster(false);
        }}
      />
    </Layout>
  );
}

export default SadnBooking;
