/**
 *
 *
 * See a Doctors Landing Page
 *
 */

import { Spin } from "antd";
import axios from "axios";
import CryptoJS from "crypto-js";
import momenttz from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useCookies } from 'react-cookie';
import * as RNLocalize from "react-native-localize";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useDebounce from "../.././hooks/useDebounce";
import AndroidLogo from "../../assets/images/androidLogo.svg";
import AppleLogo from "../../assets/images/appleLogo.svg";
import AppointmentBtn from "../../assets/images/AppointmentBtn.svg";
import CreateAccountBtn from "../../assets/images/CreateAccountBtn.svg";
import DashboardBtn from "../../assets/images/DashboardBtn.svg";
import Docktall from "../../assets/images/Docktall.svg";
import DoctorsBox1 from "../../assets/images/doctors-box1.svg";
import DoctorsBox2 from "../../assets/images/doctors-box2.svg";
import DoctorsBox3 from "../../assets/images/doctors-box3.svg";
import DoctorsBox4 from "../../assets/images/doctors-box4.svg";
import DoctorsBox5 from "../../assets/images/doctors-box5.svg";
import DoctorsBox6 from "../../assets/images/doctors-box6.svg";
import Customer from "../../assets/images/doctors-customer.png";
import FdLogo from "../../assets/images/fdLogo.svg";
import LoginBtn from "../../assets/images/LoginBtn.svg";
import SadnLogo from "../../assets/images/sadnLogo.svg";
import Search from "../../assets/images/Search.svg";
import Sort from "../../assets/images/Sort.svg";
import DoctorProfile from "../../components/DoctorProfile/Appointment";
import FiltersForm from "../../components/FiltersForm";
import InputField from "../../components/InputField";
import Pagination from "../../components/Pagination";
import { login } from "../../redux/auth/actions";
import { api, baseUrl } from "../../utils/constants";
import { doctorsFilter } from "../../utils/schema";
import "./style.scss";
import { Helmet } from 'react-helmet';


/**
 *
 * @param {object} props
 * @returns
 */
function Doctors(props) {

  const [cookies, setCookie] = useCookies(['username', 'token', 'passcode']);
  const { history } = props;
  const userdata = useSelector((state) => state.auth);
  const [dataval, setDataVal] = useState([]);
  const [specialities, setSpecialities] = useState([]);
  const [filterForm, openFilterForm] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [languages, setlanguages] = useState([]);
  const [load, setLoad] = useState(false)

  const [page, setPage] = useState(0)
  const [limits, setLimit] = useState(10)

  
  const [amount, setAmount] = useState('')

  const [filterData, setFilterData] = useState({
    country: "",
    speciality: "",
    experience: "",
    start_price: "",
    last_price: "",
    language: "",
    search: "",
    type: "SAD_landing",
    limit:limits,
    skip:page
  });
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [inputValue, setinputValue] = useState("");
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);

  function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  
  const fetchDoctorsList = (data) => {
    axios
      .get(baseUrl + "/user/doctorListFilter", {
        params: Object.assign({}, data),
        headers: { "x-auth-token": userdata.token },
      })
      .then((res) => {
        setDataVal((res && res.data && res.data.user) || []);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const changeHandler = (e) => {
    setinputValue(e.target.value);
    setSearchTerm(e.target.value);
  };

  function strikeThrough(text) {
    return text
      .split('')
      .map(char => char + '\u0336')
      .join('')
  }

  useEffect(() => {
    const cookie = getCookie('doctallLog')
    if (cookie) {
      setLoad(true)
      let org = CryptoJS.AES.decrypt(cookie, 'doctall').toString(CryptoJS.enc.Utf8);
      org = JSON.parse(org)
      if (org) {
        const username = org.username;
        const password = org.password;
        const val = { username, password };
        dispatch(
          login(val, (res) => {
            setLoad(false)
            history.push("/dashboard");
            localStorage.setItem("CTKN", res.token);
          })
        );

        // setLoad(false)
      }
    }
  }, [])


  // useEffect(() => {
  //   axios.get(api.clinic_by_doctor + "/all", {
  //     headers: { "x-auth-token": userdata.token },
  //   })

  //     .then((r) => {
  //       const deviceTimeZone = RNLocalize.getTimeZone();
  //       const today = momenttz().tz(deviceTimeZone);
  //       let dataval = {
  //         booking_date: today.utc().format(),
  //         clinic_uuid: r.data.uuid,
  //       };

  //       axios.post(api.appointment + "sadn/waiting", dataval, {
  //         headers: { "x-auth-token": userdata.token },
  //       })
  //         .then((r) => {
  //           if (r.data) {
  //             // setAmount(r.data.amount);

  //           } else {
  //             console.log(r, "ressss")
  //           }
  //         })
  //         .catch((err) => {
  //           console.log(err, "err")
  //         });
  //     })
  //     .catch((err) => {
  //       if (err && err.response) {

  //         setTimeout(() => {

  //         }, 100);
  //       }
  //     });
  // }, []);

    const consultAmountfee = () => {
      axios.get(api.appointment + "sadn/booking/amount")
      .then((res) => {
        setAmount(res.data.amount)
      })
      .catch((err) => {
        console.log(err)
      })
    }

    // useEffect(() => {
      
    // })

  useEffect(
    () => {
      if (debouncedSearchTerm || debouncedSearchTerm === "") {
        let data = filterData;
        data.search = inputValue;
        axios
          .get(baseUrl + "/user/doctorListFilter", {
            params: Object.assign({}, data),
            headers: { "x-auth-token": userdata.token },
          })
          .then((res) => {
            setDataVal((res && res.data && res.data.user) || []);
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
      consultAmountfee()
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );
  const fetchSpecialities = () => {
    axios
      .get(baseUrl + "/metadata/specialities", {
        params: Object.assign({}, { "created_by.uuid": userdata.uuid }),
        headers: { "x-auth-token": userdata.token },
      })
      .then((res) => {
        setSpecialities((res && res.data) || []);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const fetchLanguages = () => {
    axios
      .get(baseUrl + "/user/getTheLanguages", {
        params: Object.assign({}, { "created_by.uuid": userdata.uuid }),
        headers: { "x-auth-token": userdata.token },
      })
      .then((res) => {
        setlanguages((res && res.data && res.data.data) || []);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    let data = filterData;
    data.search = inputValue;
    fetchDoctorsList(filterData);
  //     if(filterForm == true){
  //   fetchSpecialities()
  //   fetchLanguages();
  // }
    // fetchSpecialities();
    // fetchLanguages();
  }, []);

  const fun = () => {
    fetchSpecialities()
    fetchLanguages();
  }

  const handleFilterChange = (e) => {
    let changeData = Object.assign({}, filterData, {
      [e.target.name]: e.target.value,
    });
    if (e.target && e.target.name === "price_range") {
      changeData = Object.assign({}, filterData, {
        [e.target.name]: e.target.value,
        start_price: e.target.value[0],
        last_price: e.target.value[1],
      });
    }

    setFilterData(changeData);
  };

  const clearFilter = () => {
    setFilterData({
      country: "",
      speciality: "",
      sort_type: "",
      start_price: "",
      last_price: "",
      language: "",
      search: "",
      type: "SAD_landing",
    });
    setDataVal([])

    fetchDoctorsList({
      country: "",
      speciality: "",
      sort_type: "",
      start_price: "",
      last_price: "",
      language: "",
      search: "",
      type: "SAD_landing",
    })
  };

  return (
    <>
    <Helmet><title>Consumer Web App | Book a Doctor Online - Doctall
</title>
<meta 
            name='description' 
            content='Book an appointment and speak to a doctor online immediately. Video consultations with a doctor to discuss your symptoms and health concerns. Get downloadable prescriptions and lab test orders. Doctors available online now'
            />
</Helmet>
    <Spin spinning={load}>
      <Container fluid className="doctors-page">
        <Row className="doctors-header">
          <Col sm={6} className="doctors-header-logo">
            <img crossorigin="anonymous" src={Docktall} />
          </Col>
          <Col sm={6} className="doctors-header-link">
            {userdata.token ? (
              <Link to="/dashboard">
                <img crossorigin="anonymous" src={DashboardBtn} />
              </Link>
            ) : (
              <Row  className="header-butoon">
                <Col xs={5}  >
                  <Link to="/login">
                    <img crossorigin="anonymous" src={LoginBtn} />
                  </Link>
                </Col>
                <Col xs={6} >
                  <Link to="/signup">
                    <img crossorigin="anonymous" src={CreateAccountBtn} />
                  </Link>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Row
          className="doctors-section"
          style={{ backgroundImage: `url(${Customer})` }}
        >
          <Col sm={1} />
          <Col sm={10}>
            <Row>
              <Col xs={12} className="doctors-section-col1">
                Quality <br /> healthcare on <br /> your terms
              </Col>
              <Col xs={12} className="doctors-section-col2">
                <a href="https://apps.apple.com/ng/app/doctall/id1544455716" target="_blank"
                  rel="noreferrer">
                  <img
                    crossorigin="anonymous"
                    src={AppleLogo}
                    style={{ marginRight: "15px", paddingTop: "50px" }}
                  />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.doctall" target="_blank"
                  rel="noreferrer">
                  <img
                    crossorigin="anonymous"
                    src={AndroidLogo}
                    style={{ paddingTop: "50px" }}
                  />
                </a>
              </Col>
            </Row>
          </Col>
          <Col sm={1} />
        </Row>

        <Row className="doctors-section2">
          <Col sm={1} />
          <Col sm={10}>
            <Row className="doctors-box">
              <Col
                lg
                className="doctors-box-container"
                onClick={() => history.push("/sadn_booking")}
              >
                <Row>
                  <Col sm={2} className="doctors-box-logo">
                    <img crossorigin="anonymous" src={SadnLogo} />
                  </Col>
                  <Col sm={10}>
                    <Row>
                      <Col xs={12} className="doctors-box-text1">
                        See a Doctor Now<br/>
                        {/* ₦ {strikeThrough('1, 500')}  */}
                        ₦{amount}
                        {/* See a Doctor Now <br/>₦${strikeThrough('1, 500')} ₦750 */}
                      </Col>
                      <Col xs={12} className="doctors-box-text2">
                        See our ﬁrst available Nigerian Medical Oﬃcer.
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col
                lg
                className="doctors-box-container middle"
                style={{ border: "2px solid rgb(255, 92, 60)" }}
                onClick={() => history.push("/specialist")}
              >
                <Row>
                  <Col sm={2} className="doctors-box-logo">
                    <img crossorigin="anonymous" src={FdLogo} />
                  </Col>
                  <Col sm={10}>
                    <Row>
                      <Col xs={12} className="doctors-box-text1">
                        Find a Doctor or Specialist
                      </Col>
                      <Col xs={12} className="doctors-box-text2">
                        Book your preferred Nigerian or International specialist.
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              {/* <Col
                lg
                className="doctors-box-container"
                style={{ border: "2px solid rgb(0, 131, 155)" }}
                onClick={() => history.push("/free_booking")}
              >
                <Row>
                  <Col sm={2} className="doctors-box-logo">
                    <img crossorigin="anonymous" src={FreeLogo} />
                  </Col>
                  <Col sm={10}>
                    <Row>
                      <Col xs={12} className="doctors-box-text1">
                        Book a FREE Appointment
                      </Col>
                      <Col xs={12} className="doctors-box-text2">
                        Schedule a time to see our on- call Nigerian Medical
                        Officer
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col> */}
            </Row>
          </Col>
          <Col sm={1} />
        </Row>
        <Row className="doctors-section3">
          <Col className="doctors-section3-title">
            Begin your journey <br /> to better health
          </Col>
        </Row>
        <Row className="doctors-section4">
          <Col sm={1} />
          <Col sm={10}>
            <Row>
              <Col sm className="doctors-section4-box">
                <img
                  crossorigin="anonymous"
                  src={DoctorsBox1}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col sm className="doctors-section4-box">
                <img
                  crossorigin="anonymous"
                  src={DoctorsBox2}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col sm className="doctors-section4-box">
                <img
                  crossorigin="anonymous"
                  src={DoctorsBox3}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <Row>
              <Col sm className="doctors-section4-box">
                <img
                  crossorigin="anonymous"
                  src={DoctorsBox4}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col sm className="doctors-section4-box">
                <img
                  crossorigin="anonymous"
                  src={DoctorsBox5}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col sm className="doctors-section4-box">
                <img
                  crossorigin="anonymous"
                  src={DoctorsBox6}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={1} />
        </Row>
        <Row className="doctors-section5">
          <Col xs={12} className="doctors-section5-link">
            <Link to="/dashboard">
              <img crossorigin="anonymous" src={AppointmentBtn} />
            </Link>
          </Col>
        </Row>
        <Row className="doctors-section6">
          <Col sm={1} />
          <Col sm={10}>
            <Row>
              <Col xs={12} className="doctors-section6-title">
                Our Doctors
              </Col>
              <Col xs={12} className="doctors-section6-text">
                Browse through our doctors
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row style={{ paddingTop: "25px", paddingBottom: "25px" }}>
                  <Col sm={8}>
                    <InputField
                      className="doctors-search"
                      size="large"
                      placeholder="Search for doctors"
                      prefix={<img crossorigin="anonymous" src={Search} />}
                      InputStyle={{ outline: "none !important" }}
                      onChange={changeHandler}
                    />
                  </Col>
                  <Col
                    sm={4}
                    className="doctors-section6-filter"
                    onClick={() => { openFilterForm(!filterForm); fun()}}
                  >
                    <img crossorigin="anonymous" src={Sort} />
                  </Col>
                </Row>
              </Col>
              <Col md={6} />
            </Row>
            {filterForm ? (
              <Row className="filter-section-form">
                <Col xs={12}>
                  <FiltersForm
                    initialValues={filterData}
                    onChange={handleFilterChange}
                    fields={doctorsFilter}
                    metaData={Object.assign(
                      {},
                      {
                        specialities: specialities.map((s) =>
                          Object.assign({}, { value: s.name, label: s.name })
                        ),
                      },
                      {
                        languages: languages.map((s) =>
                          Object.assign({}, { value: s, label: s })
                        ),
                      }
                    )}
                    handleSubmit={() => fetchDoctorsList(filterData)}
                    handleClear={() => clearFilter()}
                  />
                </Col>
              </Row>
            ) : null}
          </Col>
          <Col sm={1} />
        </Row>
        <Row className="doctors-section7">
          <Col sm={1} />
          <Col sm={10}>
            <Row className="profile-row">
              <Pagination
                pageSize={limits}
                className="row"
                style={{ flexDirection: "row" }}
                records={dataval}
              >
                {(pageData) =>
                  pageData &&
                  pageData.length > 0 &&
                  pageData.map((item, index) => {
                    return (
                      <DoctorProfile
                        history={history}
                        item={item}
                        key={index}
                        token={userdata.token}
                      />
                    );
                  })
                }
              </Pagination>
              {/* {
                                 dataval &&
                                 dataval.length > 0 &&
                                 dataval.map((item, index) => {
                                   return (
                                     <DoctorProfile
                                       history={history}
                                       item={item}
                                       key={index}
                                       token={userdata.token}
                                     />
                                   );
                                 })
              } */}
            </Row>
          </Col>
          <Col sm={1} />
        </Row>
      </Container>
    </Spin>
    </>);
}

export default Doctors;
