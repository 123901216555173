import React, { useState, useEffect } from "react";
import "./style.scss"
import Item from "./Item"
import sadnLogo from '../../assets/images/sadnLogo.svg';
import fdLogo from '../../assets/images/fdLogo.svg';
import freeLogo from '../../assets/images/freeLogo.svg';
import { useHistory } from "react-router-dom";

import axios from "../../redux/axios";
import { useSelector } from "react-redux";
import { api } from "../../utils/constants";
import { Spin } from "antd";
import * as RNLocalize from "react-native-localize";
import momenttz from "moment-timezone";
import moment from "moment";
import Toast from "../../components/Toast";
import LoaderComponent from "../Loading/LoaderComponent";
// import moment from "moment";



const List = (props) => {
  let history = useHistory();

  const userdata = useSelector((state) => state.auth);
  const [waiting, setWaiting] = useState("");
  const [amount, setAmount] = useState('')
  const [freewaiting, setFreeWaiting] = useState("");
  const [loader, setLoader] = useState(true);
  const [toster, setToster] = useState(false);
  const [msg, setMsg] = useState("");
  const [type, setType] = useState("");
  useEffect(() => {
    consultAmountfee()
  },[amount])
    const consultAmountfee = () => {
      axios.get(api.appointment + "sadn/booking/amount")
      .then((res) => {
        setAmount(res.data.amount)
      })
      .catch((err) => {
        console.log(err)
      })
    }
  useEffect(() => {
    setLoader(true);
    axios.get(api.clinic_by_doctor + "/all", {
      headers: { "x-auth-token": userdata.token },
    })

      .then((r) => {
        const deviceTimeZone = RNLocalize.getTimeZone();
        const today = momenttz().tz(deviceTimeZone);
        let dataval = {
          booking_date: today.utc().format(),
          clinic_uuid: r.data.uuid,
        };
        fetchFreeWaitingTime(dataval)
        axios.post(api.appointment + "sadn/waiting", dataval, {
          headers: { "x-auth-token": userdata.token },
        })
          .then((r) => {
            if (r.data) {
              let timeslot = moment(r.data.slot_time).format("LT");
              //let waiting_time = timeConvert(r.data.waitTime);
              setWaiting(r.data.waitTime);
              // setAmount(r.data.amount);
              setLoader(false);
            } else {
              console.log(r, "ressss")
              setWaiting(false);
            }
            setLoader(false);

          })
          .catch((err) => {
            console.log(err, "err")
            // setAmount(r.data.amount);
            setWaiting(false);
            setLoader(false);
          });
      })
      .catch((err) => {
        setLoader(false);
        if (err && err.response) {

          setTimeout(() => {
            setToster(true);
            setMsg("Clinc :" + err.response.data);
            setType("error");
          }, 100);
        }
      });
  }, []);


  const fetchFreeWaitingTime = (dataval) => {
    axios.post(api.appointment + "free/waiting", dataval, {
      headers: { "x-auth-token": userdata.token },
    })
      .then((r) => {
        if (r.data && r.data.waitTime) {

          let timeslot;
          if (moment(r.data.slot_time).format('ll') === moment().format('ll')) {
            timeslot = moment(r.data.slot_time).format("LT");
          } else {
            let timeslotOne = moment(r.data.slot_time).format("lll");
            timeslot = moment(timeslotOne).format('MMM DD, h:mm A')
          }
          //let waiting_time = timeConvert(r.data.waitTime);
          setFreeWaiting(timeslot);
          setLoader(false);
        } else {
          setWaiting(false);
        }
        setLoader(false);
      })
      .catch((err) => {
        setWaiting(false);
        setLoader(false);
      });
  }





  function strikeThrough(text) {
    return text
      .split('')
      .map(char => char + '\u0336')
      .join('')
  }


  return (
    <>
      {loader && <LoaderComponent />}
      <div className="ListContainer">
        <Item image={sadnLogo} availableAppointment={true} waitTime={waiting} amount ={amount} title={`See a Doctor Now`}  desc={"See our ﬁrst available Nigeria Medical Officer."} color={"#37CC8C"} onClick={() => { history.push("/sadn_booking"); }} />
        <Item image={fdLogo} FAD={true} title={"Find a Doctor or\n Specialist Consultant"}  desc={"See our first available Nigeria Medical Officer."} color={"#FF5C3C"}  onClick={() => { history.push("/specialist") }} />
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>

        {/* <Item image={freeLogo} freeAppointment={true} freeWaitTime={freewaiting} title={"Book a FREE\nAppointment"} desc={"Schedule a time to see our on- call Nigerian Medical Officer."} color={"#00839B"} onClick={() => { history.push("/free_booking"); }} /> */}
      </div>
    </>
  );
};

export default List;