import types from './types'

export const login = (data,cb) => ({
    type: types.LOGIN_USER,
    data,
    cb
})

export const loginSuccess = (data) => ({
    type: types.SAVE_LOGIN,
    data
})

export const logout = () => ({
    type: types.LOGOUT
})
// export const goBack=()=>({
//     type:types.GO_BACK
// })
export const location =()=>({
    type: types.LOCATION
})

export const availability = (data,cb) => ({
    type: types.CHECK_AVALIABLITY,
    data,cb
})

export const verifyOtp=(data,cb)=>({
    type:types.VERIFY_OTP,
    data,cb
})

export const resendOtp=(data,cb)=>({
    type:types.RESEND_OTP,
    data,cb
})

export const verify = data => ({
    type: types.VERIFY_USER,
    data
})

export const stopLoading = () => ({
    type: types.LOADING_STOP
})

export const register = (data,cb) => ({
    type: types.REGISTER_USER,
    data,
    cb
})

export const registerComplete = data => ({
    type: types.REGISTER_COMPLETE,
    data
})

export const notifyUpdate = data => ({
    type: types.NOTIFY_UPDATE,
    data
})

export const cartUpdate = data => ({
    type: types.CART_UPDATE,
    data
})

export const UpdateProfile = data => ({
    type: types.UPDATE_PROFILE,
    data
})
