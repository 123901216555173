import React, { useState, useEffect } from "react";
import "./DoctorProfile.scss";
import { useSelector, useDispatch } from "react-redux";
import star from "../../assets/images/star.svg";
import Profile from "../../assets/images/pic.svg";
import Indian from "../../assets/images/indian.svg";
import Nigerian from "../../assets/images/nigerian.svg";
import British from "../../assets/images/ukflag.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import { numberWithCommas } from "../../utils";
import { useHistory } from "react-router-dom";
import axios from "../../redux/axios";
import { api } from "../../utils/constants";
import * as RNLocalize from "react-native-localize";
import momenttz from "moment-timezone";

function DoctorProfile(props) {
  let history = useHistory();
  const userdata = useSelector(state => state.auth);
  const [rate, setRate] = useState(0);
  const [amount, setAmount] = useState();
  const { data } = props;
  const getYear = (year) => {
    if (year) {
      let currentyear = moment().format("YYYY");
      return currentyear - year + " Years";
    } else {
      return "";
    }
  };

  // useEffect(() => {
  //   axios
  //     .get(`${api.star_rating}/booking/feedback/starRating/${data.doctor_uuid}`)
  //     .then((res) => setRate(Math.round(res.data.result)));
  // }, []);

  function strikeThrough(text) {
    return text
      .split('')
      .map(char => char + '\u0336')
      .join('')
  }

  const AllFun = () => {
    axios.get(api.clinic_by_doctor + "/all", {
      headers: { "x-auth-token": userdata.token },
    })

      .then((r) => {
        const deviceTimeZone = RNLocalize.getTimeZone();
        const today = momenttz().tz(deviceTimeZone);
        let dataval = {
          booking_date: today.utc().format(),
          clinic_uuid: r.data.uuid,
        };

        // axios.post(api.appointment + "sadn/waiting", dataval, {
        //   headers: { "x-auth-token": userdata.token },
        // })
        //   .then((r) => {
        //     if (r.data) {
        //       // setAmount(r.data.amount);

        //     } else {
        //       console.log(r, "ressss")
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err, "err")
        //   });
      })
      .catch((err) => {
        if (err && err.response) {

          setTimeout(() => {

          }, 100);
        }
      });
  }
  useEffect(() => {
    // AllFun()
    // consultAmountfee()
  }, [amount]);

  // useEffect(() => {
  //   consultAmountfee()
  // },[])
  const consultAmountfee = () => {
    axios.get(api.appointment + "sadn/booking/amount")
    .then((res) => {
      setAmount(res.data.amount)
    })
    .catch((err) => {
      console.log(err)
    })
  }
  const getAppointmentType = (appointmentType) => {
    switch (appointmentType) {
      case "sadn":
        return `See a Doctor Now\n ₦${amount}`
        break;
      case "free":
        return "Book a FREE Appointment"
        break;
      case "international":
        return "Find a Doctor / Specialist (International & Nigerian)"
        break;
      case "Find a doctor":
        return "Find a Doctor / Specialist (International & Nigerian)"
        break;
      case "normal":
        return "Find a Doctor / Specialist (International & Nigerian)"
        break;
      default:
        return "N/A"
    }
  }


  return (
    <div className="DoctorProfile">
      {data && data.doctors && data.doctors.length > 0 && (
        <div className="docContainer">
          {data && data.doctors && data.doctors.length > 0 && data.doctors[0].profile && data.doctors[0].profile.avatar ? (
            <img
              crossorigin="anonymous"
              className="profileimg"
              src={data && data.doctors && data.doctors.length > 0 && data.doctors[0].profile.avatar}
              alt=""
              
            />
          ) : (
            <img
              crossorigin="anonymous"
              className="avatarimg"
              src={Profile}
              alt=""
            />
          )}
          <div className="docDetailsContainer">
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip id={`tooltip-top`}>
                  Dr.{data && data.doctors && data.doctors.length > 0 && data.doctors[0].full_name}
                </Tooltip>
              }
            >
              <div className="docNAme">Dr.{data && data.doctors && data.doctors.length > 0 && data.doctors[0].full_name}</div>
            </OverlayTrigger>

            {data && data.doctors && data.doctors.length > 0 && data.doctors[0].profile && data.doctors[0].profile && data.doctors[0].profile.professional_detail && data.doctors[0].profile.professional_detail.qualification && data.doctors[0].profile.professional_detail.qualification &&
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-top`}>
                    {data && data.doctors && data.doctors.length > 0 && data.doctors[0].profile.professional_detail && data.doctors[0].profile.professional_detail.qualification}
                  </Tooltip>
                }
              >
                <div className="details">
                  <p class="font-italic">
                    {data && data.doctors && data.doctors.length > 0 && data.doctors[0].profile.professional_detail && data.doctors[0].profile.professional_detail.qualification && data.doctors[0].profile.professional_detail.qualification}
                  </p>
                </div>
              </OverlayTrigger>
            }

            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip id={`tooltip-top`}>
                  {data && data.doctors && data.doctors.length > 0 && data.doctors[0].profile && data.doctors[0].profile.speciality.join(" ")}
                </Tooltip>
              }
            >
              <div className="speciality">
                {data && data.doctors && data.doctors.length > 0 && data.doctors[0].profile.speciality.join(" ")}
              </div>
            </OverlayTrigger>
            <div className="docDetails">



              <div className="details">
                Location:&nbsp;
                <span>
                  {data && data.doctors && data.doctors.length > 0 && data.doctors[0].profile.address &&
                    Object.keys(data.doctors[0].profile.address).length > 0
                    ? data.doctors[0].profile.address.country
                    : ""}
                </span>
                <span className="imgholder">
                  <img
                    style={{ width: 17, height: 12.28, marginRight: 3 }}
                    src={
                      data && data.doctors && data.doctors.length > 0 && data.doctors[0].profile.address.country.toLowerCase() ==
                        "india"
                        ? Indian
                        : data && data.doctors && data.doctors.length > 0 && data.doctors[0].profile.address.country.toLowerCase() ==
                          "united kingdom"
                          ? British
                          : Nigerian
                    }
                  />
                </span>
              </div>
              <div className="details">
                Experience:&nbsp;
                <span>
                  {getYear(data && data.doctors && data.doctors.length > 0 && data.doctors[0].profile.practice_started_year)}
                </span>
              </div>
              <div className="details">Languages Spoken:</div>
              <div className="details">
                <span>{data && data.doctors && data.doctors.length > 0 && data.doctors[0].profile.languages.join(", ")}</span>
              </div>
            </div>
            {/* <div className="starcontainer">
              {[...Array(rate)].map((el, index) => {
                return (
                  <img
                    key={index}
                    crossorigin="anonymous"
                    src={star}
                    alt="start"
                  />
                );
              })}
            </div> */}
            <div className="hr" />
            <div className="feeContainer">

              {data.amount && data.amount > 0 ? (
                <span>₦{numberWithCommas(data.amount)}</span>
              ) : (
                <span>Free</span>
              )}
              <span style={{ fontSize: "10px" }}>Consultation Fee:</span>{" "}
            </div>
          </div>
        </div>
      )}
      {/* {props.state && props.state === 'searchList'} */}
      {props.type && props.type == "appointlist" && (
        <div className="appoContainer">
          <div className="heading">Appointment Details</div>
          <div className="appointmentDetails">
            <div className="details">
              Date:&nbsp;
              <span>{moment(data.booking_date).format("DD,MMM, YYYY")} </span>
            </div>
            <div className="details">
              Time:&nbsp;<span>{moment(data.start_time).format("LT")}</span>
            </div>
            <div className="details">
              ID:&nbsp;<span>{data.uuid}</span>
            </div>
            <div className="details">
              Status:&nbsp;
              <span>
                {data.status.charAt(0).toUpperCase() + data.status.slice(1)}
              </span>
            </div>
            <div className="detailswithout">
              Type:&nbsp;
              <span>
                {getAppointmentType(data.type)}
              </span>
            </div>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className={data.status != "cancelled" ? "appoButton" : "disable"}
            onClick={() => {
              data.status != "cancelled"
                ? history.push({ pathname: "/appointmentdetail", data: data })
                : console.log("");
            }}
          >
            VIEW DETAILS
          </div>
        </div>
      )}
    </div>
  );
}

export default DoctorProfile;
