import React, { useState } from "react";
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from "react-redux";
import RedErroricon from "../../../assets/images/rederror.svg";
import ConfirmChangePasscode from '../../../components/ChangePasscode/successpop';
import Toast from '../../../components/Toast';
import Layout from "../../../layout/DashboardLayout";
import { logout } from "../../../redux/auth/actions";
import axios from "../../../redux/axios";
import { api, passcodeKey, tokenKey, usernameKey } from "../../../utils/constants";
import './NewPasscode.scss';


const Changepasscode = () => {
    const [cookies, setCookie] = useCookies([usernameKey, passcodeKey, tokenKey]);
    const dispatch = useDispatch();
    const [typed, setTyped] = useState("");
    const [newpass, setNewpass] = useState({
        newpass: "",
        confirmpass: ""
    })
    const [showalert, setshowalert] = useState(false);
    const [showalertmsg, setshowalertmsg] = useState(false);
    const [BTNContent, setBTNContent] = useState({});

    const [msg, setMsg] = useState("");
    const [message, setMessage] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [type, setType] = React.useState('');
    const [toster, setToster] = React.useState(false);
    const userdata = useSelector(state => state.auth);
    const handleClickOpen = (e) => {
        setModalShow(true)
    };
    const logoutfn = () => {
        localStorage.clear();
        dispatch(logout())
        console.log('consumer location clearing cookie ');
        setCookie(usernameKey, null)
        setCookie(passcodeKey, null)
        setCookie(tokenKey, null)
        // setTimeout(()=>{navigate("/")}) 
    }
    const handleSubmit = () => {
        console.log(newpass)
        setshowalertmsg(false);
        if (newpass.newpass && newpass.confirmpass) {
            if (newpass.newpass != newpass.confirmpass) {
                setshowalertmsg(true);
                setMsg("New passcode and Confirm Passcode must match")
            }
            else {
                axios.post(api.changepassword, { mobile: userdata.mobile, password: newpass.confirmpass })
                    .then((res) => {
                        if (res.status == "200") {
                            handleClickOpen()
                            setTimeout(function () { logoutfn() }, 3000);
                        }

                    })
                    .catch((err) => {
                        // Toast.showWithGravity('Failed to reset password!', Toast.LONG, Toast.TOP);
                        setToster(true)
                        setMessage('please check the details you fill')
                        setType('error')
                        console.log(err, 'error')
                    })
            }


        }
        else {
            if (newpass.newpass == undefined || newpass.newpass.trim() == "") {
                // Toast.showWithGravity('New Passcode is required', Toast.LONG, Toast.TOP);
                setToster(true)
                setMessage('New Passcode is required')
                setType('error')
                return false;
            }
            if (newpass.confirmpass == undefined || newpass.confirmpass.trim() == "") {
                // Toast.showWithGravity('Confirm New Passcode is required', Toast.LONG, Toast.TOP);
                setToster(true)
                setMessage('Confirm New Passcode is required')
                setType('error')
                return false;
            }

            setshowalertmsg(true);
            setMsg("Your passcode must be a 4 digit numeric pin")



        }
    }
    const handlePass = (e) => {
        const re = /^[0-9\b]+$/;
        const lengthcheck = /^[0-9]{0,4}$/;
        if (e.target.value === '' || re.test(e.target.value) && lengthcheck.test(e.target.value)) {
            setshowalertmsg(false);
            setNewpass({
                ...newpass,
                [e.target.name]: e.target.value
            }
            )
        }
        else {
            setshowalertmsg(true);
            setMsg("Your passcode must be a 4 digit numeric pin")
        }
    }
    return (
        <Layout>
            <div className="change-screen-two-cont">
                <div className="header-text">Change Passcode</div>
                <div className="sub-header-text">Change and create new passcode</div>
                <div className="new-pass-cont">
                    <div className="new-pass-text" >Enter New Passcode</div>
                    <div className="input-cont" >
                        <input type="text" placeholder="Enter passcode" name="newpass" value={newpass.newpass} onChange={(e) => handlePass(e)} />
                    </div>
                    <div className="new-pass-text" style={{ paddingTop: "24px" }}>Confirm New Passcode</div>
                    <div className="input-cont">
                        <input type="text" placeholder="Enter passcode" name="confirmpass" value={newpass.confirmpass} onChange={(e) => handlePass(e)} />
                    </div>
                    {
                        showalertmsg ?
                            <div className="error-cls-cont">
                                <img crossorigin="anonymous" src={RedErroricon} />
                                <div className="error-text">{msg}</div>
                            </div> : ""
                    }

                </div>


                <div className="submit-btn" onClick={() => handleSubmit()}><span>Submit</span></div>
                <ConfirmChangePasscode show={modalShow} onHide={() => setModalShow(false)} />
            </div>
            <Toast text={msg} type={type} open={toster} setClose={() => { setToster(false) }} />
        </Layout>
    )
}


export default Changepasscode;