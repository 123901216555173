import React, { useState, useEffect } from "react";
import "./DoctorProfile.scss";
import star from "../../assets/images/star.svg";
import Profile from "../../assets/images/pic.svg";
import Indian from "../../assets/images/indian.svg";
import Nigerian from "../../assets/images/nigerian.svg";
import British from "../../assets/images/ukflag.png";
import { Toast } from "../../components";
import DropDown from "../Select/";
import axios from "../../redux/axios";
import { api } from "../../utils/constants";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import { numberWithCommas } from "../../utils";
import VideoPlayer from "../VideoPlayer";
import VideoBtn from "../../assets/images/VideoBtn.svg";
import { Row, Col } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

function DoctorProfile2(props) {
  let history = useHistory();
  const { data } = props;
  const [slotDate, setSlotDate] = useState([]);
  const [array, setArray] = useState([]);
  const [error, setError] = useState(false);
  const [videolink, setvideolink] = useState([]);
  const [rate, setRate] = useState(0);
  const getYear = (year) => {
    if (year) {
      let currentyear = moment().format("YYYY");
      return currentyear - year + " Years";
    } else {
      return "";
    }
  };




  useEffect(() => {
    if (data) {
      let videoslist =
        data.profile &&
        data.profile.profileVideoUrl &&
        data.profile.profileVideoUrl.filter((e) => e != "");
      setvideolink(videoslist);
    }
  }, [data]);
  useEffect(() => {
    console.log(data.uuid, "con data");
    console.log(array, "con array");
  }, [array]);
  const handleClickdetails = () => {
    if (array.length !== 0) {
      history.push({
        pathname: "/book_specialist",
        state: {
          selectedData: array,
          doc: data,
        },
      });
    } else {
      setError(true);
    }
  };
  function arrUnique(arr) {
    var cleaned = [];
    arr.forEach(function (itm) {
      var unique = true;
      cleaned.forEach(function (itm2) {
        if (
          moment(itm.date).format("ddd  MM/DD") ==
          moment(itm2.date).format("ddd  MM/DD") &&
          moment(itm.start_time).format("LT") ==
          moment(itm2.start_time).format("LT")
        )
          unique = false;
      });
      if (unique) cleaned.push(itm);
    });
    return cleaned;
  }
  useEffect(async () => {
    if (data) {
      await axios
        .get(api.slots + "/normal/doctor/" + data.uuid)
        .then((res) => {
          let slotlist =
            res.data && res.data.length > 0 ? res.data[0].slots : [];
          slotlist = slotlist.filter(
            (e) => moment(e.start_time) >= moment() && e.isActive == true
          );

          // slotlist = slotlist.filter((e) => e.isActive);
          slotlist = arrUnique(slotlist);

          let resulttime = slotlist.map((e, i) => {
            return {
              label:
                // moment(e.date).format("ddd  MM/DD") +
                moment(e.date).format("DD MMM YYYY") +
                " | " +
                moment(e.start_time).format("LT"),
              value: e._id,
              date: moment(e.date).format("ddd  MM/DD"),
            };
          });
          setSlotDate(resulttime);
          if (
            resulttime.length > 0 &&
            resulttime.length != undefined &&
            resulttime.length != ""
          ) {
            setArray(resulttime[0]);
          } else {
            setArray([]);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [data]);

  // useEffect(() => {
  //   axios
  //     .get(`${api.star_rating}/booking/feedback/starRating/${data.uuid}`)
  //     .then((res) => setRate(Math.round(res.data.result)));
  // }, []);

  function strikeThrough(text) {
    return text
      .split('')
      .map(char => char + '\u0336')
      .join('')
  }


  return (
    <div className="DoctorProfile">
      {
        <div className="docContainer">
          {data.profile &&
            data.profile.avatar &&
            data.profile.avatar != null &&
            data.profile.avatar != "" ? (
            <img
              className="profileimg"
              src={data.profile.avatar}
              alt=""
              crossorigin="anonymous"
            />
          ) : (
            <img
              className="avatarimg"
              src={Profile}
              alt=""
              crossorigin="anonymous"
            />
          )}
          <div className="docDetailsContainer">
            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip id={`tooltip-top`}>Dr.{data.full_name}</Tooltip>
              }
            >
              <div className="docNAme">Dr.{data.full_name}</div>
            </OverlayTrigger>
            {data && data.profile.professional_detail && data.profile.professional_detail.qualification &&
              <OverlayTrigger
                placement={"top"}
                overlay={
                  <Tooltip id={`tooltip-top`}>{data && data.profile.professional_detail.qualification}</Tooltip>
                }
              >
                <div className="docNAme"><div className="details">

                  <p class="font-italic">{data && data.profile.professional_detail.qualification}</p>
                </div></div>
              </OverlayTrigger>
            }






            <OverlayTrigger
              placement={"top"}
              overlay={
                <Tooltip id={`tooltip-top`}>
                  {data.profile.speciality.join(" ")}
                </Tooltip>
              }
            >
              <div className="speciality">
                {data.profile.speciality.join(" ")}
              </div>
            </OverlayTrigger>
            <div className="docDetails">


              <div className="details">
                Location:&nbsp;
                <span>
                  {data.profile.address &&
                    Object.keys(data.profile.address).length > 0
                    ? data.profile.address.country
                    : ""}
                </span>
                <span className="imgholder">
                  <img
                    style={{ width: 17, height: 12.28, marginRight: 3 }}
                    src={
                      data.profile.address.country.toLowerCase() == "india"
                        ? Indian
                        : data.profile.address.country.toLowerCase() ==
                          "united kingdom"
                          ? British
                          : Nigerian
                    }
                  />
                </span>
              </div>
              <div className="details">
                Experience:&nbsp;
                <span>{getYear(data.profile.practice_started_year)}</span>
              </div>
              <div className="details">Languages Spoken:</div>
              <div className="details">
                <span>{data.profile.languages.join(", ")}</span>
              </div>
            </div>
            {/* <div className="starcontainer">
              {[...Array(rate)].map((el, index) => {
                return (
                  <img
                    key={index}
                    crossorigin="anonymous"
                    src={star}
                    alt="start"
                  />
                );
              })}
            </div> */}
            <div className="hr" />
            <div className="feeContainer">

              {data && data.consultation_fee > 0 ? (
                <span>₦{numberWithCommas(data.consultation_fee)}</span>
              ) : (
                <span>Free</span>
              )}
              <span style={{ fontSize: "10px" }}>Consultation Fee:</span>{" "}
            </div>
          </div>
        </div>
      }
      {props.type && props.type === "searchdoctor" && (
        <div className="appoContainer">
          <div className="heading">Earliest Available Time</div>
          <div className="appointmentDetails" style={{ padding: "10px" }}>
            {slotDate.length > 0 &&
              slotDate.length !== undefined &&
              slotDate.length !== "" ? (
              <>
                <div className="availableTime">
                  <DropDown
                    placeholder={"Slots"}
                    // value = {slotDate && slotDate[0] ? slotDate[0] :""}
                    value={array}
                    // value={slotDate[0].label}
                    setValue={(e) => setArray(e)}
                    options={slotDate}
                  />
                </div>
              </>
            ) : (
              <div className="availableTime">No Slots Avaliable</div>
            )}
            {/* {data.slots.length !== 0?
                <>
                <div className="availableTime" >
                {data.slots.length > 0?
                
                
                            <>
                            <div className="availableTime" >
                            <DropDown
                            placeholder={"Slots"} 
                            // value = {slotDate && slotDate[0] ? slotDate[0] :""}
                            value={array}
                            // value={slotDate[0].label} 
                            setValue={(e) => setArray(e)} 
                            options={slotDate}
                            />
                            </div>
                            </>
                            : 

                            <div className="dateOfBooking" >
                            <select name="bookingDate" className='inputForm'>
                                <option value="">No slots Found</option>
                            </select>
                            </div>
                            }

                </div>
                
                </>
            
                : 
                <div className="availableTime" >
                   No Slots Avaliable
                </div>
                } */}
          </div>
          <Toast
            text={"Select the Date first"}
            type="error"
            open={error}
            setClose={() => {
              setError(false);
            }}
          />
          <div className="iconButtons">
            <div>
              <Col lg={12} xs={12} >
                {slotDate.length > 0 &&
                  slotDate.length !== undefined &&
                  slotDate.length !== "" ? (
                  <button
                    className="bookDocBtn"
                    disabled={false}
                    onClick={handleClickdetails}
                  >
                    Book Doctor
                  </button>
                ) : (
                  <button
                    className="bookDocBtn"
                    style={{ background: "#4993a0bf" }}
                    disabled={true}
                    onClick={handleClickdetails}
                  >
                    Book Doctor
                  </button>
                )}
              </Col>
            </div>
            <div>
              <Col lg="12" xs="12" md="12">
                {/* <button className="bookDocBtn" disabled={true} onClick={handleClickdetails}>
                Book Doctor
                </button> */}
                <button
                  className="docDetailBtn"
                  onClick={() => {
                    history.push({
                      pathname: "/about_doctor",
                      data: data,
                    });
                  }}
                >
                  <img
                    crossorigin="anonymous"
                    src={require("../../assets/images/eyeProf.svg").default}
                    alt=""
                  />
                </button>

                {videolink !== "" &&
                  videolink !== undefined &&
                  videolink.length > 0 ? (
                  <button className="docVideoBtn">
                    <VideoPlayer popup videos={videolink}>
                      {(play) => (
                        <Col xs={12}>
                          <img
                            crossorigin="anonymous"
                            src={
                              require("../../assets/images/videoicon.svg").default
                            }
                            alt=""
                            onClick={play}
                          />
                        </Col>
                      )}
                    </VideoPlayer>
                  </button>
                ) : (
                  <button style={{ cursor: "not-allowed" }} className="docVideoBtn">
                    <img
                      crossorigin="anonymous"
                      src={require("../../assets/images/videoicon.svg").default}
                      alt=""
                      style={{ opacity: "0.3" }}
                    />
                  </button>
                )}
              </Col>
            </div>
          </div>
        </div>
      )}
      {props.type && props.type === "searchdoctor" && (
        // <a href="https://doctall.com/more/contact/" target="_blank">
        <p className="contactAdmin">
          {/* Can’t find a convenient slot? <br />
            Please contact us to book this doctor. */}
          Please call us or WhatsApp at <br />
          +234 9010996000 to book this doctor
        </p>
        // </a>
      )}
      {props.type && props.type == "appointlist" && (
        <div className="appoContainer">
          <div className="heading">Appointment Details</div>
          <div className="appointmentDetails">
            <div className="details">
              Date:&nbsp;
              <span>{moment(data.booking_date).format("DD,MMM, YYYY")} </span>
            </div>
            <div className="details">
              Time:&nbsp;<span>{moment(data.start_time).format("LT")}</span>
            </div>
            <div className="details">
              ID:&nbsp;<span>{data.uuid}</span>
            </div>
            <div className="details">
              Status:&nbsp;
              <span>
                {data.status.charAt(0).toUpperCase() + data.status.slice(1)}
              </span>
            </div>
            <div className="detailswithout">
              Type:&nbsp;
              <span>
                {data.type == "sadn"
                  ? `See a Doctor Now ₦750`
                  : data.type == "free"
                    ? "Book an appointment\n- FREE"
                    : "Find a Doctor / Specialist (International & Nigerian)"}
              </span>
            </div>
          </div>
          <div
            className={data.status != "cancelled" ? "appoButton" : "disable"}
            onClick={() => {
              data.status != "cancelled"
                ? history.push({ pathname: "/appointmentdetail", data: data })
                : console.log("");
            }}
          >
            VIEW DETAILS
          </div>
        </div>
      )}
    </div>
  );
}

export default DoctorProfile2;
