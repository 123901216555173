import React from 'react';
import Chat from '../../components/support/Chat';
import Layout from '../../layout/DashboardLayout'
const ChatView = () => {
    return (
        <Layout>
            <div className="pictogram-container">
                <div>
                    <Chat />
                </div>
            </div>
        </Layout>
    );
};

export default ChatView;