import React, { useState, useEffect, Fragment } from "react";
import "./Zoom.scss";
import Profile from "../../assets/images/pic.svg";
import Bulb from "../../assets/images/bulb.svg";
import Consultation from "../../assets/images/consultation.svg";
import Quotes from "../../assets/images/quotes.svg";
import Carousel from "react-bootstrap/Carousel";
import axios from "../../redux/axios";
import { api } from "../../utils/constants";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const ZoomWebSdK = () => {
  const [index, setIndex] = useState(0);
  const [tipstate, setTipstate] = useState([]);
  const [doctor, setDoctor] = useState(false);
  const history = useHistory();
  const data = history.location.data;
  const userdata = useSelector((state) => state.auth);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  useEffect(() => {
    if (!history.location.data) {
      history.push("/dashboard");
    }
  }, []);
  useEffect(() => {
    if (data) {
      axios
        .get(api.tips)
        .then((res) => {
          if (res.data) setTipstate(res.data);
        })
        .catch((err) => {});

      axios
        .get(api.user + "/" + data.doctor_uuid)
        .then((res) => {
          setDoctor(res.data);
        })
        .catch((err) => {});
    }
  }, []);
  // const Navigate = () => {
  //     let currenttime = moment().add("20", 'minutes')
  //     let bookingtimeminus = moment().subtract(7, 'minutes')
  //     let bookingtime = moment(data.start_time);
  //     let aftercheck = bookingtime > bookingtimeminus && bookingtime < currenttime;
  //     if (aftercheck) {
  //         history.push({ pathname: "/meeting/" + data.zoomData.meetingId + "/" + data.zoomData.password, data: data });
  //     }
  // }
console.log(data, "datadatadatadata")
  useEffect(() => {
    if (moment().isBefore(data.start_time) === false) {
            var fdata = {
                "patient_uuid": data.patient_uuid,
                "doctor_uuid": data.doctor_uuid,
                "booking_uuid": data.uuid,
            }
            let enc = window.btoa(JSON.stringify(fdata))
            var bookdata ={
                fullname : userdata.full_name,
                email: userdata.email,
                leaveurl: window.location.origin + '/dashboard?feedback='+enc,
                zoomtype :"consumer"
            }
            let encData = window.btoa(JSON.stringify(window.location.origin + '/dashboard?feedback='+enc))
            console.log(encData, "encData")
            // let encdata = window.encodeURIComponent(window.btoa(JSON.stringify(bookdata)));
            // window.location.href = "https://zoom.doctall.com/meeting/"+ data.zoomData.meetingId + "/" + data.zoomData.password+"/"+encdata;
      // history.push({
      //   pathname:
      //     "/meeting/" + data.zoomData.meetingId + "/" + data.zoomData.password,
      //   data: data,
      // });
      history.push({
        pathname:
          "/meeting/" + window.btoa(data.zoomData.roomUrl) + "/" + window.btoa(`/dashboard?feedback=${enc}`),
        data: data,
      });
    }
  }, [moment()]);
  // useEffect(()=>{
  //     setTimeout(() => {
  //     Navigate();
  //         console.log('hit')
  //     }, 3000);
  // },[])
  // useEffect(() => {
  //     let repeat;

  //     const fetchData = async () => {
  //         try {
  //             Navigate();
  //             repeat = setTimeout(fetchData, 6000);

  //         } catch (error) {
  //             console.error(error.message)
  //         }
  //     }

  //     fetchData();

  //     return () => {
  //         if (repeat) {
  //             clearTimeout(repeat);
  //         }
  //     }
  // }, []);
  return (
    <>
      <div className="zoomiframe">
        <div className="doctorcontainer">
          {doctor && (
            <Fragment>
              <div className="blur1">
                <div className="blur2">
                  {doctor.profile && doctor.profile.avatar ? (
                    <img
                      crossorigin="anonymous"
                      className="profileimg"
                      src={doctor.profile.avatar}
                      alt=""
                      crossorigin="anonymous"
                    />
                  ) : (
                    <img
                      crossorigin="anonymous"
                      className="avatarimg"
                      src={Profile}
                      alt=""
                      crossorigin="anonymous"
                    />
                  )}
                </div>
              </div>
              <div className="welcome-text-cont">
                <div className="welcome-text">
                  Welcome to Doctall waiting room{" "}
                </div>
              </div>
              <div className="profile-text">{`Dr ${doctor.full_name} will see you shortly. Please ensure \n you are situated in a quiet environment with great\n internet connection.`}</div>
              <div className="appoint-cont">
                <div className="appoint-det">
                  <div className="appoint-text">
                    Your appointment is scheduled to begin at
                  </div>
                  <div className="appoint-sub-cont">
                    <span>{moment(data.start_time).format("LT")}</span>
                  </div>
                </div>
              </div>
            </Fragment>
          )}
          <div className="carousel-cls">
            <Carousel activeIndex={index} onSelect={handleSelect}>
              {tipstate != undefined && tipstate.length > 0
                ? tipstate.map((val, ind) => {
                    return (
                      <Carousel.Item>
                        <div className="item-cls-one">
                          <div className="item-cont">
                            <div className="img-cont">
                              {val.category == "health" ? (
                                <img crossorigin="anonymous" src={Bulb} />
                              ) : val.category == "consultation" ? (
                                <img
                                  crossorigin="anonymous"
                                  src={Consultation}
                                />
                              ) : val.category == "quote" ? (
                                <img crossorigin="anonymous" src={Quotes} />
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="right-cont">
                              <div className="row-one">
                                {val.category == "health"
                                  ? "Health Tips"
                                  : val.category == "consultation"
                                  ? "Consultation Tips"
                                  : val.category == "quote"
                                  ? "Quotes"
                                  : ""}
                              </div>
                              <div className="row-two">
                                {val.category == "health"
                                  ? val.title
                                  : val.category == "consultation"
                                  ? val.title
                                  : val.category == "quote"
                                  ? val.title
                                  : ""}
                              </div>
                              <div className="row-three">
                                {val.category == "health"
                                  ? val.description
                                  : val.category == "consultation"
                                  ? val.description
                                  : val.category == "quote"
                                  ? val.description
                                  : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Carousel.Item>
                    );
                  })
                : ""}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  );
};
export default ZoomWebSdK;
