import React, { useEffect, useState } from 'react';
import './OrderTracking.scss';
import { Row, Col } from "react-bootstrap";
import TrackIndividualTests from './TrackIndividualTests';
import { useSelector, useDispatch } from "react-redux";
import { getSearchedOrders } from './../../redux/orders/actions';
import Layout from "../../layout/DashboardLayout";
import ICON13 from '../../assets/images/ICON13.svg'
import { useHistory } from "react-router-dom";


function OrderTracking(props) {
    let history = useHistory()
    const dispatch = useDispatch();

    let orderState = props.location.state || {};
    let orderDetails = orderState.order_details ? orderState.order_details : {}
    let orderFullDetails = useSelector((state) => state.orders.data);

    const getOrderType = (status) => {
        switch (status) {
            case "popular":
                return "Diagnostics";
            case "recommended":
                return "Recommended Test";
            case "prescription":
                return "Prescription";
            case "biointel":
                return "Bio Intel Panel";
            default:
                return status;
        }
    }

    return (
        <Layout loadin={null} load={null}>
            <div>
                <div className="cont-wrapper-health-record">
                    <div className="text-wrap" style={{ marginBottom: '8%' }}>
                        <div className="goBack mt-4" onClick={() => { history.goBack() }}>
                            <img crossorigin="anonymous" src={ICON13} alt="arrow" className="img-fluid" />
                            <p>back</p>
                        </div>
                    </div>

                </div>
                <hr style={{ border: "1px solid #C5D8FF", width: "95%" }} />
                <div className='order-details'>
                    <Row style={{ color: "#163E8C" }}>
                        <div className="test-22">
                            <Col>
                                <span style={{ marginLeft: "-2%", fontSize: "20px" }}>ORDER ID: <b> {orderDetails.uuid} </b></span>
                                <div className='order-date-type'>
                                    <Row>
                                        <span style={{ fontSize: "16px", marginTop: "5%" }}>  Order Date: {orderDetails.order_info ? orderDetails.order_info.order_date : ''}</span>
                                    </Row>
                                    <Row>
                                        <span style={{ fontSize: "16px", marginTop: "0%" }}> Order Type: {orderDetails && orderDetails.order_info && orderDetails.order_info.type && getOrderType(orderDetails.order_info.type)}</span>
                                    </Row>
                                </div>
                            </Col>
                            <Col>

                                <div className="test-33">
                                    <Row>
                                        <span style={{ fontSize: "16px" }}>PAYMENT ID: {orderDetails.order_info ? orderDetails.order_info.reference_id : ''}</span>
                                    </Row>
                                    <Row>
                                        <span style={{ 'font-size': '12px', marginTop: "10%" }}>Total Cost:</span>
                                    </Row>

                                    <Row>
                                        <span style={{ 'font-size': '25px', marginTop: "3%" }}> ₦{orderDetails.order_info ? orderDetails.order_info.total_cost : ''}</span>
                                    </Row>
                                    {orderDetails.home_service && orderDetails.home_service.home_service && orderDetails.home_service.home_service == 'yes' ?
                                        <Row>
                                            <span className='order-tracking-paid'>Home sample collection included ({orderDetails.home_service.home_service_amount ? orderDetails.home_service.home_service_amount : "N/A"})</span>
                                        </Row> : null

                                    }

                                </div>
                            </Col>
                        </div>
                    </Row>

                </div>
                <hr style={{ border: "1px solid #C5D8FF", width: "95%" }} />
                <div className='individual-tests'>
                    <TrackIndividualTests orderDetails={orderDetails} />
                </div>
            </div>
        </Layout >
    )
}

export default OrderTracking
