import React, { useState, useEffect } from 'react'
import { Tabs, Tab, Typography, Box } from '@material-ui/core';
import './tabs.scss';
import PropTypes from 'prop-types';
import PrescriptionTab from './prescription'
import Prescription from './prescription';
import LabTest from './Labtest';
import Upload from './Upload';

function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}


const RecordTabs = (props) => {
  const { load } = props
  const [value, setValue] = useState(0)

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="tab-cls-health-rec">
      <Tabs
        style={{ backgroundColor: '#FFFFFF', paddingRight: "20px" }}
        value={value}
        onChange={handleChangeTab}
        indicatorColor="primary"
        textColor="#00839B"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example">

        <Tab
          style={{
            color: "#00839B",
            fontSize: '16px',
            fontFamily: "VisueltMedium",
            backgroundColor: value === 0 ? '#F2F8F9' : '#FFFFFF'
          }}
          label="Prescriptions"
          {...a11yProps(0)} />
        <Tab
          style={{
            color: "#00839B",
            fontSize: '16px',
            fontFamily: "VisueltMedium",
            backgroundColor: value === 1 ? '#F2F8F9' : '#FFFFFF'
          }}
          label="Lab Test Advice"
          {...a11yProps(0)} />

        {/* <Tab 
            style={{color:"#00839B",
            fontSize:'16px',
            fontFamily:"VisueltMedium",
            backgroundColor: value === 2 ? '#F2F8F9':'#FFFFFF'}} 
            label="Orders" 
            {...a11yProps(0)}  disabled/>
               <Tab 
            style={{color:"#00839B",
            fontSize:'16px',
            fontFamily:"VisueltMedium",
            backgroundColor: value === 3 ? '#F2F8F9':'#FFFFFF'}} 
            label="Lab Results" 
            {...a11yProps(0)} disabled/> */}
        <Tab
          style={{
            color: "#00839B",
            fontSize: '16px',
            fontFamily: "VisueltMedium",
            backgroundColor: value === 2 ? '#F2F8F9' : '#FFFFFF'
          }}
          label="Uploads"
          {...a11yProps(0)} />
      </Tabs>

      <TabPanel value={value} index={0}>
        <Prescription setLoading={load} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <LabTest setLoading={load} />
      </TabPanel>
      {/* <TabPanel value={value} index={2}>
              tab3
              </TabPanel>
              <TabPanel value={value} index={3}>
              tab4
              </TabPanel> */}
      <TabPanel value={value} index={2}>
        <Upload setLoading={load} />
      </TabPanel>
    </div>
  )
}

export default RecordTabs;