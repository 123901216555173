import React, { useEffect, useState } from "react";
import "./TrackingStatus.scss";
import { select } from "redux-saga/effects";
import { api } from "../../utils/constants";
import axios from "axios";
import moment from "moment";

export const getUser = (state) => state.auth;

function TrackingStatus(props) {
  console.log(props,"props")
  let trackingDetails = [
    {
      status: "Sample Collected",
      date: "1st Sept",
    },
    {
      status: "Pending",
      date: "15th Sept",
    },
    {
      status: "In-progress",
      date: "1st Oct",
    },
    {
      status: "Completed",
      date: "15th Oct",
    },
    {
      status: "Result released",
      date: "1st Nov",
    },
  ];

  const [full_order_details, set_full_order_details] = useState({});
  const [test_tracking_details, set_test_tracking_details] = useState({});

  useEffect(() => {
    set_test_tracking_details(props.trackInfo);
  }, [props.trackInfo]);

  let getTrackingDetails = (orderId, testUUID) => {
    let url = `${api.Order}/ordertracking/byOrderIdAndItemId?orderId=${orderId}&itemId=${testUUID}`;

    //  let url=`http://test-api.doctall.com/api/v1/order/ordertracking/byOrderIdAndItemId?orderId=DOC-67D3FE9C9E1D&itemId=LPT-C30C9669`

    axios.get(url).then((res) => {
      console.log(res.data);
      set_test_tracking_details(res.data);
    });
  };

  let stage = "sample collected";
  let dotColor = "#fff4ff";

  const getCustomizeStatus = (status) => {
    switch (status) {
      case "paymentmade":
        return "Payment Made";
      case "agreedforrefund":
        return "Agreed For Refund";
      case "agreedforreschedule":
        return "Agreed For Schedule";
      case "samplecollected":
        return "Sample Collected";
      case "acceptedtest":
        return "Accepted Test";
      case "rejectedtest":
        return "Rejected Test";
      case "agreedforaneworder":
        return "Agreed For New Order";
      case "resultuploaded":
        return "Result Uploaded";
      case "refunded":
        return "Refunded";
      case "rescheduled":
        return "Rescheduled";
      case "sampleinsufficient":
        return "Sample Insufficient";
      case "completed":
        return "Completed";
      default:
        return status;
    }
  };

  let trackingColors = [
    "#A587CA",
    "#36CEDC",
    "#FFEA56",
    "#8FE968",
    "#FFB750",
    "#517664",
    "#ffaff0",
  ];

  return (
    <div
      style={{
        borderStyle: "ridge",
        borderSize: "1px",
        borderColor: "#37CC8C",
        padding: "10px",
        marginLeft: "0px",
        marginRight: "120px",
        marginBottom: "120px",
        borderRadius: "10px",
      }}
    >
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="bg-white p-2  rounded px-3 status_tracker">
              <div className="status_tracker_indicator">
                <span
                  style={{
                    height: "10px",
                    width: "10px",
                    marginLeft: "3px",
                    marginRight: "3px",
                    marginTop: "0px",
                    backgroundColor: "#A587CA",
                    border: "5px",
                    boxShadow: "0 0 10px 5px #A587CA",
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                ></span>

                {test_tracking_details &&
                  test_tracking_details.length > 0 &&
                  Object.keys(test_tracking_details).map((key) => {
                    if (key != "0") {
                      return (
                        <>
                          <hr className="flex-fill track-line" />
                          <span
                            style={{
                              height: "10px",
                              width: "10px",
                              marginLeft: "3px",
                              marginRight: "3px",
                              marginTop: "0px",
                              color: dotColor,
                              background: trackingColors[Number(key)],
                              boxShadow: `0 0 10px 5px ${trackingColors[Number(key)]
                                }`,
                              opacity: "0.5",

                              borderRadius: "50%",
                              display: "inline-block",
                            }}
                          ></span>
                        </>
                      );
                    }
                  })}
              </div>
              <div className="status_tracker_text">
                {test_tracking_details &&
                  test_tracking_details.length > 0 &&
                  Object.keys(test_tracking_details).map((key) => {
                    return (
                      <div className="status_tracker_text_inner">
                        <small>
                          {test_tracking_details[key] &&
                            test_tracking_details[key]["createdAt"]
                            ? moment(
                              test_tracking_details[key]["createdAt"]
                            ).format("DD-MMM-YYYY")
                            : ""}
                        </small>
                        <span>
                          {test_tracking_details[key] &&
                            test_tracking_details[key]["test_status"]
                            ? getCustomizeStatus(
                              test_tracking_details[key]["test_status"]
                            )
                            : ""}
                        </span>
                      </div>
                    );
                  })}

                {/* <div className="d-flex flex-column justify-content-center">
                  <span>15 Mar</span>
                  <span>{stage}</span>
                </div>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <span>15 Mar</span>
                  <span>{stage}</span>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <span>15 Mar</span>
                  <span>{stage}</span>
                </div>
                <div className="d-flex flex-column align-items-end">
                  <span>15 Mar</span>
                  <span>{stage}</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrackingStatus;
