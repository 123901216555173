import InputField from '../components/InputField';
import Select from '../components/Select';
import RadioButtonField from '../components/RadioButtonField';
import CheckBtn from '../components/CheckBtn';
import RangeField from '../components/RangeField';

const Fields = {
   input: InputField,
   select: Select,
   radio: RadioButtonField,
   checkbox: CheckBtn,
   range: RangeField
};

export default Fields;