import React from 'react';
import './DropDown.scss'
import Dropdown from 'react-dropdown';
import img from '../../assets/images/ICON16.svg';

function DropDown(props) {

    const { metaData } = props;

    const isPredefined = props.options && typeof props.options === 'string' && metaData && Object.keys(metaData).length > 0;
    return (
        <>
            <Dropdown
                options={isPredefined ? metaData[props.options] : props.options ? props.options : []}
                onChange={value => props.onChange ? props.onChange(Object.assign({}, { target: { name: props.name, ...value } })) : props.setValue(value)}
                value={props.value}
                defaultValue={props.defaultValue}
                name={props.name}
                data-id={props.data_id}
                placeholder={props.placeholder}
                className={`${props.hr ? 'dropdown-health-record' : 'dropdown'}`}
                menuClassName="dropdownMenu"
                controlClassName='myControlClassName'
                arrowClassName='myArrowClassName'
                arrowClosed={<img crossorigin="anonymous" src={img} />}
                arrowOpen={<img crossorigin="anonymous" src={img} />}

            />
        </>
    )
}

export default DropDown
