import React from 'react'
import './DoctorProfile.scss';
import Profile from "../../assets/images/pic.svg";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
function DoctorProfile(props) {
    const { data } = props

    return (
        <div className="ChaperoneProfile">
            <div className="docContainer">
                {data.profile.avatar ?
                    <img crossorigin="anonymous" className="avatarimg" src={data.profile.avatar} alt="" /> :
                    <img crossorigin="anonymous" className="avatarimg" src={Profile} alt="" />}
                <div className="docDetailsContainer">
                    <div className="headtitle">Doctor Chaperone</div>
                    <OverlayTrigger
                        placement={"top"}
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                Dr.{data.full_name}
                            </Tooltip>
                        }
                    >
                        <div className="docNAme">Dr.{data.full_name}</div>
                    </OverlayTrigger>

                    <div className="docDetails">
                        <OverlayTrigger
                            placement={"top"}
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    {data.profile.speciality.join(" ")}
                                </Tooltip>
                            }
                        >

                            <div className="details">Speciality:&nbsp;{data.profile.speciality.join(" ")}</div>
                        </OverlayTrigger>
                        <div className="details">
                            Location:&nbsp;<span>{data.profile.address && Object.keys(data.profile.address).length > 0 ? data.profile.address.country : ""}</span>
                        </div>
                        <div className="details">
                            Nationality:&nbsp;<span>{data.profile.address && Object.keys(data.profile.address).length > 0 ? data.profile.address.country : ""}</span>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    )
}

export default DoctorProfile
