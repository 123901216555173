import React, { useEffect, useState } from "react";
import "./VideoCard.scss";
import Videoicon from "../../assets/images/VideoCall.svg";
import Clock from "../../assets/images/clock.svg";
import Calender from "../../assets/images/calender.svg";
import Info from "../../assets/images/info.svg";
import moment from 'moment';
import { useHistory } from "react-router-dom";
const Videocard = (props) => {
  const [show, setShow] = useState(false);
  const history = useHistory();
  const [videoAble, setVideoable] = useState(false);
  const showAlert = () => {
    setShow(!show);
  }
  const videoenable = (data) => {

    if (data) {
      let currenttime = moment().add("5", 'minutes')
      let bookingtimeminus = moment().subtract(5, 'minutes')
      let currenttimeend = moment(data.end_time).subtract(5, 'minutes')
      let bookingtime = moment(data.start_time);
      let bookingtimeaddused = moment(data.start_time).add("5", 'minutes')
      let aftercheck = bookingtime > bookingtimeminus && bookingtime < currenttime;
      let endcheck = moment() > bookingtimeaddused && moment() < currenttimeend;
      console.log(bookingtime,bookingtimeminus,bookingtime,currenttime)
      if (aftercheck) {
        setVideoable(true);
      }
      else if (endcheck) {
        setVideoable(true);
      }
      else {
        setVideoable(false);
      }
    }
  }

  useEffect(() => {
    videoenable(props.next)
  }, [props.next])
  if (props.appointment && !videoAble) {
    return null;
  }
  return (
    <>
      {
        props.next != undefined && props.next != "" && props.next != null ?
          <div className="video-container" onClick={() => { videoAble ? history.push({ pathname: "waiting", data: props.next }) : console.log("video") }}>
            <div className="container-left">
              <div className="blur1">
                <div className="blur2">
                  <img crossorigin="anonymous" src={Videoicon} className="icon" alt="doctall"  ></img>
                </div>
              </div>
            </div>
            <div className="container-right">
              <div className="top-text">Join pending appointment</div>
              <div className="bottom-text-container">
                <div>
                  <img crossorigin="anonymous" src={Clock} alt="doctall" className="icon" ></img>
                  <div className="time-text">{props.next != undefined && props.next.start_time != undefined ? moment(props.next.start_time).format("hh:mm A") : ""}</div>
                </div>

                <div>
                  <img crossorigin="anonymous" src={Calender} className="icon2" alt="doctall"  ></img>
                  <div className="time-text" >{props.next != undefined && props.next.booking_date != undefined ? moment(props.next.booking_date).format("MMM, DD YYYY") : ""}</div>
                </div>

              </div>
            </div>
            {videoAble == false && <div className="disablelayer" />}
            {videoAble == false && <img crossorigin="anonymous" src={Info} alt="doctall" className="infoimg" onClick={() => { showAlert() }} ></img>}
            {show && <div className="showinfo">
              <div>
                This button will become active five (5) minutes before your scheduled appointment
              </div>
            </div>}
          </div>
           : ""
      }

    </>
  )

}

export default Videocard;