import Modal from 'react-bootstrap/Modal';
import Closebutton from "../../assets/images/closebutton.svg";
import Circle from "../../assets/images/circle.svg";
import Tick from "../../assets/images/tick.svg";
import './successpop.scss';

export default function MydModalWithGrid(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered contentClassName="modal-cls">
      <div className="header">
        <div className="close-btn">
          <img crossorigin="anonymous" src={Closebutton} alt="close-btn" onClick={() => props.onHide()} ></img>
        </div>
      </div>
      <div className="success-img-cont-pass">
        <div className="img-wrap">
          <img crossorigin="anonymous" src={Circle} alt="circle" ></img>
          <div className="img-tick">
            <img crossorigin="anonymous" src={Tick} alt="tick" ></img>
          </div>
        </div>
      </div>
      <div className="font-one">Passcode changed successfully</div>
      <div className="font-two">You will be automatically logged <br />out in a few seconds</div>
    </Modal>
  );
}