import { Fragment } from 'react';
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { logout } from '../redux/auth/actions';
import { encrypt } from '../utils';
import { usernameKey } from '../utils/constants';

const ProtectedRoutes = ({ children }) => {
  const [cookies, setCookies] = useCookies(["redirect"])
  const history = useHistory();
  const dispatch = useDispatch();

  const { isAuthenticated, verified } = useSelector(state => state.auth); 
  console.log(isAuthenticated, verified, history.location.pathname, "gfhgh")
  // if (isAuthenticated && cookies[usernameKey] === 'null') {
  //   dispatch(logout())
  //   setTimeout(() => {
  //     document.cookie = 'doctallLog' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;domain=.doctall.com';
  //     history.push("/");
  //     window.location.reload(false);
  //   });
  // }

  const encodeUrl = history.location.pathname
  return (
    <Fragment>
      {isAuthenticated && verified ? <Fragment>{children}</Fragment> : <Redirect to={`/login?redirect=${encodeUrl}`}/>}
      {/* {(isAuthenticated == false && history.location.pathname == "/dashboard") && <Redirect to={`/login?redirect=${encodeUrl}`}/>} */}
    </Fragment>
  )
}

export default ProtectedRoutes;