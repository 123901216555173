export default {
    LOGIN_USER: 'LOGIN_USER',
    SAVE_LOGIN: 'SAVE_LOGIN',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_SUCCESS_NOTVERIFY: 'LOGIN_SUCCESS_NOTVERIFY',
    LOGIN_ERROR: 'LOGIN_ERROR',
    // new types
    CHECK_AVALIABLITY: 'CHECK_AVALIABLITY',
    AVALIABLITY_SUCCESS: 'AVALIABLITY_SUCCESS',
    AVALIABLITY_ERROR: 'AVALIABLITY_ERROR',

    VERIFY_OTP: 'VERIFY_OTP',
    VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
    VERIFY_OTP_ERROR: 'VERIFY_OTP_ERROR',

    RESEND_OTP: 'RESEND_OTP',
    RESEND_OTP_SUCCESS: 'RESEND_OTP_SUCCESS',
    RESEND_OTP_ERROR: 'RESEND_OTP_ERROR',

    LOCATION: "LOCATION",
    // new types


    REGISTER_USER: 'REGISTER_USER',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_ERROR: 'REGISTER_ERROR',
    REGISTER_COMPLETE: "REGISTER_COMPLETE",

    LOGOUT: 'LOGOUT',

    VERIFY_USER: 'VERIFY_USER',
    VERIFY_SUCCESS: 'VERIFY_SUCCESS',
    VERIFY_ERROR: 'VERIFY_ERROR',

    LOADING_START: 'LOADING_START',
    LOADING_STOP: 'LOADING_STOP',

    NOTIFY_UPDATE: 'NOTIFY_UPDATE',
    CART_UPDATE: 'CART_UPDATE',

    UPDATE_PROFILE: 'UPDATE_PROFILE',


    UPDATE_PROFILE: 'GET_USER_DETAILS',
}
