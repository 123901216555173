import React, { useState, useEffect } from "react";
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'
import FullNavLayout from '../../layout/FullNavLayout'
import 'react-phone-input-2/lib/bootstrap.css'
import './auth.scss'
import { api } from '../../utils/constants'
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Helmet } from 'react-helmet';


function ForgetPasscodeNumber() {

    const [error, setError] = useState(false)
    const [otp, setOtp] = useState(false)
    const [res, setRes] = useState(false)

    const [number, setNumber] = useState("")
    const [mail, setmail] = useState("")
    const [country, setCountry] = useState()

    useEffect(() => {
        fetch('https://ipapi.co/json/')
            .then(res => res.json())
            .then(res => {
                setCountry(res.country_code.toLowerCase());
            })
            .catch((data, status) => {
                console.log('Request failed:', data);
            });
    }, [])

    let history = useHistory();
    const handleSubmit = async (e) => {
        e.preventDefault()
        localStorage.clear()
        console.log(number, mail)
        localStorage.setItem("forgetData", JSON.stringify({ number, mail }))
        if (number !== null && number !== "" && mail !== null && mail !== "") {
            const mobile = number
            const email = mail
            const val = { mobile, email }
            await axios.post(api.resetpassword, val)
                .then((response) => {
                    setOtp(true)
                    setTimeout(function () { history.push('/forgetPin') }, 2000);
                }, (error) => {
                    setRes(true)
                });
        } else {
            setError(true)
        }
    }





    return (
        <FullNavLayout>
            <Helmet>
                <title>Consumer Web Forgot Password - Doctall
</title>
<meta 
            name='description' 
            content='Book a doctor appoinment online. Get packaged treatments for hair loss, erectile dysfunction, premature ejaculation, and other acute and chronic conditions. Find accurate health information about cancer, malaria and much more'
            />
            </Helmet>
            <div className="forgotContainer">
                <h2>Forgot Passcode</h2>

                <form onSubmit={handleSubmit}>
                    <div className="forgetNumber">
                        <label htmlFor="Phone">Phone number</label>
                        <PhoneInput
                            className="inputPhone"
                            country={country}
                            value={number}
                            onChange={setNumber}
                        />
                        <label style={{ marginTop: "12px" }} htmlFor="email">Email</label>
                        <input
                            className="inputForm"
                            type="email"
                            name='mail'
                            value={mail}
                            onChange={e => setmail(e.target.value)}
                        />
                    </div>


                    <button type="submit" className='sumbitBtn'>Send Code</button>
                    <p>You’ll receive the code on your registered phone number
                        and email address</p>
                </form>
            </div>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={error}
                autoHideDuration={3000}
                onClose={() => setError(false)}>
                <Alert severity="warning">
                    Enter the number and mail-id
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={otp}
                autoHideDuration={3000}
                onClose={() => setOtp(false)}>
                <Alert severity="success">
                    Otp sent!
                </Alert>
            </Snackbar>
            <Snackbar anchorOrigin={{ vertical: "top", horizontal: "right" }} open={res}
                autoHideDuration={3000}
                onClose={() => setRes(false)}>
                <Alert severity="error">
                    something went wrong!
                </Alert>
            </Snackbar>


        </FullNavLayout>
    )
}

export default ForgetPasscodeNumber
