import Login from '../views/Auth/Login';
import SignUp from '../views/Auth/signUp';
import SetPassCode from '../views/Auth/setPassCode';
import ConfirmVerification from '../views/Auth/confirmVerification';
import ForgotPassword from '../views/Auth/forgotPassword';
import ForgetPasscodeNumber from '../views/Auth/forgetPasscodeNumber';
import ForgetPin from '../views/Auth/forgetPin';
import CreateNewPasscode from '../views/Auth/createNewPasscode';
import Doctors from '../views/Doctors';
import LandingPage from '../views/Landing page/LandingPage';
import DoctorDetails from '../views/DoctorDetails';
import OrdersResults from '../views/OrdersResults';
import Dashboard from "../views/Home";
import FADNBooking from '../views/Auto-Login/FADNBooking';
import FADNPage from '../views/Auto-Login/FADNPage';
import CreatePass from '../../src/views/Auth/CreatePasswordUserName/index'
// import Notfound from '../views/notfound';

const IndexRoutes = [
  { path: '/login', component: Login, id: 'login' },
  { path: '/create_password/:id', component: CreatePass, id: 'createPass' },
  { path: '/signup', component: SignUp, id: 'signup' },
  { path: '/signup1/:userId', component: SignUp, id: 'signup' },
  { path: '/setPassCode', component: SetPassCode, id: 'setPassCode' },
  { path: '/confirmVerification', component: ConfirmVerification, id: 'confirmVerification' },
  // { path: '/forgotPassword', component: ForgotPassword },
  { path: '/forgotPassword', component: ForgetPasscodeNumber, id: 'forgotPassword' },
  { path: '/forgetPin', component: ForgetPin, id: 'forgetPin' },
  { path: '/createNewPasscode', component: CreateNewPasscode, id: 'createNewPasscode' },
  { path: '/', component: Doctors, id: 'doctors' },
  // { path: '/', component: LandingPage },
  { path: '/doctorDetails/:id', component: DoctorDetails, id: 'doctorDetails' },
  { path: '/orders-result-dmp/:userId', component: OrdersResults, id: 'ordersResults' },
  { path: '/dashboardData/:userId', component: Dashboard, id: 'dashboarddata' },
  { path: '/book-doctor/:queryParam', component: FADNBooking, id: 'bookDoctor' },
  { path: '/find-doctor/:queryParam', component: FADNPage, id: 'findDoctor' },
  { path: '/dashboard', component: Dashboard, id: 'dashboard' },
  { path: '/signup1/:userId', component: SignUp, id: 'signup' },
  // { path: '*', component: Notfound},
];

export default IndexRoutes;