import React from 'react';
import moment from 'moment-timezone'
import './card.scss';

function CardItem({ elbow }) {
    return (
        <div className="card_container">
            <img  className="card_img" src={elbow.guid} alt="img" />
            <div className="text_container">
                <div className="heading">{elbow.post_title}</div>
                <div className="hr"></div>
                <div className="small_container">
                <div className="date">{moment(elbow.post_date).format("D MMMM  YYYY")}</div><div className="txt"> <span>|</span> 3 MINS READ</div>
                </div>
            </div>
        </div>
    )
}

export default CardItem
