/**
 *
 * Filters Form
 *
 */

import React, { useState } from "react";
import ImplementationFields from "../../utils/Fields";
import { Container, Row, Col } from "react-bootstrap";
import "./tabs.scss";
import BookCards from "./BookCards";
import DateRange from "./DateRange";
import moment from 'moment';
/**
 *
 * @param {object} props
 * @returns
 */
export default function FiltersForm(props) {
  const {
    initialValues = {},
    fields,
    onChange,
    className,
    style = {},
    handleSubmit,
    metaData = {},
    handleClear,
  } = props;

  const [startDate, handleStartDate] = useState(null);
  const [endDate, handleEndDate] = useState(null);
  const [status, handlestatus] = useState(null);

  let getDates = (dates) => {
    if (dates && dates.length == 2) {
      if (dates[0] && dates[1]) {
        let sDate = moment(dates[0]).format('YYYY-MM-DD');
        let eDate = moment(dates[1]).format('YYYY-MM-DD');
        handleStartDate(sDate);
        handleEndDate(eDate)
      }
    }
  }

  let handleApply = () => {
    
    let filterObj = {
      start_date: startDate?startDate:null,
      end_date: endDate?endDate:null,
      status: status?status:null
    }

    props.handleAppliedFilter(filterObj)
  }
  let handleStatus = (e) => {
    
    handlestatus(e.target.value)
  }
  return (
    <>
      <div className="drop-filter"  >
        <Row className={`FilterForm ${className}`} style={style}>
          <Col xs={12}>

            <Row style={{ alignItems: "center" }}>
              <Col className="form-label" xs={2} md={2} >
                Filter By



              </Col>
              <Col>

                <DateRange getDates={getDates} />

              </Col>

              {(fields || []).map((field, index) => {
                const Input = ImplementationFields[field.type];
                return (
                  <Col
                    className={field.className || null}
                    key={index}
                    sm={5}
                    md={5}
                    lg
                    style={field.style || {}}
                  >
                    <label className="field-label">{field.label}</label>
                    <Input
                      onChange={handleStatus}
                      metaData={metaData}
                      {...field}
                      value={status}
                    />
                  </Col>
                );
              })}
              <Col
                sm={5}
                md={5}
                lg
                className="field-button"
                onClick={handleApply}
                style={{ marginRight: "10px", marginTop: "50px" }}
              >
                <label className="field-label"> </label>
                APPLY FILTER
              </Col>
            </Row>
          </Col>
        </Row>

      </div>

    </>
  );
}
