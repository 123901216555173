import Dashboard from "../views/Home/index";
import FreeBooking from "../views/FreeBooking";
import Meeting from "../views/Zoom";
import Waiting from "../views/Zoom/Waiting";
import HealthProfile from "../views/Profile/healthProfile";
import Appointment from "../views/Appointment";
import chatSupport from '../views/Support/chat'
import AppointmentDetail from "../views/Appointment/Details";
import Changepasscode from "../views/Auth/Changepasscode";
import NewPasscode from "../views/Auth/Changepasscode/NewPasscode"
import RelativeProfile from "../views/Profile/relativeProfile";
import HealthRecords from "../views/Healthrecords/index";
import DoctorConsultations from '../views/DoctorConsultations'
import HealthAndSupport from '../views/HealthAndSupport'
import SadnBooking from '../views/SadnBooking';
import Notification from '../views/Notification';
import FindAnSpecialist from "../views/FindADoc/findAnSpecialist";
import DocProfile from "../views/FindADoc/doctorProfile";
import SadnProoceed from "../views/SadnBooking/sadnProceed";
import RelativeList from "../views/Profile/relativeList";
import AddRelative from "../views/Profile/addRelative";
import BookAnSpecialist from "../views/FindADoc/bookAnDoc";
import OrdersResults from '../views/OrdersResults/index'
import OrderTracking from "../views/OrdersResults/OrderTracking";
import Wallet from "../views/Wallet/index"
import Reffer from "../views/Wallet/Reffer/refer";
import LabOrderView from "../views/OrdersResults/LabOrderView";
import Login from "../views/Auth/Login";
import Notfound from '../views/notfound';
const privateRoutes = [
    { exact: true,  path: '/login', component: Login },
    { exact: true,  path: '/dashboard', component: Dashboard },
    { exact: true,  path: '/orders-results', component: OrdersResults },
    { exact: true,  path: '/orders-view', component: OrderTracking },
    { exact: true,  path: '/orders-view-lab', component: LabOrderView },

    { exact: true,  path: '/waiting', component: Waiting },
    { exact: true,  path: '/meeting/:meetingid/:password', component: Meeting },
    { exact: true,  path: '/free_booking', component: FreeBooking },
    { exact: true,  path: '/sadn_booking', component: SadnBooking },
    // { exact: true,  path: '/sadn_booking', component: SadnBooking },
    { exact: true,  path: '/sadn', component: SadnProoceed },
    { exact: true,  path: '/profile', component: HealthProfile },
    { exact: true,  path: '/relatives', component: RelativeList },
    { exact: true,  path: '/relativeProfile', component: RelativeProfile },
    { exact: true,  path: '/addRelative', component: AddRelative },
    { exact: true,  path: '/appointments', component: Appointment },
    { exact: true,  path: '/appointmentdetail', component: AppointmentDetail },
    { exact: true,  path: '/changescreen', component: Changepasscode },
    { exact: true,  path: '/changepasscode', component: NewPasscode },
    { exact: true,  path: '/healthrecords', component: HealthRecords },
    { exact: true,  path: '/doctor_consultations', component: DoctorConsultations },
    { exact: true,  path: '/help_and_support', component: HealthAndSupport },
    { exact: true,  path: '/notification', component: Notification },
    { exact: true,  path: '/specialist', component: FindAnSpecialist },
    { exact: true,  path: '/about_doctor', component: DocProfile },
    { exact: true,  path: '/book_specialist', component: BookAnSpecialist },
    { exact: true,  path: '/support', component: chatSupport },
    { exact: true,  path: '/Wallet', component: Wallet },
    { exact: true,  path: '/Reffer', component: Reffer },
    // { path: '/dfg', component: Notfound},
    // { path: '*', component: Notfound, id:"Notfound"},



];
export default privateRoutes;