import React, { useState, useEffect } from 'react'
import './upload.scss'
import Pdfimg from '../../../../../../assets/images/pdfexe.svg';
import Docimg from '../../../../../../assets/images/docexe.svg';
import Jpegimg from '../../../../../../assets/images/jpegexe.svg';
import Tiffimg from '../../../../../../assets/images/tiffexe.svg';
import Xmlimg from '../../../../../../assets/images/xmlexe.svg';
import Bmpimg from '../../../../../../assets/images/bmpexe.svg';
import Deleteicon from '../../../../../../assets/images/deleteicon.svg';
import { useSelector, useDispatch } from "react-redux";
import axios from '../../../../../../redux/axios';
import { api } from '../../../../../../utils/constants';
import moment from 'moment';
import { getPatients } from '../../../../../../redux/patients/actions';
import Pagination from "../../../../../../components/Pagination";
import DeleteModal from "./uploadpop";
import { Dialog } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import Close from "../../../../../../assets/images/close.svg";
import FailurePdf from '../prescription/Failurepdf';
import Filterrecord from '../../components/filter'
import Toast from '../../../../../../components/Toast';
import UploadDocumentPopup from '../../../../../../components/AddDocument/UploadDocument';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ProgressBar from '../../../../../../components/ProgressBar';
import UploadSuccssMsg from '../../../../../../components/UploadSuccessMsg';
const Upload = (props) => {
    const { setLoading } = props;
    const dispatch = useDispatch();
    const [date, setDate] = useState()
    const [showalert, setshowalert] = useState(false);
    const [BTNContent, setBTNContent] = useState({});
    const [slotpop, setSlotPOP] = useState(false);
    const [slotpop2, setSlotPOP2] = useState(false);
    const [slotpop3, setSlotPOP3] = useState(false);
    const [patientval, setPatient] = useState();
    const [docType, setDocType] = useState("");
    const userdata = useSelector(state => state.auth);
    const [patientList, setPatientList] = useState([]);
    const [modalVisible, setmodalVisible] = useState(false)
    const [previewurl, setPreviewURL] = useState("")
    const [docId, setDocId] = useState("")
    const [uploadlist, setUpload] = useState([])
    const patient = useSelector(state => state.patients);
    const [modalShow, setModalShow] = useState(false);
    const [openpdf, setOpenPdf] = useState(false);
    const [closepdf, setClosePdf] = useState(false);
    const [filterForm, openFilterForm] = useState(false);
    const [toster, setToster] = React.useState(false);
    const [toastMsg, setToastMsg] = React.useState('');
    const [toastType, setToastType] = React.useState('');
    const [showAddDocument, setShowAddDocument] = useState(false);
    const [progress, setProgress] = React.useState(0)
    const [startupload, setStart] = React.useState(false)
    const [etime, setETime] = React.useState(0)
    const [fileData, setFileData] = React.useState('');
    const [name, setName] = React.useState("");
    const [note, setNote] = React.useState("");
    const [pat, setPat] = React.useState("");
    const [hrUpload, setHrUpload] = useState(new Date());
    const [doctypeUpload, setDocTypeUpload] = React.useState("");
    const [uploadDoc, setUploadDoc] = React.useState([])
    const [docs, setDocs] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [msg, setMsg] = React.useState('');
    const [progressShow, setProgressShow] = React.useState(false);
    const [uploadSpeed, setUploadSpeed] = React.useState(0);
    const [documentuploadName, setDocumentuploadName] = React.useState('');
    const [showUploadSucc, setUploadSucc] = React.useState(false);

    const ApiFetch = () => {
        dispatch(getPatients())
        setLoading(true)
    }
    const handleClickOpen = (id) => {
        setModalShow(true)
        setDocId(id)
    };
    const InitialLoad = () => {
        let res = patient.data.map((e, i) => { return { label: e.full_name, value: e.uuid } })
        setPatientList(res)
        if (res.length > 0 && res[0].value) {
            setLoading(true)
            setPatient(res[0].value)
            var data = {
                startDate: moment().add(-3, "months").format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD")
            }
            axios.post(api.uploadlist + "/" + res[0].value, data, { headers: { 'x-auth-token': userdata.token } })

                .then(r => {

                    let valdata = r.data;
                    valdata.sort(function (a, b) {
                        return new Date(b.createdAt) - new Date(a.createdAt);
                    });
                    setUpload(valdata)
                    setLoading(false)


                })
                .catch(err => {
                    setLoading(false)
                    console.log(err, 'neww err')
                    setTimeout(() => {
                        setToster(true);
                        setToastMsg("Request Failed");
                        setToastType('error');
                    }, 100);
                })
        }
    }
    useEffect(() => {
        if (patient)
            InitialLoad();
    }, [patient])
    useEffect(() => {
        ApiFetch();
    }, []);
    const DeleteDocID = () => {

        axios.delete(api.deleteupload + "/" + docId, { headers: { 'x-auth-token': userdata.token } })
            .then(r => {
                InitialLoad();
            })
            .catch(err => {
                console.log(err, 'err')
            })
        setModalShow(false)
    }
    const DateFormat = (val) => {
        if (val) {
            let checkval = val.split("-");
            return checkval[2] + "-" + checkval[0] + "-" + checkval[1] + "T00:00:00.449Z";
        }
        return "";
    }
    const handleViewPres = async (id) => {
        async function getpdf(id) {
            try {
                handlePdfOpen();
                await fetch("data:application/pdf;base64,")
                const iframe = document.querySelector('#pdf')
                iframe.setAttribute('src', id)

            } catch (error) {
                failurePdfOpen();
            }
        }

        getpdf(id);
    }
    const handlePdfOpen = () => {
        setOpenPdf(true);
    };
    const handlePdfClose = () => {
        setOpenPdf(false);
    };
    const failurePdfOpen = () => {
        setClosePdf(true);
    }
    const failurePdfClose = () => {
        setClosePdf(false);
    }
    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    const uploadProgress = (status) => {
        if (status == 100) {
            setProgressShow(false)
            setUploadSucc(true)
        } else {
            setProgressShow(true)
        }
    }
    const handleUpload = async ({ target: { files } }) => {
        setFileData(files[0])
    }
    const handleDrop = async (name, files, event) => {
        setFileData(files[0])
    }
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    const handleDocSubmit = async () => {
        if (fileData === '') {
            setOpen(true)
            setSeverity('error')
            setMsg('File Upload is Required!')
        }
        else if (fileData.size / 1000000 >= 5) {
            setOpen(true)
            setSeverity('warning')
            setMsg('Max. file size: 5MB Accepted')
        }
        else if (name.length === 0) {
            setOpen(true)
            setSeverity('error')
            setMsg('Document Name is Required')
        }
        else if (pat.length === 0) {
            setOpen(true)
            setSeverity('error')
            setMsg('Select Self or Relative is Required')
        }
        else if (doctypeUpload.length === 0) {
            setOpen(true)
            setSeverity('error')
            setMsg('Type of Document is Required')
        }
        else {
            const formData = new FormData()
            formData.append('media', fileData)
            let timeStarted = new Date();
            var config = {
                method: 'post', url: api.upload, data: formData, onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    let timeElapsed = (new Date()) - timeStarted;
                    let uploadSpeed = progressEvent.loaded / (timeElapsed / 1000) / 1000000;
                    let etiming = ((progressEvent.total - progressEvent.loaded) / uploadSpeed);
                    etiming = parseInt(etiming).toFixed(2);
                    etiming = etiming.toString().replace(".", ":")
                    setShowAddDocument(false)
                    setETime(etiming);
                    setUploadSpeed(uploadSpeed.toFixed(2));
                    setProgress(percentCompleted)
                    setProgressShow(true)
                    setDocumentuploadName(fileData.name)
                    uploadProgress(percentCompleted)

                }
            };

            axios(config).then(function (response) {
                var data = {
                    "docType": doctypeUpload.value,
                    "docName": name,
                    "location": response.data.url,
                    "patient": pat.value,
                    "notes": note,
                    "docDate": moment(hrUpload).format('YYYY-MM-DD')
                }

                let updoc = uploadDoc;
                updoc.push(data)
                setUploadDoc(updoc)
                axios.post(api.uploaddata, data)
                    .then(res => {
                        console.log(res.data)

                        axios.post(api.uploadlist + "/" + pat.value, data,)
                            .then(r => {
                                let valdata = r.data;
                                valdata.sort(function (a, b) {
                                    return new Date(b.createdAt) - new Date(a.createdAt);
                                });
                                setDocs(valdata)//
                                InitialLoad()

                            })
                            .catch(err => {
                                console.log(err.response.data)
                            })
                    })
                    .catch(err => console.log(err.response.data))

            })
                .catch(err => console.log("upload fail"))


        }
    }
    const handleUploadSuccClose = () => {
        setUploadSucc(false);
        InitialLoad();
    }
    return (
        <>
            <Dialog disableBackdropClick style={{ borderRadius: '0px' }}
                fullScreen
                maxWidth={"xl"}
                fullWidth={true}
                open={openpdf}
                scroll={"body"}
                onClose={handlePdfClose}>
                <div className="ContainerWrapper">
                    <img crossorigin="anonymous" src={Close} alt="Close" className="Closepopup" onClick={() => handlePdfClose()} />
                    <DialogContent>
                        <iframe src="" crossorigin="anonymous" className="pdfiframe" id="pdf" ></iframe>
                    </DialogContent>
                </div>
            </Dialog>
            <Dialog disableBackdropClick
                maxWidth={"sm"}
                open={closepdf}
                scroll={"body"}
                onClose={failurePdfClose}>
                <div className="ContainerWrapper">
                    <img crossorigin="anonymous" src={Close} alt="Close" className="Closepopup" onClick={() => failurePdfClose()} />
                    <FailurePdf text={'Something Went Wrong!'} subtext={'Please Contact admin'} />
                </div>
            </Dialog>

            <div className="filter-row-upload-rec">
                <Filterrecord setUpload={setUpload} upload={true} patientval={patientval} setPatient={setPatient} setLoading={setLoading} upfn={InitialLoad} patientList={patientList} />
                <div className="filter-row">
                    <div className="add-doc-btn" onClick={() => setShowAddDocument(true)}><span>+ Add New Document</span></div>
                </div>
                <div className="content-box">

                    <Pagination
                        pageSize={6}
                        records={uploadlist}>
                        {(list) =>
                            <div className="card-align-cont">
                                {list && list.length > 0 && list.map((item, ind) => {
                                    let exe = item.location != undefined && item.location.split(/[#?]/)[0].split('.').pop().trim()
                                    return (
                                        <>
                                            <div className="card-cont">
                                                <div className="header-font">Upload</div>
                                                <div className="sub-cont">
                                                    <div className="icon">
                                                        {
                                                            exe == "pdf" ?
                                                                <img crossorigin="anonymous" src={Pdfimg} /> : exe == "doc" ? <img crossorigin="anonymous" src={Docimg} /> : exe == "jpg" ?
                                                                    <img crossorigin="anonymous" src={Jpegimg} /> : exe == "tiff" ? <img crossorigin="anonymous" src={Tiffimg} /> : exe == "xml" ?
                                                                        <img crossorigin="anonymous" src={Xmlimg} /> : exe == "bmp" ? <img crossorigin="anonymous" src={Bmpimg} /> : ""
                                                        }

                                                    </div>
                                                    <div className="sub-cont-font">{item.docName}</div>
                                                </div>
                                                <div className="card-info-cont">
                                                    <div className="card-info-font">Date of Document: {moment(item.docDate).format("DD, MMM YYYY") != "Invalid date" ? moment(item.docDate).format("DD, MMM YYYY") : moment(DateFormat(item.docDate)).format("DD, MMM YYYY")}</div>
                                                    <div className="card-info-font">Date of Uploaded: {moment(item.createdAt).format("DD, MMM YYYY")}</div>
                                                    <div className="card-info-font">Document Type: {item.docType}</div>
                                                    <div className="divider"></div>
                                                    <div className="sub-header-font">Additional Notes:</div>
                                                    <div className="sub-header-sub-font">{item.notes != undefined ? item.notes : ""}</div>
                                                    <div className="card-bottom-row">
                                                        <div className="review-btn" onClick={() => handleViewPres(item.location)}><span>Preview</span></div>
                                                        <div className="delete-cont" onClick={() => handleClickOpen(item._id)}>
                                                            <img crossorigin="anonymous" src={Deleteicon} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                                }
                            </div>
                        }
                    </Pagination>
                    <DeleteModal show={modalShow} onHide={() => setModalShow(false)} handleConfirmChangePass={DeleteDocID} />
                </div>
            </div>
            <Toast text={toastMsg} type={toastType} open={toster} setClose={() => { setToster(false) }} />
            <UploadDocumentPopup show={showAddDocument}
                onHide={() => setShowAddDocument(false)}
                setProgress={setProgress}
                setStart={setStart}
                setETime={setETime}
                setFileData={setFileData}
                fileData={fileData}
                setDocType={setDocTypeUpload}
                setName={setName}
                setNote={setNote}
                setPat={setPat}
                handleUpload={handleUpload}
                handleDocSubmit={handleDocSubmit}
                handleDrop={handleDrop}
                hrUpload={hrUpload}
                setHrUpload={setHrUpload}
            />
            <ProgressBar
                show={progressShow}
                onHide={() => setProgressShow(false)}
                progress={progress}
                uploadSpeed={uploadSpeed}
                documentuploadName={documentuploadName}
            />

            <UploadSuccssMsg
                show={showUploadSucc}
                onHide={() => handleUploadSuccClose()}

            />
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert onClose={handleClose} severity={severity}>
                    {msg}
                </Alert>
            </Snackbar>
        </>
    )
}
export default Upload;
