import { takeLatest, put, select, all } from "redux-saga/effects";
import axios from "../axios";
import types from "./types";
import { api } from '../../utils/constants'

export const getUser = state => state.auth


export function* getUserDetails() {
  try {
    const user = yield select(getUser);
    const data = yield axios.get(api.user + "/" + user.uuid, {
      headers: { "x-auth-token": user.token },
    });
    yield put({ type: types.GET_USER_DETAILS_SUCCESS, payload: data.data });
  } catch (err) {
    yield put({ type: types.GET_USER_DETAILS_ERROR, payload: err.response.data });
  }
}


export function* userWatcher() {
  yield takeLatest(types.GET_USER_DETAILS, getUserDetails);
}
export default function* mainSaga() {
  yield all([userWatcher()]);
}
