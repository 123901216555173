import firebase from 'firebase/app';
import 'firebase/analytics'
import 'firebase/messaging';
import axios from 'axios'

export const config = {
  apiKey: "AIzaSyAfsWUZVgBp1rDVi7PZN77w4XK5R7lzKV0",
  authDomain: "doctall.firebaseapp.com",
  databaseURL: "https://doctall-default-rtdb.firebaseio.com",
  projectId: "doctall",
  storageBucket: "doctall.appspot.com",
  messagingSenderId: "815723410350",
  appId: "1:815723410350:web:4e7429e4d3fae3978b0e3f",
  measurementId: "G-642DQXT222"
};

let messaging = null;

export const getFirebaseInstance = () => {
  firebase.initializeApp(config);
  if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
  }
  return firebase;
}

export const getFirebaseAnalyticsInstance = () => {
  return firebase.analytics();
}

export const getToken = (setTokenFound) => {
  return messaging && messaging.getToken({ vapidKey: 'BBYRSZQYDO97VuIcVO1CgVSTzWaFMJXhItu4klK9BjXL3BBLw5dFUA33iLYTzw0Yr29K2AlwTGozsI1wpa2t9XE' }).then((currentToken) => {
    if (currentToken) {
      let userdata = localStorage.getItem("persist:root");
      userdata = userdata != null ? JSON.parse(userdata) : false;
      userdata = userdata ? JSON.parse(userdata.auth) : false;
      if (userdata && userdata && userdata.uuid) {
        var config = {
          method: 'post',
          url: `${process.env.REACT_APP_API_URL}/notification/` + userdata.uuid,
          data: {
            firebase_token: currentToken,
            device_type: "webappconsumer"
          },
          headers: {
            'x-auth-token': userdata.token
          }
        };
        axios(config).then((resp) => {
          console.log(resp)
        })
          .catch(err => {
            console.log(err)

          })
      }
      setTokenFound(true);
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
}

export const getTokenUuid = (uuid,token) => {
  return messaging.getToken({ vapidKey: 'BBYRSZQYDO97VuIcVO1CgVSTzWaFMJXhItu4klK9BjXL3BBLw5dFUA33iLYTzw0Yr29K2AlwTGozsI1wpa2t9XE' }).then((currentToken) => {
    if (currentToken) {
      if (uuid && token) {

        var config = {
          method: 'post',
          url: `${process.env.REACT_APP_API_URL}/notification/` + uuid,
          data: {
            firebase_token: currentToken,
            device_type: "webappconsumer"
          },
          headers: {
            'x-auth-token':token
          }
        };
        axios(config).then((resp) => {
          console.log(resp)
        })
          .catch(err => {
            console.log(err)

          })
      }
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging && messaging.onMessage((payload) => {
      resolve(payload);
    });
  });