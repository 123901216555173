import moment from 'moment'
import React from 'react'
import './HealthRecords.scss'

function HealthRecords(props) {
    console.log(props)
    return (
        <div className="healthdetails">
            <div className="heading">Health Documents</div>
            <div className="docContainer">
                {props.data.map((item, index) => {
                    if (item.type !== "doctorCase") {
                        return (
                            <div className="container" key={"health" + index} >
                                <div className="title" onClick={() => props.handleview(item.type, item.uuid)}>{item.type == "medicine" ? "E-Prescription" : item.type == "Ep_lab_detail" ? "Lab Test" : (item.type == "doctorCase" || item.type == "doctor_case_notes") ? "Case Note" : item.type == "psychiatryCase" ? "Case Note" : "Referral"}</div>
                                <div className="value">{moment(item.createdAt).format("DD MMM YYYY")}</div>
                            </div>

                        )
                    } else {
                        return <></>
                    }

                })}
            </div>
        </div>
    )
}

export default HealthRecords