import React, { useEffect, useState } from 'react'
import Layout from '../../layout/DashboardLayout'
import { ProfileForm } from '../../components'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { ProgressBar } from 'react-bootstrap';
import axios from '../../redux/axios'
import { api } from '../../utils/constants'
import './health.scss'


function RelativeProfile() {
    const [progressNum, setProgressNum] = useState(0)
    const userdata = useSelector(state => state.auth)
    const [loader, setLoader] = useState(false)
    const history = useHistory()
    useEffect(() => {
        if (userdata) {
            axios.get(api.profileupdate + history.location.state.uuid,
                { headers: { 'x-auth-token': userdata.token } })
                .then(res => {

                    if (res.data && res.data.percentage) {

                        setProgressNum(res.data.percentage)
                    }
                })
                .catch(err => {
                })
        }
    }, [])

    useEffect(() => {
        if (!history.location.state) {
            history.goBack()
        }
    }, [])

    return (
        <Layout loadin={loader} load={setLoader}>
           
            <div className="profileHead">
                <div className="profileInner1">
                    <div>
                        <Link to='/relatives' style={{ color: '#66B5C3' }}> <img crossorigin="anonymous" style={{ margin: "0 4px 5px 0" }} src={require('../../assets/images/ICON13.svg').default} /> Back</Link>
                        <h1>Relative Profile</h1>
                    </div>
                </div>
                <div className="profileInner2">
                    <h4 className="progressHead">Relative Profile Completion</h4>
                    <div className='progressSpace'>
                        <ProgressBar animated now={progressNum} />
                        <span style={{ color: '#37CC8C' }}>{progressNum}%</span>
                    </div>
                </div>
            </div>
            <div className="profileFormCard">
                <ProfileForm
                    type="relative"
                    setLoading={setLoader}
                    uuidData={history.location.state.uuid === undefined ? "" : history.location.state.uuid}
                    medCheck={history.location.state.prof === undefined ? "" : 'medUpdate'}
                    setProgressNum={setProgressNum} />
            </div>
        </Layout>
    )
}

export default RelativeProfile
