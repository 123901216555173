import React from 'react'
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';
import './stylesDialog.scss'

export default function DialogBoxWithClose(props) {
    const { open, _onClose, resImg, children, actionBtnYes, actionBtnNo, onClickYes, onClickNo } = props
    return (
        <div>
            <Dialog open={open} onClose={_onClose}>
                <DialogContent>
                    <DialogContentText >
                        <div className="dialoglimit">
                            <img crossorigin="anonymous" className="res" src={resImg} alt="" />
                            {children}
                            <div className='actions'>
                                <button className='actionButtonsYes' onClick={onClickYes}>
                                    {actionBtnYes}
                                </button>
                                <button className='actionButtonsNo' onClick={onClickNo}>
                                    {actionBtnNo}
                                </button>
                            </div>
                            <img crossorigin="anonymous" className="xbutton" onClick={_onClose} src={require('../../assets/images/Xbutton.svg').default} alt="" />
                        </div>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

        </div>
    )
}
