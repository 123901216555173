import React, { useEffect, useState } from 'react';
import './OrderTracking.scss';
import { Row, Col } from "react-bootstrap";
import TrackIndividualTests from './TrackIndividualTests';
import { useSelector, useDispatch } from "react-redux";
import { getSearchedOrders } from './../../redux/orders/actions';
import Layout from "../../layout/DashboardLayout";
import ICON13 from '../../assets/images/ICON13.svg'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { api } from '../../utils/constants';
import moment from 'moment';
import LoaderComponent from '../../components/Loading/LoaderComponent';
import { Helmet } from 'react-helmet';

function LabOrderView(props) {
    let history = useHistory()
    const dispatch = useDispatch();

    let orderState = props.location.state || {};
    // let orderDetails = orderState.order_details ? orderState.order_details : {}
    const [orderDetails, setOrderDetails] = useState([]);
    const [loading, setLoading] = useState([]);



    useEffect(() => {
        try {
            if (orderState) {
                getIndividualOrderDetails(orderState)
            }
        } catch (error) {
            console.log(error)
        }
    }, [orderState])


    let getIndividualOrderDetails = (orderState) => {
        setLoading(true)
        let orderId = orderState
        let url = `${api.Order}/ordertracking/byOrderId?orderId=${orderId}`
        axios.get(url).then(res => {
            console.log(res.data)
            setOrderDetails(res.data)
            setLoading(false)
        })
    }


    const getOrderType = (status) => {
        switch (status) {
            case "popular":
                return "Diagnostics";
            case "recommended":
                return "Recommended Test";
            case "prescription":
                return "Prescription";
            case "biointel":
                return "Bio Intel Panel";
            default:
                return status;
        }
    }

    return (
        <Layout loadin={null} load={null}>
            <Helmet>
      <title>View Orders - Doctall Consumer Web 



</title>
<meta 
            name='description' 
            content='View all orders, types, dates and payments made on Doctall. Order lab tests at discounted rates and get downloadable results
            '
            />
    </Helmet>
            <div>
                <div className="cont-wrapper-health-record">
                    <div className="text-wrap" style={{ marginBottom: '8%' }}>
                        <div className="goBack mt-4" onClick={() => { history.goBack() }}>
                            <img crossorigin="anonymous" src={ICON13} alt="arrow" className="img-fluid" />
                            <p>back</p>
                        </div>
                    </div>

                </div>
                <hr style={{ border: "1px solid #C5D8FF", width: "95%" }} />
                <div className='order-details'>
                    <Row style={{ color: "#163E8C" }}>
                        <div className="test-22">
                            <Col>
                                <span style={{ marginLeft: "-2%", fontSize: "20px" }}>ORDER ID: <b> {orderDetails && orderDetails.length > 0 && orderDetails[0].uuid} </b></span>
                                <div className='order-date-type'>
                                    <Row>
                                        <span style={{ fontSize: "16px", marginTop: "5%" }}>  Order Date: {orderDetails && orderDetails.length > 0 && orderDetails[0].fullOrderDetails && orderDetails[0].fullOrderDetails.length > 0 && orderDetails[0].fullOrderDetails[0].createdAt ? moment(orderDetails[0].fullOrderDetails[0].createdAt).format("DD MMM YYYY") : 'NA'}</span>
                                    </Row>
                                    <Row>
                                        <span style={{ fontSize: "16px", marginTop: "0%" }}> Order Type: {orderDetails && orderDetails.length > 0 && orderDetails[0].fullOrderDetails && orderDetails[0].fullOrderDetails.length > 0 && orderDetails[0].fullOrderDetails[0] && getOrderType(orderDetails && orderDetails.length > 0 && orderDetails[0].fullOrderDetails && orderDetails[0].fullOrderDetails.length > 0 && orderDetails[0].fullOrderDetails[0].type)}</span>
                                    </Row>
                                </div>
                            </Col>
                            <Col>

                                <div className="test-33">
                                    <Row>
                                        <span style={{ fontSize: "16px" }}>PAYMENT ID: {orderDetails && orderDetails.length > 0 && orderDetails[0].fullOrderDetails && orderDetails[0].fullOrderDetails.length > 0 && orderDetails[0].fullOrderDetails[0] ? orderDetails[0].fullOrderDetails[0].payment_ref_id : ''}</span>
                                    </Row>
                                    <Row>
                                        <span style={{ 'font-size': '12px', marginTop: "10%" }}>Total Cost:</span>
                                    </Row>

                                    <Row>
                                        <span style={{ 'font-size': '25px', marginTop: "3%" }}> ₦{orderDetails && orderDetails.length > 0 && orderDetails[0].fullOrderDetails && orderDetails[0].fullOrderDetails.length > 0 && orderDetails[0].fullOrderDetails[0] ? orderDetails[0].fullOrderDetails[0].item_cost : ''}</span>
                                    </Row>
                                    {orderDetails && orderDetails.length > 0 && orderDetails && orderDetails[0].home_service_included === 'yes' ?
                                        <Row>
                                            <span className='order-tracking-paid'>Home sample collection included ({orderDetails && orderDetails.length > 0 && orderDetails[0].home_service ? orderDetails[0].home_service.home_service_amount : "N/A"})</span>
                                        </Row> : null

                                    }

                                </div>
                            </Col>
                        </div>
                    </Row>

                </div>
                <hr style={{ border: "1px solid #C5D8FF", width: "95%" }} />
                <div className='individual-tests'>
                    <TrackIndividualTests orderDetails={orderDetails} />
                </div>
            </div>
            {loading && <LoaderComponent />}
        </Layout >
    )
}

export default LabOrderView
