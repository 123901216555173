import React, { useState, useEffect } from 'react'
import Layout from '../../layout/DashboardLayout'
import { ProfileForm } from '../../components'
import { ProgressBar } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux'
import './health.scss'
import { IntialGen } from "../../utils"
import axios from '../../redux/axios'
import { api } from "../../utils/constants"
import { useHistory } from "react-router-dom";
import { getPatients } from '../../redux/patients/actions'



function HealthProfile() {
    const [progressNum, setProgressNum] = React.useState(0);
    const userdata = useSelector(state => state.auth)
    const Patients = useSelector(state => state.patients.data);
    const userDetails = useSelector(state => state.userDetails);


    const [loader, setLoader] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(getPatients())
    }, [])

    useEffect(() => {
        if (userdata && Patients && Patients.length > 0) {
            axios.get(api.profileupdate + Patients[0].uuid,
                { headers: { 'x-auth-token': userdata.token } })
                .then(res => {
                    if (res.data && res.data.percentage) {

                        setProgressNum(res.data.percentage)
                    }
                })
                .catch(err => {
                })
        }
    }, [Patients])



    const incrementProgress = () => {
        setProgressNum(progressNum + 25)
    }

    return (
        <>
 
        <Layout loadin={loader} load={setLoader}>
            <h1 className="profileTitle">Profile</h1>
            <div className="profileHead">
                <div className="profileInner1">

                    {userdata.profile && userdata.profile.profile_pic ?
                        <img crossorigin="anonymous"
                            style={{ width: "110px", height: '110px', borderRadius: "50%" }}
                            src={userdata.profile && userdata.profile.profile_pic ? userdata.profile.profile_pic :
                                require('../../assets/images/prof.svg').default} alt="" /> :
                        <div className="profilediv">{IntialGen(userdata)}</div>
                    }
                    <div style={{ display: 'block', margin: 'auto' }}>
                        <h3>{userDetails && userDetails.data && userDetails.data.full_name}</h3>
                        <p>{userdata.email}</p>
                        <p>{userdata.mobile}</p>
                    </div>
                </div>
                <div className="profileInner2">
                    <h4 className="progressHead">Profile completion</h4>
                    <div className='progressSpace'>
                        <ProgressBar animated now={progressNum} />
                        <span style={{ color: '#37CC8C' }}>{progressNum}%</span>
                    </div>

                </div>
            </div>
            <div className="profileFormCard">
                <ProfileForm
                    type="owner"
                    medCheck={history.location.state && history.location.state.prof ? "medUpdate" : ''}
                    uuidData={userdata.uuid}
                    setProgressNum={setProgressNum}
                    setLoading={setLoader}
                />
            </div>
        </Layout>
        </>
    )
}

export default HealthProfile
