import React, { useEffect, useState } from 'react';
import TestBookDetails from './TestBookDetails';
import './tabs.scss';
import { Pagination } from '../../components';
import { Helmet } from 'react-helmet';


export default function OrdersList(props) {

    const [completeOrdersData, setcompleteOrdersData] = useState([])
    const [page, setPage] = useState()

    useEffect(() => {
        setcompleteOrdersData(props.completeOrdersData)
    }, props.completeOrdersData)

console.log(completeOrdersData)
    return (
        <div className='test-book-details'>
            <Helmet>
      <title>Lab Test Orders & Results - Doctall Consumer Web


</title>
<meta 
            name='description' 
            content='View your Diagnostic tests and BioCheck panel orders and results. Order lab tests at discounted rates and get downloadable results'
            />
    </Helmet>
            <Pagination
                pageSize={6}
                isloading={false}
                records={completeOrdersData}>
                {(pageData) =>
                    <div className="listing">
                        {pageData && pageData.length > 0 && pageData.map((item, index) => {
                            return (<TestBookDetails orderDetails={item} viewIndividualOrder={props.viewIndividualOrder} />)
                        })}
                    </div>}
            </Pagination>
        </div>
    )
}
