// import React, { useState, useEffect } from 'react';
// import { ZoomMtg } from '@zoomus/websdk';
// import {
//   useParams
// } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";

// var apiKey = 'C242esPLShG3CS7o7HQALA'
// var leaveUrl = window.location.origin + '/dashboard?feedback='

// ZoomMtg.setZoomJSLib('https://source.zoom.us/2.16.0/lib', '/av');

// ZoomMtg.preLoadWasm();
// ZoomMtg.prepareWebSDK();
// // loads language files, also passes any error messages to the ui
// ZoomMtg.i18n.load('en-US');
// ZoomMtg.i18n.reload('en-US');


// const ZoomWebSdK = () => {
//   let { meetingid, password } = useParams();
//   const history = useHistory();
//   const userdata = useSelector(state => state.auth)
//   const data = history.location.data;
//   useEffect(() => {
//     // console.log(JSON.stringify(ZoomMtg.checkSystemRequirements()));
//     // ZoomMtg.setZoomJSLib('http://localhost:3000//node_modules/@zoomus/websdk/dist/lib', '/av');
//     // ZoomMtg.setZoomJSLib(window.location.origin+'/lib', '/av');
//     ZoomMtg.setZoomJSLib('https://source.zoom.us/zoom-meeting-2.16.0.min.js', '/av');
//     // ZoomMtg.preLoadWasm();
//     // ZoomMtg.prepareJssdk();

//     if(navigator?.mediaDevices?.getUserMedia){
//       if (typeof navigator.mediaDevices.getUserMedia === 'undefined') {
//         navigator.getUserMedia({ audio: true, video: true }, function (stream) {
//           stream.getTracks().forEach(x => x.stop());
//           setTimeout(() => { getSignaturesss() }, 1000)
//         }, err => console.log(err));
//     } else {
//         navigator.mediaDevices.getUserMedia({
//             audio: true, video: true
//         }).then((stream)=> {
//           stream.getTracks().forEach(x => x.stop());
//           setTimeout(() => { getSignaturesss() }, 1000)
//         }).catch(err => console.log(err));
//     }
//     }
//     else{
//       getSignaturesss()
//     }

//   }, [])
//   useEffect(() => {
//     if (!history.location.data) {
//       history.push("/dashboard")
//     }
//   }, [])


//   function getSignaturesss(e) {
//     // e.preventDefault();

//     // fetch(authEndpoint, {
//     //   method: 'POST',
//     //   headers: { 'Content-Type': 'application/json' },
//     //   body: JSON.stringify({
//     //     meetingNumber: meetingid,
//     //     role: role
//     //   })
//     // }).then(res => res.json())
//     // .then(response => {
//     //   startMeeting(response.signature)
//     // }).catch(error => {
//     //   console.error(error)
//     // })

//     ZoomMtg.generateSDKSignature({
//       meetingNumber: meetingid,
//       sdkKey: 'by1cV_2UTgnMoLjjMTOGw', //'S1CwR_PvR9u2tKU85Ri_g',
//       sdkSecret: "qGXIfI3hbI2h6PU5vjTbepQ6bi2ku968",
//       role: 0,
//       // alg: "HS256", // Specify HS256 as the signature algorithm
//       success(res) {
//         startMeeting(res.result)
//       }
//     })


//   }

//   function startMeeting(signature) {
//     document.getElementById('zmmtg-root').style.display = 'block'
//     console.log(signature)

//     var fdata = {
//       "patient_uuid": data.patient_uuid,
//       "doctor_uuid": data.doctor_uuid,
//       "booking_uuid": data.uuid,
//     }
//     let enc = window.btoa(JSON.stringify(fdata))

//     ZoomMtg.init({
//       leaveUrl: leaveUrl + enc,
//       isSupportAV: true,
//       success: (success) => {
//         console.log(success)

//         ZoomMtg.join({
//           signature: signature,
//           sdkKey: 'by1cV_2UTgnMoLjjMTOGw', //'S1CwR_PvR9u2tKU85Ri_g',
//           meetingNumber: meetingid,
//           passWord: password,
//           userName: userdata.full_name,
//           userEmail: userdata.email,
//           // tk: registrantToken,
//           // zak: zakToken,
//           success: (success) => {
//             console.log(success)
//           },
//           error: (error) => {
//             console.log(error)
//           }
//         })

//       },
//       error: (error) => {
//         console.log(error, "ERROR")
//       }
//     })
//   }
//   const getSignature = () => {


//     ZoomMtg.generateSignature({
//       meetingNumber: meetingid,
//       apiKey: apiKey,
//       apiSecret: "0AOZtKFry0Tiw6BfW3uzI10QHixLGWnejKBb",
//       role: 0,
//       success(res) {
//         document.getElementById('zmmtg-root').style.display = 'block'
//         if (data) {
//           var fdata = {
//             "patient_uuid": data.patient_uuid,
//             "doctor_uuid": data.doctor_uuid,
//             "booking_uuid": data.uuid,
//           }
//           let enc = window.btoa(JSON.stringify(fdata))
//           ZoomMtg.init({
//             leaveUrl: leaveUrl + enc,
//             isSupportAV: true,
//             disableCORP: !window.crossOriginIsolated,
//             disablePreview: true,
//             success() {
//               ZoomMtg.join({
//                 meetingNumber: meetingid,
//                 userName: userdata.full_name,
//                 signature: res.result,
//                 apiKey: apiKey,
//                 userEmail: userdata.email,
//                 passWord: password,
//                 success() {
//                   console.log('join meeting success');
//                 },
//                 error(res) {
//                   console.log(res);
//                 }
//               });
//             },
//             error(res) {
//               console.log(res);
//             }
//           });
//         }
//       }
//     });
//   }
//   return (
//     <div className="zoomiframe" style={{ width: "100%", height: "100vh", overflow: "hidden" }}>

//     </div>
//   )
// }
// export default ZoomWebSdK;

import React, { useState, useLayoutEffect, useRef } from "react";
import "@whereby.com/browser-sdk";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import "./styles.css";

function ZoomWebSdK() {
  let { meetingid, password } = useParams();
  const history = useHistory();
  const userdata = useSelector((state) => state.auth);
  const [join, setJoin] = useState(false);

  const handleJoin = () => {
    history.push(`${window.atob(password)}`);
  };

  const packageWrapperRef = useRef(null);

  // Function to handle the click event for the new button
  const handleNewButtonClick = () => {
    
    history.push(`${window.atob(password)}`)
    console.log('New button clicked!');
  };

  useLayoutEffect(() => {
    // Check if the packageWrapper element with the specific classname exists
    const packageWrapper = document.querySelector('.buttonsWrapper-YYuy');

    if (packageWrapper) {
      // Create and append a new button
      const newButton = document.createElement('button');
      newButton.className = 'buttonIconWrapper-FUFj'; // You may need to adjust the class name
      newButton.innerText = 'Leave';

      newButton.style.position = 'fixed';
      newButton.style.bottom = '10px';
      newButton.style.right = '10px';

      newButton.addEventListener('click', handleNewButtonClick);
      packageWrapper.appendChild(newButton);

      // Clean up event listener on component unmount
      return () => {
        newButton.removeEventListener('click', handleNewButtonClick);
      };
    }
  }, []); // Empty dependency array ensures this effect runs once after the initial render


  return (
    <div className="App" style={{ justifyContent: 'center', alignItems: 'center' }}>
      <div ref={packageWrapperRef} className="buttonsWrapper-YYuy" style={{ width: '100%', height: '100%' }}>
        <whereby-embed
          floatSelf
          background="off"
          displayName={userdata.full_name}
          room={`${window.atob(meetingid)}?minimal&chat=on&leaveButton=off`}
          minimal
          style={{ display: 'contents', width: '100%', height: '100%' }}
          subgridLabels="off"
          screenshare="off"
        ></whereby-embed>
      </div>
    </div>
  );
}

export default ZoomWebSdK;


// import React, { useState, useLayoutEffect, useRef } from "react";
// import "@whereby.com/browser-sdk"
// import { useParams, useHistory } from "react-router-dom";
// import { useSelector } from "react-redux";
// import './styles.css'

// function ZoomWebSdK() {

//   let { meetingid, password } = useParams();
//   console.log(useParams(), "sfsdfada")
//   // console.log(window.atob(meetingid), window.atob(password), "safasfadfad")
//   const history = useHistory();
//   const userdata = useSelector(state => state.auth)
//   // const data = history.location.leaveurl;
//   // console.log(history.location.data, "history.location")
//   const [join, setJoin] = useState(false);

//   const handleJoin = () => {
//     history.push(`${window.atob(password)}`)
//   }

//   var leaveButton = document.getElementById('buttonIconWrapper-FUFj');

//   leaveButton?.addEventListener('click', function () {
//     console.log(window.atob(password), "safasfadfad")
//     history.push(`${window.atob(password)}`)
//   });

//   const packageWrapperRef = useRef(null);

//   // Function to handle the click event for the new button
//   const handleNewButtonClick = () => {
//     // Add your logic for the new button click event here
//     console.log('New button clicked!');
//   };

//   useLayoutEffect(() => {
//     // Check if the packageWrapper element with the specific classname exists
//     const packageWrapper = document.querySelector('.buttonsWrapper-YYuy');

//     if (packageWrapper) {
//       const newButton = document.createElement('button');
//       newButton.className = 'buttonsWrapper-YYuy';
//       newButton.innerText = 'New Button';
//       newButton.addEventListener('click', handleNewButtonClick);

//       // Append the new button to the packageWrapper container
//       packageWrapper.appendChild(newButton);

//       // Clean up event listener on component unmount
//       return () => {
//         newButton.removeEventListener('click', handleNewButtonClick);
//       };
//     }
//   }, []); // Empty dependency array ensures this effect runs once after the initial render


//   console.log(window.atob(meetingid), "safasfadfad")
//   // Embeded Prebuilt View
//   return <div className="App" style={{ justifyContent: 'center', alignItems: "center" }}>
//     {/* {join === true ?
//       <whereby-embed floatSelf background='off' displayName="Rick Astley" room={`${window.atob(meetingid)}?minimal&chat=on&leaveButton=on`} minimal
//         style={{ display: "contents" }} subgridLabels="off" screenshare="off"></whereby-embed>
//       :
//       <div
//         onClick={() => {
//           handleJoin();
//         }}
//       >
//         <span style={{cursor:'pointer',fontWeight:'bold',fontSize:"20px"}} >Click to Join</span>
//       </div>
//     } */}

//       <div ref={packageWrapperRef} className="buttonsWrapper-YYuy" style={{width: '100%', height: '100%'}}>
//       <whereby-embed floatSelf background='off' displayName={userdata.full_name} room={`${window.atob(meetingid)}?minimal&chat=on&leaveButton=off`} minimal
//         style={{ display: "contents", width:"100%", height : '100%' }} subgridLabels="off" screenshare="off" >
//       </whereby-embed>
//     </div>

//     {/* <button className="buttonIconWrapper-FUFj" onClick={handleJoin}>Leave Meeting</button> */}


//   </div>
// }
// export default ZoomWebSdK;