import React from 'react';
import { Modal } from 'react-bootstrap';
import Close from '../../assets/images/close.svg';
import './ProgressBar.scss';
import { Progress } from 'antd'
function ProgressBar(props) {
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modal-upload"
        >
            <div className="ProgressBar">
                <img crossorigin="anonymous" src={Close} alt="Close" onClick={() => { props.onHide() }} />
                <div className="progressContainer">
                    <Progress type="circle" percent={props.progress} strokeColor="#00839B" trailColor="#F2F8F9" />
                    <p>{props.uploadSpeed}mb/s</p>
                    <div className="docName">{props.documentuploadName}</div>
                </div>
            </div>
        </Modal>
    )
}

export default ProgressBar
