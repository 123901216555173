import Modal from 'react-bootstrap/Modal';
import Closebutton from "../../assets/images/closebutton.svg";
import Circle from "../../assets/images/circle.svg";
import Tick from "../../assets/images/tick.svg";
import './confirmpop.scss';


export default function MydModalWithGrid(props) {
  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered contentClassName="modal-cls-confirm-pop-change-passcode">
      <div className="change-passcode-confirm-cont">
        <div className="header">
          <div className="close-btn">
            <img crossorigin="anonymous" src={Closebutton} alt="close-btn" onClick={() => props.onHide()} />
          </div>
        </div>

        <div className="success-img-cont-pass-confirm">
          <div className="img-wrap">
            <img crossorigin="anonymous" src={Circle} alt="circle" />
            <div className="img-tick">
              <img crossorigin="anonymous" src={Tick} alt="tick" />
            </div>
          </div>

        </div>

        <div className="font-one">Are you sure you want to change <br />your passcode?</div>
        <div className="btn-cont">
          <div className="yes-btn" onClick={() => props.handleConfirmChangePass()}><span>Yes</span></div>
          <div className="no-btn" onClick={() => props.onHide()}><span>No</span></div>
        </div>
      </div>
    </Modal>
  );
}