import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { applyMiddleware, createStore, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createBrowserHistory } from "history";
import { routerMiddleware } from "react-router-redux";
import rootReducer , { rootSaga } from './redux/index.js'


const persistConfig = {
    key: 'root',
    storage,
    // backlist:['patients']
}

const sagaMiddleware = createSagaMiddleware();
const history = createBrowserHistory();


const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
    let composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    let store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware,routerMiddleware(history))))
    sagaMiddleware.run(rootSaga)
    let persistor = persistStore(store)
    
    return { store, persistor, history }
}