import types from './types'

export const getAllOrders = (searchTerm, filterObj, userId) => ({

    type: types.GET_ALL_ORDERS,
    searchTerm,
    filterObj,
    userId
})

export const getSearchedOrders = (orderId) => ({
    type: types.GET_SEARCHED_ORDERS,
    orderId
})

export const viewReceipt = (orderID) => ({
    type: 'GET_RECEIPT',
    orderID
})

