import React, { useEffect, useState, Fragment } from 'react';
import './feedback.scss';
import Modal from 'react-bootstrap/Modal';
import Closebutton from "../../assets/images/closebutton.svg";
import Ratingstar from "../../assets/images/ratingstar.svg";
import Nostar from "../../assets/images/nostar.svg";
import Radioon from "../../assets/images/radioon.svg";
import Radiooff from "../../assets/images/radiooff.svg";
import Radioon1 from "../../assets/images/radio1act.svg";
import Radiooff1 from "../../assets/images/radio1.svg";
import UnRatingstar from "../../assets/images/unselectstar.svg";
import axios from "../../redux/axios"
import { api } from "../../utils/constants"
import { useSelector } from "react-redux";
import Submitpop from "./submitpop";


var starlike = [1, 2, 3, 4, 5]
const url = "https://www.surveymonkey.com/r/9RXK9RF";
export default function MydModalWithGrid(props) {
    const [apidata, setApidata] = useState([]);
    const [rate, setRate] = useState(0);
    const [update, setUpdate] = useState(false);
    const [formdata, setFormdata] = useState({})
    const [otherFormdata, setOtherFormdata] = useState({})
    const [reccom, setReccom] = useState(0);
    const userdata = useSelector(state => state.auth);
    const [doctor, setDoctor] = useState({ full_name: "" });
    const [docid, setDocid] = useState("");
    const OtherUpdateValue = (val, uuid) => {
        let oldval = otherFormdata;
        oldval[uuid] = val;
        setOtherFormdata(oldval)
        setUpdate(!update)
    }

    const ApiFetch = () => {
        axios.get(api.feedbackget, { headers: { 'x-auth-token': userdata.token } })
            .then(res => {
                if (res.data) {
                    let ourrecord = res.data.filter((e) => e.order);
                    ourrecord = ourrecord.sort(function (a, b) { return a["order"] - b["order"] });

                    setApidata(ourrecord)
                    setUpdate(!update)
                }
            })
            .catch(err => {


            })

    }
    useEffect(() => {
        ApiFetch();
    }, [])
    useEffect(() => {
        let datav;
        if (window.location.search) {

            datav = window.location.search.replace("?feedback=", "");
            datav = window.atob(datav);
            datav = JSON.parse(datav);

        }
        if (datav && datav.doctor_uuid) {
            setDocid(datav.doctor_uuid)
            axios.get(api.user + '/' + datav.doctor_uuid, { headers: { 'x-auth-token': userdata.token } })
                .then(res => {
                    setDoctor(res.data);
                    // setUpdate(!update)
                })
                .catch(err => {
                    console.log(err)
                })

        }

    }, [])
    const RatingStar = (i) => {
        setRate(i)
    }
    const UpdateValue = (val, uuid) => {
        let oldval = formdata;
        oldval[uuid] = val;
        setFormdata(oldval)
        setUpdate(!update)
    }
    const Recommened = (i) => {
        setReccom(i)
    }
    useEffect(() => {


    }, [])

    const Done = () => {
        let questions = [];
        for (var x of Object.keys(formdata)) {
            let ansval;
            if (Array.isArray(formdata[x])) {
                ansval = formdata[x] && formdata[x].length ? formdata[x].map((e) => { return e.toLowerCase() === "others [please specify]" ? otherFormdata[x] : e }) : []
            }
            else {
                ansval = formdata[x].toString().toLowerCase() === "others [please specify]" ? [otherFormdata[x]] : [formdata[x]]
            }
            questions.push({ questionId: x, answer: ansval })
        }

        if (window.location.search) {

            let datav = window.location.search.replace("?feedback=", "");
            datav = window.atob(datav);
            datav = JSON.parse(datav);
            datav.questions = questions;
            axios.post(api.feedbackpost, datav, { headers: { 'x-auth-token': userdata.token } })
                .then(res => {
                    props.onHide(true)
                })
                .catch(err => {

                })
        }

    }

    return (
        <Modal {...props}
            aria-labelledby="contained-modal-title-vcenter"
            contentClassName="modal-content-feedback"
            onHide={false}
        >
            <div className="container-feedback">
                <div className="header">
                    <div className="feedback-header-text">Appointment Feedback </div>
                    <div className="close-btn">
                        <img crossorigin="anonymous" src={Closebutton} alt="close-btn" onClick={props.onHide}></img>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="second-header-cont">
                    <div className="second-header">Rate your appointment with Dr.&nbsp;{doctor.full_name != "" ? doctor.full_name : ""}</div>
                    <div className="second-header-subtext">Please help us serve you better in the future by taking a <span> moment to provide your feedback. Thank you.</span></div>
                </div>
                <Fragment>
                    {apidata.map((e, i) => {
                        if (e.type == "star")
                            return (
                                <div className="feedback-one-cont">
                                    <div className="feedback-one">{(i + 1) + ". " + e.question}</div>
                                    <div className="rating-cont">
                                        {
                                            starlike.map((ee, i) => {
                                                return (
                                                    <div key={"star" + i} onClick={() => { RatingStar(ee); UpdateValue(ee, e.uuid) }}>
                                                        <img crossorigin="anonymous" src={rate >= ee ? Ratingstar : UnRatingstar} alt="rating-btn"></img>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                </div>
                            )
                        else if (e.type == "radio")
                            return (
                                <div className="feedback-two-cont">
                                    <RadioCheck e={e} i={i} UpdateValue={(value, id) => { UpdateValue(value, id) }} />
                                    {e.ansList.findIndex((valuee) => valuee.toLowerCase().indexOf("others") > -1) > -1 ?
                                        <div className="feedback-input">
                                            <input type="text" value={otherFormdata && otherFormdata[e.uuid] && e.ansList.indexOf(otherFormdata[e.uuid]) == -1 ? otherFormdata[e.uuid] : ""} placeholder="Please type here" onChange={(val) => { OtherUpdateValue(val.target.value, e.uuid) }} />
                                        </div> : null}
                                </div>

                            )
                        else if (e.type == "checkbox") {

                            return (
                                <div className="feedback-two-cont">
                                    <MulitCheck e={e} i={i} UpdateValue={(value, id) => { UpdateValue(value, id) }} />
                                    {e.ansList.findIndex((valuee) => valuee.toLowerCase().indexOf("others") > -1) > -1 ?
                                        <div className="feedback-input">
                                            <input type="text" value={otherFormdata && otherFormdata[e.uuid] && e.ansList.indexOf(otherFormdata[e.uuid]) == -1 ? otherFormdata[e.uuid] : ""} placeholder="Please type here" onChange={(val) => { OtherUpdateValue(val.target.value, e.uuid) }} />
                                        </div> : null}
                                </div>
                            )
                        }
                        else if (e.type == "textarea")
                            return (
                                <div className="feedback-two-cont">
                                    <div className="feedback-two">{(i + 1) + ". " + e.question}</div>
                                    <div className="text-area-cont">
                                        <textarea name="styled-textarea" value={formdata[e.uuid] ? formdata[e.uuid] : ""} className="text-area-cls" placeholder="Please type here" onChange={(txt) => { UpdateValue(txt.target.value, e.uuid) }}></textarea>
                                    </div>
                                </div>
                            )
                        else if (e.type == "number")
                            return (
                                <div className="feedback-two-cont">
                                    <div className="feedback-two">{(i + 1) + ". " + e.question}</div>
                                    <div className="number-box-cont">
                                        {e.ansList.map((val, ind) => {
                                            return (
                                                <div key={"no" + ind} className={reccom == val ? "number-box-cls boxselected" : "number-box-cls"} onClick={() => { Recommened(val); UpdateValue(val, e.uuid) }}>
                                                    <div className={reccom == val ? "number-class selecttxt" : "number-class"}>{val}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                    })}

                </Fragment>
                <div className="footer-cls">
                    <div className="submit-btn" onClick={() => { Done() }}><span>Submit Feedback</span></div>
                    {/* <div className="footer-cont">
                        <div className="footer-dir">
                            <div className="font-cls-one">Enjoy the chance to win ₦10,000 weekly cash prize</div>
                            <div className="font-cls-two" onClick={(e) => {
                                e.preventDefault();
                                window.open(url, "_blank");
                            }}>Click here to answer a short survey and enter into our weekly cash draw

                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </Modal>
    );
}

const MulitCheck = (props) => {
    const { e, i, UpdateValue } = props;
    const [checkval, setSelected] = useState([])
    const [updatedata, setUpdateData] = useState(false)
    return (
        <div className="feedback-two-cont">
            <div className="feedback-two">{(i + 1) + ". " + e.question}</div>
            {
                e.ansList.map((val, ind) => {

                    const CheckMulti = (val) => {
                        let dataval = checkval;
                        let indexofval = dataval.indexOf(val);
                        if (indexofval > -1) {
                            dataval.splice(indexofval, 1)
                        }
                        else
                            dataval.push(val)
                        setUpdateData(!updatedata)
                        setSelected(dataval);
                        UpdateValue(dataval, e.uuid)
                    }

                    return (
                        <div key={"check" + ind} className="feedback-two-options" onClick={() => { CheckMulti(val) }}>
                            <div className="radio-btn">
                                {checkval.indexOf(val) == -1 ? <img crossorigin="anonymous" src={Radiooff} alt="radio_off" style={{ cursor: 'pointer' }} /> : <img crossorigin="anonymous" src={Radioon} alt="radio_on" style={{ cursor: 'pointer' }} />}
                            </div>
                            <div className="option-text">{val}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}

const RadioCheck = (props) => {
    const [checkval, setSelected] = useState([])
    const { e, i, UpdateValue } = props;
    return (
        <div className="feedback-two-cont">
            <div className="feedback-two">{(i + 1) + ". " + e.question}</div>
            {
                e.ansList.map((val, ind) => {

                    const CheckValue = (val) => {
                        setSelected(val);
                        UpdateValue(val, e.uuid)
                    }

                    return (
                        <div key={"radio" + ind} className="feedback-two-options" onClick={() => { CheckValue(val) }}>
                            <div className="radio-btn">
                                {checkval != val ? <img crossorigin="anonymous" src={Radiooff1} alt="radio_off" style={{ cursor: 'pointer' }} /> : <img crossorigin="anonymous" src={Radioon1} alt="radio_on" style={{ cursor: 'pointer' }} />}
                            </div>
                            <div className="option-text">{val}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}