/**
 * 
 * Radio
 * 
 * 
 */

import React from 'react';
import radio1 from '../../assets/images/radio1.svg';
import radio1act from '../../assets/images/radio1act.svg';

export default function Radio(props) {

    const { onChange, name, value, checked, label } = props;

    return (<label className="radioBoxLabel">
        <span className="radioBoxInput" aria-disabled="false">
            <input name={name} type="radio" value={value} onChange={onChange} />
            {!checked ? <img crossorigin="anonymous" src={radio1} /> : <img crossorigin="anonymous" src={radio1act} />}
        </span>
        <span>{label}</span>
    </label>)
}
