import React from 'react'
import './Symptoms.scss' 

function Symptoms(props) {
    return (
        <div className="Symptomsdetails">
               {props.data.map((item, index) => {
                   return(
                       <div key={`symptoms${index}`} className="container">
                           {item.title !="" && <div className="title">{item.title}:</div>}
                           <div className="value">{item.value}</div>
                       </div>
                   )
               })}
        </div>  
    )
}

export default Symptoms