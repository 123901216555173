import { takeLatest, put, select, all } from "redux-saga/effects";
import axios from "../axios";
import types from "./types";
import { api } from '../../utils/constants'

export const getUser = state => state.auth

export function* getOrders(payload,page) {
  let filterParams = ''
  let term = payload && payload.searchTerm ? payload.searchTerm : ''
  let url1 = `http://test-api.doctall.com/api/v1/order/diagnostic/getConsumerOrderDatewise?user_uuid=CN-20CA8F32&type=cart&pagenum=${page}&limit=20&search=${term}`
  if (payload.filterObj) {
    if (payload.filterObj.start_date) {
      filterParams = filterParams + '&start_date=' + payload.filterObj.start_date
    }
    if (payload.filterObj.end_date) {
      filterParams = filterParams + '&end_date=' + payload.filterObj.end_date
    }
    //order_status
    if (payload.filterObj.status) {
      filterParams = filterParams + '&order_status=' + payload.filterObj.status
    }
  }
  try {
    const user = yield select(getUser);


    let userUUID = user.uuid;
    let url = `${api.Order}/diagnostic/getConsumerOrderDatewise?user_uuid=${userUUID}&type=cart${filterParams}&pagenum=0&limit=200&search=${term}`

    const data = yield axios.get(url);
    yield put({ type: types.GET_ALL_ORDERS_SUCCESS, payload: data.data });
  } catch (err) {
    yield put({ type: types.GET_ALL_ORDERS_ERROR, payload: err.response.data });
  }
}



export function* viewReceipt(payload) {

  let orderID = payload && payload.orderId ? payload.orderId : '';
  let url1 = ` http://test-api.doctall.com/api/v1/ep/orderPaymentPreview/DOC-851897D25991`

  try {
    const user = yield select(getUser);
    let userUUID = user.uuid;
    let url = `${api.star_rating}/ep/orderPaymentPreview/${orderID}`

    const data = yield axios.get(url, {
      // params: { "created_by.uuid": user.uuid },
      headers: {
        "created_by.uuid": 'CN-20CA8F32',
        "x-auth-token": user.token
      },
    });

    yield put({ type: types.GET_RECEIPT_SUCCESS, payload: data.data });
  } catch (err) {
    yield put({ type: types.GET_RECEIPT_ERROR, payload: err.response.data });
  }
}





export function* getSearchedOrders(payload) {
  const { token } = yield select(getUser);

  let orderID = payload && payload.orderId ? payload.orderId : '';
  let url1 = `http://test-api.doctall.com/api/v1/order/diagnostic/getConsumerOrderDetail?user_uuid=CN-20CA8F32&order_id=${orderID}`

  try {
    const user = yield select(getUser);
    let userUUID = user.uuid;
    let url = `${api.Order}/diagnostic/getConsumerOrderDatewise?user_uuid=${userUUID}&order_id=${orderID}`

    const data = yield axios.get(url)

    yield put({ type: types.GET_SEARCHED_ORDERS_SUCCESS, payload: data.data })
    yield payload.cb()
  }
  catch (err) {
    yield put({ type: types.GET_SEARCHED_ORDERS_ERROR, payload: err.response.data })
  }
}


export function* orderWatcher() {

  yield takeLatest(types.GET_ALL_ORDERS, getOrders);
  yield takeLatest(types.GET_SEARCHED_ORDERS, getSearchedOrders);
  yield takeLatest(types.GET_RECEIPT, viewReceipt);


}

export default function* mainSaga() {
  yield all([orderWatcher()]);
}
