import React, { useState } from "react";
import { Calendar } from 'primereact/calendar';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css'

function DateRange(props) {

  const [dates, setDates] = useState(null)

  let handleDateChange = (e) => {

    setDates(e.value)
    props.getDates(e.value)
  }
  return (

    <div className="p-field p-col-6 p-md-4" >
      <label htmlFor="range" className="field-label">Select Date Range</label>
      <Calendar id="range" value={dates} onChange={handleDateChange}
        autoZIndex
        // visible={true}
        showButtonBar selectionMode="range" style={{ 'width': '200px' }} />
    </div>


  )
};

export default DateRange
