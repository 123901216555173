import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllOrders, getSearchedOrders } from './../../redux/orders/actions';
import axios from '../../redux/axios';
import { api } from '../../utils/constants';
import Layout from "../../layout/DashboardLayout";
import OrdersTabs from "./OrdersTabs";
import ICON13 from '../../assets/images/ICON13.svg'
import { useHistory } from "react-router-dom";
import useForceUpdate from 'use-force-update';
import { login } from "../../redux/auth/actions";
import CryptoJS from "crypto-js";


function OrdersResults(props) {

    let history = useHistory();
    const forceUpdate = useForceUpdate();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false);
    let ordersdata = useSelector((state) => state.orders.data);
    let orderFullDetails = useSelector((state) => state.orders.SEARCHED_ORDERS_data);
    const [completeOrdersData, setcompleteOrdersData] = useState([]);
    const [viewOrderDetails, setviewOrderDetails] = useState('');
    const [clickedOrderDetails, setclickedOrderDetails] = useState({});

    const [filterObjDetails, setfilterObjDetails] = useState('');


    useEffect(() => {

        try {
            var decryption = CryptoJS.AES.decrypt(props.match.params.userId.toString().replaceAll("DMP", "/"), 'doctall');
            let decryptKey = decryption.toString(CryptoJS.enc.Utf8);
            let identifier = decryptKey.substring(4)
            let passwords = decryptKey.substring(0, 4)
            // const val = { username: identifier, password: passwords };
            const val = { username: identifier, password: passwords, type: 'loginwithcredential' };
            dispatch(
                login(val, (res) => {
                    localStorage.setItem("CTKN", res.token);
                    fetchOrders()
                })
            );
        } catch (error) {
            fetchOrders()
        }

    }, [])


    let fetchOrders = (searchTerm, filterObj) => {
        setfilterObjDetails(filterObj)
        dispatch(getAllOrders(searchTerm, filterObj, props.match.params.userId))
    }

    useEffect(() => {
        setcompleteOrdersData(ordersdata ? ordersdata.order_info : [])
    }, [ordersdata])

    let toBeRenderedComponent = (index) => {
        return <OrdersTabs load={setLoader}
            completeOrdersData={completeOrdersData}
            fetchOrders={fetchOrders}
            viewIndividualOrder={viewIndividualOrder}
        />
    }

    let viewIndividualOrder = (id, order_details) => {
        let orderDetails = { id, order_details }
        history.push({ pathname: '/orders-view', state: orderDetails })
        setviewOrderDetails(id)
        setclickedOrderDetails(order_details)
        dispatch(getSearchedOrders(id))
    }


    let goBack = () => {
        dispatch(getAllOrders('', filterObjDetails));
        history.goBack()
    }

    return (
        <>

            <Layout loadin={loader} load={setLoader}>

                <div className="cont-wrapper-health-record">
                    <div className="text-wrap">
                        <div className="goBack mt-4" onClick={goBack}>
                            <img crossorigin="anonymous" src={ICON13} alt="arrow" className="img-fluid" />
                            <p>back</p>
                        </div>
                        <div className="header-text">Orders & Results
                        </div>
                        <div className="sub-header">View your test and panel orders and results</div>
                    </div>
                    {toBeRenderedComponent()}
                </div>
            </Layout>
        </>
    )
}

export default OrdersResults