import React from 'react'
import './BookingDetails.scss'

function BookingDetails(props) {
    return (
        <div className="bookdetails">
               {props.data.map((item, index) => {
                   return(
                       <div className="containers">
                           {item.title !="" && <div className="title">{item.title}:</div>}
                           <pre className="value">{item.value}</pre>
                       </div>
                   )
               })}
        </div>  
    )
}

export default BookingDetails
