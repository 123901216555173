import React, { useState, useEffect } from "react";
import Layout from "../../../layout/DashboardLayout";
import './changepasscode.scss';
import { useSelector } from "react-redux";
import { api } from "../../../utils/constants";
import axios from "../../../redux/axios";
import { useHistory } from "react-router-dom";
import Erroricon from "../../../assets/images/infoerror.svg";
const Changepasscode = () => {
    const history = useHistory();
    const [typed, setTyped] = useState("");
    const [showerror, setShowerror] = useState(false);
    const [errmag, setErrmsg] = useState("");
    const userdata = useSelector(state => state.auth);

    const handleSubmit = () => {
        var data = {
            username: userdata.mobile,
            password: typed
        }
        axios.post(api.login, data).then((res) => {
            if (res.data) {
                if (res.data.verified == true) {
                    history.push('/changepasscode')
                }
                else {
                    setErrmsg("Please enter the correct passcode")
                    setShowerror(true)

                }
            }
        }).catch((e) => {
            setShowerror(true)
            setErrmsg("Please enter the correct passcode")

        });
    }
    const handlePass = (e) => {
        const re = /^[0-9\b]+$/;
        const lengthcheck = /^[0-9]{0,4}$/;
        if (e.target.value === '' || re.test(e.target.value) && lengthcheck.test(e.target.value)) {
            setShowerror(false)
            setTyped(e.target.value)

        }
        else {
            setShowerror(true)
            setErrmsg("Your passcode must be a 4 digit numeric pin")
        }
    }
    return (
        <Layout>
            <div className="change-screen-one-cont">
                <div className="header-text">Change Passcode</div>
                <div className="sub-header-text">Change and create new passcode</div>
                <div className="pass-cont-outer">
                    <div className="cont-text">Enter Current Passcode</div>
                    <div className="input-cont">
                        <input type="text" placeholder="Enter passcode " value={typed} onChange={(e) => handlePass(e)} />
                    </div>
                    {
                        showerror ?
                            <div className="error-cls-cont">
                                <img crossorigin="anonymous" src={Erroricon} />
                                <div className="error-text">{errmag}</div>
                            </div> : ""
                    }

                </div>
                <div className="submit-btn" onClick={() => handleSubmit()}><span>Submit</span></div>
            </div>
        </Layout>
    )
}


export default Changepasscode;