import React, { useEffect, useState } from 'react'
import './AddDocuments.scss';
import ICON15 from '../../assets/images/ICON15.svg';
import Label from '../Label'
import UploadDocumentPopup from './UploadDocument';
import axios from '../../redux/axios';
import { docTypes, api } from '../../utils/constants';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import ProgressBar from '../ProgressBar';
import UploadSuccssMsg from '../UploadSuccessMsg';
import ICON16 from '../../assets/images/ICON16.svg'
import moment from 'moment';
import { Checkbox } from '@material-ui/core';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckBtnTwo from '../CheckBtnTwo'
import DeletePopup from '../DeletePopup';
import Toast from '../../components/Toast';
import Applycoupon from '../apply-coupon/Applycoupon';


function AddDocuments(props) {


    const { selfOrRelative, setSubDoc, subDoc } = props;
    const [buttondis, setbuttondis] = useState(false)
    const [modalShow, setModalShow] = React.useState(false);
    const [progress, setProgress] = React.useState(0)
    const [startupload, setStart] = React.useState(false)
    const [etime, setETime] = React.useState(0)
    const [fileData, setFileData] = React.useState('');
    const [doctype, setDocType] = React.useState("")
    const [name, setName] = React.useState("");
    const [note, setNote] = React.useState("");
    const [pat, setPat] = React.useState("");
    const [date, setDate] = React.useState("");
    const [uploadDoc, setUploadDoc] = React.useState([])
    const [doc, setDoc] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [severity, setSeverity] = React.useState('');
    const [msg, setMsg] = React.useState('')
    const [progressShow, setProgressShow] = React.useState(false);
    const [uploadSpeed, setUploadSpeed] = React.useState(0);
    const [documentuploadName, setDocumentuploadName] = React.useState('');
    const [showUploadSucc, setUploadSucc] = React.useState(false);
    const [showHide, setShowHide] = React.useState(false);
    const [docSearch, setDocSearch] = React.useState('')
    const [selectedDoc, setSelectedDoc] = React.useState([]);
    const [doslistChange, setDoclistChange] = React.useState(true)
    const [update, setUpdate] = React.useState(false)
    const [delPopup, setDelPopup] = React.useState(false);
    const [docid, setDocid] = React.useState({})
    const [showlist, setShowlist] = useState(false)
    const [initialShow, setInitialShow] = useState(false)

    useEffect(() => {
        var data = {
            startDate: moment().add(-3, "months").format("YYYY-MM-DD"),
            endDate: moment().format("YYYY-MM-DD")
        }

        axios.post(api.uploadlist + "/" + selfOrRelative.value, data)
            .then(r => {
                let valdata = r.data;
                valdata.sort(function (a, b) {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                });
                setDoc(valdata);
            })
            .catch(err => {
                console.log(err.response)
            })
    },[])


    useEffect(() => {
        if (props.patientId && props.patientId !== "") {
            props.setSelforRelative({
                value: props.patientId
            })
        }
    }, [])
    React.useEffect(() => {
        let arr = subDoc
        if (subDoc.length === 0) {
            setDoclistChange(true)

            for (var i = subDoc.length - 1; i >= 0; i--) {
                const result = subDoc.splice(i, 1);
                arr.push(result)
            }
            setSelectedDoc(arr)

        }

    }, [showlist])

    function Alert(props) {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const handleUpload = async ({ target: { files } }) => {
        setFileData(files[0])
    }

    const handleDrop = async (name, files, event) => {
        setFileData(files[0])
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const uploadProgress = (status) => {
        if (status == 100) {
            setProgressShow(false)
            setUploadSucc(true)
        } else {
            setProgressShow(true)
        }
    }

    const handleDocSubmit = async () => {
        if (fileData === '') {
            setOpen(true)
            setSeverity('error')
            setMsg('File Upload is Required!')
        }
        else if (fileData.size / 1000000 >= 5) {
            setOpen(true)
            setSeverity('warning')
            setMsg('Max. file size: 5MB Accepted')
        }
        else if (name.length === 0) {
            setOpen(true)
            setSeverity('error')
            setMsg('Document Name is Required')
        }
        else if (pat.length === 0) {
            setOpen(true)
            setSeverity('error')
            setMsg('Select Self or Relative is Required')
        }
        else if (doctype.length === 0) {
            setOpen(true)
            setSeverity('error')
            setMsg('Type of Document is Required')
        }

        else {
            setbuttondis(true)

            const formData = new FormData()
            formData.append('media', fileData)
            let timeStarted = new Date();
            var config = {
                method: 'post', url: api.upload, data: formData, onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    let timeElapsed = (new Date()) - timeStarted;
                    let uploadSpeed = progressEvent.loaded / (timeElapsed / 1000) / 1000000;
                    let etiming = ((progressEvent.total - progressEvent.loaded) / uploadSpeed);
                    etiming = parseInt(etiming).toFixed(2);
                    etiming = etiming.toString().replace(".", ":")
                    setModalShow(false)
                    setETime(etiming);
                    setUploadSpeed(uploadSpeed.toFixed(2));
                    setProgress(percentCompleted)
                    setProgressShow(true)
                    setDocumentuploadName(fileData.name)
                    uploadProgress(percentCompleted)


                }
            };

            axios(config).then(function (response) {
                var data = {
                    "docType": doctype.value,
                    "docName": name,
                    "location": response.data.url,
                    "patient": pat.value,
                    "notes": note,
                    "docDate": props.dateAppoint ? moment(props.dateAppoint).format('YYYY-MM-DD') : props.freedate ? moment(props.freedate).format('YYYY-MM-DD')
                        : props.sadndate ? moment(props.sadndate).format('YYYY-MM-DD') : date
                }

                let updoc = uploadDoc;
                updoc.push(data)
                setUploadDoc(updoc)
                axios.post(api.uploaddata, data)
                    .then(res => {


                        axios.post(api.uploadlist + "/" + pat.value, data,)
                            .then(r => {
                                let valdata = r.data;
                                valdata.sort(function (a, b) {
                                    return new Date(b.createdAt) - new Date(a.createdAt);
                                });

                                console.log(valdata)
                                setbuttondis(false)
                                // setDoc(valdata)


                            })
                            .catch(err => {
                                setbuttondis(false)

                                setOpen(true)
                                setSeverity('error')
                                setMsg(err.response.data)
                            })
                    })
                    .catch(err => {
                        setOpen(true)
                        setSeverity('error')
                        setMsg(err.response.data)
                    })

            })
                .catch(err => {
                    setOpen(true)
                    setSeverity('error')
                    setMsg(err.response.data)
                })


        }



    }

    const handleUploadDoc = () => {
        if (selfOrRelative !== '' || props.type == "appointment-booking") {
            setModalShow(true)
        } else {
            setOpen(true)
            setSeverity('error')
            setMsg('Please Select Self or Relative')
        }

    }
    const handleSelectSaveDoc = () => {
        if (selfOrRelative !== '') {
            setShowHide(!showHide)
            setShowlist(true)
            setInitialShow(false)
            setDoclistChange(true)
        } else {
            setOpen(true)
            setSeverity('error')
            setMsg('Please Select Self or Relative')



        }
    }

    const filterDoc = doc.filter(res => res.docName.toLowerCase().includes(docSearch.toLowerCase()))

    // console.log(filterDoc)

    const handleChange = (e) => {
        let listval = selectedDoc;
        let indexval = selectedDoc.findIndex((data) => data._id === e._id);
        if (indexval > -1) {
            listval.splice(indexval, 1);
        }
        else {
            listval.push(e)
        }
        setSelectedDoc(listval)

    }

    const handleDocumentSubmit = () => {
        if (selectedDoc.length === 0) {
            setOpen(true)
            setSeverity('warning')
            setMsg('Please Select the document ')
        } else {
            // const result = selectedDoc.concat(uploadDoc)
            setSubDoc(selectedDoc)
            setDoclistChange(false)
        }
    }

    const handleDelecteDoc = (val) => {
        setDelPopup(true)
        setDocid(val)
    }

    const confirmDelete = () => {
        if (props.documents && props.documents !== "" && subDoc.length <= 0) {
            props.documents.splice(props.documents.findIndex(e => e._id === docid._id), 1)
        }
        else {
            subDoc.splice(subDoc.findIndex(e => e._id === docid._id), 1)
        }
        setUpdate(!update)
        setDelPopup(false)
    }

    useEffect(() => {
        if (props.documents && props.documents !== "") {
            // setShowHide(!showHide)
            // setDoclistChange(false)
            setInitialShow(true)
        }
    }, [])

    useEffect(() => {
        if (subDoc.length === 0) {
            setDoclistChange(true)
        }
    })


    return (
        <>
            <div className="AddDocuments" style={props.styletype == true ? { maxWidth: "996px" } : {}}>
                <div className="inputContainer">
                    <div className="dropdownContainer">
                        <Label text="Add Relevant Document(s)" />
                        <div className="selectSaveDocContainer" onClick={handleSelectSaveDoc}  >
                            <div>Select Saved Document</div> <img crossorigin="anonymous" src={ICON16} alt="icon" onClick={handleSelectSaveDoc} />
                        </div>
                    </div>
                    {showHide ? <div className="searchContainer">
                        <Label text="Search Document" />
                        <div className="searchField">
                            <input name="search" className="search" onChange={e => setDocSearch(e.target.value)} disabled={!doslistChange} />
                            <img crossorigin="anonymous" src={ICON15} alt="search" />
                        </div>
                    </div> : ''}

                </div>
                {showHide && initialShow == false ? <div>
                    <div>
                        <Label text="Additional information" />
                    </div>
                    {
                        doslistChange ? <div className="additionalInformation">
                            <table className="table table-borderless">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th scope="col">File name</th>
                                        <th scope="col">Document Date</th>
                                        <th scope="col">Date uploaded</th>
                                        <th scope="col">Document Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        filterDoc.length !== 0 ? filterDoc.map((e, i) =>

                                            <tr key={i}>

                                                <td>
                                                    <CheckBtnTwo
                                                        id={e._id}
                                                        name={e.docName}
                                                        value={e}
                                                        onChange={() => handleChange(e)}
                                                        img={require('../../assets/images/check.svg').default}
                                                        imgAct={require('../../assets/images/checkact.svg').default}
                                                    />

                                                </td>
                                                <td>{e.docName}</td>
                                                <td>{e.docDate.length === 0 ? '' : moment(e.docDate).format('DD MMMM YYYY')}</td>
                                                <td>{moment(e.updatedAt).format('DD MMMM YYYY')}</td>
                                                <td>{e.docType}</td>
                                            </tr>
                                        )
                                            : <tr>
                                                <td colSpan={4}>No Records Found!</td>
                                            </tr>
                                    }

                                </tbody>
                            </table>
                        </div> :
                            <div className="additionalInformation">
                                {subDoc.map((e, i) =>
                                    <div className="docContainer" key={i} >
                                        <FolderOpenIcon className="folderIcon" /><div className="docName">{e.docName}</div><DeleteIcon className="delIcon" onClick={() => handleDelecteDoc(e)} />
                                    </div>
                                )}
                            </div>
                    }




                </div> :

                    props.documents && props.documents !== "" && initialShow == true ?

                        <div className="additionalInformation">
                            {props.documents.length !== 0 ? props.documents.map((e, i) =>
                                <div className="docContainer" key={i} >
                                    <FolderOpenIcon className="folderIcon" /><div className="docName">{e.docName}</div><DeleteIcon className="delIcon" onClick={() => handleDelecteDoc(e)} />
                                </div>
                            ) : initialShow == false ? <div className="noRecords">No Record</div> : ""}
                        </div> : ""
                }


                {
                    doslistChange ? <div className="buttonContainer">
                        <div className="button" onClick={handleDocumentSubmit}>
                            SUBMIT
                        </div>
                        <div className="buttonTwo" onClick={handleUploadDoc}>
                            Upload additional document
                        </div>
                    </div> : ''
                }


                <UploadDocumentPopup show={modalShow}
                    backdrop="static"
                    onHide={() => setModalShow(false)}
                    buttondis={buttondis}
                    setProgress={setProgress}
                    setStart={setStart}
                    setETime={setETime}
                    setFileData={setFileData}
                    fileData={fileData}
                    setDocType={setDocType}
                    setName={setName}
                    setNote={setNote}
                    setPat={setPat}
                    setDate={setDate}
                    handleUpload={handleUpload}
                    handleDocSubmit={handleDocSubmit}
                    handleDrop={handleDrop}
                    setAppoint={props.setDateAppoint ? props.setDateAppoint : ""}
                    dateAppoint={props.dateAppoint ? props.dateAppoint : ""}
                    freedate={props.freedate ? props.freedate : ""}
                    setFreedate={props.setFreedate ? props.setFreedate : ""}
                    setSadndate={props.setSadndate ? props.setSadndate : ""}
                    sadndate={props.sadndate ? props.sadndate : ""}


                />

                <ProgressBar
                    show={progressShow}
                    onHide={() => setProgressShow(false)}
                    progress={progress}
                    uploadSpeed={uploadSpeed}
                    documentuploadName={documentuploadName}
                />

                <UploadSuccssMsg
                    show={showUploadSucc}
                    onHide={() => setUploadSucc(false)}

                />

                <DeletePopup
                    show={delPopup}
                    onHide={() => setDelPopup(false)}
                    confirmDelete={confirmDelete}
                />

                <Toast text={msg} type={severity} open={open} setClose={() => { setOpen(false) }} />

            </div>

        </>
    )
}

export default AddDocuments