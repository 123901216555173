/**
 * 
 * Schema
 * 
 */

import { Schedule } from "@material-ui/icons";


export const doctorsFilter = [
    {
        name: 'country',
        label: 'NATIONALITY',
        editRecord: true,
        options: [
            {
                value: 'Nigeria',
                label: 'Nigeria'
            },
            {
                value: 'India',
                label: 'India'
            },
            {
                value: 'United Kingdom',
                label: 'United Kingdom'
            }
        ],
        type: 'select'
    },
    {
        name: 'speciality',
        label: 'SPECIALITY',
        options: 'specialities',
        editRecord: true,
        type: 'select'
    },
    {
        name: 'experience',
        label: 'YEARS OF EXPERIENCE',
        editRecord: true,
        options: [
            {
                value: '0-5',
                label: '0 - 5'
            },
            {
                value: '5-10',
                label: '5 - 10'
            },
            {
                value: '10-15',
                label: '10 - 15'
            },
            {
                value: '15-20',
                label: '15 - 20'
            },
            {
                value: '20-30',
                label: '20 - 30'
            },
            {
                value: '30-100',
                label: 'Above 30'
            }
        ],
        type: 'select'
    },
    // {
    //     name: 'price_range',
    //     label: 'PRICE RANGE',
    //     editRecord: true,
    //     min: 0,
    //     max: 15000,
    //     defaultValue: [10, 15000],
    //     step: 10,
    //     marks: [
    //         {
    //             value: 0,
    //             label: '₦0'
    //         },
    //         {
    //             value: 15000,
    //             label: '₦15000'
    //         }
    //     ],
    //     type: 'range'
    // },
    // {
    //     name: 'language',
    //     label: 'LANGUAGES SPOKEN',
    //     options: 'languages',
    //     editRecord: true,
    //     type: 'radio',
    //     style: { flex: '0 0 78%' },
    //     // options: [
    //     //     {
    //     //         label: 'English',
    //     //         value: 'English'
    //     //     },
    //     //     {
    //     //         label: 'Igbo',
    //     //         value: 'Igbo'
    //     //     },
    //     //     {
    //     //         label: 'Pidgin',
    //     //         value: 'Pidgin'
    //     //     },
    //     //     {
    //     //         label: 'Yoruba',
    //     //         value: 'Yoruba'
    //     //     },
    //     //     {
    //     //         label: 'Hausa',
    //     //         value: 'Hausa'
    //     //     }
    //     // ]
    // }
];

export const finddoctorsFilter = [
    {
        name: 'country',
        label: 'NATIONALITY',
        editRecord: true,
        options: [
            {
                value: 'Nigeria',
                label: 'Nigeria'
            },
            {
                value: 'India',
                label: 'India'
            },
            {
                value: 'United Kingdom',
                label: 'United Kingdom'
            }
        ],
        type: 'select'
    },
    {
        name: 'speciality',
        label: 'SPECIALITY',
        options: 'specialities',
        editRecord: true,
        type: 'select'
    },
    {
        name: 'sort_type',
        label: 'SORT BY',
        editRecord: true,
        options: [
            {
                value: 'eat',
                label: 'Earliest Available Time'
            },
            {
                value: 'price',
                label: 'Price'
            },
            {
                value: 'exp',
                label: 'Years of Experiencce'
            }
        ],
        type: 'select'
    },
    {
        name: 'price_range',
        label: 'PRICE RANGE',
        editRecord: true,
        min: 0,
        max: 15000,
        defaultValue: [10, 15000],
        step: 10,
        marks: [
            {
                value: 0,
                label: '₦0'
            },
            {
                value: 15000,
                label: '₦15000'
            }
        ],
        type: 'range'
    },
    {
        name: 'language',
        label: 'LANGUAGES SPOKEN',
        options: 'languages',
        editRecord: true,
        type: 'radio',
        style: { flex: '0 0 78%' },
        // options: [
        //     {
        //         label: 'English',
        //         value: 'English'
        //     },
        //     {
        //         label: 'Igbo',
        //         value: 'Igbo'
        //     },
        //     {
        //         label: 'Pidgin',
        //         value: 'Pidgin'
        //     },
        //     {
        //         label: 'Yoruba',
        //         value: 'Yoruba'
        //     },
        //     {
        //         label: 'Hausa',
        //         value: 'Hausa'
        //     }
        // ]
    }
];



export const ordersFilter = [


    {
        name: 'country',
        label: 'Status',
        editRecord: true,
        options: [
            {
                value: 'inProgress',
                label: 'In Progress'
            },
            {
                value: 'completed',
                label: 'Completed',
            },
            {
                value: 'pending',
                label: 'Pending'
            },
            {
                value: '',
                label: 'All'
            }

        ],
        type: 'select'
    },


]
