import React from 'react'
import { Link } from 'react-router-dom'
import './specialStyle.scss'

function SpecialCard(props) {
    const { title, para, img, color, _onClick } = props
    return (

        <div className='specialCard' style={{ borderColor: color }} onClick={_onClick}>
            <div className="titleSpecial">
                <h3>{title}</h3>
                <img crossorigin="anonymous" src={img} alt='' />
            </div>
            <div className="paraSpecial">
                <h5>{para}</h5>
            </div>
        </div>

    )
}

export default SpecialCard
