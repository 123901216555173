import React, { useEffect, useState } from 'react'
import "./checkBtn.scss"

function CheckBtn(props) {
    const { styles } = props
    const [checkval, setSelected] = useState([])
    const [updatedata, setUpdateData] = useState(false)
    useEffect(() => {
        if (props.defaultValue) {
            setSelected(props.defaultValue);
        }
    }, [props.defaultValue])
    const handleRadio = (id) => {
        let dataval = checkval;
        if (dataval.includes(id)) {
            const filteredPeople = dataval.filter((item) => item !== id);
            dataval=filteredPeople;
            setSelected(filteredPeople);
            props.onChange(dataval)
        }
        else if (id === "None." && !dataval.includes("None.") || id === "None" && !dataval.includes("None")) {
            setSelected([]);
            if (id === "None.") {
                dataval=["None."];
                setSelected(["None."]);
            props.onChange(dataval);
            }
            else if (id === "None") {
                dataval=["None"];
                setSelected(["None"])
            props.onChange(dataval);
            }

        }

        else  {
            if(dataval.includes("None.")){
                const filteredPeople = dataval.filter((item) => item !== "None.");
                dataval=filteredPeople;
                dataval.push(id)
                setSelected(dataval);
                props.onChange(dataval)

            }
            else if(dataval.includes("None")){

                const filteredPeople = dataval.filter((item) => item !== "None");
                dataval=filteredPeople;
                dataval.push(id)
                setSelected(dataval);
                props.onChange(dataval)
            }
            else{
            dataval.push(id)
            setSelected(dataval)
            props.onChange(dataval)
            }
        }
        // else if (!dataval.includes("None.") && !dataval.includes("None")) {
        //     dataval.push(id)
        //     setSelected(dataval)
        //     props.onChange(dataval)
        // }
    }
    return (
        props.data.map((val, index) => {
            return <div key={`${index}_${props.name}`} className="radioComponent">
                <label className="newRadio" htmlFor={val} onClick={() => handleRadio(val)}>
                    {checkval.includes(val) ? <img crossorigin="anonymous" src={require('../../assets/images/checkact.svg').default} alt="" /> :
                        <img crossorigin="anonymous" src={require('../../assets/images/check.svg').default} alt="" />}
                    <div style={styles} className="radioName">{val}</div>
                </label>

            </div>
        })
    )
}
export default CheckBtn
