import moment from 'moment';
import CryptoJS from "crypto-js";

export const encrypt = (data) => {
  try {
    return CryptoJS.AES.encrypt(
      data,
      process.env.REACT_APP_SECRET_KEY
    ).toString();
  } catch (error) {
    console.error(error);
  }
}

export const decrypt = (encrypteddata) => {
  try {
    return CryptoJS.AES.decrypt(
      encrypteddata,
      process.env.REACT_APP_SECRET_KEY
    ).toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.error(error);
  }
}

export const getnumber = (value) => {
  var numberPattern = /\d+/g;
  return value.match(numberPattern).join([]);

}
export const checkindexzero = (value) => {
  value = value.toString();
  if (value.charAt(0) == "0") {
    return value.substring(1, value.length)
  }
  return value;
}
export const numberWithCommas = (x) => {
  if (x) {
    x = parseFloat(x).toFixed(0)
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
      x = x.replace(pattern, "$1,$2");
    return x;
  }
  return "0";
}

export const ValidateEmail = (mail) => {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
    return (true)
  }

  return (false)
}
export const Validphonenumber = (inputtxt) => {
  var phoneno = /^\d{10}$/;
  if ((inputtxt.match(phoneno))) {
    return (true)
  }

  return (false)
}

export const IntialGen = (nameString) => {
  console.log(nameString,"svdhjgsdvj")
  if (nameString && Object.keys(nameString).length > 0) {
    let fullName = "";
    if (nameString.firstName != "")
      fullName = (nameString["firstName"] + ' ' + nameString["lastName"]).split(' ');
    else
      fullName = (nameString["username"]).split(' ');
    let initials = "";
    if (fullName.length > 1)
      initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    else
      initials = fullName.shift().charAt(0);
    return initials.toUpperCase();
  }
  return "";
}

export const checkSafari = () => {
  return /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
}

export const getYear = (year) => {
  if (year) {
    let currentyear = moment().format("YYYY");
    return currentyear - year + " Years"
  }
  else {
    return "";
  }
}
