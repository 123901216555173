import React, { useState, useEffect } from 'react';
import './stylesDoc.scss';
import Layout from '../../layout/DashboardLayout';
import { SelectTimeSlote, AppointmentTip, ReasonForVisit, AddDocuments, TitleWithNav } from "../../components"
import axios from '../../redux/axios';
import { api } from '../../utils/constants'
import * as RNLocalize from 'react-native-localize';
import momenttz from 'moment-timezone';
import moment from 'moment';
import { useSelector } from "react-redux";
import usePaystackHook from "../../components/Payment";
import { Spin } from "antd";
import Toast from '../../components/Toast';
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import DoctorProfile2 from '../../components/DoctorProfile/docSearchProf'
import SelectPatient from '../../components/SelectTimeSlote/selectPatient';
import Submitpop from "../../components/HealthPop/index";
import Applycoupon from '../../components/apply-coupon/Applycoupon';

function arrUnique(arr) {
  var cleaned = [];
  arr.forEach(function (itm) {
    var unique = true;
    cleaned.forEach(function (itm2) {
      if (moment(itm.date).format('ddd  MM/DD') == moment(itm2.date).format('ddd  MM/DD') && moment(itm.start_time).format('LT') == moment(itm2.start_time).format('LT')) unique = false;
    });
    if (unique) cleaned.push(itm);
  });
  return cleaned;
}

function BookAnSpecialist() {
  // const [docList,setDocList] =useState([])

  const userdata = useSelector((state) => state.auth);
  const [reference, setReference] = useState("");
  const [appointmentData, setAppointmentData] = useState();
  const [feeamount, setfeeamount] = useState(0)
  const [finalAmount, setFinalAmount] = React.useState(0);
  const [appliedCoupanId, setAppliedCoupanId] = React.useState('');
  const [couponAppliedFlag, setCouponsAppliedFlag] = useState(false);


  console.log(finalAmount, "finalAmount")

  const [doc, setDoc] = useState([])
  console.log(doc, "doc")
  const [isloading, setIsLoading] = useState(false)
  const [clinic, setClinic] = useState(false);
  const [originalslot, setOriginalSlot] = useState([]);
  const [slotDate, setSlotDate] = useState([]);
  const [slot, setSlot] = useState([]);
  const [slotsection, setSlotSection] = useState('');
  const [symptom, setsymptom] = useState("");
  const [notes, setNotes] = useState("");
  const [selfOrRelative, setSelforRelative] = useState('');
  const [slotTime, setSlotTime] = useState("");
  const [toster, setToster] = useState(false);
  const [msg, setMsg] = useState('');
  const [type, setType] = useState('');
  const [subDoc, setSubDoc] = useState([]);
  let history = useHistory();
  const [loader, setLoader] = useState(false)
  const [confirmloader, setconfirmloader] = React.useState(false);
  const [dateAppoint, setDateAppoint] = useState(new Date());
  const Patients = useSelector(state => state.patients);
  const [modalVisibleHP, setmodalVisibleHP] = useState(false)

  const [totalmt, settotal] = useState('')
  const [walletmt, setwalletmt] = useState('')

  // const paymentStart = usePaystackHook({ email: userdata.email, amt: couponAppliedFlag === true && finalAmount && finalAmount !== null && finalAmount !== '' && finalAmount !== undefined && finalAmount > 0 ? finalAmount : feeamount });
  const paymentStart = usePaystackHook({ email: userdata.email, amt: finalAmount && finalAmount !== null && finalAmount !== '' && finalAmount !== undefined && finalAmount > 0 && finalAmount });

  useEffect(() => {
    if (history.location.state.length === 0) {
      history.push("/search_doctor")

    }

  }, [])
  const selectedValue = history.location.state && history.location.state.selectedData !== undefined && history.location.state.selectedData !== "" ? history.location.state.selectedData : ''


  useEffect(() => {
    if (selectedValue && selectedValue !== undefined && selectedValue !== '') {
      setSlotTime(selectedValue)
    }
  }, [])
  useEffect(() => {

    if (slotsection.length > 0) {
      setIsLoading(true)
    }

  }, [slotsection])
  useEffect(() => {
    handleSelf()
  }, [selfOrRelative])
  const docData = history.location.state.doc ? history.location.state.doc : "";
  const doctorData = history.location.state.doctordata ? history.location.state.doctordata : "";


  useEffect(async () => {
    await axios.get(api.doctor_search, { params: { "search": docData !== "" ? docData.full_name : doctorData.doctors[0].full_name, "skip": 0, "limit": 100, } })
      .then((res) => {
        setDoc(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })

  }, [])

  useEffect(() => {
    axios.get(api.clinic_by_doctor + "/all")
      .then(res => setClinic(res.data))
      .catch(err => console.log(err))

  }, []);

  useEffect(async () => {
    if (docData.uuid) {


      await axios.get(api.slots + "/normal/doctor/" + docData.uuid)
        .then(res => {
          let slotlist = res.data && res.data.length > 0 ? res.data[0].slots : [];
          // slotlist = slotlist.filter((e) => e.isActive);
          slotlist = slotlist.filter((e) => moment(e.start_time) >= moment() && e.isActive == true);

          slotlist = arrUnique(slotlist);
          setOriginalSlot(slotlist);
          let result = slotlist.map((e, i) => {
            return {
              label: moment(e.date).format('ddd  MM/DD'), value: e._id
            }
          })
          var outputList = [];
          for (var x in result) {
            if (outputList.findIndex((e) => e.label === result[x].label) === -1)
              outputList.push(result[x]);
          }
          setSlot(outputList)

          let resulttime = slotlist.map((e, i) => { return { label: moment(e.start_time).format('LT'), value: e._id, date: moment(e.date).format('ddd  MM/DD') } })
          setSlotDate(resulttime)

          let SelectedDate = slotDate.filter((i) => (i.value === selectedValue.value))
          setSlotSection(SelectedDate)
        })
        .catch(err => console.log(err))
    }

  }, [doc])
  useEffect(async () => {
    if (doctorData.uuid) {

      await axios.get(api.slots + "/normal/doctor/" + doctorData.doctor_uuid)
        .then(res => {
          let slotlist = res.data && res.data.length > 0 ? res.data[0].slots : [];
          // slotlist = slotlist.filter((e) => e.isActive);
          slotlist = slotlist.filter((e) => moment(e.start_time) >= moment() && e.isActive == true);

          slotlist = arrUnique(slotlist);
          setOriginalSlot(slotlist);
          let result = slotlist.map((e, i) => {
            return {
              label: moment(e.date).format('ddd  MM/DD'), value: e._id
            }
          })
          var outputList = [];
          for (var x in result) {
            if (outputList.findIndex((e) => e.label === result[x].label) === -1)
              outputList.push(result[x]);
          }
          setSlot(outputList)

          let resulttime = slotlist.map((e, i) => { return { label: moment(e.start_time).format('LT'), value: e._id, date: moment(e.date).format('ddd  MM/DD') } })
          setSlotDate(resulttime)

          let SelectedDate = slotDate.filter((i) => (i.value === selectedValue.value))
          setSlotSection(SelectedDate)
        })
        .catch(err => console.log(err))
    }

  }, [doctorData])




  const ExtendConfirmBooking = () => {
    let bookingdate;
    let today;
    let doctors;
    if (selfOrRelative == '' && doctorData == "") {
      setToster(true);
      setMsg("Please select Patient");
      setType('error');
      return false;

    }
    if (slotTime == '') {
      setToster(true);
      setMsg("Please select Slot");
      setType('error');
      return false;

    }
    setLoader(true)
    let datareasons = [];
    if (symptom == "") datareasons.push("Abdominal Pain")
    else
      datareasons.push(symptom.value)
    bookingdate = originalslot.filter((e, i) => { return e._id == slotTime.value });
    bookingdate = bookingdate.length > 0 ? bookingdate[0].date : "";
    const deviceTimeZone = RNLocalize.getTimeZone();
    today = momenttz(bookingdate).tz(deviceTimeZone);
    if (doctorData != undefined) {
      doctors = doctorData != undefined && doctorData.doctors != undefined ? doctorData.doctors[0] : null
    }
    let bookingData = {
      reasons: doctorData !== "" && datareasons.length <= 1 && datareasons[0] == undefined && doctorData.reasons.length > 0 ? doctorData.reasons : datareasons,
      video: true,
      slot: slotTime.value,
      desc: notes == "" ? "NA" : notes,
      booking_date: today.utc().format(),
      patient_uuid: doctorData != undefined && doctorData.patient != undefined && doctorData.patient.uuid ? doctorData.patient.uuid : selfOrRelative.value,
      category: "GC",
      ref_id: doctorData !== "" ? doctorData.uuid : "",
      doctor_uuid: doctorData !== "" ? doctors.uuid : docData.uuid,
      type: docData !== "" ? docData.profile.address && Object.keys(docData.profile.address).length > 0 && docData.profile.address.country ? (docData.profile.address.country.toLowerCase() == "nigeria" ? "Find a doctor" : "international") : "normal"
        : doctors.profile.address && Object.keys(doctors.profile.address).length > 0 && doctors.profile.address.country ? (doctors.profile.address.country.toLowerCase() == "nigeria" ? "Find a doctor" : "international") : "normal",
      doctor_uuid: doctorData !== "" ? doctors.uuid : docData.uuid,
      amount: couponAppliedFlag === true && finalAmount >= 0 ? finalAmount : doctorData !== "" && doctors.consultation_fee && docData.consultation_fee ? docData.consultation_fee : 0,
      clinic_uuid: clinic.uuid,
      documents: doctorData != "" && subDoc.length <= 0 && doctorData.documents.length > 0 ? doctorData.documents : subDoc.length > 0 ? subDoc : []

    }
    setfeeamount(doctorData !== "" ? doctors.consultation_fee : docData.consultation_fee ? docData.consultation_fee : 0)
    axios.post(api.appointment + "normal", bookingData,
      {
        headers: { "x-auth-token": userdata.token }

      })
      .then(res => {
        setLoader(false)
        setAppointmentData(res.data);
        setconfirmloader(false);
        ExtPaymentSuccess(res.data);
      })
      .catch((err) => {
        setTimeout(() => {
          setToster(true);
          setMsg("Booking :" + err.response.data);
          setType("error");
          setconfirmloader(false);
        }, 100);
        setLoader(false);
      });

  }
  const ExtPaymentSuccess = (e) => {
    axios.put(api.appointmentall + "/" + e.uuid + "/booked", {}, { headers: { 'x-auth-token': userdata.token } })
      .then(res2 => {
        history.push({ pathname: "/doctor_consultations", data: e });

        console.log(e, "8585 -----")
        // props.navigation.navigate("FindDocApponitDetail", { data: e })
      }).catch(err => {
        console.log(err)
        // Toast.showWithGravity("Payment :" + err.response.data, Toast.LONG, Toast.TOP);

      })
  }

  const getFinalAmount = (consultationFee) => {
    if (finalAmount && finalAmount !== null && finalAmount !== '' && finalAmount !== undefined && finalAmount > 1) {
      return finalAmount
    } else {
      return consultationFee
    }
  }


  const ConfirmBooking = () => {
    console.log('ConfirmBooking', 'called')
    let bookingdate;
    let today;
    let doctors;
    if (selfOrRelative == '' && doctorData == "") {
      setToster(true);
      setMsg("Please select Patient");
      setType('error');
      return false;

    }
    if (slotTime == '') {
      setToster(true);
      setMsg("Please select Slot");
      setType('error');
      return false;

    }
    setconfirmloader(true);

    // else if (slotTime.length == 0) {
    //     setToster(true)
    //     setMsg("please select slot")
    //     setType('error')
    // }
    //  else {
    // setLoader(true)
    let datareasons = [];
    if (symptom == "") datareasons.push("Abdominal Pain")
    else
      datareasons.push(symptom.value)
    bookingdate = originalslot.filter((e, i) => { return e._id == slotTime.value });
    bookingdate = bookingdate.length > 0 ? bookingdate[0].date : "";
    const deviceTimeZone = RNLocalize.getTimeZone();
    today = momenttz(bookingdate).tz(deviceTimeZone);
    if (doctorData != undefined) {
      doctors = doctorData != undefined && doctorData.doctors != undefined ? doctorData.doctors[0] : null
    }

    let bookingData = {
      reasons: doctorData !== "" && datareasons.length <= 1 && datareasons[0] == undefined && doctorData.reasons.length > 0 ? doctorData.reasons : datareasons,
      video: true,
      slot: slotTime.value,
      desc: notes == "" ? "NA" : notes,
      booking_date: today.utc().format(),
      patient_uuid: doctorData != undefined && doctorData.patient != undefined && doctorData.patient.uuid ? doctorData.patient.uuid : selfOrRelative.value,
      category: "GC",
      doctor_uuid: doctorData !== "" ? doctors.uuid : docData.uuid,
      type: docData !== "" ? docData.profile.address && Object.keys(docData.profile.address).length > 0 && docData.profile.address.country ? (docData.profile.address.country.toLowerCase() == "nigeria" ? "Find a doctor" : "international") : "normal"
        : doctors.profile.address && Object.keys(doctors.profile.address).length > 0 && doctors.profile.address.country ? (doctors.profile.address.country.toLowerCase() == "nigeria" ? "Find a doctor" : "international") : "normal",
      doctor_uuid: doctorData !== "" ? doctors.uuid : docData.uuid,
      amount: couponAppliedFlag === true && finalAmount >= 0 ? finalAmount : doctorData !== "" ? doctors.consultation_fee : docData.consultation_fee ? docData.consultation_fee : 0,
      clinic_uuid: clinic.uuid,
      documents: doctorData != "" && subDoc.length <= 0 && doctorData.documents.length > 0 ? doctorData.documents : subDoc.length > 0 ? subDoc : []
    }
    setfeeamount(doctorData !== "" ? doctors.consultation_fee : docData.consultation_fee ? docData.consultation_fee : 0)
    if (doctorData !== "") {
      bookingData.ref_id = doctorData.uuid;
    }
    console.log(bookingData, 'bookingData')
    axios.post(api.appointment + "normal", bookingData,
      {
        headers: { "x-auth-token": userdata.token }

      })
      .then(res => {
        console.log(res,"bookingData")
        setAppointmentData(res.data);
        setconfirmloader(false);
       

        axios
          .get(api.payment + "booking/" + res.data.uuid, {
            headers: { "x-auth-token": userdata.token },
          })
          .then((res2) => {
            if (res2.data && res2.data.reference) {
              setReference(res2.data.reference);
              // blockAppliedCoupon(res2.data.reference)
            } else {
              setTimeout(() => {
                setToster(true);
                setMsg("Payment Reference is Empty");
                setType("error");
              }, 100);
              setLoader(false);
            }
          })
          .catch((err) => {
            setTimeout(() => {
              setToster(true);
              setMsg("Payment Ref :" + err.response.data);
              setType("error");
            }, 100);
            setLoader(false);
          });

      })
      .catch((err) => {
        setTimeout(() => {
          if (err && err.response) {
            setToster(true);
            setMsg("Booking :" + err.response.data);
            setType("error");
            setconfirmloader(false);
          }
        }, 100);
        setLoader(false);
      });
    //  }

  }

  useEffect(() => {
    if (reference) {
      setLoader(false);
      if (couponAppliedFlag === true && finalAmount === 0) {
        applyFullDiscount()
      } else {
        paymentStart(PaymentSuccess, PaymentCancel);
      }
    }
  }, [reference])


  const applyFullDiscount = async () => {
    try {
      blockAppliedCoupon(reference)
      setLoader(true);
      let response = await axios.put(api.appointmentall + "/" + appointmentData.uuid + "/booked", {}, { headers: { "x-auth-token": userdata.token } })
      history.push({ pathname: "/doctor_consultations", data: appointmentData });
    } catch (error) {
      if (error && error.response) {
        setLoader(false);
        setToster(true);
        setMsg("Payment :" + error.response.data);
        setType("error");
      }
    }
  }

const walletDat = () => {
  console.log("WALLET CALLED")
  var payLoad  = {
    "totalamount" : totalmt,
    "amount":walletmt,
    "order_id":appointmentData.uuid,
    "user_uuid" : userdata.uuid
}
  axios.put(api.updatewalletAmnt,payLoad,{ headers: { "x-auth-token": userdata.token } })
  .then((res) => {
    console.log("update")
  })
  .catch((err) => {
    console.log(err, "WALLEt ERRORR")
  })
}
const bookedDat = () => {
  console.log("BOOKED CALLED")
  axios
  .put(
    api.appointmentall + "/" + appointmentData.uuid + "/booked",
    {},
    { headers: { "x-auth-token": userdata.token } }
  )
  .then((res2) => {
    history.push({ pathname: "/doctor_consultations", data: appointmentData });
  }).catch((err) => {
    setLoader(false);
    setToster(true);
    setMsg("Payment :" + err.response.data);
    setType("error");
  });
}

  const PaymentSuccess = (e) => {
    setLoader(true);

    try {
      if (e.status == "success") {
        blockAppliedCoupon(reference)
        setLoader(true);
        walletDat();
        bookedDat();
        console.log("suCCESS")
      } else {
        axios
          .put(
            api.appointmentall + "/" + appointmentData.uuid + "/declined",
            {},
            { headers: { "x-auth-token": userdata.token } }
          )
          .then((res2) => {
            setLoader(false);
          })
          .catch((err) => {
            setLoader(false);
            setToster(true);
            setMsg("Payment :" + err.response.data);
            setType("error");
          });
      }
    } catch (e) {
      setLoader(false);
      console.log(e)
    }
  };

  const blockAppliedCoupon = async (transactionId) => {
    try {
      let response = await axios.put(`${api.blockCoupon}/${appliedCoupanId && appliedCoupanId}/${transactionId}`, { headers: { "x-auth-token": userdata.token } })
      if (response) {
        console.log("Success")
      }

      var payLoad  = {
        "totalamount" : totalmt,
        "amount":walletmt,
        "user_uuid" : userdata.uuid
    }
      axios.put(api.updatewalletAmnt,payLoad,{ headers: { "x-auth-token": userdata.token } })
      .then((res) => {
        console.log("update")
      })
      .catch((err) => {
        console.log("err")
      })

    } catch (error) {
      console.log(error)
    }
  }


  const PaymentCancel = (e) => {
    setLoader(true);
    axios
      .put(
        api.appointmentall + "/" + appointmentData.uuid + "/cancelled",
        {},
        { headers: { "x-auth-token": userdata.token } }
      )
      .then((res2) => {
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        setToster(true);
        setMsg("Payment :" + err.response.data);
        setType("error");
      });
  };
  const handleSelf = () => {
    let res;
    if (Patients != undefined && Patients !== "") {
      res = Patients.data.filter((e, i) => e.uuid == selfOrRelative.value)
    }

    if (res.length > 0) {
      let data = res[0];
      if (data.medicalQuestions && data.medicalQuestions.length > 0) {
      }
      else {
        setmodalVisibleHP(true)
      }
    }
    // setPatient(item)
  }
  return (
    <>
      <Layout>
        <div className="specialistContainer">
          <TitleWithNav title={"Find a Doctor / Specialist (International & Nigerian)"} />
          <Spin spinning={loader}>

            <div className="containerWrapper">
              <div className="componentContainer">
                {doc != undefined && doc.length !== 0 ?
                  <DoctorProfile2 data={doc[0]} />
                  : ""}
                {isloading === true ?
                  <SelectTimeSlote
                    type={'specialist'}
                    slot={slot}
                    // defaultVal={slotsection}
                    slotDate={slotDate}
                    setSlotTime={setSlotTime}
                    slotTimes={slotTime}
                  /> :
                  <SelectTimeSlote
                    type={'specialist'}
                    slot={slot}
                    // defaultVal={slotsection}
                    slotDate={slotDate}
                    setSlotTime={setSlotTime}
                    patientName={doctorData != "" ? doctorData.patient.full_name : ""}
                  />
                }

              </div>
              <div className="componentSection">
                <div className="componentSectionChild">
                  <SelectPatient setSelforRelative={setSelforRelative} type={doctorData !== "" ? 'doctor' : ""} name={doctorData !== "" ? doctorData.patient.full_name : ""} />
                  <AppointmentTip type="specialist" />
                </div>
                <ReasonForVisit type='specialist' symptom={symptom} setsymptom={setsymptom} setNotes={setNotes} types={doctorData !== "" ? 'doctor' : ""} reason={doctorData !== "" ? doctorData.reasons[0] : ""} />
              </div>
            </div>
            <AddDocuments selfOrRelative={selfOrRelative} setSelforRelative={setSelforRelative} setSubDoc={setSubDoc} subDoc={subDoc}
              type={doctorData !== "" ? 'appointment-booking' : ""} dateAppoint={dateAppoint}
              setDateAppoint={setDateAppoint} styletype={true}
              patientId={doctorData !== "" ? doctorData.patient_uuid : ""} documents={doctorData !== "" && doctorData.documents ? doctorData.documents : ""}
            />

            <div>
              <Applycoupon
                userId={userdata && userdata.uuid}
                totalAmount={docData && docData.consultation_fee}
                doctorUUID = { doc && doc[0] && doc[0].uuid}
                doctorSpecaility = { doc && doc[0] && doc[0].profile && doc[0].profile.speciality && doc[0].profile.speciality[0]}
                billtotal={(amount) => { settotal(amount) }}
                walletAmount={(amount) => {setwalletmt(amount)}}
                finalAmount={(amount) => { setFinalAmount(amount) }}
                blockCouponid={(couponId) => { setAppliedCoupanId(couponId) }}
                couponAppliedFlags={(event) => {
                  setCouponsAppliedFlag(event);
                }}
                appointmentCategory={"fadn"}
              />
            </div>


            <div className="buttonConfirm" style={{ maxWidth: "260px", marginTop: '1%' }} onClick={() => doctorData !== "" && doctorData.status == "followup" ? ConfirmBooking()
              : doctorData !== "" && doctorData.status !== "followup" ? ExtendConfirmBooking() : ConfirmBooking()}>
              {confirmloader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Please Wait...&nbsp;
                  <CircularProgress size="25" style={{ color: "#fff", marginLeft: 5 }} />
                </div>
              ) : (
                "CONFIRM APPOINTMENT"
              )}                </div>
          </Spin>



        </div>
        <Toast text={msg} type={type} open={toster} setClose={() => { setToster(false) }} />
      </Layout>
      <Submitpop show={modalVisibleHP} onHide={() => setmodalVisibleHP(false)} />

    </>
  )
}

export default BookAnSpecialist