import React from 'react'
import './AddDocuments.scss';
import { Modal } from 'react-bootstrap';
import Close from '../../assets/images/close.svg';
import SecondLabel from '../SecondaryLabel';
import Select from '../Select/'
import { docTypes, api } from '../../utils/constants';
import { useSelector } from "react-redux";
import { FileDrop } from 'react-file-drop';
import DatePicker from 'react-date-picker'
import { ConsulationList } from '..';




function UploadDocumentPopup(props) {

    const patient = useSelector(state => state.patients);
    const [patientList, setPatientList] = React.useState([]);


    React.useEffect(() => {
        if (patient && patient.data) {
            let res = patient.data.map((e, i) => { return { label: e.full_name, value: e.uuid } })
            setPatientList(res)
        }

    }, [patient])

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            dialogClassName="modal-upload"
            style={{width:"100%"}}
        >
            <div className="uploadDocuments">
                <div className="header">
                    Upload Document
                    <img crossorigin="anonymous" style={{ cursor: "pointer" }} src={Close} onClick={() => { props.onHide() }} alt="Close" crossorigin="anonymous" />
                </div>
                <hr className="heading_line" />
                <main>
                    <div className="uploadContainer">

                        <input type="file" id="uploadDoc" className="fileInput" onChange={props.handleUpload} />

                        <FileDrop
                            onDrop={(files, event) => props.handleDrop('onDrop!', files, event)}
                        >
                            Upload file here
                            <label className="uploadFile" for="uploadDoc" >Browse Files</label>
                        </FileDrop>


                    </div>
                    <div className="uploadedText">{props.fileData && props.fileData.name.length !== 0 ? props.fileData.name : ''}</div>
                    <div className="instruction">
                        Max. file size: <span style={{ fontWeight: '900', marginRight: '10px' }}>5MB</span> Accepted formats: <span style={{ fontWeight: '900' }}>pdf, jpeg, png, docx, tiff, xml, bmp</span>
                    </div>

                    <SecondLabel text="Document Name:" />
                    <div className="inputField">
                        <input type="text" onChange={e => props.setName(e.target.value)} className="input" />
                    </div>
                    <SecondLabel text="Select Self or Relative" />
                    <Select placeholder={"Please select"} value={""} setValue={(value) => props.setPat(value)} options={patientList} />
                    <div style={{ marginBottom: '30px' }}></div>
                    <div className="samllInputContainer">
                        <div style={{ width: '47%' }} >
                            <SecondLabel text="Type of Document:" />
                            <Select placeholder={"Please select"} value={""} setValue={(value) => props.setDocType(value)} options={docTypes} />
                        </div>
                        <div style={{ width: '50%' }}>
                            <SecondLabel text="Date on the Document (optional)" />
                            <div className="inputFieldTwo" style={{ width: '100%' }}>
                                <DatePicker
                                    maxDate={new Date()}
                                    onChange={props.setHrUpload ? props.setHrUpload : props.setAppoint !== "" ? props.setAppoint : props.setFreedate !== "" ? props.setFreedate : props.setSadndate ? props.setSadndate : ""}
                                    value={props.hrUpload ? props.hrUpload : props.dateAppoint !== "" ? props.dateAppoint : props.freedate ? props.freedate : props.sadndate ? props.sadndate : ""}
                                />
                            </div>



                        </div>
                    </div>
                    <SecondLabel text="Additional Notes (optional)" />
                    <div className="inputField" style={{ height: '84px' }}>
                        <textarea type="text" className="input" onChange={e => props.setNote(e.target.value)} />
                    </div>
                    <div className="buttonContainer">
                        <div className="saveDoc" onClick={props.handleDocSubmit}>
                            Save Document
                        </div>
                        <div className="cancelDoc float-right" onClick={() => { props.onHide() }}>
                            Cancel
                        </div>
                    </div>
                </main>
            </div>
        </Modal>

    )
}
export default UploadDocumentPopup