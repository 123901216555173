export default {
    GET_ALL_ORDERS: "GET_ALL_ORDERS",
    GET_ALL_ORDERS_SUCCESS: "GET_ALL_ORDERS_SUCCESS",
    GET_ALL_ORDERS_ERROR: "GET_ALL_ORDERS_ERROR",

    GET_SEARCHED_ORDERS: "GET_SEARCHED_ORDERS",
    GET_SEARCHED_ORDERS_SUCCESS: "GET_SEARCHED_ORDERS_SUCCESS",
    GET_SEARCHED_ORDERS_ERROR: "GET_SEARCHED_ORDERS_ERROR",

    GET_RECEIPT: "GET_RECEIPT",
    GET_RECEIPT_SUCCESS: "GET_RECEIPT_SUCCESS",
    GET_RECEIPT_ERROR: "GET_RECEIPT_ERROR"
   
  };
